const Library_mobile_ro = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Echipat cu procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Echipat cu procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Asemenea unui creier, procesorul tău controlează ce face PC-ul. Aceasta înseamnă că videoclipurile pe care le urmărești, jocurile pe care le joci, site-urile web pe care le vizitezi, toate încep cu procesorul tău.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Procesorul este creierul PC-ului tău"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Performanță și valoare.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Silver",
                    "tr_subtitle": "Rămâi în fruntea lumii digitale cu&nbsp;performanța de accesare a&nbsp;fișierelor de pe web. Multitasking simplu prin navigarea facilă între programe și realizarea mai multor lucruri într&#8209;un timp mai scurt.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Gold",
                    "tr_subtitle": "Productivitate îmbunătățită - Performanțe ridicate pentru a trece în forță prin cele mai solicitante zile. Navigare pe web eficientizată - Încarcă rapid paginile cu multă publicitate, pentru mai multă explorare și mai puțină așteptare.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Celeron®",
                    "tr_subtitle": "Performanță pentru a-ți amplifica munca și divertismentul. Bucură-te de filme și videoclipuri de pe web cu detalii uimitoare. Petrece mai mult timp lucrând și mai puțin timp așteptând.",
                    "tr_gen_core_title": "Procesor Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® ",
                    "tr_subtitle": "Proiectat pentru divertisment incredibil, streaming video și productivitate. Bucură-te de filme și videoclipuri de pe web cu detalii uimitoare. Petrece mai mult timp lucrând și mai puțin timp așteptând.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® "
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Performanța de care ai nevoie. Accesibilitatea pe care o dorești.",
                "tr_title": "Procesor Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Valoare într-o categorie proprie",
                    "tr_subtitle": "Procesorul Intel® poate gestiona aproape orice sarcină de care ai nevoie, la un preț accesibil. Navighează cu ușurință de la un software la altul. Sau bucură-te de imagini de înaltă calitate, cu performanță stabilă.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Creează legături mai puternice",
                    "tr_subtitle": "Calitatea video mai vibrantă, claritatea audio mai bogată și conectivitatea la internet de aproape trei ori mai rapidă îți permit să colaborezi cu încredere oriunde ai nevoie.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Diferența este ca între zi și noapte",
                    "tr_subtitle": "De la maratoane de filme de dimineață la retușuri de ultim moment, procesorul Intel® extinde durata de viață a bateriei dispozitivului tău, pentru experiențe cu adevărat captivante și fără întreruperi.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Fiecare mediu este unul de învățare",
                    "tr_subtitle": "Rulează cu ușurință mai multe instrumente de învățare online, din fiecare colț al locuinței tale."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Învață unde dorești",
                    "tr_subtitle": "Termină lecțiile oriunde ai nevoie să o faci, utilizând în același timp diverse instrumente de învățare electronică."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Obține puterea pentru divertisment și productivitate, care se potrivește cu bugetul și stilul tău de viață.",
                "tr_title": "Performanțe Intel pentru orice buget.",
                "tr_gen_core_title": "Procesor Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE&nbsp;PERFORMANȚĂ&nbsp;ȘI&nbsp;PREȚ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Procesor Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigare pe web rapidă și cu multă publicitate",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Comutare ușoară între programe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming ocazional",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și video simplă",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivitate rapidă cu performanțe inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;7&#8209;a"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;6&#8209;a"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;5&#8209;a"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;4&#8209;a"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;3&#8209;a"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;2&#8209;a"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;[cpu_fam]<br />din generația a&nbsp;1&#8209;a"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Recomandat pentru:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Echipat cu procesor Intel® Core™ i3 din generația a 8-a",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a 8-a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesoarele Intel® Core™ din generația a 9-a au fost create să țină pasul cu lumea ta digitală. Datorită acestei combinații de viteză și performanță, vei putea efectua mai multe activități preferate pe PC, fără frustrări.",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i3<br />din generația a 9-a",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a 9-a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performanțele versatile întâlnesc durata amplă de viață a bateriei care să îți ofere autonomie, oriunde.",
                "tr_title": "Performanță pentru întreaga zi",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a&nbsp;10‑a",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE PERFORMANȚĂ AL GENERAȚIEI A 10-A",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filme în 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectivitate rapidă și fiabilă",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și filtrare video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming la 1080p, fără blocări ale cadrelor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Excelent pentru lucrul cu fișiere de mari dimensiuni și programe creative",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Treci rapid de la streaming de filme la crearea de prezentări, datorită performanțelor puternice.",
                "tr_title": "Performanță pentru întreaga zi",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a&nbsp;10‑a"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pentru persoanele aflate mereu în mișcare, un laptop care este creat special pentru tine.",
                "tr_title": "Întâlnește o nouă clasă de laptopuri",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a&nbsp;10‑a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Proiectat special pentru PC-urile portabile, procesorul Intel® Core™ i3 din generația a 11&#8209;a îți permite să faci mai multe ca niciodată, oriunde ai fi.",
                "tr_title": "Obține mai multe cu laptopul tău cel nou",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Când întreaga familie depinde de un PC desktop pentru toate activitățile lor, ai nevoie de puterea și versatilitatea unui procesor Intel® Core™ i3 din generația a 11&#8209;a.",
                "tr_title": "Fii gata pentru orice cu performanțele care depășesc orice limite.",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i5<br />din generația a&nbsp;8&#8209;a",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a 8-a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesoarele Intel® Core™ din generația a 9-a au fost create să țină pasul cu lumea ta digitală. Datorită acestei combinații de viteză și performanță, vei putea efectua mai multe activități preferate pe PC, fără frustrări.",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i5<br />din generația a 9-a",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a 9-a"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "EXPERIENȚĂ DE JOC FĂRĂ CUSUR ȘI VR LA CELE MAI SOLICITANTE TITLURI",
                "tr_title": "Gamingul serios începe de aici",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Navighează facil între multiple programe pentru a fi gata de orice, oriunde mergi.",
                "tr_title": "Multitasking în deplasare",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE PERFORMANȚĂ AL GENERAȚIEI A 10-A",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filme în 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectivitate rapidă și fiabilă",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și filtrare video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming la 1080p, fără blocări ale cadrelor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Excelent pentru lucrul cu fișiere de mari dimensiuni și programe creative",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ridică nivelul la tot ceea ce faci, de la divertisment la productivitate și creație de conținut.",
                "tr_title": "Treci la un PC mai performant",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pentru persoanele aflate mereu în mișcare, un laptop care este creat special pentru tine.",
                "tr_title": "Întâlnește o nouă clasă de laptopuri",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Construit pentru streaming fără cusur și gaming AAA",
                "tr_title": "Joacă-te în stilul tău",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Construit pentru streaming fără cusur și gaming AAA",
                "tr_title": "Joacă-te în stilul tău",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 10<br/>Fire de execuție: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Navighează facil între multiple programe pentru a fi gata de orice, oriunde mergi.",
                "tr_title": "Multitasking în deplasare",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;10‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A&nbsp;10‑A"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i5 din generația a 11&#8209;a oferă experiențe vizuale vibrante și productivitate pe scară largă în cele mai subțiri și ușoare PC-uri.",
                "tr_title": "Performanță impresionantă a laptopului",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Când întreaga familie depinde de un PC desktop pentru toate activitățile lor, ai nevoie de puterea și versatilitatea unui procesor Intel® Core™ i5 din generația a 11&#8209;a.",
                "tr_title": "Fii gata pentru orice cu performanțele care depășesc orice limite.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesorul Intel® Core™ i5 din generația a 11-a oferă experiențe vizuale vibrante și productivitate de mare amploare, pe laptopuri subțiri și ușoare.",
                "tr_title": "Performanță impresionantă a laptopului",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A 11-A"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i7<br />din generația a&nbsp;8&#8209;a",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a 8-a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Procesoarele Intel® Core™ din generația a 9-a au fost create să țină pasul cu lumea ta digitală. Datorită acestei combinații de viteză și performanță, vei putea efectua mai multe activități preferate pe PC, fără frustrări.",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i7<br />din generația a 9-a",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a 9-a"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "CU PÂNĂ LA 32% MAI MULTE FPS în timp ce joci, faci streaming și înregistrezi, față de un PC VECHI DE 3 ANI<sup>3</sup>",
                "tr_title": "Puterea de a-ți împărtăși cele mai bune momente de gaming",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Creat pentru eficiență, acest dispozitiv subțire poate avea un impact major în modul în care creezi, te conectezi și faci streaming.",
                "tr_title": "Performanțe avansate oriunde",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE PERFORMANȚĂ AL GENERAȚIEI A 10-A",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filme în 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectivitate rapidă și fiabilă",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și filtrare video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming la 1080p, fără blocări ale cadrelor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Excelent pentru lucrul cu fișiere de mari dimensiuni și programe creative",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Performanțe avansate, pentru a duce tot ceea ce faci la nivelul următor.",
                "tr_title": "Puterea de a face totul",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pentru persoanele aflate mereu în mișcare, un laptop care este creat special pentru tine.",
                "tr_title": "Întâlnește o nouă clasă de laptopuri",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Joacă-te, realizează streaming și înregistrează cu un avantaj competitiv",
                "tr_title": "Performanță pentru gaming",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Joacă-te, realizează streaming și înregistrează cu un avantaj competitiv",
                "tr_title": "Performanță pentru gaming",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 10<br/>Fire de execuție: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Proiectat pentru eficiență, acest dispozitiv elegant poate avea un impact imens asupra modului în care creezi, te conectezi și faci streaming.",
                "tr_title": "Performanțe premium, oriunde",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A&nbsp;10‑A"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i7 din generația a 11&#8209;a oferă creație de conținut avansată, jocuri fără cusur și divertisment de nivel superior pentru PC-uri ultraportabile.",
                "tr_title": "Performanțe de laptop care depășesc orice limite",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Transformă-ți PC-ul desktop în destinația preferată pentru orice ar avea nevoie căminul tău, cu performanțe care depășesc orice limite, oferite de un procesor Intel® Core™ i7 din generația a 11&#8209;a.",
                "tr_title": "Lucrează. Învață. Joacă-te. Fără limite.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesorul Intel® Core™ i7 din generația a 11-a oferă creație de conținut avansată, gaming fără probleme și divertisment de nivel superior, pe laptopuri subțiri și ușoare.",
                "tr_title": "Performanțe de laptop care depășesc orice limite",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Transformă-ți PC-ul desktop în destinația preferată pentru orice ar avea nevoie căminul tău, cu performanțe care depășesc orice limite, oferite de un procesor Intel® Core™ i7 din generația a 11&#8209;a.",
                "tr_title": "Creează fără limite",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cu creație de conținut avansată, gaming fără probleme și divertisment de nivel superior, pe laptopuri subțiri și ușoare, acest procesor Intel® Core™ i7 din generația a 11-a a devenit și mai bun.",
                "tr_title": "Performanțe de laptop care depășesc orice limite",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i9<br />din generația a&nbsp;8&#8209;a",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a 8-a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesoarele Intel® Core™ din generația a 9-a au fost create să țină pasul cu lumea ta digitală. Datorită acestei combinații de viteză și performanță, vei putea efectua mai multe activități preferate pe PC, fără frustrări.",
                "tr_title": "Echipat cu procesor Intel®&nbsp;Core™&nbsp;i9<br />din generația a 9-a",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a 9-a"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "CU PÂNĂ LA 41% MAI MULTE FPS în timp ce joci, faci streaming și înregistrare față de un PC DE ACUM 3 ANI<sup>4</sup>",
                "tr_title": "Gamerii solicită. Intel® Core™ i9 furnizează.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Depășește-ți potențialul cu experiența de gaming absolută",
                "tr_title": "Concurează la cel mai înalt nivel",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Depășește-ți potențialul cu experiența de gaming absolută",
                "tr_title": "Concurează la cel mai înalt nivel",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 10<br/>Fire de execuție: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Depășește-ți potențialul cu experiența de creație supremă",
                "tr_title": "Creează la cel mai înalt nivel",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A&nbsp;10‑A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptopurile Intel® Evo™ echipate cu un procesor Intel® Core™ i5 din generația a 11-a și grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> îți oferă viteză și performanțe incredibile, totul într&#8209;un model subțire și ușor.",
                "tr_title": "Putere ridicată pentru un laptop evoluat",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptopurile Intel® Evo™ echipate cu un procesor Intel® Core™ i7 din generația a 11-a și grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> îți oferă viteză și performanțe avansate, pentru o experiență mobilă premium.",
                "tr_title": "Putere avansată pentru un laptop evoluat",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bucură-te de un echilibru excelent între performanțe, experiențele media și de conectivitate, la un preț uimitor.",
                "tr_title": "Performanțe și conectivitate la un preț uimitor.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE&nbsp;PERFORMANȚĂ&nbsp;ȘI&nbsp;PREȚ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigare pe web rapidă și cu multă publicitate",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Comutare ușoară între programe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming ocazional",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și video simplă",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivitate rapidă cu performanțe inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bucură-te de multitasking fără cusur, editare foto și video simplă, videoclipuri 4K și imagini vii, totul la un preț incredibil.",
                "tr_title": "Performanțe impresionante pentru a lucra și a te juca.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "GĂSEȘTE NIVELUL POTRIVIT DE&nbsp;PERFORMANȚĂ&nbsp;ȘI&nbsp;PREȚ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigare pe web rapidă și cu multă publicitate",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Comutare ușoară între programe",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming ocazional",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Editare foto și video simplă",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivitate rapidă cu performanțe inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA și durata mare de viață a bateriei într&#8209;un PC ultraportabil. Nu este nevoie să faci un compromis în ceea ce privește modul în care joci.",
                "tr_title": "Nu este nevoie să faci compromis.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL TĂU DE GAMING ULTRAPORTABIL INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i5&#8209;11300H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11370H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11375H Special Edition din&nbsp;generația a&nbsp;11&#8209;a"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td2_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td3_title": "4 nuclee/ 8&nbsp;fire de execuție"
                    },
                    {
                      "tr_td1_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tehnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA la 1080p, cu multe FPS pe setări înalte și performanțe excelente când ești deconectat de la priză, totul într&#8209;un PC ultraportabil.",
                "tr_title": "Continuă. Ai totul.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL TĂU DE GAMING ULTRAPORTABIL INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i5&#8209;11300H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11370H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11375H Special Edition din&nbsp;generația a&nbsp;11&#8209;a"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td2_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td3_title": "4 nuclee/ 8&nbsp;fire de execuție"
                    },
                    {
                      "tr_td1_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tehnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Frecvență maximă de până la 5,0 GHz, pentru a-ți duce jocurile mai departe, și un PC ultraportabil, pentru a le lua cu tine oriunde ai merge.",
                "tr_title": "Mai multă putere pentru fiecare kg.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition din generația a&nbsp;11&#8209;a",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL TĂU DE GAMING ULTRAPORTABIL INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i5&#8209;11300H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11370H din&nbsp;generația a 11&#8209;a"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™ i7&#8209;11375H Special Edition din&nbsp;generația a&nbsp;11&#8209;a"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Frecvență maximă de&nbsp;până la&nbsp;<span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td2_title": "4 nuclee/ 8&nbsp;fire de execuție",
                      "tr_td3_title": "4 nuclee/ 8&nbsp;fire de execuție"
                    },
                    {
                      "tr_td1_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tehnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tehnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Intel® Smart Cache de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesorul Intel® Core™ i7 Special Edition din generația a 11-a aduce creația avansată de conținut, gamingul fără cusur și divertismentul de nivel următor pe PC-urile ultraportabile.",
                "tr_title": "Performanțe de laptop care depășesc orice limite",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition din generația a&nbsp;11&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION DIN GENERAȚIA A 11-A"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesorul Intel® Core™ i7 Special Edition din generația a 11-a aduce creația avansată de conținut, gamingul fără cusur și divertismentul de nivel următor pe PC-urile ultraportabile.",
                "tr_title": "Performanțe de laptop care depășesc orice limite",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition din generația a&nbsp;11&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Echilibrul potrivit de frecvență și performanțele nucleelor scoate ce-i mai bun din jocurile tale, indiferent dacă este vorba de gaming competitiv cu multe FPS sau experiențe captivante de AAA.",
                "tr_title": "Performanță cu scop",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Joacă, înregistrează și realizează streaming pe titlurile solicitante, cu multe FPS-uri, și treci fără efort la sarcini de multitasking grele.",
                "tr_title": "Puterea de a împinge limitele",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Treci rapid de la streaming de filme la crearea de prezentări, datorită performanțelor puternice.",
                "tr_title": "Performanță pentru întreaga zi",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 cu tehnologia Intel® Hybrid",
                "tr_subTitle2": "Procesor Intel® Core™ i3 cu tehnologia Intel® Hybrid"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performanțele versatile întâlnesc durata amplă de viață a bateriei care să îți ofere autonomie, oriunde.",
                "tr_title": "Performanță pentru întreaga zi",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 cu tehnologia Intel® Hybrid",
                "tr_subTitle2": "Procesor Intel® Core™ i3 cu tehnologia Intel® Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Navighează facil între multiple programe pentru a fi gata de orice, oriunde mergi.",
                "tr_title": "Multitasking în deplasare",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 cu tehnologia Intel® Hybrid",
                "tr_subTitle2": "Procesor Intel® Core™ i5 cu tehnologia Intel® Hybrid"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ridică nivelul la tot ceea ce faci, de la divertisment la productivitate și creație de conținut.",
                "tr_title": "Treci la un PC mai performant",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 cu tehnologia Intel® Hybrid",
                "tr_subTitle2": "Procesor Intel® Core™ i5 cu tehnologia Intel® Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Performanțele de gaming de clasă desktop se întâlnesc cu mobilitatea, indiferent dacă este pentru joc competitiv cu FPS ridicat sau pentru experiențe AAA captivante.",
                "tr_title": "Performanță cu scop",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Performanțele de gaming de clasă desktop se întâlnesc cu mobilitatea, indiferent dacă este pentru joc competitiv cu FPS ridicat sau pentru experiențe AAA captivante.",
                "tr_title": "Performanță cu scop",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un laptop care poate reda, înregistra și efectua streaming simultan, cu FPS ridicat și care poate trece fără efort la sarcini de multitasking solicitante.",
                "tr_title": "Puterea de a împinge limitele",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un laptop care poate reda, înregistra și efectua streaming simultan, cu FPS ridicat și care poate trece fără efort la sarcini de multitasking solicitante.",
                "tr_title": "Puterea de a împinge limitele",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă, indiferent unde pleci cu PC-ul.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă, indiferent unde pleci cu PC-ul.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide, numărul mare de nuclee și performanțele deblocate funcționează fără cusur împreună, pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă, indiferent unde pleci cu PC-ul.",
                "tr_title": "Performanță deblocată. Perfect&nbsp;echilibrată.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide, numărul mare de nuclee și performanțele deblocate funcționează fără cusur împreună, pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă, indiferent unde pleci cu PC-ul.",
                "tr_title": "Performanță deblocată. Perfect&nbsp;echilibrată.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună, pentru a obține mai mult din creația de conținut complexă, oriunde îți iei PC-ul.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună, pentru a obține mai mult din creația de conținut complexă.",
                "tr_title": "Putere imensă. Perfect echilibrat.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Depășește-ți potențialul cu experiența de gaming absolută",
                "tr_title": "Concurează la cel mai înalt nivel",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Depășește-ți potențialul cu experiența de gaming absolută",
                "tr_title": "Concurează la cel mai înalt nivel",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ DIN GENERAȚIA A 10-A POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA fără cusur",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming și înregistrare",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creație de conținut avansată",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performanțe de vârf pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Deblocat și overclockabil<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 10<br/>Fire de execuție: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pentru persoanele aflate mereu în mișcare, un laptop care este creat special pentru tine.",
                "tr_title": "Întâlnește o nouă clasă de laptopuri",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;10‑a"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună pentru a obține mai multe din gamingul competitiv și din creația de conținut complexă.",
                "tr_title": "Performanță deblocată. Perfect&nbsp;echilibrată.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide, numărul mare de nuclee și performanțele deblocate funcționează fără cusur împreună, pentru a obține mai mult din creația de conținut complexă, oriunde îți iei PC-ul.",
                "tr_title": "Performanță deblocată. Perfect&nbsp;echilibrată.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Frecvențele rapide și numărul mare de nuclee funcționează fără cusur împreună, pentru a obține mai mult din creația de conținut complexă.",
                "tr_title": "Performanță deblocată. Perfect&nbsp;echilibrată.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a&nbsp;11‑a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 DIN GENERAȚIA A 11-A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/>de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Experiență de joc și VR fără cusur pe cele mai solicitante titluri de jocuri",
                "tr_title": "Gamingul serios începe de aici",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "CU PÂNĂ LA 32% MAI MULTE FPS <br/><span class='tr_subtitle_font'>în timpul gamingului, streamingului și înregistrării vs. un PC vechi de 3 ani<sup>3</sup></span>",
                "tr_title": "Puterea de a-ți împărtăși cele mai bune momente de gaming",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "CU PÂNĂ LA 41% MAI MULTE FPS <br/><span class='tr_subtitle_font'>în timpul gamingului, streamingului și înregistrării vs. un PC vechi de 3 ani<sup>4</sup></span>",
                "tr_title": "Gamerii solicită. Intel® Core™ i9 furnizează.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 din generația a 9-a",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Fire <br/>de execuție</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Nuclee</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Până la <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Deblocat",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "GĂSEȘTE PROCESORUL INTEL® CORE™ POTRIVIT MODULUI TĂU DE JOC.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitiv",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming și înregistrare fără compromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Cel mai bun procesor Intel® Core™ din generația a 9-a pentru gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 4<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 6<br/>Fire de execuție: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frecvență<sup>2</sup>: Până la 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Nuclee: 8<br/>Fire de execuție: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Construit pentru următoarea generație de gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;12&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;12&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Stația de luptă face cunoștință cu stația de lucru",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;12&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanțe superioare unde ai nevoie cel mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i3 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "O creștere a performanțelor unde este nevoie cel mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "O creștere a performanțelor unde este nevoie cel mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanțe superioare unde ai nevoie cel mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanțe superioare unde ai nevoie cel mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cu laptopurile Intel® Evo™, obții performanță, inteligență încorporată pentru chat-uri video, internet mai rapid, durată mare de viață a bateriei și multe altele.",
                "tr_title": "Un impuls de performanțe acolo unde ai nevoie cel mai mult.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cu laptopurile Intel® Evo™, obții performanță, inteligență încorporată pentru chat-uri video, internet mai rapid, durată mare de viață a bateriei și multe altele.",
                "tr_title": "Performanțe superioare unde ai nevoie cel mai mult",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cu laptopurile Intel® Evo™, obții performanță, inteligență încorporată pentru chat-uri video, internet mai rapid, durată mare de viață a bateriei și multe altele.",
                "tr_title": "Performanță extremă într&#8209;un laptop premium",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Cu tehnologia Intel® Turbo Boost 2.0 până la [max_turbo_frequency] GHz",
                "tr_title": "Construit pentru afaceri.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obținerea unei securități încorporate, bazate pe hardware",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferirea unor capacități de gestionare a dispozitivului de la distanță",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizarea automată a performanțelor Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Alimentarea prin aplicații bazate pe date",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nivelul cel mai înalt de productivitate cu performanțe îmbunătățite",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Cu tehnologia Intel® Turbo Boost 2.0 până la [max_turbo_frequency] GHz",
                "tr_title": "Funcționează din greu, pentru ca tu să te poți concentra asupra afacerii tale.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obținerea unei securități încorporate, bazate pe hardware",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferirea unor capacități de gestionare a dispozitivului de la distanță",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizarea automată a performanțelor Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Alimentarea prin aplicații bazate pe date",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nivelul cel mai înalt de productivitate cu performanțe îmbunătățite",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Cu tehnologia Intel® Turbo Boost 2.0 până la [max_turbo_frequency] GHz",
                "tr_title": "Accelerează-ți afacerea",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;12&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;12&#8209;A",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obținerea unei securități încorporate, bazate pe hardware",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferirea unor capacități de gestionare a dispozitivului de la distanță",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizarea automată a performanțelor Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Alimentarea prin aplicații bazate pe date",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nivelul cel mai înalt de productivitate cu performanțe îmbunătățite",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță fiabilă dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 din&nbsp;generația a&nbsp;13&#8209;a",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță reală, dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță reală, dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță puternică dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță puternică dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Dincolo de performanță",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conectează-te fără probleme la telefonul actual<sup>24</sup>, selectează în mod inteligent cel mai bun Wi-Fi și încarcă rapid cu o baterie care durează mult.",
                "tr_title": "Mergi dincolo de performanță pentru a face mai mult oriunde",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conectează-te fără probleme la telefonul actual<sup>24</sup>, selectează în mod inteligent cel mai bun Wi-Fi și încarcă rapid cu o baterie care durează mult.",
                "tr_title": "Mergi dincolo de performanță pentru a-i permite PC-ului tău să facă și mai multe deodată",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conectează-te fără probleme la telefonul actual<sup>24</sup>, selectează în mod inteligent cel mai bun Wi-Fi și încarcă rapid cu o baterie care durează mult.",
                "tr_title": "Mergi dincolo de performanță pentru a accelera îndeplinirea celor mai exigente sarcini",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Dincolo de performanță",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;13&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;13&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;13&#8209;a",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Dincolo de performanță",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță fiabilă dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță reală, dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță puternică dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Dincolo de performanță",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță fiabilă dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță reală, dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Performanță puternică dedicată pentru a face mai mult",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Nuclee</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Fire de execuție</span></span>",
                    "tr_badgePreTitle": "[p_thread] pe Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] pe Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] pe LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Joacă-te fără compromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Afacerile mici merită soluții de&nbsp;afaceri&nbsp;mari.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i5 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obținerea de protecții de securitate predefinită, bazată pe hardware",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protejarea dispozitivelor și a datelor împotriva atacurilor fizice de alterare",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Afacerile mici merită soluții de&nbsp;afaceri&nbsp;mari.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i7 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obținerea de protecții de securitate predefinită, bazată pe hardware",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protejarea dispozitivelor și a datelor împotriva atacurilor fizice de alterare",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Afacerile mici merită soluții de&nbsp;afaceri&nbsp;mari.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 din&nbsp;generația a&nbsp;13&#8209;a",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;i9 DIN&nbsp;GENERAȚIA&nbsp;A&nbsp;13&#8209;A",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEST PROCESOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispozitiv actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimarea zgomotului și estomparea fundalului",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizare automată a performanțelor, pentru a-ți prioritiza activitățile frecvente",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experimentarea de apeluri web fluide, de înaltă calitate",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obținerea de protecții de securitate predefinită, bazată pe hardware",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protejarea dispozitivelor și a datelor împotriva atacurilor fizice de alterare",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Activează noi experiențe IA pentru afaceri",
                "tr_title": "Fii pregătit pentru ce urmează utilizând procesoare Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] și Intel vPro®",
                "tr_gen_core_title": "Procesor Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "Îmbunătățește-ți afacerea cu PC-uri cu IA de la Intel",
                    "tr_badgeSubTitle": "PC-uri puternice, care creează o experiență excelentă, stau la baza productivității afacerii. Intel vPro® stimulează eficiența angajaților și performanța în toate sarcinile și aplicațiile, datorită unei noi arhitecturi care oferă performanțe superioare, eficiente din punct de vedere energetic, pentru sarcini de lucru de afaceri variate și complexe, prin trimiterea sarcinii corecte la motorul potrivit, la momentul potrivit.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "CARACTERISTICI DE SECURITATE PE MAI MULTE STRATURI ACTIVATE CHIAR DE LA ÎNCEPUT",
                    "tr_badgeSubTitle": "Amenințările noi și emergente necesită o abordare amplă a securității. Intel vPro® oferă protecții la nivel de hardware și pentru tot pachetul, pentru a-ți proteja mai bine afacerea. Aceste măsuri de securitate încorporate unice, inclusiv capacitățile de detectare a amenințărilor pe platformă de IA, ajută la protejarea utilizatorilor, datelor și afacerii tale.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INSTRUMENTE ÎNCORPORATE, PENTRU A MENȚINE UTILIZATORII PRODUCTIVI PRACTIC ORIUNDE",
                    "tr_badgeSubTitle": "Pretutindeni sunt birouri, deci și PC-uri. Intel vPro® oferă capacități moderne de gestionare bazate pe hardware, pentru a ajuta întreprinderile să ușureze povara gestionării flotei de PC-uri, să simplifice asistența și să îmbunătățească experiențele utilizatorilor finali, permițând în același timp practici de calcul sustenabile.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mai mult decât un PC grozav, un PC IA",
                "tr_subtitle1": "ECHIPAT CU PROCESOARE INTEL®&nbsp;CORE™ ULTRA (SERIA&nbsp;2)",
                "tr_subtitle2": "Este posibil pe PC-ul tău Intel IA",
                "tr_subtitle": "Cel mai eficient, sigur și performant procesor mobil de la Intel<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Seria&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mai creativ</span> cu instrumente IA pentru a crea mai rapid"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mai productiv</span> cu asistență IA pentru economisirea timpului"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mai sigur</span> cu protecție a datelor integrată"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mai mult decât un PC grozav, un PC IA",
                "tr_subtitle1": "ECHIPAT CU PROCESOARE INTEL®&nbsp;CORE™ ULTRA (SERIA&nbsp;2)",
                "tr_subtitle2": "Este posibil pe PC-ul tău Intel IA",
                "tr_subtitle": "Cel mai eficient, sigur și performant procesor mobil de la Intel<sup>39</sup>",
                "tr_gen_core_title": "Procesorul Intel®&nbsp;Core™ Ultra&nbsp;7 (Seria&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mai creativ</span> cu instrumente IA pentru a crea mai rapid"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mai productiv</span> cu asistență IA pentru economisirea timpului"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mai sigur</span> cu protecție a datelor integrată"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mai mult decât un PC grozav, un PC IA",
                "tr_subtitle1": "ECHIPAT CU PROCESOARE INTEL®&nbsp;CORE™ ULTRA (SERIA&nbsp;2)",
                "tr_subtitle2": "Este posibil pe PC-ul tău Intel IA",
                "tr_subtitle": "Cel mai eficient, sigur și performant procesor mobil de la Intel<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Seria&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mai mult decât un PC grozav, un PC IA",
                "tr_subtitle1": "ECHIPAT CU PROCESOARE INTEL®&nbsp;CORE™ ULTRA (SERIA&nbsp;2)",
                "tr_subtitle2": "Este posibil pe PC-ul tău Intel IA",
                "tr_subtitle": "Cel mai eficient, sigur și performant procesor mobil de la Intel<sup>39</sup>",
                "tr_gen_core_title": "Procesorul Intel®&nbsp;Core™ Ultra&nbsp;7 (Seria&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_tileTitle1": "Grafică dedicată",
                "tr_title": "CE ESTE O UNITATE DE PROCESARE GRAFICĂ?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Unitatea de procesare grafică sau GPU creează imaginile pe care le vezi pe ecran.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrat",
                    "tr_badgeSubTitle": "Un GPU integrat utilizează aceeași memorie RAM ca și restul computerului, făcând ca acesta să fie mai eficient din punct de vedere energetic.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicat",
                    "tr_badgeSubTitle": "Aceasta este cea mai performantă clasă a unui GPU, deoarece deține propria memorie RAM și alte resurse care sunt dedicate pentru a crea o grafică excepțională.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU hibrid",
                    "tr_badgeSubTitle": "Aceasta este cea mai performantă clasă a unui GPU, deoarece deține propria memorie RAM și alte resurse care sunt dedicate pentru a crea o grafică excepțională.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Editare foto și video îmbunătățită",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Vizionează maraton conținut video 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Rulează jocurile populare, în detalii uimitoare",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "GPU dual",
                    "tr_badgeSubTitle": "Unele sisteme, precum cele cu grafică Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, pot beneficia atât de procesorul grafic integrat cât și de placa video dedicată, pentru a distribui sarcina grafică de lucru.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrat",
                    "tr_badgeSubTitle": "Un GPU integrat este inclus în același cip ca și procesorul principal sau CPU. Acest tip de GPU utilizează aceeași memorie RAM și alte resurse ca și restul computerului, ceea ce-l face mai eficient din punct de vedere energetic și permite fabricarea de dispozitive mai subțiri și mai ușoare.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicat",
                    "tr_badgeSubTitle": "Aceasta este cea mai performantă clasă a unui GPU, deoarece deține propria memorie RAM și alte resurse care sunt dedicate pentru a crea o grafică excepțională.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Până la 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Competitiv",
                    "tr_badgeSubTitle": "Până la 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Mai mult de 8 GB RAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Putere grafică intensă",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică care se poate axa exclusiv pe crearea de imagini clare, frumoase și animații fluide.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Grafică bogată și vibrantă integrată",
                "tr_pretitle": "Intel® Iris® Plus Graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafica Intel® Iris® Plus îți permite să te lași captivat de experiența video HDR 4K și de jocurile full HD, fără să mai fie nevoie de plăci grafice suplimentare.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Elemente vizuale uluitoare, performanță fără efort",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafica Intel® oferă performanță de grafică integrată transformatoare, pentru a accepta design fluid pentru creatori și experiențe de joc captivante.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Grafică Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Grafică UHD Intel® echipată cu arhitectură X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Experiențe vizuale captivante",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Experimentează editarea foto și video eficientă, urmărește conținut video premium 4K sau joacă-te jocuri populare cu detalii uimitoare.",
                "tr_photo_text": "Editare foto și video îmbunătățită",
                "tr_video_text": "Vizionează maraton conținut video 4K",
                "tr_game_text": "Rulează jocurile populare, în detalii uimitoare",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MAI MULT DECÂT FOTOGRAFII FRUMOASE",
                "tr_subtitle": "Atunci când este ajutată de procesor (sau CPU), placa video (sau GPU) poate stabili modul în care rulează și sunt afișate jocurile tale. VRAM, sau video RAM, dedicat doar pentru grafică este doar un mod rapid de a vedea unde se situează GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PENTRU GAMING CU GRAFICĂ SOLICITANTĂ",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică separată care se poate axa exclusiv pe crearea de imagini clare și frumoase și de animații fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PENTRU GAMING CU GRAFICĂ SOLICITANTĂ",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică separată care se poate axa exclusiv pe crearea de imagini clare și frumoase și de animații fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MAI MULT DECÂT FOTOGRAFII FRUMOASE",
                "tr_subtitle": "Atunci când este ajutată de procesor (sau CPU), placa video (sau GPU) poate stabili modul în care rulează și sunt afișate jocurile tale. VRAM, sau video RAM, dedicat doar pentru grafică este doar un mod rapid de a vedea unde se situează GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Putere grafică intensă",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică care se poate axa exclusiv pe crearea de imagini clare, frumoase și animații fluide.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PENTRU GAMING CU GRAFICĂ SOLICITANTĂ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică care se poate axa exclusiv pe crearea de imagini clare, frumoase și animații fluide.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică separată care se poate axa exclusiv pe crearea de imagini clare și frumoase și de animații fluide.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Oferă multă putere grafică pentru a îndeplini cerințele minime ale jocului.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Pentru jucătorii de top care au nevoie de gaming de nivel competițional",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Pentru gamerii avansați care își folosesc PC-urile și pentru creație de conținut performantă",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Video vibrant și gaming atractiv",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Beneficiază la maximum de ecranul de înaltă rezoluție cu Grafica HD Intel® și bucură&#8209;te de o experiență multimedia bogată și de procesare grafică încorporată.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Alimentare de imagini și videoclipuri vibrante",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obține mai multe detalii și culori strălucitoare de la videoclipurile și fotografiile tale în streaming.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Creație și gaming uimitoare",
                "tr_pretitle": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De la divertismentul imersiv și creația de conținut avansată, până la gamingul obișnuit, grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> oferă performanțe grafice revoluționare în acest PC.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_pretitle": "PENTRU GAMING CU GRAFICĂ SOLICITANTĂ",
                "tr_title": "Grafică Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică separată care se poate axa exclusiv pe crearea de imagini clare și frumoase și de animații fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[x] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Dezlănțuie-ți creativitatea",
                "tr_pretitle": "Grafică Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Navighează prin proiecte creative și cufundă-te într-o gamă largă de jocuri cu puterea combinată a graficii de pe procesor și a primului motor grafic Intel® dedicat.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "tr_title": "Grafică Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PENTRU GAMING CU GRAFICĂ SOLICITANTĂ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Cea mai performantă procesare grafică necesită o placă grafică separată care se poate axa exclusiv pe crearea de imagini clare și frumoase și de animații fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM de<br/></span><span class='value'>[x] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafică",
                "tr_title": "Grafică frumoasă încorporată",
                "tr_subtitle": "Bucură-te de jocuri, filme și materiale video web cu detalii și claritate uluitoare.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafică",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Îmbunătățește-ți fluxurile de lucru"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Supraîncarcă-ți gamingul"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Supraîncarcă-ți gamingul"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Ridică-ți nivelul de joc"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "DEZLĂNȚUIE-ȚI CREATIVITATEA",
                    "tr_specText": "Accelerează-ți productivitatea oriunde mergi cu cele mai cuprinzătoare capabilități media, precum <strong>prima codificare hardware AV1 din industrie</strong> și creația de conținut accelerat de IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "DEZLĂNȚUIE-ȚI CREATIVITATEA",
                    "tr_specText": "Accelerează-ți productivitatea cu cele mai cuprinzătoare capabilități media, precum <strong>prima codificare hardware AV1 din industrie</strong> și creația de conținut accelerat de IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "DEZLĂNȚUIE-ȚI CREATIVITATEA",
                    "tr_specText": "Experimentează crearea de conținut de ultimă oră, accelerată de IA cu <strong>prima codificare hardware AV1 din industrie</strong> și <strong>motorul media avansat X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DEZLĂNȚUIE-ȚI CREATIVITATEA",
                    "tr_specText": "Experimentează crearea de conținut de ultimă oră, accelerată de IA cu <strong>prima codificare hardware AV1 din industrie</strong> și <strong>motorul media avansat X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DEZLĂNȚUIE-ȚI CREATIVITATEA",
                    "tr_specText": "Experimentează crearea de conținut de ultimă oră, accelerată de IA cu <strong>prima codificare hardware AV1 din industrie</strong> și <strong>motorul media avansat X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PORTABILITATEA ÎNTÂLNEȘTE PERFORMANȚA",
                    "tr_specText": "Joacă cele mai recente jocuri în mișcare și obține performanță și fidelitate superioare cu ajutorul capabilităților moderne de joc. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® de upscaling îmbunătățită cu IA pentru o performanță mai mare și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "PREIA CONTROLUL ASUPRA JOCURILOR TALE",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> îți permite să îți perfecționezi experiența cu un aspect intuitiv și modern. Obține actualizări de drivere fără întreruperi, optimizează-ți jocurile și multe altele."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "PREIA CONTROLUL ASUPRA JOCURILOR TALE",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> îți permite să îți perfecționezi experiența cu un aspect intuitiv și modern. Obține actualizări de drivere fără întreruperi, optimizează-ți jocurile și multe altele."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "PREIA CONTROLUL ASUPRA JOCURILOR TALE",
                    "tr_specText": "Obține actualizări de drivere fără întreruperi și optimizează-ți multe dintre jocurile preferate cu software-ul <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMIZEAZĂ-ȚI PLATFORMA INTEL®",
                    "tr_specText": "Asociază procesoarele Intel® Core™ compatibile pentru a dezlănțui <strong>tehnologia Intel® Deep Link</strong> pentru a-ți îmbunătăți experiența de creație, gaming și streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMIZEAZĂ-ȚI SISTEMUL INTEL®",
                    "tr_specText": "Asociază procesoarele Intel® Core™ compatibile pentru a dezlănțui <strong>tehnologia Intel® Deep Link</strong> pentru a-ți îmbunătăți experiența de creație, gaming și streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMIZEAZĂ-ȚI PLATFORMA INTEL®",
                    "tr_specText": "Asociază procesoarele Intel® Core™ compatibile pentru a dezlănțui <strong>tehnologia Intel® Deep Link</strong> pentru a-ți îmbunătăți experiența de creație, gaming și streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZEAZĂ-ȚI PLATFORMA INTEL®",
                    "tr_specText": "Asociază procesoarele Intel® Core™ compatibile pentru a dezlănțui <strong>tehnologia Intel® Deep Link</strong> pentru a-ți îmbunătăți experiența de creație, gaming și streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZEAZĂ-ȚI SISTEMUL INTEL®",
                    "tr_specText": "Asociază procesoarele Intel® Core™ compatibile pentru a dezlănțui <strong>tehnologia Intel® Deep Link</strong> pentru a-ți îmbunătăți experiența de creație, gaming și streaming."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "FII ÎN CENTRUL ATENȚIEI",
                    "tr_specText": "Difuzează fără întreruperi cu ajutorul tehnologiilor avansate de streaming activate de software-ul <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "FII ÎN CENTRUL ATENȚIEI",
                    "tr_specText": "Difuzează fără întreruperi cu ajutorul tehnologiilor avansate de streaming activate de software-ul <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTEAZĂ GAMING IMERSIV DE MARE PERFORMANȚĂ",
                    "tr_specText": "Pune cele mai bune performanțe pe primul loc cu cele mai recente jocuri AAA la rezoluții înalte și rate ridicate de reîmprospătare. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® de upscaling îmbunătățită cu IA pentru o performanță mai mare și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "EXPERIMENTEAZĂ GAMING IMERSIV DE MARE PERFORMANȚĂ",
                    "tr_specText": "Pune cele mai bune performanțe pe primul loc cu cele mai recente jocuri AAA la rezoluții înalte și rate ridicate de reîmprospătare. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® de upscaling îmbunătățită cu IA pentru o performanță mai mare și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DĂ UN IMPULS JOCURILOR TALE",
                    "tr_specText": "Du-ți experiența de gaming la nivelul următor cu: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® îmbunătățită cu IA pentru mai multă performanță și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "EXPERIMENTEAZĂ GAMING AVANSAT ÎNTR-UN RITM ALERT",
                    "tr_specText": "Bucură-te de gaming fluid pe multe jocuri AAA populare și de un gaming cu o rată mare de reîmprospătare de la shootere e-sports de mare impact la hituri RTS cu un ritm rapid. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® de upscaling îmbunătățită cu IA pentru o performanță mai mare și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTEAZĂ GAMING IMERSIV DE MARE PERFORMANȚĂ",
                    "tr_specText": "Pune cele mai bune performanțe pe primul loc cu cele mai recente jocuri AAA la rezoluții înalte și rate ridicate de reîmprospătare. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tehnologia Intel® de upscaling îmbunătățită cu IA pentru o performanță mai mare și un gaming fluid. <br/><br/>Suport pentru <strong>DirectX* 12 Ultimate:  </strong>Du-ți gamingul la un nou nivel de realism cu ajutorul tehnologiilor grafice, precum urmărirea razelor și rata variabilă a umbrelor."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Un univers grafic cu spațiu pentru toată lumea"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACCELEREAZĂ-ȚI CADRELE, ACCELEREAZĂ-ȚI JOCURILE",
                    "tr_specText": "Tehnologia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) îți duce experiența de gaming la următorul nivel, cu o creștere în&nbsp;gamă îmbunătățită de IA, pentru performanțe maxime, cu&nbsp;fidelitate ridicată a imaginii. X<sup class='small_text_sup_xe'>e</sup>SS este optimizat pentru&nbsp;produse cu&nbsp;grafică&nbsp;Intel® Arc™, astfel încât să poți profita din plin de accelerarea hardware Intel®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;Matrix&nbsp;Extensions&nbsp;(Intel®&nbsp;XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "SUNT DISPONIBILE ELEMENTE VIZUALE SUPREME",
                    "tr_specText": "Cu suport complet pentru DirectX* 12 Ultimate pe toate graficile Intel® Arc™, te vei bucura de o experiență de joc captivantă și uluitoare, datorită tehnologiilor grafice de ultimă generație precum urmărirea razelor, umbrirea cu rată variabilă, umbrirea de tip mesh și feedback de la dispozitivul de eșantionare, care sunt elemente fundamentale ale următoarei generații de gaming."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CREAȚIE FOTO, VIDEO ȘI MEDIA PERFECTĂ",
                    "tr_specText": "Dă frâu liber imaginației tale și captivează publicul cu creație de conținut digital bogat, utilizând un motor media extrem de avansat, amplificat IA și accelerat cu tehnologia Intel®. Creează conținut spectaculos, alimentat de grafică cu suport pentru toate formatele media de top actuale, rămânând în același timp la curent cu cele mai avansate capacități de codificare video AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "EXPERIMENTEAZĂ CREAȚIA CU IA ȘI GAMING REVOLUȚIONARE",
                    "tr_specText": "Grafica Intel® Arc™ activează capacități IA puternice, de la creare avansată la gaming captivant. Profită la maxim de software-ul avansat de creație de astăzi, inclusiv de generare text în imagine și de editare video accelerată de IA. În plus, bucură-te de experiență de joc de înaltă rezoluție prin îmbunătățire X<sup class='small_text_sup_xe'>e</sup>SS accelerată de IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafică",
                    "tr_specText": "Grafica profesională începe aici"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "EXCELENTĂ PENTRU CREATORI, DESIGNERI ȘI INGINERI ÎN DEPLASARE",
                    "tr_specText": "Disponibilă pe anumite procesoare Intel® Core™ Ultra seria H, grafica Intel® Arc™ Pro integrată activează îmbunătățiri duble ale performanței grafice cu certificări de Producător independent de software (ISV) pentru fiabilitate suplimentară, și oferă accelerare hardware IA și de urmărire a razelor eficientă pentru creație de conținut uimitoare în deplasare.<br/><br/><span class='expandable_heading'>Certificări software<br/></span><br/>Intel a colaborat strâns cu sute de companii software de-a lungul anilor, iar această experiență de neegalat este prezentă în plăcile noastre grafice Intel® Arc™ Pro seria A. Certificările sunt la fel de importante pentru noi precum sunt pentru tine."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "IA DEVINE ACCESIBIL",
                    "tr_specText": "Gama de GPU-uri este formată dintr-o amplă selecție de tehnologii excelente, inclusiv suport pentru instrumentele tale IA compatibile cu accelerare IA dedicată încorporată, acolo unde ai nevoie de ea."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "URMĂRIREA RAZELOR FĂRĂ PREMIUM",
                    "tr_specText": "Plăcile grafice trebuie să facă mai mult decât simpla afișare a pixelilor pe monitoare, acestea trebuie să accelereze crearea de imagini frumoase. Tehnologia hardware de urmărire a razelor construită în grafica Intel® Arc™ Pro seria A permite software-ului tău compatibil să înceapă explorarea acestor noi fluxuri de lucru profesionale. Urmărirea razelor este considerată de obicei ca fiind disponibilă doar pe grafică costisitoare, însă cea mai recentă gamă de grafică profesională de la Intel elimină această așteptare."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "ÎMPUTERNICIREA CREATORILOR CU AFIȘAJE CAPTIVANTE",
                    "tr_specText": "Cu suport complet pentru DirectX* 12 Ultimate pe toate graficile Intel® Arc™, te vei bucura de o experiență de joc captivantă și uluitoare, datorită tehnologiilor grafice de ultimă generație precum urmărirea razelor, umbrirea cu rată variabilă, umbrirea de tip mesh și feedback de la dispozitivul de eșantionare, care sunt elemente fundamentale ale următoarei generații de gaming."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "IMAGINI OPTIMIZATE DINAMIC",
                    "tr_specText": "Cu decodare hardware dedicată pe GPU-urile Pro, Dolby Vision* ajută la adăugarea unei profunzimi mai mari, un contrast mai puternic și multe culori la afișajul tău compatibil. Dolby Vision* valorifică întregul potențial al tehnologiei HDR prin optimizarea dinamică a calității imaginilor pe baza serviciului tău, a dispozitivului de afișare și a platformei, pentru a oferi imagini uimitoare de fiecare dată."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SOFTWARE VERIFICAT ÎN MOD PROFESIONIST",
                    "tr_specText": "Intel a colaborat strâns cu sute de companii software de-a lungul anilor, iar această experiență de neegalat este prezentă în gama de grafică Intel® Arc™ Pro seria A profesională. Certificările și calificările de flux de lucru software sunt la fel de importante pentru noi precum sunt pentru tine."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "URMĂRIREA RAZELOR FĂRĂ PREMIUM",
                    "tr_specText": "Plăcile grafice trebuie să facă mai mult decât simpla afișare a pixelilor pe monitoare, acestea trebuie să accelereze crearea de imagini frumoase. Tehnologia hardware de urmărire a razelor construită în grafica Intel® Arc™ Pro seria A permite software-ului tău compatibil să înceapă explorarea acestor noi fluxuri de lucru profesionale. Urmărirea razelor este considerată de obicei ca fiind disponibilă doar pe grafică costisitoare, însă cea mai recentă gamă de grafică profesională de la Intel elimină această așteptare."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "MAI MULTE ECRANE MARI CU UN SINGUR GPU MOBIL DE MICI DIMENSIUNI",
                    "tr_specText": "Grafica Intel® Arc™ Pro pentru laptopuri poate accepta până la patru ecrane ultra-mari, pentru a îmbunătăți eficiența fluxurilor tale de lucru. Adaugă și suport pentru intervalul dinamic ridicat (HDR) și obții tot ce ai nevoie pentru configurarea cu mai multe monitoare de nivel următor pentru birou și acasă, demnă de rețelele sociale."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENȚIAL DE LĂȚIME DE BANDĂ CU MEMORIE COMPLETĂ",
                    "tr_specText": "Lățimea de bandă a plăcii grafice este un element important într-o stație de lucru. Alege greșit și poți încetini fluxurile de lucru profesionale, motiv pentru care ne-am asigurat că GPU-ul Intel® Arc™ Pro seria A oferă suport pentru PCIE modern* 4.0, fără a penaliza compatibilitatea inversă. Memoria grafică este îmbunătățită cu o viteză de bandă mare, permițând o și mai rapidă accesare a datelor de proiect de către software-ul tău."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "INSUFLĂ VIAȚĂ FIECĂRUI CADRU",
                    "tr_specText": "<span class='expandable_heading'>IA stimulat. Sprijinit de Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS este o tehnologie de ameliorare îmbunătățită de IA, care îți crește performanța fără a sacrifica frumusețea niciunui cadru, astfel încât să poți experimenta jocul așa cum a fost menit să fie."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "ÎN TIMP REAL.<br/> REALMENTE CAPTIVANT.<br/> REALISM LA CEL MAI ÎNALT NIVEL.",
                    "tr_specText": "Și mai multe lumi realiste te așteaptă. Cu caracteristica de urmărire a razelor încorporată, vei experimenta totul, de la umbre și reflexii realiste până la iluminare magistrală. Cu alte cuvinte, este o experiență vizuală de gaming pe care nu vrei să o pierzi."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "CEA MAI BUNĂ EXPERIENȚĂ DE JOC A TA, ÎMPĂRTĂȘITĂ ÎN TOATĂ SPLENDOAREA SA",
                    "tr_specText": "Oferă publicului tău experiența de joc perfectă pe care merită să o vadă. Cu capacitățile de codificare AV1 accelerată de hardware ale graficii Intel® Arc™, publicul va fi captivat de fiecare mișcare a ta. O calitate vizuală mai bună la același debit de biți înseamnă să transmiți tot ce ai mai bun, economisind în același timp lățimea de bandă."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Bun venit tuturor creatorilor",
                    "tr_specText": "Experiențe IA noi și îmbunătățite sunt disponibile la îndemână ta. Cu acceleratoarele avansate de IA integrate în grafica Intel® Arc™ seria A, petrece mai mult timp creând și mai puțin timp așteptând în mai multe aplicații.<br/><br/>Du-ți creația video la nivelul următor și deschide calea cu video AV1 de înaltă calitate, și eficient în ceea ce privește spațiul de stocare, cu ajutorul capacităților hardware AV1 avansate integrate în grafica Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Tot ceea ce ai nevoie pentru a-ți termina treaba",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Le poți avea pe toate: performanțe puternice, într&#8209;o formă subțire, ușoară și construită pentru a dura mult. Cu durată mare de viață a bateriei, ecrane tactile* atractive și cele mai noi Solid&#8209;State Drives (SSD), vei găsi tot ce ai nevoie într&#8209;un PC cu Windows* 10.",
                "tr_noteSection": "*În funcție de hardware",
                "titleArray": [
                  {
                    "tr_title": "Mai puțin înseamnă mai mult",
                    "tr_subtitle": "PC-urile de astăzi utilizează de obicei mai puțină energie, astfel încât să beneficiezi de o durată mai mare de viață a bateriei tale — în medie, cu până la peste opt ore de redare video.**",
                    "tr_noteSection": "**Durata de viață a bateriei diferă semnificativ în funcție de setări, utilizare și alți factori."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TOT CEEA CE AI NEVOIE PENTRU A-ȚI TERMINA TREABA",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Le poți avea pe toate: performanțe puternice, într&#8209;o formă subțire, ușoară și construită pentru a dura mult. Cu durată mare de viață a bateriei, ecrane tactile* atractive și cele mai noi Solid&#8209;State Drives (SSD), vei găsi tot ce ai nevoie într&#8209;un PC cu Windows* 10.",
                "tr_noteSection": "*În funcție de hardware",
                "titleArray": [
                  {
                    "tr_title": "MAI PUȚIN ÎNSEAMNĂ MAI MULT",
                    "tr_subtitle": "PC-urile de astăzi utilizează de obicei mai puțină energie, astfel încât să beneficiezi de o durată mai mare de viață a bateriei tale — în medie, cu până la peste opt ore de redare video.**",
                    "tr_noteSection": "**Durata de viață a bateriei diferă semnificativ în funcție de setări, utilizare și alți factori."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Eficientizat pentru siguranță și performanță",
                "tr_pretitle": "Windows* 10 în modul S",
                "tr_subtitle": "Atunci când utilizați un dispozitiv cu Windows* 10 în S mode, veți beneficia de un nivel suplimentar de protecție, care vă permite să lucrați, să vă jucați și să explorați, având încrederea că dispozitivul dumneavoastră se află în siguranță."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "EFICIENTIZAT PENTRU SIGURANȚĂ ȘI PERFORMANȚĂ",
                "tr_title": "Windows* 10 în modul S",
                "tr_subtitle": "Atunci când utilizați un dispozitiv cu Windows* 10 în S mode, veți beneficia de un nivel suplimentar de protecție, care vă permite să lucrați, să vă jucați și să explorați, având încrederea că dispozitivul dumneavoastră se află în siguranță."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Realizează lucruri extraordinare",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Performanță, preț și multe altele din care să alegi. De la dispozitive 2 în 1 inovatoare până la laptopuri subțiri și ușoare, ecrane tactile, tastaturi detașabile — indiferent care este bugetul sau stilul tău, poți găsi un PC cu Windows* care să ți se potrivească."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "REALIZEAZĂ LUCRURI EXTRAORDINARE",
                "tr_title": "Windows*",
                "tr_subtitle": "Performanță, preț și multe altele din care să alegi. De la dispozitive 2 în 1 inovatoare până la laptopuri subțiri și ușoare, ecrane tactile, tastaturi detașabile — indiferent care este bugetul sau stilul tău, poți găsi un PC cu Windows* care să ți se potrivească."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem de operare",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memorie",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "DE CÂT RAM AI NEVOIE?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Utilizare zilnică - DE LA 2 GB PÂNĂ LA 8 GB RAM",
                    "tr_badgeSubTitle": "Ideal pentru persoanele care utilizează PC-ul în principal pentru procesarea de documente și navigarea pe internet și tind să se concentreze pe un singur program o dată."
                  },
                  {
                    "tr_badgeTitle": "Performanță - DE LA 8 GB PÂNĂ LA 16 GB RAM",
                    "tr_badgeSubTitle": "Cu mai multă memorie RAM, aplicațiile tale vor rula mai rapid și, de asemenea, vei putea rula mai multe aplicații simultan."
                  },
                  {
                    "tr_badgeTitle": "Avansat - PESTE 16 GB RAM",
                    "tr_badgeSubTitle": "Dacă ești interesat de gaming, editare foto sau video sau chiar modelare 3D, vei avea nevoie de memorie RAM suplimentară pentru a rula aceste programe solicitante."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM pentru multitasking zilnic",
                "tr_subtitle": "Memoria va permite ca aplicațiile de bază, cum ar fi procesarea de documente și navigarea pe internet, să se încarce și să ruleze fără probleme.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM pentru multitasking performant",
                "tr_subtitle": "Dacă utilizezi mai multe programe simultan, [memory_total]&nbsp;GB de memorie îți vor permite să navighezi între ele cu ușurință.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM pentru multitasking avansat",
                "tr_subtitle": "Vei avea destulă memorie pentru a rula mai multe programe intense simultan fără a încetini PC-ul.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Memorie [Value1] GB = memorie Intel® Optane™ [Value2] GB + [Value3] GB RAM",
                "tr_subtitle": "Această combinație de soluții de memorie îți va oferi performanțe accelerate ale HDD-ului cu ajutorul memoriei Intel® Optane™ plus o mai mare receptivitate de la memoria RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>MEMORIE de<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Mai puțină așteptare. Mai mult din ceea ce îți place.",
                "tr_subtitle": "Atunci când memoria Intel® Optane™ lucrează împreună cu memoria sistemului tău, îți oferă acces mai rapid la programele frecvent utilizate, astfel încât acestea se lansează și se încarcă instantaneu în clipa în care ai nevoie de ele.",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "CE FACE MEMORIA PENTRU PC-UL MEU?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Creație de conținut mai rapidă",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Gaming mai receptiv",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Multitasking mai facil",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Deschiderea mai rapidă a programelor",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerează-ți gamingul</span>",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "tr_pretitle1": "[XX] GB de memorie Intel® Optane™ îți accelerează gamingul",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Lansează jocurile cu până la<br/><span class=\"optane-game\">98% mai rapid</span><br/>de pe unitatea de date echipată cu un procesor<br/>[optane_legal_processor_name] cu memorie Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Încarcă nivelurile cu până la<br/><span class=\"optane-game\">de 4,1 ori mai rapid</span><br/>de pe unitatea de date echipată cu un procesor<br/>[optane_legal_processor_name] cu memorie Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerează-ți gamingul</span>",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "tr_pretitle1": "[XX] GB de memorie Intel® Optane™ îți accelerează gamingul",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Lansează jocurile cu până la<br/><span class=\"optane-game\">98% mai rapid</span><br/>de pe unitatea de date echipată cu un procesor<br/>[optane_legal_processor_name] cu memorie Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Încarcă nivelurile cu până la<br/><span class=\"optane-game\">de 4,1 ori mai rapid</span><br/>de pe unitatea de date echipată cu un procesor<br/>[optane_legal_processor_name] cu memorie Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Memorie [Value1] GB = memorie Intel® Optane™ [Value2] GB + [Value3] GB RAM",
                "tr_subtitle": "Această combinație de soluții de memorie îți va oferi performanțe accelerate ale HDD-ului cu ajutorul memoriei Intel® Optane™ plus o mai mare receptivitate de la memoria RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB de RAM pentru multitasking",
                    "tr_subtitle": "Dacă utilizezi mai multe programe simultan, [memory_total]&nbsp;GB de memorie îți vor permite să navighezi între ele cu ușurință."
                  },
                  {
                    "tr_title": "Pentru sarcinile zilnice",
                    "tr_subtitle": "Memoria va permite ca aplicațiile de bază, cum ar fi procesarea de documente și navigarea pe internet, să se încarce și să ruleze fără probleme."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "PENTRU UN MULTITASKING SPORIT",
                "tr_subtitle": "Memoria RAM îi oferă procesorului acces la fișierele de program, permițându-i procesorului să le acceseze mai rapid și să ruleze mai multe sarcini simultan.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memorie standard",
                    "tr_badgeSubTitle": "Până la 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Memorie avansată",
                    "tr_badgeSubTitle": "RAM de minimum 8 GB și până la 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Memorie de elită",
                    "tr_badgeSubTitle": "Mai mult de 16 GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB pentru mai mult multitasking",
                "tr_pretitle": "PENTRU UN MULTITASKING SPORIT",
                "tr_subtitle": "Memoria RAM îi oferă procesorului acces la fișierele de program, permițându-i procesorului să le acceseze mai rapid și să ruleze mai multe sarcini simultan.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memorie standard",
                    "tr_badgeSubTitle": "Până la 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Memorie avansată",
                    "tr_badgeSubTitle": "RAM de minimum 8 GB și până la 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Memorie de elită",
                    "tr_badgeSubTitle": "Mai mult de 16 GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEASTĂ MEMORIE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avansat",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Ultra",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memorie",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "CUM SE COMPARĂ ACEASTĂ MEMORIE?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "CUM SE COMPARĂ ACEASTĂ MEMORIE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispozitiv actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Zilnic",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Puternic",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avansat",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Spațiu de stocare",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "CÂT POATE SUPORTA PC-UL TĂU?",
                "tr_subtitle": "Nu este nevoie să ghicești. Îți vom arăta exact ce poți rula pe acest PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Creează o bibliotecă de</span> <br/><span class=\"storage-standard\">[num_songs] melodii<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>bazat pe o medie de 5 MB pentru fiecare melodie",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Creează o bibliotecă de</span><br/><span class=\"storage-standard\">[num_games] jocuri<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>bazat pe o medie de 40 GB pe joc",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Spațiu de stocare pentru</span><br/><span class=\"storage-standard\">[num_videos] videoclipuri<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>bazat pe o medie de 5 GB pentru fiecare videoclip",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Realizează și stochează</span><br/><span class=\"storage-standard\">[num_photos] fotografii<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>bazat pe o medie de 4 MB pentru fiecare fotografie",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Memorie Intel® Optane™ de [total_optane_memory] GB + SSD de [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Stocare pe SSD de [capacity] GB",
                "tr_preTitle1": "Stocare pe SSD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Acest PC are un SSD cu [capacity] GB de stocare. Acest lucru înseamnă că vei putea salva un număr impresionant de fotografii, videoclipuri și programe și le vei putea accesa mult mai rapid decât ai face-o de pe o unitate de hard disk.",
                    "tr_title": "Un loc pentru tot ce ai în&nbsp;PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Acest PC are un SSD cu [capacity] TB de stocare. Acest lucru înseamnă că vei putea salva un număr impresionant de fotografii, videoclipuri și programe și le vei putea accesa mult mai rapid decât ai face-o de pe o unitate de hard disk.",
                    "tr_title": "Acest dispozitiv are un spațiu de stocare impresionant"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] GB</span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] TB</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Stocare pe HDD de [capacity] GB",
                "tr_preTitle1": "Stocare pe HDD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Unitățile hard disk sunt o alegere bună pentru oamenii care doresc o cantitate mare de date direct pe PC-ul lor.",
                    "tr_title": "Un loc pentru tot ce ai în&nbsp;PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Unitățile hard disk sunt o alegere bună pentru oamenii care doresc o cantitate mare de date direct pe PC-ul lor.",
                    "tr_title": "Acest dispozitiv are un spațiu de stocare impresionant"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "CÂT POATE SUPORTA PC-UL TĂU?",
                "tr_subtitle": "Nu este nevoie să ghicești. Îți vom arăta exact ce poți rula pe acest PC.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "Intel® SSD de [total_capacity] GB accelerat de memoria Intel® Optane™ de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD Intel® de [total_capacity] TB accelerat de memoria Intel® Optane™ de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Stocare pe SSD de [convertedSSD] GB + HDD de [convertedHDD] GB",
                "tr_preTitle1": "Stocare pe SSD de [convertedSSD] TB + HDD de [convertedHDD] GB",
                "tr_preTitle2": "Stocare pe SSD de [convertedSSD] GB + HDD de [convertedHDD] TB",
                "tr_preTitle3": "Stocare pe SSD de [convertedSSD] TB + HDD de [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Un loc pentru tot ce ai în&nbsp;PC",
                    "tr_title1": "Acest dispozitiv are un spațiu de stocare impresionant",
                    "tr_subtitle": "Acest PC este echipat cu un SSD cu stocare de [convertedSSD] GB și cu un hard disk de [convertedHDD] GB. Acest lucru îți va oferi capacități enorme, alături de performanțele de stocare mai rapide.",
                    "tr_subtitle1": "Acest PC este echipat cu un SSD cu stocare de [convertedSSD] TB și cu un hard disk de [convertedHDD] GB. Acest lucru îți va oferi capacități enorme, alături de performanțele de stocare mai rapide.",
                    "tr_subtitle2": "Acest PC este echipat cu un SSD cu stocare de [convertedSSD] GB și cu un hard disk de [convertedHDD] TB. Acest lucru îți va oferi capacități enorme, alături de performanțele de stocare mai rapide.",
                    "tr_subtitle3": "Acest PC este echipat cu un SSD cu stocare de [convertedSSD] TB și cu un hard disk de [convertedHDD] TB. Acest lucru îți va oferi capacități enorme, alături de performanțele de stocare mai rapide."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] GB</span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>SPAȚIU DE STOCARE<br/></span><span class='value'>[capacity] TB</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Beneficiază de accesul accelerat la stocarea de mare capacitate cu ajutorul memoriei Intel® Optane™ cu SSD.",
                "tr_title": "Fișierele mari sunt binevenite",
                "tr_pretitle": "Intel® SSD de [total_capacity] GB accelerat de memoria Intel® Optane™ de [optane_memory_size] GB",
                "tr_pretitle1": "SSD Intel® de [total_capacity] TB accelerat de memoria Intel® Optane™ de [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Această soluție de stocare revoluționară este prima care să combine memoria Intel® Optane™ și SSD-ul pe un singur cip. Iată cu ce te ajută:",
                    "tr_title": "CE ESTE MEMORIA INTEL® OPTANE™ CU SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Deschide rapid fișierele și programele dorite",
                    "tr_badgeSubTitle": "Datorită preîncărcării fișierelor și programelor folosite frecvent, poți petrece mai mult timp creând și mai puțin timp așteptând.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Stocare plus viteză",
                    "tr_badgeSubTitle": "Datorită performanțelor rapide oferite de un SSD, vei avea spațiul de care ai nevoie pentru fișierele media.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Mai multă receptivitate în timp",
                    "tr_badgeSubTitle": "Memoria Intel® Optane™ cu SSD învață modul tău de lucru, eficientizând PC-ul.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCARE",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Păstrează-ți toate fișierele media la îndemână cu un spațiu de stocare suficient și fiabil.",
                "tr_title": "Spațiu pentru toate fotografiile, videoclipurile și melodiile tale"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milioane de pixeli pentru a glisa, a apropia degetele și uimi",
                "tr_subtitle": "Interacționează cu PC-ul ca și cu un smartphone cu ajutorul controalelor intuitive și a detaliilor mai clare și vii.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType] de<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rezoluție: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixeli: [screenResolution] de milioane"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecran de [screenDiagonal] inchi",
                    "tr_badgeSubTitle": "Înălțimea ecranului: [screenHeight] de inchi",
                    "tr_badgePreTitle": "Lățimea ecranului: [YY] de inchi"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ecran tactil",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Pătrunde&nbsp;în [screenResolution] milioane&nbsp;de&nbsp;pixeli",
                "tr_subtitle": "Totul ține de pixeli. Cu cât ai mai mulți, cu atât vor fi imaginile mai clare și frumoase.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType] de<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rezoluție: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixeli: [screenResolution] de milioane"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecran de [screenDiagonal] inchi",
                    "tr_badgeSubTitle": "Înălțimea ecranului: [screenHeight] de inchi",
                    "tr_badgePreTitle": "Lățimea ecranului: [YY] de inchi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] inchi <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "FEREASTRA CĂTRE O LUME A JOCURILOR",
                "tr_subtitle": "Întocmai unui televizor, grijile tale în ceea ce privește display-ul de gaming vor fi dimensiunea și rezoluția.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rezoluție: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixeli: [screenResolution] de milioane"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecran de [screenDiagonal] inchi",
                    "tr_badgeSubTitle": "Înălțimea ecranului: [screenHeight] de inchi",
                    "tr_badgePreTitle": "Lățimea ecranului: [screenWidth] de inchi"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ecran tactil"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Acest dispozitiv are un ecran impresionant",
                "tr_subtitle": "Cu de peste opt ori mai mulți pixeli decât HD, videoclipurile și jocurile tale vor avea un nou nivel de claritate și culoare.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rezoluție: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixeli: [screenResolution] de milioane"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecran de [screenDiagonal] inchi",
                    "tr_badgeSubTitle": "Înălțimea ecranului: [screenHeight] de inchi",
                    "tr_badgePreTitle": "Lățimea ecranului: [YY] de inchi"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milioane de pixeli pentru a atinge și glisa",
                    "tr_subTitle": "Vizualizează și interacționează cu tot ce&#8209;ți place, în detalii mai clare și mai vii."
                  },
                  {
                    "tr_title": "[screenResolution] milioane de pixeli pentru a-ți îmbunătăți lumea digitală",
                    "tr_subTitle": "Totul ține de pixeli. Cu cât ai mai mulți, cu atât vor fi imaginile mai clare și frumoase."
                  },
                  {
                    "tr_title": "Acest dispozitiv are un ecran impresionant",
                    "tr_subTitle": "Cu un număr de pixeli HD de aproape patru ori mai mare, videoclipurile și jocurile vor ajunge la un nivel nou de claritate și culoare."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectivitate",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Tehnologia Thunderbolt™ 3 oferă cele mai rapide și mai versatile conexiuni la orice dispozitiv de andocare, ecran sau dispozitiv de date.",
                "tr_title": "Un port bun la toate",
                "tr_title1": "UN PORT BUN LA TOATE",
                "titleArray": [
                  {
                    "tr_title": "CUM POT UTILIZA TEHNOLOGIA THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tehnologia Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitoare 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conectează-te la ecrane cu rezoluție, contrast și culori incredibile"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Placa video externă",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oferă instantaneu oricărui PC un upgrade grafic major"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Rețea rapidă",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oferă conexiuni rapide între dispozitive"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectivitate",
                "detailBGUrl": "",
                "tr_subtitle": "Tehnologia Thunderbolt™ 3 transferă datele la viteze mult mai mari față de USB-ul tradițional, ceea ce îți permite să muți rapid fișierele și să conectezi facil periferice, cum ar fi mai multe monitoare sau plăci grafice externe.",
                "tr_title": "Tehnologia Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DATE EXTREM DE RAPIDE",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tehnologia Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectivitate",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un port și cablu universal suportă toate accesoriile tale preferate</li> <li>Încărcare pentru laptop fiabilă </li> <li>Certificarea robustă oferă liniște</li></ul>",
                "tr_title": "Conectivitate prin cablu cu adevărat universală",
                "tr_title1": "CONECTIVITATE PRIN CABLU CU ADEVĂRAT UNIVERSALĂ",
                "tr_noteSection": "<sup>†</sup>Bazat pe cerințe specifice minime",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "CUM POT UTILIZA TEHNOLOGIA THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tehnologia Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "La creare",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conectează un monitor extern și un dispozitiv de stocare rapidă pentru a vizualiza și edita fotografii și videoclipuri."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Suport de andocare",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Creează un birou simplu și fără cabluri încurcate, conectându-ți laptopul la toate accesoriile cu un singur cablu."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Joacă-te",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adaugă mai multă capacitate pentru SSD și HDD, cu viteze de până la 3.000 MB/s, pentru a stoca biblioteci de jocuri și fișiere media."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performanță maximă<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "De 4 ori mai rapid decât USB* 3.2 <br/>De 2 ori mai rapid decât HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectivitate",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Bucură-te de aceeași performanță uimitoare a tehnologiei Thunderbolt™ 3 cu și mai multe capacități care îți permit să conectezi și să folosești cu ușurință cele mai noi suporturi de andocare, monitoare, stocare rapidă sau orice accesoriu USB* de la Thunderbolt™.",
                "tr_title": "Tehnologia Thunderbolt™ 4",
                "tr_pretitle": "CONECTIVITATE PRIN CABLU CU ADEVĂRAT UNIVERSALĂ",
                "tr_noteSection": "<sup>†</sup>Bazat pe cerințe specifice minime",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Un cablu universal Thunderbolt™ 4 îți poate înlocui toate cablurile USB-C*, pentru a face selecția de cabluri rapidă și ușoară."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Suporturile de andocare Thunderbolt™ 4 oferă până la patru porturi Thunderbolt™ pentru mai multă flexibilitate de conectare la oricare dintre accesoriile tale."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performanță maximă<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "De 4 ori mai rapid decât USB* 3.2 <br/>De 2 ori mai rapid decât HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectivitate",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un port și cablu universal suportă toate accesoriile tale preferate</li> <li>Încărcare pentru laptop fiabilă </li> <li>Certificarea robustă oferă liniște</li></ul>",
                "tr_title": "Maximizează-ți experiența cu accesoriile Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Bazat pe cerințe specifice minime",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "CUM POT UTILIZA TEHNOLOGIA THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "La creare",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conectează un monitor extern și un dispozitiv de stocare rapidă pentru a vizualiza și edita fotografii și videoclipuri."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Suport de andocare",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Creează un birou simplu și fără cabluri încurcate, conectându-ți laptopul la toate accesoriile cu un singur cablu."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Joacă-te",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adaugă mai multă capacitate pentru SSD și HDD, cu viteze de până la 3.000 MB/s, pentru a stoca biblioteci de jocuri și fișiere media."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performanță maximă<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "De 4 ori mai rapid decât USB* 3.2 <br/>De 2 ori mai rapid decât HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Cele mai comune tipuri de Wi‑Fi sunt:",
                "tr_title": "ÎNȚELEGEREA STANDARDELOR WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 permite viteze mai mari chiar și atunci când mai mulți utilizatori folosesc același semnal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Cu cea mai recentă inovație Wi-Fi a Intel, poți atinge viteze mai mari decât conexiunea Wi-Fi gigabit, chiar și cu mai mulți utilizatori la același semnal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Cea mai mare dezvoltare Wi‑Fi din ultimii 20 de ani, Wi‑Fi 6E deschide noi canale de mare viteză, pentru performanțe și fiabilitate îmbunătățite și mai puține interferențe."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 permite viteze mai mari chiar și atunci când mai mulți utilizatori folosesc același semnal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Toate avantajele Wi-Fi 6E au fost asociate cu motorul de prioritizare Intel® Killer™, care canalizează lățimea de bandă pe site-urile web și aplicațiile care au cea mai mare nevoie de el."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Cu o concurență mai mică pe canalele tale de mare viteză, te vei bucura de performanțe și fiabilitate îmbunătățite."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 permite viteze mai mari chiar și atunci când mai mulți utilizatori folosesc același semnal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Cu mai puține interferențe de la Wi-Fi 6 și performanțele inteligente ale motorului de prioritizare Intel® Killer™, experiența ta online va fi mai rapidă și mai receptivă⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Cu o concurență mai mică pe canalele tale de mare viteză, te vei bucura de performanțe și fiabilitate îmbunătățite."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Viteze Wi-Fi pe PC de peste 5 Gbps, cu latență ultra-scăzută consistentă: distribuie fișiere în câteva secunde, în loc de minute."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) este cea mai recentă tehnologie care asigură fiabilitate „extremă”, latență și viteze reduse prin optimizarea rețelei bazată pe IA."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 permite viteze mai mari chiar și atunci când mai mulți utilizatori folosesc același semnal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) cu viteze de peste 1 Gbps, fiabilitate și latență redusă prin utilizarea canalului de 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Eficiență, capacitate și viteză mai bune față de Wi-Fi 5 în rețelele aglomerate."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Rămâi mai bine conectat cu Wi‑Fi [XX] și bucură&#8209;te de internet cu viteze<sup>†</sup> și fiabilitate crescute.",
                "tr_title": "Beneficiază de distanță și viteză îmbunătățite",
                "tr_noteSection": "<sup>†</sup>Viteza și/sau acoperirea wireless actuale vor diferi.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "În era gamingului multiplayer și online, este mai important ca niciodată să rămâi conectat. Pe lângă viteză, îți vei dori și un semnal wireless care poate păstra aceste viteze la distanță.",
                "tr_title": "[XX]",
                "tr_pretitle": "CONEXIUNEA ESTE CHEIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conectează-te cu Intel® Wi‑Fi 6 (Gig+) la routere Wi‑Fi 6 cu suport pentru canale de 160 MHz, pentru a permite conexiuni mai rapide și mai multă fiabilitate pentru utilizatorii mai activi.",
                "tr_title": "Viteze de aproape de 3 ori mai mari cu Intel® <span class='no-warp'>Wi‑Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Față de Wi‑Fi AC standard. Funcții selectate disponibile numai cu anumite modele. Consultă producătorul pentru detalii. Pentru mai multe informații despre Wi‑Fi, vizitează intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 de nivel începător acceptă performanțe îmbunătățite pentru nevoile de conectivitate de bază, cum ar fi e-mailul și navigarea pe Internet în rețelele aglomerate.<sup>9</sup>",
                "tr_title": "Wi-Fi esențial",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "În era gamingului multiplayer și online, este mai important ca niciodată să rămâi conectat. Pe lângă viteză, îți vei dori și un semnal wireless care poate păstra aceste viteze la distanță.",
                "tr_title": "[XX]",
                "tr_pretitle": "CONEXIUNEA ESTE CHEIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Cu aceste canale Wi‑Fi noi, nu trebuie să partajezi lățimea de bandă cu dispozitivele mai vechi de prin împrejurimi, îți este oferit un streaming fără cusur, navigare rapidă și lucru și învățare de la distanță fără întreruperi.",
                "tr_title": "Conectează-te fără competiție",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Rămâi mai bine conectat cu [XX] și bucură&#8209;te de internet cu viteze mai mari<sup>†</sup> și mai multă fiabilitate.",
                "tr_title": "Beneficiază de distanță și viteză îmbunătățite",
                "tr_noteSection": "<sup>†</sup>Viteza și/sau acoperirea wireless actuale vor diferi.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "În era gamingului multiplayer și online, este mai important ca niciodată să rămâi conectat. Pe lângă viteză, îți vei dori și un semnal wireless care poate păstra aceste viteze la distanță.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "CONEXIUNEA ESTE CHEIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi ultrarapid încorporat",
                "tr_subtitle": "Deconectează dispozitivul și rămâi conectat<br/>la universul online."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Cu benzi dedicate de mare viteză pentru mai puține interferențe și motorul de prioritizare Intel® Killer™, care pune gamingul pe primul plan, gamingul online tocmai a primit un upgrade serios.",
                "tr_title": "Lasă competiția în urmă",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Cu benzi dedicate de mare viteză pentru mai puține interferențe și motorul de prioritizare Intel® Killer™, care pune gamingul pe primul plan, gamingul online tocmai a primit un upgrade serios.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASĂ COMPETIȚIA ÎN URMĂ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Cu benzi dedicate de mare viteză pentru mai puține interferențe, care pun gamingul pe primul plan, gamingul online tocmai a primit un upgrade serios.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASĂ COMPETIȚIA ÎN URMĂ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi accelerează automat traficul de rețea pentru aplicațiile care au nevoie cel mai mult, astfel încât videoclipurile tale de streaming să aibă detalii și să utilizeze mai puțin zona tampon.",
                "tr_title": "Un mod mai rapid și&nbsp;mai&nbsp;inteligent pentru&nbsp;a&nbsp;te conecta⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "În era gamingului multiplayer și online, este mai important ca niciodată să rămâi conectat. Pe lângă viteză, îți vei dori și un semnal wireless care poate păstra aceste viteze la distanță.",
                "tr_title": "[XX]",
                "tr_pretitle": "CONEXIUNEA ESTE CHEIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Rămâi mai bine conectat cu Wi-Fi 5 și bucură&#8209;te de internet cu viteze mai mari<sup>†</sup> și multă fiabilitate.",
                "tr_title": "Beneficiază de distanță și viteză îmbunătățite",
                "tr_noteSection": "<sup>†</sup>Viteza și/sau acoperirea wireless actuale vor diferi.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "În era gamingului multiplayer și online, este mai important ca niciodată să rămâi conectat. Pe lângă viteză, îți vei dori și un semnal wireless care poate păstra aceste viteze la distanță.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASĂ COMPETIȚIA ÎN URMĂ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Caracteristicile Wi-Fi 7 se vor baza pe și vor ridica nivelul generațiilor Wi-Fi anterioare. Acest lucru va însemna nu doar viteze mai rapide, ci și receptivitate și fiabilitate îmbunătățite semnificativ, pentru experiențe captivante pentru consumatori și tehnologii viitoare sofisticate.",
                "tr_title": "Rețele de PC asemănătoare celor cu fir",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Caracteristicile Wi-Fi 7 se vor baza pe și vor ridica nivelul generațiilor Wi-Fi anterioare. Acest lucru va însemna nu doar viteze mai rapide, ci și receptivitate și fiabilitate îmbunătățite semnificativ, pentru experiențe captivante pentru consumatori și tehnologii viitoare sofisticate.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMĂ EXPERIENȚA UTILIZATORULUI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Viteză, latență și fiabilitate îmbunătățite prin optimizarea rețelei bazată pe IA",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Viteză, latență și fiabilitate îmbunătățite prin optimizarea rețelei bazată pe IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "RECEPTIVITATE ASEMĂNĂTOARE CELEI CU FIR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Cum se măsoară?",
                    "tr_badgeSubTitle": "Majoritatea producătorilor măsoară durata de viață a bateriei în ore. Cu toate că aceste calcule nu sunt 100% exacte, ar trebui să te ajute să compari durata de viață a bateriei unui PC față de altul."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PENTRU GAMING ÎN DEPLASARE",
                "tr_subtitle": "Cu performanțele oferite de laptopurile de gaming din ziua de astăzi, nu există niciun motiv pentru care să rămâi legat de un birou. Acum, jocurile tale pot merge oriunde.",
                "tr_title": "[Dynamic.battery.values.hours] ore de durată de viață a bateriei"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Scoate cablul și rămâi conectat doar la aplicații, jocuri și muzică pentru ore în&nbsp;șir.",
                "tr_title": "[XX] ore de streaming cu o singură încărcare<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bazat pe durata de viață a bateriei declarată de producătorul PC-ului.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Scoate cablul și rămâi conectat doar la aplicații, jocuri și muzică pentru ore în&nbsp;șir.",
                "tr_title": "[Dynamic.battery.values.hours] ore de autonomie în deplasare<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bazat pe durata de viață a bateriei declarată de producătorul PC-ului.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Scoate cablul și rămâi conectat doar la aplicații, jocuri și muzică pentru ore în&nbsp;șir.",
                "tr_title": "[Dynamic.battery.values.hours] ore pentru o zi întreagă de utilizare cu o singură încărcare<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bazat pe durata de viață a bateriei declarată de producătorul PC-ului.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Acest PC îți optimizează durata de viață a bateriei pentru a rezista mai mult și îți oferă o durată de viață a bateriei de cel puțin 4 ore, cu o încărcare de 30 de minute.<sup>4</sup>",
                "tr_title": "Stai mai mult timp deconectat cu [Dynamic.battery.values.hours] ore de durată de viață a bateriei<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bazat pe durata de viață a bateriei declarată de producătorul PC-ului.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Pagina principală",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "De ce acest PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Vezi toate specificațiile",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "De la distanță",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Dispozitiv mobil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Informații juridice",
        "tr_paragraph_array": [
          "Este posibil ca pachetele software și volumul de sarcini folosite la testele de performanță să fi fost optimizate pentru performanță numai pe microprocesoarele Intel. Testele de performanță, cum ar fi SYSmark* și MobileMark*, sunt efectuate prin folosirea unor sisteme, componente și funcții specifice computerelor. Orice modificare asupra acestora poate duce la modificarea rezultatelor. Vă sfătuim să consultați și alte informații și teste de performanță pentru a vă ajuta să evaluați corect produsul pe care intenționați să îl achiziționați, inclusiv performanța acelui produs atunci când este combinat cu altele. Pentru informații complete despre performanțe și rezultatele testelor, vizitează www.intel.com/benchmarks.",
          "¹Produsele Intel® Wi-Fi 6 (Gig+) acceptă canale opționale de 160 MHz, permițând cele mai rapide viteze maxime teoretice posibile (2.402 Mbps) pentru produsele Wi-Fi obișnuite 2x2 802.11ax pentru PC. Produsele Intel® Wi-Fi 6 (GIG+) premium permit viteze maxime teoretice de 2-4 ori mai mari față de produsele standard Wi-Fi 2x2 (1.201 Mbps) sau 1x1 (600 Mbps) 802.11ax pentru PC, care acceptă exclusiv cerința obligatorie de canale de 80 MHz.",
          "²Recomandare doar cu scop informațional. Înainte să luați o decizie de achiziție, vă rugăm să consultați și alte surse.",
          "³Funcțiile și beneficiile tehnologiilor Intel depind de configurația sistemului și pot necesita activarea hardware-ului, software-ului sau a serviciilor. Performanțele diferă în funcție de configurația sistemului. Niciun computer nu poate oferi securitate completă. Solicitați informații de la producătorul sau retailerul sistemului sau aflați mai multe pe intel.com.",
          "⁴Pentru sistemele echipate cu ecrane FHD, atunci când sunt utilizate pentru navigare web wireless. Atunci când este închis, de la nivelul implicit de închidere al OEM.",
          "⁵În comparație cu alte tehnologii de conectare I/O a PC-ului, inclusiv eSATA, USB și IEEE 1394* FireWire. Performanța diferă în funcție de componentele hardware și de pachetele software specifice utilizate. E necesară utilizarea unui dispozitiv cu suport pentru tehnologia Thunderbolt™.",
          "⁶De aproape 3 ori mai rapid: 802.11ax 2x2 160 MHz permite rate de date teoretice maxime de 2.402 Mbps. De 3 ori mai rapid decât Wi‑Fi-ul standard 802.11ac 2x2 80 MHz (867 Mbps), așa cum este documentat în specificațiile wireless standard IEEE 802.11* și necesită utilizarea unor rutere de rețea wireless 802.11ax configurate similar.",
          "⁷Reducerea latenței cu 75%: Este bazată pe datele simulărilor Intel (79%) ale 802.11ax cu și fără OFDMA, utilizând 9 clienți. Latența medie fără OFDMA este de 36 ms, cu latență medie OFDMA redusă la 7,6 ms. Îmbunătățirea latenței necesită ca routerul 802.11ax (Wi-Fi 6) și toți clienții să accepte OFDMA.",
          "⁸Analiza bazată pe comparația ratei maxime teoretice a datelor în specificația 802.11, 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) poate oferi 2.402 Mbps comparativ cu 2x2 80 MHz Wi‑Fi 5 (802.11ac), care poate furniza 867 Mbps, adică viteze de 2,8X mai mari.",
          "⁹Cel mai bun Wi‑Fi 6 din clasa sa: produsele Intel® Wi‑Fi 6 (Gig+) acceptă canale de 160 MHz opționale, activând cele mai mari viteze maxime teoretice posibile (2.402 Mbps) pentru produsele Wi‑Fi obișnuite 2x2 802.11ax pentru PC. Produsele Intel® Wi‑Fi 6 (GIG+) premium permit viteze maxime teoretice de 2-4 ori mai mari față de produsele standard Wi‑Fi 2x2 (1.201 Mbps) sau 1x1 (600 Mbps) 802.11ax pentru PC, care acceptă exclusiv cerința obligatorie de canale de 80 MHz.",
          "Doar anumite SKU-uri, funcționalitatea variază în funcție de designul OEM. Pentru detalii, consultați OEM sau retailerul. Pentru mai multe informații despre Wi&#8209;Fi, vizitați intel.com/wifi6disclaimers.",
          "¹⁰Tehnologia Thunderbolt™ 4 la 40 Gbps, oferă cea mai rapidă, cea mai simplă și cea mai fiabilă soluție prin cablu pentru orice dispozitiv de andocare, ecran sau dispozitiv de date în comparație cu alte tehnologii de conectare I/O, inclusiv eSATA*, USB* și IEEE 1394* FireWire.",
          "¹¹Necesită un router bazat pe 802.11ax, care suportă OFDMA și mai mulți clienți în rețea cu suport pentru AX. Faptul că este mai bun în mediile dense se datorează funcției OFDMA, care este suportată de clienții 802.11ax și AP. 2 Gbps pe baza unor ipoteze ale aproximativ 70% din specificațiile IEEE 802.11*, referitor la vitezele maxime teoretice ale datelor pentru 802.11ax 160 MHz de 2.402 Mbps.",
          "¹²Măsurat cu lansarea documentelor cu sarcini pe fundal, comparând procesorul Intel® Core™ i7-8565U din generația a 8-a (TLC SSD de 512 GB) cu procesorul Intel® Core™ i7-8565U din generația a 8-a (memorie Intel® Optane™ H10 de 32 GB + SSD de 512 GB).",
          "¹³Intel® Thread Director este proiectat în procesoarele Intel® Core™ din generația a 12-a și ajută la sprijinirea sistemelor de operare pentru a canaliza sarcinile mai inteligent în nucleul potrivit. Nu este necesară nicio acțiune a utilizatorilor. Pentru detalii, accesați intel.com.",
          "¹⁴Nu este disponibil pe anumite procesoare Intel® Core™ din generația a 12-a. Arhitectura hibridă performantă combină două noi microarhitecturi de nuclee, Performance-cores (P-cores) și Efficient-cores (E-cores), pe pastila aceluiași procesor. Anumite procesoare Intel® Core™ din generația a 12-a (și unele procesoare Intel® Core™ i5 din generația a 12-a și versiuni anterioare) nu acceptă arhitectura hibridă performantă, ci doar P-cores.",
          "¹⁵Măsurat cu sarcina din timpul lansării jocului comparând un procesor Intel® Core™ i7-8750H din generația a 8-a (cu modul de memorie Intel® Optane™ de 32GB + Intel® SSD PCIe* de 256GB + HDD de 1TB) cu un procesor Intel® Core™ i7-8750H din generația a 8-a (Intel® SSD PCIe* de 256GB + HDD de 1TB). Sarcina din timpul lansării jocului – Sarcină dezvoltată de către Intel, care măsoară durata de timp necesară lansării Total War*: WARHAMMER* II Build: 5577.0 și intrarea în meniul principal cu filmele de introducere dezactivate (Lansare). Configurațiile de accelerare a unității de stocare cu memorie Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo până la 4GHz pe un sistem OEM în pre-producție, grafică: NVIDIA* GeForce* GTX 1070, memorie: 2x4GB DDR4, stocare: Intel® SSD 760p de 256GB (PCIe*) + HDD de 1TB + memorie Intel® Optane™ de 32GB, SO: Windows* 10 RS3 Build 1709, MCU 0x84; procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo până la 4GHz pe un sistem OEM în pre-producție, grafică: NVIDIA* GeForce* GTX 1070, memorie: 2x4GB DDR4, stocare: Intel® SSD 760p de 256GB (PCIe*) + HDD de 1TB, SO: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Măsurat de sarcina de încărcare a nivelurilor din joc comparând un procesor Intel® Core™ i7-8750H din cea de-a 8-a generație (cu modul de memorie 32GB Intel® Optane™) cu un procesor Intel® Core™ i7-8750H din cea de-a 8-a generație (doar HDD). Sarcina din timpul încărcării nivelului de joc – Sarcină dezvoltată de Intel, care măsoară durata de timp de la accesarea meniului principal, până la încărcarea completă a nivelului din jocul Total War*: WARHAMMER* II Build: 5577.0. Configurațiile de accelerare a unității de stocare cu memorie Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo până la 4GHz pe un sistem OEM în pre-producție, grafică: NVIDIA* GeForce* GTX 1070, memorie: 2x4GB DDR4, stocare: Intel® SSD 760p de 256GB (PCIe*) + HDD de 1TB + memorie Intel® Optane™ de 32GB, SO: Windows* 10 RS3 Build 1709, MCU 0x84; procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo până la 4GHz pe un sistem OEM în pre-producție, grafică: NVIDIA* GeForce* GTX 1070, memorie: 2x4GB DDR4, stocare: Intel® SSD 760p de 256GB (PCIe*) + HDD de 1TB, SO: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Pe Performance-cores. Performanța variază în funcție de utilizare, configurare și alți factori. Aflați mai multe la www.intel.com/PerformanceIndex.",
          "¹⁸Modificarea frecvenței sau a tensiunii poate cauza defecțiuni sau reduce durata de viață a procesorului și a altor componente de sistem, și poate reduce stabilitatea și performanța sistemului. Garanția produselor ar putea să nu mai fie valabilă dacă procesorul nu este utilizat conform specificațiilor sale. Solicitați detalii suplimentare din partea producătorilor sistemului și ai componentelor.",
          "¹⁹Măsurat cu combinația de neegalat a platformei Intel vPro®, de capacități de securitate a sistemelor de operare superioare și inferioare, protecții ale datelor și aplicațiilor, protecții avansate împotriva amenințărilor pentru firme de orice dimensiune și prima abordare de securitate a Intel pentru proiectarea, fabricarea și asistența pentru produse. Toate PC-urile pentru afaceri construite pe platforma Intel vPro® au fost validate în raport cu specificații riguroase, inclusiv caracteristici unice de securitate bazată pe hardware. Pentru detalii, consultă www.intel.com/PerformanceIndex (platforme). Niciun produs sau componentă nu poate oferi siguranță totală.",
          "²⁰Intel® Connectivity Performance Suite este disponibil doar pe sisteme bazate pe Windows*. Testarea Wi-Fi OTA (Over The Air) a Intel pe o rețea congestionată, versus fără aplicarea prioritizării traficului wireless pentru aplicațiile software ICPS și schimbarea punctului de acces inteligent. Pentru detalii, consultă www.intel.com/PerformanceIndex (conectivitate). Rezultatele pot varia.",
          "²¹În comparație cu generația de procesor anterioară ",
          "²²Grafica Intel® Arc™ este prima din piață care oferă suport de codificare AV1 (începând cu Q1 2022). Detalii la intel.com/performanceindex.",
          "²³Măsurat prin caracteristicile unice ale modelelor Intel® Evo™, care de asemenea, sunt verificate pentru a îndeplini pragurile cheie ale experienței utilizatorului mobil. Testare începând cu februarie 2022.",
          "²⁴Soluția Intel® Unison™ este disponibilă în prezent doar pe modele Intel® Evo™ eligibile pe PC-uri bazate pe Windows* și se asociază doar cu telefoanele bazate pe Android sau iOS; toate dispozitivele trebuie să ruleze o versiune de sistem de operare acceptată.",
          "²⁵Vitezele maxime de memorie sunt asociate cu configurațiile de 1 DIMM (1DPC) per canal. Încărcarea DIMM suplimentară pe orice canal poate afecta viteza maximă a memoriei. Până la DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 și DDR5-5200 1Rx8, 1Rx16, 2Rx8 pe anumite modele. Capacitatea maximă de memorie poate fi obținută cu configurațiile 2DPC.",
          "²⁶Comparativ cu Wi-Fi standard. Pentru detalii, vezi intel.com/performance-wireless. Rezultatele pot varia.",
          "²⁷Arhitectura hibridă performantă combină două microarhitecturi de nucleu, Performance&#8209;cores (P&#8209;cores) și Efficient&#8209;cores (E&#8209;cores) pe pastila aceluiași procesor, și a fost introdusă pentru prima dată pe procesoarele Intel® Core™ din generația a 12&#8209;a. Anumite procesoare Intel® Core™ din generația a 12&#8209;a nu au arhitectură hibridă performantă, au doar P&#8209;cores și aceeași dimensiune a memoriei cache precum generația anterioară; vezi ark.intel.com pentru detalii despre modele.",
          "²⁸Integrat în hardware, Intel® Thread Director este furnizat doar în configurațiile de arhitectură hibridă performantă ale procesoarelor Intel® Core™ din generația a 12-a; este necesară activarea sistemului de operare. Caracteristicile disponibile și funcționalitățile variază în funcție de sistemul de operare.",
          "²⁹Integrat în hardware, Intel® Thread Director este furnizat doar în configurațiile de arhitectură hibridă performantă ale procesoarelor Intel® Core™ din generația a 12-a și 13-a; este necesară activarea sistemului de operare. Caracteristicile disponibile și funcționalitățile variază în funcție de sistemul de operare.",
          "³⁰După cum se măsoară performanța platformei de validare de referință a procesorului mobil Intel®&nbsp;Core™ Ultra (Seria 2 - Nume de cod: Lunar Lake) vs. procesorul mobil Intel®&nbsp;Core™ Ultra (Seria 1 - Nume de cod: Meteor Lake) pe 3DMark Time Spy. A se vedea intel.com/performanceindex pentru detalii. Rezultatele pot varia.",
          "³¹Estomparea fundalului și urmărirea feței sunt disponibile doar pe sistemele bazate pe Windows*.",
          "³²Produsele Wi-Fi 7 pot accesa canale de 320 MHz în 6 GHz și noi combinații de canale de 160 MHz în 5 GHz cu noi capacități de puncționare a unităților cu resurse multiple.",
          "³³Pe baza specificațiilor standardului wireless IEEE și a vitezelor teoretice maxime ale datelor pentru dispozitive cu 2 fluxuri.",
          "³⁴Simulare de inginerie Intel privind mediile de rețea aglomerate indică faptul că este posibilă o reducere majoră a latenței cu ajutorul noilor capacități de operare Wi-Fi 7 Multi-Link.",
          "³⁵Încărcarea minimă a fost atinsă de la nivelul de închidere implicit oferit de OEM. Rezultatele sistemelor individuale pot varia. Pentru detalii, consultați intel.com/performance-evo.",
          "³⁶Banda Wi-Fi de 6 GHz nu este disponibilă pentru utilizare în toate țările/regiunile.",
          "³⁷Doar Windows*",
          "³⁸Încărcarea a fost atinsă de la nivelul de închidere implicit oferit de OEM. Pentru informații complete despre performanță și rezultatele evaluării comparative, vizitați intel.com/Evo.",
          "³⁹Se referă la procesoare mobile Intel®&nbsp;Core™ Ultra (Seria&nbsp;2), pe baza caracteristicilor unice de securitate bazate pe hardware, a combinației de neegalat de capacități de securitate de deasupra și dedesubtul sistemului de operare, performanțe impresionante chiar și la o putere mai mică în comparație cu generația anterioară (începând din septembrie 2024). A se vedea intel.com/performanceindex pentru detalii. Rezultatele pot varia.",
          "⁴⁰Aplicații software descrise doar în scopuri ilustrative. Caracteristicile IA pot necesita achiziționarea de software, abonament sau activare suplimentare de către un software sau un furnizor de platformă, sau pot avea cerințe specifice de configurare sau de compatibilitate. Pentru detalii, accesați www.intel.com/PerformanceIndex. Rezultatele pot varia. © Intel Corporation.",
          "⁴¹Este posibil ca imaginile prezentare să fi fost modificate sau simulate. Caracteristicile IA pot necesita achiziționarea de software, abonament sau activare suplimentare de către un software sau un furnizor de platformă, sau pot avea cerințe specifice de configurare sau de compatibilitate. Detalii la www.intel.com/AIPC.",
          "⁴²Dezvoltare treptată în previzualizare în cadrul celei mai recente actualizări pentru Windows* 11 pe anumite piețe globale. Calendarul disponibilității variază în funcție de dispozitiv și de piață. Mai multe detalii aici: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Soluția Intel® Unison™ este disponibilă în prezent pentru modele eligibile. Pentru detalii, a se consulta www.intel.com/PerformanceIndex.",
          "⁴⁴FPS amplificat de Intel® X<sup>e</sup>SS vs. FPS nativ, măsurat utilizând Dying Light 2 Stay Human, în timpul rulării pe procesorul Intel® Core™ Ultra 7 165H. Pentru detalii, a se consulta www.intel.com/PerformanceIndex. Rezultatele pot varia.",
          "⁴⁵În funcție de puterea SoC mai mică a platformei de validare de referință a procesorului mobil Intel®&nbsp;Core™ Ultra (Seria 2 - Nume de cod: Lunar Lake) vs. platforma de validare de referință a procesorului mobil Intel®&nbsp;Core™ Ultra (Seria 1 - Nume de cod: Meteor Lake) pe YouTube* 4K 30FPS AV1. A se vedea intel.com/performanceindex pentru detalii. Rezultatele pot varia.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel nu-și asumă răspunderea pentru prețuri incorecte.",
          "*Alte denumiri și mărci pot fi revendicate ca fiind proprietatea altor părți.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES ȘI MĂRCILE COMPOZITE ȘI DE DESIGN SUNT MĂRCI COMERCIALE ALE INFLEXION STUDIOS INC. TOATE DREPTURILE REZERVATE.",
          "Tehnologiile Intel ar putea avea nevoie de hardware și software activat sau servicii activate.",
          "Toate rezultatele și datele NERO* sunt furnizate exclusiv pentru facilitatea utilizării și cu scop informativ. Intel Corporation nu își asumă nicio responsabilitate pentru, de exemplu, precizia aplicației NERO*, rezultatele și scorurile de performanță sau pentru posibile variații de la alte versiuni descărcabile ale aplicației disponibile pe www.nero.com sau pe alte site-uri web terțe. Scorurile testelor de referință NERO* pentru PC sunt măsurate utilizând sisteme, componente, software, operațiuni și funcții de computer specifice. Orice modificare asupra acestora poate duce la modificarea rezultatelor. Vă sfătuim să consultați și alte informații și teste de performanță pentru a vă ajuta să evaluați corect produsul pe care intenționați să îl achiziționați, inclusiv performanța acelui produs atunci când este combinat cu altele.",
          "Toate versiunile de platformă Intel vPro® necesită un procesor Intel® Core™ eligibil, un sistem de operare acceptat, siliciu LAN și/sau WLAN Intel®, îmbunătățiri firmware, precum și alte componente hardware și software necesare pentru a furniza cazurile de utilizare privind manevrabilitatea, caracteristicile de securitate, performanța sistemului și stabilitatea care definesc platforma.",
          "Performanța variază în funcție de utilizare, configurare și alți factori. Aflați mai multe la www.intel.com/PerformanceIndex.",
          "Intel nu controlează și nu auditează datele furnizate de terți. Pentru a evalua precizia acestora, ar trebui să consultați și alte surse.",
          "© Intel Corporation. Intel, sigla Intel și alte mărci Intel sunt mărci comerciale ale Intel Corporation sau ale subsidiarelor acesteia. *Alte denumiri și mărci pot fi revendicate ca fiind proprietatea altor părți.",
          "Niciun produs sau componentă nu poate oferi siguranță totală.",
          "Costurile și rezultatele pot varia.",
          "Intel, sigla Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt și sigla Thunderbolt sunt mărci comerciale ale Intel Corporation sau ale subsidiarelor sale. Alte denumiri și mărci pot fi revendicate ca fiind proprietatea altor părți.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Pagina principală",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Scanează pentru a compara",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tendințe",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Activează experiențe noi IA cu ajutorul procesoarelor Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Activează experiențe noi IA cu ajutorul procesoarelor Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Activează experiențe noi IA cu ajutorul procesoarelor Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Text în imagine",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Editare foto",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Mărirea imaginii",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Efecte de colaborare (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Controlul gesturilor",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Sincronizează-ți PC-ul cu telefonul și vezi notificările, apelurile și mesajele text",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Grafică bogată și vibrantă integrată",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Trăiește-ți viața în felul tău",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Totul despre tine. Totul dintr-o privire, mereu la o atingere distanță",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Fă-ți procesorul să ruleze mai rapid și la frecvențe mai mari prin optimizarea sistemului",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Îmbunătățește-ți performanța sistemului desktop sau mobil fără a sacrifica spațiul de stocare",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Îmbunătățește-ți performanța sistemului desktop sau mobil fără a sacrifica spațiul de stocare",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: O încântare pentru ochii tăi",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Viteză, latență și fiabilitate îmbunătățite prin optimizarea rețelei bazată pe IA",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Rețele de PC asemănătoare celor cu fir",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Rețele de PC asemănătoare celor cu fir",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Viteze de aproape de 3 ori mai mari cu Intel®&nbsp;Wi-&#xfeff;Fi&nbsp;6&nbsp;(Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Conectează-te fără competiție",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Conectivitate prin cablu cu adevărat universală",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Joacă-te ca un profesionist: Descoperă cum Intel oferă putere jocurilor și gamerilor",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Joacă-te ca un profesionist: Campionii se formează cu Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "BUN VENIT",
    "tr_welcomePagetrSubtitle": "Controlează acest PC de la distanță cu smartphone-ul tău",
    "tr_welcomePagembSubtitle": "Bine ai venit, explorează specificațiile acestui PC pe smartphone-ul tău",
    "tr_welcomePageBtnText": "Începe",
    "tr_welcomePageText": "Explorează și compară detaliile acestui PC de pe smartphone-ul tău",
    "tr_scrollText": "Navighează pe dispozitivul din fața ta",
    "tr_toolTipTextc1": "Utilizează aceste butoane pentru a naviga la diferite pagini de pe dispozitivul din fața ta.",
    "tr_toolTipTextc2": "Atingând cardurile de mai jos se va naviga la o pagină detaliată a fiecărei specificații de dispozitiv.",
    "tr_toolTipTextc3": "Utilizează aceste butoane pentru a naviga în sus și în jos pe ecranul din fața ta.",
    "tr_toolTipTextc4": "Oprește modul de la distanță pentru a vedea specificațiile dispozitivului de pe telefonul tău smartphone.",
    "tr_toolTipTextmb1": "Atinge cardurile pentru a vedea paginile de conținut detaliate.",
    "tr_toolTipTextmb2": "Atinge pentru a vedea un rezumat al tuturor specificațiilor dispozitivelor",
    "tr_toolTipTextmb3": "Atinge butonul pentru a scana codul QR și pentru a compara până la 4 dispozitive",
    "tr_nextButton": "Înainte",
    "tr_prevButton": "Anterior",
    "tr_gotItButton": "AM ÎNȚELES",
    "tr_skipButton": "Ignoră",
    "tr_placeholderText": "*substituent",
    "tr_processorHeading": "Procesor",
    "tr_processorSubheading": "Procesor Intel® Core™ i7 din generația a&nbsp;10‑a",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Memorie Intel® Optane™ de [XX] GB + SSD DE [YY] GB",
    "tr_coachmarkProcessor": "Procesor XX",
    "tr_coachmarkGraphics": "Grafică [XX]",
    "tr_coachmarkMemory": "Valoarea specificațiilor",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 USD",
    "tr_compare": "Compară",
    "tr_viewallspecs": "VEZI TOATE SPECIFICAȚIILE",
    "tr_operatingSystem": "SO",
    "tr_AmdProcessorNonIntel": "Procesor AMD*",
    "tr_QualcommProcessorNonIntel": "Procesor Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Specificații afișate pe ecranul laptopului",
    "tr_scanErrorText": "Poți scana până la 3 dispozitive simultan. Șterge unul sau mai multe dispozitive.",
    "tr_scanErrorText2": "Ai scanat deja acest dispozitiv, te rugăm să scanezi altul.",
    "tr_scanMsg": "Scanează pentru a adăuga dispozitivul",
    "tr_backToScan": "Înapoi la comparație",
    "tr_scanToCompare": "Scanează pentru a comparara",
    "tr_compareDevice": "Compară dispozitive",
    "tr_processorTitle": "PROCESOR",
    "tr_graphicTitle": "GRAFICĂ",
    "tr_storageTitle": "STOCARE",
    "tr_displayTitle": "DISPLAY",
    "tr_batteryTitle": "BATERIE",
    "tr_memoryTitle": "MEMORIE",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "CONECTIVITATE",
    "tr_priceTitle": "PREȚ",
    "tr_operatingSystemTitle": "SISTEM DE OPERARE",
    "tr_batteryHoursText": "[XX] ore",
    "tr_hrsText": "ore",
    "tr_touchscreeenText": "Ecran tactil de [screenDiagonal]″",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, ecran tactil",
    "tr_FHDTouchScreenText": "FHD, ecran tactil",
    "tr_4KTouchscreenText": "4K, ecran tactil",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Salvare",
    "tr_device_comparison": "Documentul de comparație a dispozitivelor [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "Salvează în format JPEG",
    "tr_Save_As_PDF": "Salvează ca PDF",
    "tr_Downloading_PDF": "Se descarcă PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Deschide",
    "tr_logo": "siglă",
    "tr_laptop_name": "Numele laptopului",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Memoria Intel® Optane™",
    "tr_inchUnit": "inchi",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MEMORIE INTEL® OPTANE™",
    "tr_Thunderbolt3": "Tehnologia Thunderbolt™ 3",
    "tr_Thunderbolt4": "Tehnologia Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "PROCESOR GRAFIC",
    "tr_processorGraphicsSmall": "Procesor grafic",
    "tr_graphicsCardCaps": "GRAFICĂ DEDICATĂ",
    "tr_graphicsCardSmall": "Grafică dedicată",
    "tr_processorTitleCamel": "Procesor",
    "tr_graphicTitleCamel": "Grafică",
    "tr_storageTitleCamel": "Spațiu de stocare",
    "tr_displayTitleCamel": "Display",
    "tr_batteryTitleCamel": "Baterie",
    "tr_memoryTitleCamel": "Memorie",
    "tr_connectivityTitleCamel": "Conectivitate",
    "tr_priceTitleCamel": "Prețul",
    "tr_operatingSystemTitleCamel": "Sistem de operare",
    "tr_viewallspecsCamel": "Vezi toate specificațiile",
    "tr_displayText": "[screenDiagonal]″",
    "tr_displayTextResolution": "[screenResolutionType] de [screenDiagonal]″",
    "tr_displayTextResolutionTouchscreen": "Ecran tactil [screenResolutionType] de [screenDiagonal]″",
    "tr_OS": "SO",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Specificații PC",
    "tr_explorePC_text": "Explorează totul despre acest PC",
    "tr_mtlPC_text": "Vezi ce înseamnă cu adevărat să faci totul prin inginerie",
    "tr_optaneMemory": "Memoria Intel® Optane™",
    "tr_displayText_Display": "ECRAN DE [screenDiagonal]″",
    "tr_displayTextResolution_Display": "ECRAN DE [screenDiagonal]″ [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "ECRAN TACTIL DE [screenDiagonal]″ [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "ECRAN TACTIL DE [screenDiagonal]″",
    "tr_learnMoreLabel": "Află mai multe",
    "tr_batteryHoursTextUpperCase": "[XX] ORE",
    "tr_processorGraphicsIrisXe": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 11-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 11-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TEHNOLOGIA THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TEHNOLOGIA THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 12-A ȘI GRAFICĂ INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 12-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 12-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 13-A ȘI GRAFICĂ&nbsp;INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 13-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] DIN GENERAȚIA A 13-A ȘI GRAFICĂ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFICA INTEL® ARC™ A350M",
      "tr_A370M": "GRAFICA INTEL® ARC™ A370M",
      "tr_A730M": "GRAFICA INTEL® ARC™ A730M",
      "tr_A770M": "GRAFICA INTEL® ARC™ A770M",
      "tr_A310": "GRAFICA INTEL® ARC™ A310",
      "tr_A380": "GRAFICA INTEL® ARC™ A380",
      "tr_A580": "GRAFICA INTEL® ARC™ A580",
      "tr_A750": "GRAFICA INTEL® ARC™ A750",
      "tr_A770": "GRAFICA INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "Memorie [Value1] GB = memorie Intel® Optane™ [Value2] GB + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "Memorie Intel®&nbsp;Optane™ de [XX]&nbsp;GB +&nbsp;SSD de [convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Memorie Intel®&nbsp;Optane™ de [XX]&nbsp;GB +&nbsp;SSD de [convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home în modul S",
      "tr_OS21": "Windows* 10 Pro în modul S",
      "tr_OS22": "Windows* 10 Enterprise în modul S",
      "tr_OS23": "Windows* 11 Home în modul S",
      "tr_OS24": "Windows* 11 Pro în modul S",
      "tr_OS25": "Windows* 11 Enterprise în modul S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafică Intel®&nbsp;Arc™&nbsp;A310",
      "tr_GR387": "Grafică Intel®&nbsp;Arc™&nbsp;A350M",
      "tr_GR388": "Grafică Intel®&nbsp;Arc™&nbsp;A370M",
      "tr_GR389": "Grafică Intel®&nbsp;Arc™&nbsp;A380",
      "tr_GR390": "Grafică Intel®&nbsp;Arc™&nbsp;A530M",
      "tr_GR391": "Grafică Intel®&nbsp;Arc™&nbsp;A550M",
      "tr_GR392": "Grafică Intel®&nbsp;Arc™&nbsp;A570M",
      "tr_GR393": "Grafică Intel®&nbsp;Arc™&nbsp;A580",
      "tr_GR394": "Grafică Intel®&nbsp;Arc™&nbsp;A730M",
      "tr_GR395": "Grafică Intel®&nbsp;Arc™&nbsp;A750",
      "tr_GR396": "Grafică Intel®&nbsp;Arc™&nbsp;A770",
      "tr_GR397": "Grafică Intel®&nbsp;Arc™&nbsp;A770M",
      "tr_GR398": "Grafică Intel® Arc™",
      "tr_GR399": "Grafică Intel®&nbsp;Arc™&nbsp;Pro A30M",
      "tr_GR400": "Grafică Intel®&nbsp;Arc™&nbsp;Pro A40/A50",
      "tr_GR401": "Grafică Intel®&nbsp;Arc™&nbsp;Pro A60",
      "tr_GR402": "Grafică Intel®&nbsp;Arc™&nbsp;Pro A60M",
      "tr_GR403": "Grafică Intel®",
      "tr_GR404": "Grafică HD&nbsp;Intel®&nbsp;2000",
      "tr_GR405": "Grafică HD&nbsp;Intel®&nbsp;2500",
      "tr_GR406": "Grafică HD&nbsp;Intel®&nbsp;3000",
      "tr_GR407": "Grafică HD&nbsp;Intel®&nbsp;400",
      "tr_GR408": "Grafică HD&nbsp;Intel®&nbsp;4000",
      "tr_GR409": "Grafică HD&nbsp;Intel®&nbsp;405",
      "tr_GR410": "Grafică HD&nbsp;Intel®&nbsp;4200",
      "tr_GR411": "Grafică HD&nbsp;Intel®&nbsp;4400",
      "tr_GR412": "Grafică HD&nbsp;Intel®&nbsp;4600",
      "tr_GR413": "Grafică HD&nbsp;Intel®&nbsp;500",
      "tr_GR414": "Grafică HD&nbsp;Intel®&nbsp;5000",
      "tr_GR415": "Grafică HD&nbsp;Intel®&nbsp;505",
      "tr_GR416": "Grafică HD&nbsp;Intel®&nbsp;510",
      "tr_GR417": "Grafică HD&nbsp;Intel®&nbsp;515",
      "tr_GR418": "Grafică HD&nbsp;Intel®&nbsp;520",
      "tr_GR419": "Grafică HD&nbsp;Intel®&nbsp;530",
      "tr_GR420": "Grafică HD&nbsp;Intel®&nbsp;5300",
      "tr_GR421": "Grafică HD&nbsp;Intel®&nbsp;5500",
      "tr_GR422": "Grafică HD&nbsp;Intel®&nbsp;5600",
      "tr_GR423": "Grafică HD&nbsp;Intel®&nbsp;6000",
      "tr_GR424": "Grafică HD&nbsp;Intel®&nbsp;610",
      "tr_GR425": "Grafică HD&nbsp;Intel®&nbsp;615",
      "tr_GR426": "Grafică HD&nbsp;Intel®&nbsp;620",
      "tr_GR427": "Grafică HD&nbsp;Intel®&nbsp;630",
      "tr_GR428": "Grafică Intel® HD",
      "tr_GR429": "Grafică Intel® Iris® 5100",
      "tr_GR430": "Grafică Intel® Iris® 540",
      "tr_GR431": "Grafică Intel® Iris® 550",
      "tr_GR432": "Grafică Intel® Iris® 6100",
      "tr_GR433": "Grafică Intel® Iris® Plus 640",
      "tr_GR434": "Grafică Intel® Iris® Plus 645",
      "tr_GR435": "Grafică Intel® Iris® Plus 650",
      "tr_GR436": "Grafică Intel® Iris® Plus 655",
      "tr_GR437": "Grafică Intel® Iris® Plus",
      "tr_GR438": "Grafică Intel® Iris® Pro 5200",
      "tr_GR439": "Grafică Intel® Iris® Pro 580",
      "tr_GR440": "Grafică Intel® Iris® Pro 6200",
      "tr_GR441": "Grafică Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafică Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafică Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Grafică integrată Intel®",
      "tr_GR445": "Grafică UHD Intel® 600",
      "tr_GR446": "Grafică UHD Intel® 605",
      "tr_GR447": "Grafică UHD Intel® 610",
      "tr_GR448": "Grafică UHD Intel® 615",
      "tr_GR449": "Grafică UHD Intel® 617",
      "tr_GR450": "Grafică UHD Intel® 620",
      "tr_GR451": "Grafică UHD Intel® 630",
      "tr_GR452": "Grafică UHD Intel® 710",
      "tr_GR453": "Grafică UHD Intel® 730",
      "tr_GR454": "Grafică UHD Intel® 770",
      "tr_GR455": "Grafică UHD Intel®",
      "tr_GR456": "Grafică UHD Intel®",
      "tr_GR457": "Grafică UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 cu design Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti cu design Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 cu design Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 cu design Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 cu design Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 cu design Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti cu design Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti cu design Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 cu design Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 cu design Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super cu design Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 cu design Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super cu design Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 cu design Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 cu design Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 cu design Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 cu design Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 cu design Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 cu design Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 cu design Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 cu design Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 cu design Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 cu design Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Grafică Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Cumpără acum",
    "tr_textTryagain": "Încearcă din nou",
    "tr_textBuynowErr": "Din cauza unei întreruperi de conexiune, opțiunea Cumpără acum nu este disponibilă",
    "tr_chatbotPlaceholder_text": "Întreabă-mă orice despre dispozitiv",
    "tr_deviceSpecs_text": "Specificațiile dispozitivului"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel® Core™ i3 cu tehnologia Intel® Hybrid"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel® Core™ i5 cu tehnologia Intel® Hybrid"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel’s Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Echipează-te cu tehnologia Intel®, pentru o experiență de gaming mai puternică",
                "tr_subtitle": "Adu la viață Marvel's Avengers* cu optimizările din joc ale PC-ului cu tehnologie Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Experiența Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Unde vin cei mai buni gameri din lume pentru a concura",
                "tr_subtitle": "Cel mai longeviv eveniment care rulează în istoria jocurilor video, Intel® Extreme Masters, stabilește standardele pentru e-sports.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Joacă-te ca un profesionist",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Jucătorii devin campioni cu Intel.",
                "tr_subtitle": "După câștigarea campionatului Rainbow Six Siege* a Ligii din America de Nord din 2021, Soniqs reflectă asupra istoriei sale de gaming, la viitorul e-sports și la parteneriatul cu Intel.",
                "tr_pretitle": "Faceți cunoștință cu Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Pregătit pentru VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Acolo unde realitatea virtuală prinde viață",
                "tr_subtitle": "Cele mai uimitoare, naturale și captivante experiențe de realitate virtuală, rulate pe un computer. Alături de cel mai recent procesor Intel® Core™ poți fi oricine, poți merge oriunde și experimenta jocuri într-o altă dimensiune.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Joacă peste 100 de jocuri de înaltă calitate pentru consolă, precum Sea of Thieves* și Psychonauts* 2 pe PC-ul tău cu Windows*, telefonul mobil și tabletă, cu Xbox* Game Pass Ultimate și un controler compatibil.",
                "titleArray": [
                  {
                    "tr_title": "Jucați împreună pe toate dispozitivele",
                    "tr_subtitle": "Pătrunde în universul Xbox*, cu o comunitate de milioane de jucători pregătiți să jucați împreună. Conectează-te și joacă cu alți utilizatori, accesând o bibliotecă de jocuri utilizată în comun, indiferent dacă aceștia se află în cealaltă parte a lumii sau lângă tine."
                  },
                  {
                    "tr_title": "Gaming pe PC-ul cu Windows*",
                    "tr_subtitle": "Joacă dintr-un catalog de jocuri pentru consolă din cloud, folosind aplicația Xbox* pentru Windows* și un controler compatibil."
                  },
                  {
                    "tr_title": "Alege și joacă",
                    "tr_subtitle": "Bucură-te de jocuri Xbox* excelente în mai multe locuri decât înainte. Joacă peste 100 de jocuri pe PC-ul tău, pe telefonul mobil sau pe tabletă, indiferent dacă ești acasă, cu televizorul deschis, la o cafenea, sau oriunde ai o conexiune la internet de mare viteză."
                  },
                  {
                    "tr_title": "Distracție fără controler",
                    "tr_subtitle": "Gamingul cu control tactil pe Xbox* deblochează noi moduri de a accesa și de a juca, care oferă o experiență familiară și acceptă un nivel de joc cu care ești obișnuit, cu un controler fizic."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Peste 100 de experiențe IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Controlează mai mult, atinge mai puțin",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Rămâi în cadru, în orice mediu",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativitate",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Corectează luminozitatea ca&nbsp;un&nbsp;profesionist",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Adaugă efecte impresionante, cu&nbsp;ușurință",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remixează melodii, cu&nbsp;puterea&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivitate",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intensifică-ți productivitatea și&nbsp;confidențialitatea",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Petrece mai mult timp departe de priză",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Divertisment",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming fluid, chiar și cu laptopul deconectat de la priză",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Gaming rapid, captivant",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Peste 100 de experiențe IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Fă ca melodiile tale preferate să îți aparțină cu adevărat",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Urmărește-ți viziunea în timp real",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativitate",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cea mai rapidă cale către montajul tău final",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Fă ca fiecare imagine să fie perfectă",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivitate",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intensifică-ți productivitatea și&nbsp;confidențialitatea",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mai eficient. Mai multă libertate.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Divertisment",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming HD fluid, chiar și cu laptopul deconectat de la priză",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Gaming captivant, în mai multe locuri",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mai creativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Editarea foto, simplificată",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Creează fotografii demne de pus în ramă în câteva secunde",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Tu scrii. IA face.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai multă performanță",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Codul de trișare pentru un joc mai fluent",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Petrece mai mult timp departe de priză",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra captivant",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai productiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Însoțitorul tău zilnic cu IA",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfecționarea apelului video",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai sigur",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protecții integrate pentru a te ajuta să-ți păstrezi datele mai sigure și mai private",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mai creativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Editarea foto, simplificată",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. Scurtătura către montajul tău final.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transcrie rapid versurile de la melodiile tale preferate",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Economisește ore de editare cu ajutorul IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai multă performanță",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Codul de trișare pentru un joc mai fluent",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Petrece mai mult timp departe de priză",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra captivant",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai productiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Însoțitorul tău zilnic cu IA",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfecționarea apelului video",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mai sigur",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protecții integrate pentru a te ajuta să-ți păstrezi datele mai sigure și mai private",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tot divertismentul tău. Un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pentru un PC care funcționează și ca un centru de divertisment, ai nevoie de spațiu de stocare suficient pentru toate melodiile, fișierele video și jocurile preferate dar ai și combinația perfectă de procesor și ecran, pentru a crea o grafică captivantă. Acest PC le are pe toate trei.",
                "titleArray": [
                  {
                    "tr_title": "Pregătit pentru grafica de generație următoare",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming și streaming fără cusur",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fii mai productiv. Oriunde.",
              "tr_tileTitle1": "Realizează mai mult. Rapid.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Acest PC are echilibrul perfect de putere și portabilitate. Începând cu un procesor creat pentru designuri subțiri și ușoare, acest PC mai deține și modelul de Wi&#8209;Fi care să te țină conectat și bateria care să te țină deconectat.",
                "tr_subtitle1": "Acest PC are echilibrul perfect de putere și performanță pentru a te ajuta să realizezi mai multe într&#8209;un timp mai scurt. Combinația unui procesor robust accelerat de o memorie generoasă și un Wi&#8209;Fi incredibil de rapid îți vor accelera sarcinile zilnice.",
                "tr_subtitle2": "Acest PC oferă un echilibru perfect între putere și portabilitate. Pe lângă un procesor conceput pentru a fi subțire și ușor, acest PC dispune de conectivitatea Wi-Fi de care ai nevoie pentru a rămâne conectat și de memoria necesară pentru multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Creat pentru a face mai mult",
                    "tr_title1": "Performanțe inteligente care contează",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Cele mai bune conexiuni din clasă",
                    "tr_title1": "Conectează-te la ce contează",
                    "tr_title2": "O dezvoltare importantă a Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Rămâi deconectat, mai mult timp",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Navighează ca un profesionist printre programe",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Libertatea de a crea",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Creația de conținut este una dintre cele mai solicitante sarcini pentru PC-ul tău. Din fericire, aceste componente au puterea de&nbsp;a&#8209;ți eficientiza procesul creativ.",
                "titleArray": [
                  {
                    "tr_title": "Un mod mai inteligent de a crea",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Adu-ți imaginile la viață",
                    "tr_title1": "Hai să creăm",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memorie pentru multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Uită-te în acest PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Acestea sunt cele trei componente principale de luat în considerare atunci când alegi un PC nou. Dacă decizi cum îți vei utiliza noul PC, deja ai parcurs un drum lung spre a te hotărî câte dintre aceste trei componente îți vor trebui. Acestea sunt:",
                "titleArray": [
                  {
                    "tr_title": "Performanțe pentru gaming și streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Spațiu de stocare",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memorie",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tot divertismentul tău. Un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pentru un PC care funcționează și ca un centru de divertisment, ai nevoie de spațiu de stocare suficient pentru toate melodiile, fișierele video și jocurile preferate dar ai și combinația perfectă de procesor și ecran, pentru a crea o grafică captivantă. Acest PC le are pe toate trei.",
                "titleArray": [
                  {
                    "tr_title": "Pregătit pentru grafica de generație următoare",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming și streaming fără cusur",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memorie",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Display",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitasking de mare viteză",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING DE MARE VITEZĂ",
                "tr_subtitle": "Realizează mai multe, fără a încetini ritmul",
                "tr_subtitle1": "Iată ce elemente excelente pentru multitasking am găsit în acest PC:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate naviga printre mai multe programe simultan."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "Memorie care comută între aplicații fără întreruperi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Conexiuni la calitate excelentă",
                "tr_subtitle1": "Iată ce elemente excelente pentru chat video am găsit în acest PC:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate înregistra și distribui imagini în același timp."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "O cameră web care te va ajuta să apari foarte clar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pregătit pentru VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Acolo unde realitatea virtuală prinde viață",
                "tr_subtitle": "De ce este nevoie pentru a crea o lume complet virtuală? Pe un PC vei avea nevoie de coordonarea dintre un procesor, placă grafică și memorie.",
                "titleArray": [
                  {
                    "tr_title": "Alimentarea lumilor virtuale",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realitatea se află în detalii",
                    "tr_title1": "Lasă-te captivat",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Editare video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITARE VIDEO",
                "tr_subtitle": "Fă magie de film",
                "tr_subtitle1": "Iată ce elemente excelente pentru editarea video am găsit în acest PC:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate gestiona fișiere de mari dimensiuni."
                  },
                  {
                    "tr_title": "Memorie care face editarea software rapidă și receptivă."
                  },
                  {
                    "tr_title": "Grafică ce reduce timpii de randare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitasking de mare viteză",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING DE MARE VITEZĂ",
                "tr_subtitle": "Realizează mai multe, fără a încetini ritmul",
                "tr_subtitle1": "Iată ce elemente excelente pentru multitasking am găsit în acest PC:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate naviga printre mai multe programe simultan."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "Memorie care comută între aplicații fără întreruperi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pregătit pentru VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Acolo unde realitatea virtuală prinde viață",
                "tr_subtitle": "De ce este nevoie pentru a crea o lume complet virtuală? Pe un PC vei avea nevoie de coordonarea dintre un procesor, placă grafică și memorie.",
                "titleArray": [
                  {
                    "tr_title": "Alimentarea lumilor virtuale",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realitatea se află în detalii",
                    "tr_title1": "Lasă-te captivat",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modelare 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAREA 3D",
                "tr_subtitle": "Creații de nivel superior",
                "tr_subtitle1": "Iată ce elemente excelente pentru modelarea 3D am găsit în acest PC:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate gestiona software de creație complex."
                  },
                  {
                    "tr_title": "Memorie care poate arăta rapid fișiere de mari dimensiuni."
                  },
                  {
                    "tr_title": "Grafică pentru creare și editare puternice de imagini."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaborare în timp real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORARE ÎN TIMP REAL",
                "tr_subtitle": "Lucrează de oriunde",
                "tr_subtitle1": "Iată ce elemente excelente pentru colaborările online am găsit în acest PC:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care să alimenteze software pentru lucrul la birou."
                  },
                  {
                    "tr_title": "Wi‑Fi pe care te poți baza."
                  },
                  {
                    "tr_title": "O cameră web care înregistrează la calitate HD uluitoare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pregătit pentru VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Acolo unde realitatea virtuală prinde viață",
                "tr_subtitle": "De ce este nevoie pentru a crea o lume complet virtuală? Pe un PC vei avea nevoie de coordonarea dintre un procesor, placă grafică și memorie.",
                "titleArray": [
                  {
                    "tr_title": "Alimentarea lumilor virtuale",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realitatea se află în detalii",
                    "tr_title1": "Lasă-te captivat",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptopuri așa de subțiri și ușoare nu ar trebui să fie atât de puternice",
                "tr_subtitle": "Proiectate pentru performanțe mobile, laptopurile Intel® Evo™ au toată viteza, grafica și durata de viață a bateriei de care ai nevoie, toate în cele mai uluitoare modele subțiri și ușoare.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Rezolvă rapid lucrurile",
                    "tr_subtitle": "Bucură-te de performanțe ultrareceptive cu procesoarele Intel®&nbsp;Core™&nbsp;din generația a 11-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Pornește un chat video sau partajează fișiere cu o conexiune pe care poți conta, utilizând Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Totul la dispoziția ta, într-un laptop subțire și ușor.",
                "tr_subtitle": "Laptopurile Intel® Evo™ sunt proiectate pentru performanță mobilă și sunt concepute să îți ofere cea mai bună experiență<sup>2</sup>, indiferent de sarcini.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Performanțe revoluționare",
                    "tr_subtitle": "Obține performanțe suplimentare acolo unde ai nevoie cel mai mult, cu ajutorul performanțelor inteligente din procesoarele Intel® Core™ din generația a 12-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Comunică prin chat video sau partajează fișiere, folosind o conexiune în care poți avea încredere, utilizând Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptopuri așa de subțiri și ușoare nu ar trebui să fie atât de puternice",
                "tr_subtitle": "Proiectate pentru performanțe mobile, laptopurile Intel® Evo™ au toată viteza, grafica și durata de viață a bateriei de care ai nevoie, toate în cele mai uluitoare modele subțiri și ușoare.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Rezolvă rapid lucrurile",
                    "tr_subtitle": "Bucură-te de performanțe ultrareceptive cu procesoarele Intel®&nbsp;Core™&nbsp;din generația a 11-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Pornește un chat video sau partajează fișiere cu o conexiune pe care poți conta, utilizând Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Totul la dispoziția ta, într-un laptop subțire și ușor.",
                "tr_subtitle": "Laptopurile Intel® Evo™ sunt proiectate pentru performanță mobilă și sunt concepute să îți ofere cea mai bună experiență<sup>2</sup>, indiferent de sarcini.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Performanțe revoluționare",
                    "tr_subtitle": "Obține performanțe suplimentare acolo unde ai nevoie cel mai mult, cu ajutorul performanțelor inteligente din procesoarele Intel® Core™ din generația a 12-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Comunică prin chat video sau partajează fișiere, folosind o conexiune în care poți avea încredere, utilizând Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Totul la dispoziția ta, într-un laptop subțire și ușor.",
                "tr_subtitle": "Laptopurile Intel® Evo™ sunt proiectate pentru performanță mobilă și sunt concepute să îți ofere cea mai bună experiență<sup>2</sup>, indiferent de sarcini.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Performanțe revoluționare",
                    "tr_subtitle": "Obține performanțe suplimentare acolo unde ai nevoie cel mai mult, cu ajutorul performanțelor inteligente din procesoarele Intel® Core™ din generația a 13-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Comunică prin chat video sau partajează fișiere, folosind o conexiune în care poți avea încredere, utilizând Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptopurile Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Totul la dispoziția ta, într-un laptop subțire și ușor.",
                "tr_subtitle": "Laptopurile Intel® Evo™ sunt proiectate pentru performanță mobilă și sunt concepute să îți ofere cea mai bună experiență<sup>2</sup>, indiferent de sarcini.",
                "titleArray": [
                  {
                    "tr_title": "Laptopul înseamnă mai mult ca niciodată, astfel încât ne-am asigurat că poate face mai multe ca niciodată.",
                    "tr_subtitle": "Folosește-ți ecranul tactil pentru a explora, a roti și a face zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Pornire instantanee",
                    "tr_subtitle": "Datorită pornirii instantanee, PC-ul tău poate trece de la starea de repaus la modul activ în mai puțin de o secundă."
                  },
                  {
                    "tr_title": "Vizualizare imersivă",
                    "tr_subtitle": "Un afișaj frumos îți aduce elementele vizuale la viață&nbsp;în&nbsp;rezoluție clară și culori vii."
                  },
                  {
                    "tr_title": "Performanțe revoluționare",
                    "tr_subtitle": "Obține performanțe suplimentare acolo unde ai nevoie cel mai mult, cu ajutorul performanțelor inteligente din procesoarele Intel® Core™ din generația a 13-a."
                  },
                  {
                    "tr_title": "Rămâi deconectat mai mult timp",
                    "tr_subtitle": "Rămâi activ cu un PC care optimizează durata de viață a bateriei tale și se reîncarcă instantaneu."
                  },
                  {
                    "tr_title": "Conectivitate prin cablu universal",
                    "tr_subtitle": "Face conectarea altor dispozitive în PC-ul tău să fie simplă, rapidă și sigură cu ajutorul tehnologiei Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiuni rapide și fiabile",
                    "tr_subtitle": "Comunică prin chat video sau partajează fișiere, folosind o conexiune în care poți avea încredere, utilizând Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ce este Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "De ce Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativitate",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fii mai creativ în mai multe locuri",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Editarea fotografiilor la&nbsp;viteză&nbsp;maximă",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Creează artă originală, pe loc",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivitate",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximizează productivitatea cu Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detectează fișierele deepfake înainte de a le partaja cu&nbsp;ajutorul&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitate",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fă cu mâna pentru controlul gesturilor",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Durată de viață a bateriei care nu te va ține pe loc",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Te menține conectat automat",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ce este Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "De ce Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativitate",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Redare video rapidă, și fluidă",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Creează hituri cu&nbsp;puterea&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Cel mai rapid mod către versiunea finală",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivitate",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximizează productivitatea cu Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detectează fișierele deepfake înainte de a le partaja cu&nbsp;ajutorul&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Lumea ta. Pe dispozitivele tale.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitate",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Colaborează practic de oriunde",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Durată de viață a bateriei care nu te va ține pe loc",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Te menține conectat automat",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tot divertismentul tău. Un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pentru un PC care funcționează și ca un centru de divertisment, ai nevoie de spațiu de stocare suficient pentru toate melodiile, fișierele video și jocurile preferate dar ai și combinația perfectă de procesor și ecran, pentru a crea o grafică captivantă. Acest PC le are pe toate trei.",
                "titleArray": [
                  {
                    "tr_title": "Gaming și streaming impresionante",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming și streaming fără cusur",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creează biblioteca digitală personală",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "O fereastră pentru divertismentul tău",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fii mai productiv. Oriunde.",
              "tr_tileTitle1": "Realizează mai mult. Rapid.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Acest PC are echilibrul perfect de putere și portabilitate. Începând cu un procesor creat pentru designuri subțiri și ușoare, acest PC mai deține și modelul de Wi&#8209;Fi care să te țină conectat și bateria care să te țină deconectat.",
                "tr_subtitle1": "Acest PC are echilibrul perfect de putere și performanță pentru a te ajuta să realizezi mai multe într&#8209;un timp mai scurt. Combinația unui procesor robust accelerat de o memorie generoasă și un Wi&#8209;Fi incredibil de rapid îți vor accelera sarcinile zilnice.",
                "tr_subtitle2": "Acest PC oferă un echilibru perfect între putere și portabilitate. Pe lângă un procesor conceput pentru a fi subțire și ușor, acest PC dispune de conectivitatea Wi-Fi de care ai nevoie pentru a rămâne conectat și de memoria necesară pentru multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Performanțe inteligente care contează",
                    "tr_title1": "Accelerează cu ajutorul performanțelor inteligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Cele mai bune conexiuni din clasă",
                    "tr_title1": "Conectează-te la ce contează",
                    "tr_title2": "O dezvoltare importantă a Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Rămâi deconectat, mai mult timp",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Navighează ca un profesionist printre programe",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Libertatea de a crea",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Creația de conținut este una dintre cele mai solicitante sarcini pentru PC-ul tău. Din fericire, aceste componente au puterea de&nbsp;a&#8209;ți eficientiza procesul creativ.",
                "titleArray": [
                  {
                    "tr_title": "Creativitate accelerată",
                    "tr_title1": "Navighează printre proiectele creative",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creație elevată",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memorie pentru multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Uită-te în acest PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Acestea sunt cele trei componente principale de luat în considerare atunci când alegi un PC nou. Dacă decizi cum îți vei utiliza noul PC, deja ai parcurs un drum lung spre a te hotărî câte dintre aceste trei componente îți vor trebui. Acestea sunt:",
                "titleArray": [
                  {
                    "tr_title": "Procesor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Spațiu de stocare",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memorie",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Libertatea de a crea",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Creația de conținut este una dintre cele mai solicitante sarcini pentru PC-ul tău. Din fericire, aceste componente au puterea de&nbsp;a&#8209;ți eficientiza procesul creativ.",
                "titleArray": [
                  {
                    "tr_title": "Un mod mai inteligent de a crea",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Adu-ți imaginile la viață",
                    "tr_title1": "Hai să creăm",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memorie pentru multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fii mai productiv. Oriunde.",
              "tr_tileTitle1": "Realizează mai mult. Rapid.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Acest PC are echilibrul perfect de putere și portabilitate. Începând cu un procesor creat pentru designuri subțiri și ușoare, acest PC mai deține și modelul de Wi&#8209;Fi care să te țină conectat și bateria care să te țină deconectat.",
                "tr_subtitle1": "Acest PC are echilibrul perfect de putere și performanță pentru a te ajuta să realizezi mai multe într&#8209;un timp mai scurt. Combinația unui procesor robust accelerat de o memorie generoasă și un Wi&#8209;Fi incredibil de rapid îți vor accelera sarcinile zilnice.",
                "tr_subtitle2": "Acest PC oferă un echilibru perfect între putere și portabilitate. Pe lângă un procesor conceput pentru a fi subțire și ușor, acest PC dispune de conectivitatea Wi-Fi de care ai nevoie pentru a rămâne conectat și de memoria necesară pentru multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Creat pentru a face mai mult",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Cele mai bune conexiuni din clasă",
                    "tr_title1": "Conectează-te la ce contează",
                    "tr_title2": "O dezvoltare importantă a Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Rămâi deconectat, mai mult timp",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Navighează ca un profesionist printre programe",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pregătit pentru VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Acolo unde realitatea virtuală prinde viață",
                "tr_subtitle": "De ce este nevoie pentru a crea o lume complet virtuală? Pe un PC vei avea nevoie de coordonarea dintre un procesor, placă grafică și memorie.",
                "titleArray": [
                  {
                    "tr_title": "Alimentarea lumilor virtuale",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realitatea se află în detalii",
                    "tr_title1": "Lasă-te captivat",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>O încântare pentru&nbsp;ochii&nbsp;tăi",
      "tr_sub_header": "Lumina albastră poate să provoace disconfort vizual și să îți perturbe ciclul de somn. OLED-ul testat pe ecrane SGS Eye Care de la Samsung este util nu prin modificarea culorilor, ci prin reducerea lungimii de undă a luminii albastre potențial dăunătoare, oferind confort vizual și reducând oboseala oculară.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Mai puțină lumină albastră nocivă"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra, în toate privințele",
      "tr_sub_header": "Modelul ultra-ușor și ultra-subțire al OLED-ului maximizează calitatea imaginii, minimizând în același timp greutatea."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Negru pur de",
      "tr_sub_header": "0,0005 niți"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>convențional</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Distracție nesfârșită",
                "tr_subtitle1": "Iată ce elemente excelente pentru streaming am găsit în acest PC:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate reda în flux în HD."
                  },
                  {
                    "tr_title": "Un ecran pentru imagini frumoase, luminoase."
                  },
                  {
                    "tr_title": "Wi‑Fi care asigură mai puține întreruperi și timpi de încărcare mai reduși."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navigare pe web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVIGARE PE WEB",
                "tr_subtitle": "Internetul, simplificat",
                "tr_subtitle1": "Iată ce elemente excelente pentru navigarea pe web am găsit în acest PC:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate încărca rapid pagini cu multă publicitate."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid și de încredere."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Conexiuni la calitate excelentă",
                "tr_subtitle1": "Iată ce elemente excelente pentru chat video am găsit în acest PC:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate înregistra și distribui imagini în același timp."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "O cameră web care te va ajuta să apari foarte clar."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking de mare viteză",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING DE MARE VITEZĂ",
                "tr_subtitle": "Realizează mai multe, fără a încetini ritmul",
                "tr_subtitle1": "Iată ce elemente excelente pentru multitasking am găsit în acest PC:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate naviga printre mai multe programe simultan."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "Memorie care comută între aplicații fără întreruperi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Conexiuni la calitate excelentă",
                "tr_subtitle1": "Iată ce elemente excelente pentru chat video am găsit în acest PC:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate înregistra și distribui imagini în același timp."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "O cameră web care te va ajuta să apari foarte clar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gaming ocazional",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GAMING OCAZIONAL",
                "tr_subtitle": "Este timpul pentru joc",
                "tr_subtitle1": "Iată ce elemente excelente pentru gaming relaxat am găsit în acest PC:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care alimentează o experiență de joc fără întreruperi."
                  },
                  {
                    "tr_title": "Memorie care poate să ofere chat vocal și să efectueze streaming în același timp."
                  },
                  {
                    "tr_title": "Grafică care alimentează imagini uimitoare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editare foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITARE FOTO",
                "tr_subtitle": "Fotografii perfecte la nivel de pixeli",
                "tr_subtitle1": "Iată ce elemente excelente pentru editarea foto am găsit în acest PC:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un procesor cu puterea de a edita grupuri mari de imagini."
                  },
                  {
                    "tr_title": "Memorie care îți accelerează software-ul de editare."
                  },
                  {
                    "tr_title": "Tehnologia Thunderbolt™ 4 îți permite să transferi rapid fișiere."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking de mare viteză",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING DE MARE VITEZĂ",
                "tr_subtitle": "Realizează mai multe, fără a încetini ritmul",
                "tr_subtitle1": "Iată ce elemente excelente pentru multitasking am găsit în acest PC:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate naviga printre mai multe programe simultan."
                  },
                  {
                    "tr_title": "Wi‑Fi rapid, puternic și fiabil."
                  },
                  {
                    "tr_title": "Memorie care comută între aplicații fără întreruperi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaborare în timp real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORARE ÎN TIMP REAL",
                "tr_subtitle": "Lucrează de oriunde",
                "tr_subtitle1": "Iată ce elemente excelente pentru colaborările online am găsit în acest PC:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care să alimenteze software pentru lucrul la birou."
                  },
                  {
                    "tr_title": "Wi‑Fi pe care te poți baza."
                  },
                  {
                    "tr_title": "O cameră web care înregistrează la calitate HD uluitoare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editare video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITARE VIDEO",
                "tr_subtitle": "Fă magie de film",
                "tr_subtitle1": "Iată ce elemente excelente pentru editarea video am găsit în acest PC:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate gestiona fișiere de mari dimensiuni."
                  },
                  {
                    "tr_title": "Memorie care face editarea software rapidă și receptivă."
                  },
                  {
                    "tr_title": "Grafică ce reduce timpii de randare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editare foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITARE FOTO",
                "tr_subtitle": "Fotografii perfecte la nivel de pixeli",
                "tr_subtitle1": "Iată ce elemente excelente pentru editarea foto am găsit în acest PC:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un procesor cu puterea de a edita grupuri mari de imagini."
                  },
                  {
                    "tr_title": "Memorie care îți accelerează software-ul de editare."
                  },
                  {
                    "tr_title": "Tehnologia Thunderbolt™ 4 îți permite să transferi rapid fișiere."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Editare video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITARE VIDEO",
                "tr_subtitle": "Fă magie de film",
                "tr_subtitle1": "Iată ce elemente excelente pentru editarea video am găsit în acest PC:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate gestiona fișiere de mari dimensiuni."
                  },
                  {
                    "tr_title": "Memorie care face editarea software rapidă și receptivă."
                  },
                  {
                    "tr_title": "Grafică ce reduce timpii de randare."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modelare 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAREA 3D",
                "tr_subtitle": "Creații de nivel superior",
                "tr_subtitle1": "Iată ce elemente excelente pentru modelarea 3D am găsit în acest PC:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care poate gestiona software de creație complex."
                  },
                  {
                    "tr_title": "Memorie care poate arăta rapid fișiere de mari dimensiuni."
                  },
                  {
                    "tr_title": "Grafică pentru creare și editare puternice de imagini."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaborare în timp real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORARE ÎN TIMP REAL",
                "tr_subtitle": "Lucrează de oriunde",
                "tr_subtitle1": "Iată ce elemente excelente pentru colaborările online am găsit în acest PC:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesor care să alimenteze software pentru lucrul la birou."
                  },
                  {
                    "tr_title": "Wi‑Fi pe care te poți baza."
                  },
                  {
                    "tr_title": "O cameră web care înregistrează la calitate HD uluitoare."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplicația Intel® Unison™",
    "tr_title": "Deblochează&#8209;ți lumea conectată",
    "tr_subtitle": "Transferi fără probleme fișiere și fotografii în timp ce gestionezi notificările, apelurile și mesajele de pe PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFERĂ FIȘIERE ȘI FOTOGRAFII",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "EFECTUEAZĂ ȘI PRIMEȘTE APELURI",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "TRIMITE ȘI PRIMEȘTE MESAJE TEXT",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "GESTIONEAZĂ NOTIFICĂRILE DE TELEFON",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "DEBLOCHEAZĂ&#8209;ȚI LUMEA CONECTATĂ",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}