const Library_mobile_is = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knúin af [cpuDisplay] örgjörva"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knúin af [cpuDisplay] örgjörva"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Örgjörvinn stjórnar því sem tölvan gerir, líkt og heili. Það þýðir að myndböndin sem þú horfir á, leikirnir sem þú spilar og vefsíðurnar sem þú skoðar byrja alltaf hjá örgjörvanum.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Örgjörvinn er heili tölvunnar"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Afköst og virði.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver örgjörvi",
                    "tr_subtitle": "Haltu utan um stafræna tilveru þína með afköstum sem veita þér aðgang að skrám af vefnum. Skiptu auðveldlega á milli forrita til að gera margt í einu á minni tíma.",
                    "tr_gen_core_title": "Intel® Pentium® Silver örgjörvi"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold örgjörvi",
                    "tr_subtitle": "Aukin framleiðni – enn meiri afköst til að komast í gegnum annasama daga. Fyrirhafnarlaus vefskoðun – efnismiklar vefsíður hlaðast hratt til að þú getir einbeitt þér í stað þess að bíða.",
                    "tr_gen_core_title": "Intel® Pentium® Gold örgjörvi"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® örgjörvi",
                    "tr_subtitle": "Afköst sem styðja við vinnu og afþreyingu. Njóttu kvikmynda og vefmyndbanda með ótrúlegum smáatriðum. Meiri tími til að njóta og minni tími í að bíða.",
                    "tr_gen_core_title": "Intel® Celeron® örgjörvi"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® örgjörvi",
                    "tr_subtitle": "Hannaður fyrir frábæra frammistöðu fyrir afþreyingu, myndbandsstreymi og vinnu. Njóttu kvikmynda og vefmyndbanda með ótrúlegum smáatriðum. Meiri tími til að njóta og minni tími í að bíða.",
                    "tr_gen_core_title": "Intel® Pentium® örgjörvi"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Afköst sem þú þarft. Sanngjarnt verð sem þú vilt.",
                "tr_title": "Intel® örgjörvi",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Virði í sérflokki",
                    "tr_subtitle": "Intel® örgjörvi ræður við nánast öll verkefni sem þú krefst á verði sem þú ræður við. Auðvelt að fara úr einum hugbúnaði í annan. Eða njóttu hágæðamyndvinnslu með stöðugum afköstum.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Búðu til öflugri tengingar",
                    "tr_subtitle": "Betri myndgæði, skýrara hljóð og næstum þrisvar sinnum hraðari nettenging gerir þér kleift að eiga í öruggu samstarfi við aðra hvar sem þú þarfnast þess.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Munurinn er augljós",
                    "tr_subtitle": "Hvort sem um er að ræða maraþonáhorf á morgnana eða endurbætur á lokasprettinum lengir Intel® örgjörvinn rafhlöðuendingu tækisins þíns sem tryggir þér gagntekningarupplifun án truflana.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Það er hægt að læra hvar sem maður er",
                    "tr_subtitle": "Keyrðu fjölmörg netkennsluverkfæri auðveldlega hvar sem þú ert á heimilinu."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Lærðu hvað sem þú vilt",
                    "tr_subtitle": "Kláraðu kennslustundir hvar sem þú þarft á meðan þú keyrir ýmiss konar netkennsluverkfæri samtímis."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Fáðu kraft fyrir afþreyingu og vinnu sem hentar buddunni og þínum lífsstíl.",
                "tr_title": "Intel-afköst á fjölbreyttu verði.",
                "tr_gen_core_title": "Intel® Celeron® örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU RÉTTA BLÖNDU AFKASTA OG VIRÐIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hröð og efnismikil vefskoðun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Auðvelt að skipta milli forrita",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt tölvuleikjaspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Straumspilun 4K-myndbanda",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt vinnsla með myndir og myndbönd",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mikil framleiðni með snjöllum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Knúin af 7. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Knúin af 6. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Knúin af 5. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Knúin af 4. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Knúin af 3. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Knúin af 2. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Knúin af 1. kynslóðar Intel® Core™ [cpu_fam] örgjörva"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Mælt með til að:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knúin af 8. kynslóðar Intel® Core™ i3 örgjörva",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. kynslóð Intel® Core™ örgjörva var hönnuð til að halda í við stafræna veröld. Samsetning hraða og afkasta gerir þér kleift að njóta tölvunnar án alls ergelsis.",
                "tr_title": "Knúin af 9. kynslóðar Intel® Core™ i3 örgjörva",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Sveigjanleg frammistaða og stórgóð rafhlöðuending til að halda þér gangandi, hvar sem þú ert.",
                "tr_title": "Keyrðu þig í gegnum daginn",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i3 örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐ AFKASTA SEM HÆFIR ÞÉR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Straumspilun kvikmynda í 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraðar og áreiðanlegar tengingar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mynd- og myndbandasíur",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tölvuleikir í 1080p með mjúkri rammatíðni",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best til að vinna með stórar skrár og sköpunarforrit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Einfalt að fara úr því að horfa á bíómynd yfir í að búa til kynningar og eiga nóg eftir af ónýttri afkastagetu.",
                "tr_title": "Keyrðu þig í gegnum daginn",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem er hönnuð fyrir þau sem eru alltaf á ferðinni.",
                "tr_title": "Við kynnum nýjan flokk fartölva",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i3 örgjörvi er sérstaklega hannaður fyrir fartölvur og gerir þér kleift að koma enn meiru í verk hvar sem þú ert.",
                "tr_title": "Fáðu meira út úr nýju fartölvunni",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i3 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i3 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Þegar öll fjölskyldan notar eina borðtölvu fyrir allar sínar þarfir þarftu kraftinn og sveigjanleikann sem býr í 11. kynslóðar Intel® Core™ i3 örgjörvanum.",
                "tr_title": "Vertu til í hvað sem er með byltingarkenndum afköstum.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i3 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i3 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knúin af 8. kynslóðar Intel® Core™ i5 örgjörva",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. kynslóð Intel® Core™ örgjörva var hönnuð til að halda í við stafræna veröld. Samsetning hraða og afkasta gerir þér kleift að njóta tölvunnar án alls ergelsis.",
                "tr_title": "Knúin af 9. kynslóðar Intel® Core™ i5 örgjörva",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HNÖKRALAUS SPILUN OG VR FYRIR KRÖFUHÖRÐUSTU LEIKI",
                "tr_title": "Hér byrjar spilun fyrir alvöru",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skiptu auðveldlega á milli margra forrita til að vera tilbúin(n) í allt, hvert sem dagurinn leiðir þig.",
                "tr_title": "Fjölvinnsla á ferðinni",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐ AFKASTA SEM HÆFIR ÞÉR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Straumspilun kvikmynda í 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraðar og áreiðanlegar tengingar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mynd- og myndbandasíur",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tölvuleikir í 1080p með mjúkri rammatíðni",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best til að vinna með stórar skrár og sköpunarforrit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Færðu allt upp á næsta stig, hvort sem það er afþreying, vinna eða efnissköpun.",
                "tr_title": "Uppfærðu í kraftmeiri tölvu",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem er hönnuð fyrir þau sem eru alltaf á ferðinni.",
                "tr_title": "Við kynnum nýjan flokk fartölva",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hönnuð fyrir hnökralaust streymi og AAA-tölvuleiki",
                "tr_title": "Spilaðu eftir þínu höfði",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hönnuð fyrir hnökralaust streymi og AAA-tölvuleiki",
                "tr_title": "Spilaðu eftir þínu höfði",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 10<br/>Þræðir: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Skiptu auðveldlega á milli margra forrita til að vera tilbúin(n) í allt, hvert sem dagurinn leiðir þig.",
                "tr_title": "Fjölvinnsla á ferðinni",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "10. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i5 örgjörvinn færir líflegar myndrænar upplifanir og fjölhæfa afkastagetu í léttar og þunnar tölvur.",
                "tr_title": "Aðdáunarverður kraftur í fartölvum",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Þegar öll fjölskyldan notar eina borðtölvu fyrir allar sínar þarfir þarftu kraftinn og sveigjanleikann sem býr í 11. kynslóðar Intel® Core™ i5 örgjörvanum.",
                "tr_title": "Vertu til í hvað sem er með byltingarkenndum afköstum.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i5 örgjörvinn færir líflegar myndrænar upplifanir og fjölhæfa afkastagetu í léttar og þunnar fartölvur.",
                "tr_title": "Aðdáunarverður kraftur í fartölvum",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Knúin af 8. kynslóðar Intel® Core™ i7 örgjörva",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9. kynslóð Intel® Core™ örgjörva var hönnuð til að halda í við stafræna veröld. Samsetning hraða og afkasta gerir þér kleift að njóta tölvunnar án alls ergelsis.",
                "tr_title": "Knúin af 9. kynslóðar Intel® Core™ i7 örgjörva",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ALLT AÐ 32% FLEIRI RAMMAR Á SEKÚNDU í tölvuleikjum, streymi og upptökum miðað við 3 ÁRA GAMLA TÖLVU<sup>3</sup>",
                "tr_title": "Krafturinn til að deila þínum merkustu stundum í tölvuleikjum",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Þetta stílhreina tæki er hannað með skilvirkni í huga og getur haft gríðarleg áhrif á hvernig þú skapar, tengist og streymir.",
                "tr_title": "Mikil afköst hvar sem er",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐ AFKASTA SEM HÆFIR ÞÉR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Straumspilun kvikmynda í 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraðar og áreiðanlegar tengingar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mynd- og myndbandasíur",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tölvuleikir í 1080p með mjúkri rammatíðni",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best til að vinna með stórar skrár og sköpunarforrit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mikil afköst til að færa allt yfir á næsta stig.",
                "tr_title": "Kraftur fyrir hvað sem er",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem er hönnuð fyrir þau sem eru alltaf á ferðinni.",
                "tr_title": "Við kynnum nýjan flokk fartölva",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spilaðu, streymdu og taktu upp með forskoti á keppinautana",
                "tr_title": "Kraftur fyrir leikjaheima",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spilaðu, streymdu og taktu upp með forskoti á keppinautana",
                "tr_title": "Kraftur fyrir leikjaheima",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 10<br/>Þræðir: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Þetta stílhreina tæki er hannað með skilvirkni í huga og getur haft gríðarleg áhrif á hvernig þú skapar, tengist og streymir.",
                "tr_title": "Úrvalsafköst hvar sem er",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "10. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i7 örgjörvinn gefur kraft fyrir efnissköpun, hnökralausa leikjaspilun og magnaða afþreyingu í mjög meðfærilegum tölvum.",
                "tr_title": "Byltingarkennd afköst í fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Gerðu borðtölvuna að staðnum fyrir allt sem heimilið gæti þarfnast með byltingarkenndum afköstum 11. kynslóðar Intel® Core™ i7 örgjörva.",
                "tr_title": "Vinna. Læra. Spila. Án takmarkana.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i7 örgjörvinn gefur kraft fyrir efnissköpun, hnökralausa leikjaspilun og magnaða afþreyingu í þunnum og léttum fartölvum.",
                "tr_title": "Byltingarkennd afköst í fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gerðu borðtölvuna að staðnum fyrir allt sem heimilið gæti þarfnast með byltingarkenndum afköstum 11. kynslóðar Intel® Core™ i7 örgjörva.",
                "tr_title": "Sköpun án takmarkana",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Þessi 11. kynslóðar Intel® Core™ i7 örgjörvi er enn betri en áður með krafti fyrir efnissköpun, hnökralausa leikjaspilun og magnaða afþreyingu í þunnum og léttum fartölvum.",
                "tr_title": "Byltingarkennd afköst í fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knúin af 8. kynslóðar Intel® Core™ i9 örgjörva",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i9 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. kynslóð Intel® Core™ örgjörva var hönnuð til að halda í við stafræna veröld. Samsetning hraða og afkasta gerir þér kleift að njóta tölvunnar án alls ergelsis.",
                "tr_title": "Knúin af 9. kynslóðar Intel® Core™ i9 örgjörva",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i9 örgjörvi"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ALLT AÐ 41% FLEIRI RAMMAR Á SEKÚNDU í tölvuleikjum, streymi og upptökum miðað við 3 ÁRA GAMLA TÖLVU<sup>4</sup>",
                "tr_title": "Tölvuleikir gera miklar kröfur. Intel® Core™ i9 uppfyllir þær allar.",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Reyndu á hæfileikana með fullkominni tölvuleikjaupplifun",
                "tr_title": "Kepptu við toppaðstæður",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Reyndu á hæfileikana með fullkominni tölvuleikjaupplifun",
                "tr_title": "Kepptu við toppaðstæður",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 10<br/>Þræðir: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Reyndu á hæfileikana með fullkominni upplifun fyrir sköpun",
                "tr_title": "Skapaðu við toppaðstæður",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "10. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ fartölvur sem eru knúnar af 11. kynslóðar Intel® Core™ i5 örgjörva og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnslu gefa þér ótrúlegan hraða og afköst í þunnri og léttri umgjörð.",
                "tr_title": "Aukinn kraftur fyrir háþróaða fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ fartölvur sem eru knúnar af 11. kynslóðar Intel® Core™ i7 örgjörva og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnslu gefa þér mikinn hraða og afköst þegar þú ert á ferðinni.",
                "tr_title": "Enn meiri kraftur fyrir háþróaða fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Njóttu frábærrar blöndu afkasta, margmiðlunarupplifunar og tengigetu á frábæru verði.",
                "tr_title": "Afköst og tengigeta á frábæru verði.",
                "tr_gen_core_title": "Intel® Pentium® Silver örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU RÉTTA BLÖNDU AFKASTA OG VIRÐIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hröð og efnismikil vefskoðun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Auðvelt að skipta milli forrita",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt tölvuleikjaspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Straumspilun 4K-myndbanda",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt vinnsla með myndir og myndbönd",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mikil framleiðni með snjöllum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Njóttu hnökralausrar fjölvinnslu, einfaldrar vinnslu mynda og myndbanda, 4K-myndbanda og líflegrar grafíkur á ótrúlegu verði.",
                "tr_title": "Mögnuð afköst fyrir vinnu og leik.",
                "tr_gen_core_title": "Intel® Pentium® Gold örgjörvi",
                "processorCompare": {
                  "tr_title": "FINNDU RÉTTA BLÖNDU AFKASTA OG VIRÐIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hröð og efnismikil vefskoðun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Auðvelt að skipta milli forrita",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt tölvuleikjaspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Straumspilun 4K-myndbanda",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Létt vinnsla með myndir og myndbönd",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mikil framleiðni með snjöllum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-tölvuleikir og löng rafhlöðuending í einstaklega meðfærilegri tölvu. Engar málamiðlanir í leikjaspilun.",
                "tr_title": "Engar málamiðlanir.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ LEIKJAÖRGJÖRVA FYRIR MEÐFÆRILEGAR TÖLVUR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i5-11300H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11370H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11375H Special Edition örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Allt að <span class='style_badge'>4,4 GHz</span> hámarkstiftíðni",
                      "tr_td2_title": "Allt að <span class='style_badge'>4,8 GHz</span> hámarkstiftíðni",
                      "tr_td3_title": "Allt að <span class='style_badge'>5,0 GHz</span> hámarkstiftíðni"
                    },
                    {
                      "tr_td1_title": "4 kjarnar/8 þræðir",
                      "tr_td2_title": "4 kjarnar/8 þræðir",
                      "tr_td3_title": "4 kjarnar/8 þræðir"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-tækni",
                      "tr_td2_title": "Intel® Turbo Boost-tækni",
                      "tr_td3_title": "Intel® Turbo Boost Max-tækni 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache-skyndiminni",
                      "tr_td2_title": "12 MB Intel® Smart Cache-skyndiminni",
                      "tr_td3_title": "12 MB Intel® Smart Cache-skyndiminni"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "1080p AAA-tölvuleikir með mörgum römmum á sekúndu og frábærum afköstum án tengingar við rafmagn í meðfærilegri tölvu.",
                "tr_title": "Ekki hika. Leyfðu þér allt.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ LEIKJAÖRGJÖRVA FYRIR MEÐFÆRILEGAR TÖLVUR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i5-11300H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11370H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11375H Special Edition örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Allt að <span class='style_badge'>4,4 GHz</span> hámarkstiftíðni",
                      "tr_td2_title": "Allt að <span class='style_badge'>4,8 GHz</span> hámarkstiftíðni",
                      "tr_td3_title": "Allt að <span class='style_badge'>5,0 GHz</span> hámarkstiftíðni"
                    },
                    {
                      "tr_td1_title": "4 kjarnar/8 þræðir",
                      "tr_td2_title": "4 kjarnar/8 þræðir",
                      "tr_td3_title": "4 kjarnar/8 þræðir"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-tækni",
                      "tr_td2_title": "Intel® Turbo Boost-tækni",
                      "tr_td3_title": "Intel® Turbo Boost Max-tækni 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache-skyndiminni",
                      "tr_td2_title": "12 MB Intel® Smart Cache-skyndiminni",
                      "tr_td3_title": "12 MB Intel® Smart Cache-skyndiminni"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Allt að 5,0 GHz hámarkstiftíðni til að knýja leikina enn betur og meðfærileg tölva til að taka þá hvert sem er.",
                "tr_title": "Meiri kraftur fyrir peninginn.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 Special Edition örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ LEIKJAÖRGJÖRVA FYRIR MEÐFÆRILEGAR TÖLVUR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i5-11300H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11370H örgjörvi"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. kynslóðar Intel® Core™ i7-11375H Special Edition örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Allt að <span class='style_badge'>4,4 GHz</span> hámarkstiftíðni",
                      "tr_td2_title": "Allt að <span class='style_badge'>4,8 GHz</span> hámarkstiftíðni",
                      "tr_td3_title": "Allt að <span class='style_badge'>5,0 GHz</span> hámarkstiftíðni"
                    },
                    {
                      "tr_td1_title": "4 kjarnar/8 þræðir",
                      "tr_td2_title": "4 kjarnar/8 þræðir",
                      "tr_td3_title": "4 kjarnar/8 þræðir"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-tækni",
                      "tr_td2_title": "Intel® Turbo Boost-tækni",
                      "tr_td3_title": "Intel® Turbo Boost Max-tækni 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache-skyndiminni",
                      "tr_td2_title": "12 MB Intel® Smart Cache-skyndiminni",
                      "tr_td3_title": "12 MB Intel® Smart Cache-skyndiminni"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i7 Special Edition örgjörvinn gefur kraft fyrir efnissköpun, hnökralausa leikjaspilun og magnaða afþreyingu í mjög meðfærilegum tölvum.",
                "tr_title": "Byltingarkennd afköst í fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 Special Edition örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 SPECIAL EDITION ÖRGJÖRVI"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. kynslóðar Intel® Core™ i7 Special Edition örgjörvinn gefur kraft fyrir efnissköpun, hnökralausa leikjaspilun og magnaða afþreyingu í mjög meðfærilegum tölvum.",
                "tr_title": "Byltingarkennd afköst í fartölvu",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 Special Edition örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 SPECIAL EDITION ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Rétt blanda tiftíðni og kjarnaafkasta skilar meiru úr leikjunum, hvort sem þú ert að keppa og þarft marga ramma á sekúndu eða gleymir þér í AAA-leikjum.",
                "tr_title": "Kraftur sem hefur tilgang.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spilaðu, taktu upp og streymdu kröfuharða leiki með mörgum römmum á sekúndu og skiptu fyrirhafnarlaust á milli forrita við álagsmikla fjölvinnu.",
                "tr_title": "Kraftur til að reyna á þolmörkin.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Einfalt að fara úr því að horfa á bíómynd yfir í að búa til kynningar og eiga nóg eftir af ónýttri afkastagetu.",
                "tr_title": "Keyrðu þig í gegnum daginn",
                "tr_gen_core_title": "Intel® Core™ i3 örgjörvi með Intel® Hybrid-tækni",
                "tr_subTitle2": "Intel® Core™ i3 örgjörvi með Intel® Hybrid-tækni"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Sveigjanleg frammistaða og stórgóð rafhlöðuending til að halda þér gangandi, hvar sem þú ert.",
                "tr_title": "Keyrðu þig í gegnum daginn",
                "tr_gen_core_title": "Intel® Core™ i3 örgjörvi með Intel® Hybrid-tækni",
                "tr_subTitle2": "Intel® Core™ i3 örgjörvi með Intel® Hybrid-tækni"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skiptu auðveldlega á milli margra forrita til að vera tilbúin(n) í allt, hvert sem dagurinn leiðir þig.",
                "tr_title": "Fjölvinnsla á ferðinni",
                "tr_gen_core_title": "Intel® Core™ i5 örgjörvi með Intel® Hybrid-tækni",
                "tr_subTitle2": "Intel® Core™ i5 örgjörvi með Intel® Hybrid-tækni"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Færðu allt upp á næsta stig, hvort sem það er afþreying, vinna eða efnissköpun.",
                "tr_title": "Uppfærðu í kraftmeiri tölvu",
                "tr_gen_core_title": "Intel® Core™ i5 örgjörvi með Intel® Hybrid-tækni",
                "tr_subTitle2": "Intel® Core™ i5 örgjörvi með Intel® Hybrid-tækni"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tölvuleikjaafköst sem jafnast á við borðtölvu mæta meðfærileika, hvort sem þú ert að keppa og þarft marga ramma á sekúndu eða gleymir þér í AAA-leikjum.",
                "tr_title": "Kraftur sem hefur tilgang.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tölvuleikjaafköst sem jafnast á við borðtölvu mæta meðfærileika, hvort sem þú ert að keppa og þarft marga ramma á sekúndu eða gleymir þér í AAA-leikjum.",
                "tr_title": "Kraftur sem hefur tilgang.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem getur spilað, tekið upp og streymt á sama tíma með mörgum römmum á sekúndu og skipt fyrirhafnarlaust yfir á milli forrita við álagsmikla fjölvinnu.",
                "tr_title": "Kraftur til að reyna á þolmörkin.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem getur spilað, tekið upp og streymt á sama tíma með mörgum römmum á sekúndu og skipt fyrirhafnarlaust yfir á milli forrita við álagsmikla fjölvinnu.",
                "tr_title": "Kraftur til að reyna á þolmörkin.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni, margir kjarnar og ólæst afköst ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótakmarkað afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni, margir kjarnar og ólæst afköst ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótakmarkað afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr flóknum efnissköpunarforritum.",
                "tr_title": "Ótrúlegt afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Reyndu á hæfileikana með fullkominni tölvuleikjaupplifun",
                "tr_title": "Kepptu við toppaðstæður",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Reyndu á hæfileikana með fullkominni tölvuleikjaupplifun",
                "tr_title": "Kepptu við toppaðstæður",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU 10. KYNSLÓÐAR INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hnökralaus spilun AAA-tölvuleikja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streymi og upptaka",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enn hraðari efnissköpun",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hámarksafköst fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ólæst og yfirstillanleg<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 10<br/>Þræðir: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fartölva sem er hönnuð fyrir þau sem eru alltaf á ferðinni.",
                "tr_title": "Við kynnum nýjan flokk fartölva",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i9 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr kröfuhörðum leikjum og flóknum efnissköpunarforritum.",
                "tr_title": "Ótakmarkað afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni, margir kjarnar og ólæst afköst ná meiru út úr flóknum efnissköpunarforritum, hvert sem þú ferð með tölvuna.",
                "tr_title": "Ótakmarkað afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hröð tiftíðni og margir kjarnar ná meiru út úr flóknum efnissköpunarforritum.",
                "tr_title": "Ótakmarkað afl. Fullkomið jafnvægi.",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "11. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache-skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hnökralaus spilun og VR fyrir kröfuhörðustu leiki",
                "tr_title": "Hér byrjar spilun fyrir alvöru",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ALLT AÐ 32% FLEIRI RAMMAR Á SEKÚNDU <br/><span class='tr_subtitle_font'>í tölvuleikjum, streymi og upptökum miðað við 3 ára gamla tölvu<sup>3</sup></span>",
                "tr_title": "Krafturinn til að deila þínum merkustu stundum í tölvuleikjum",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ALLT AÐ 41% FLEIRI RAMMAR Á SEKÚNDU <br/><span class='tr_subtitle_font'>í tölvuleikjum, streymi og upptökum miðað við 3 ára gamla tölvu<sup>4</sup></span>",
                "tr_title": "Tölvuleikir gera miklar kröfur. Intel® Core™ i9 uppfyllir þær allar.",
                "tr_gen_core_title": "9. kynslóðar Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>þræðir</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kjarnar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Allt að <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>skyndiminni</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ólæst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINNDU INTEL® CORE™ ÖRGJÖRVANN SEM HENTAR ÞINNI LEIKJASPILUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 örgjörvi"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 örgjörvi"
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 örgjörvi"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-tölvuleikir",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Keppnisspilun",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spilaðu, streymdu og taktu upp án málamiðlana",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Besti 9. kynslóðar Intel® Core™ örgjörvinn fyrir tölvuleiki",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 4<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 6<br/>Þræðir: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tiftíðni<sup>2</sup>:<br/>Allt að 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjarnar: 8<br/>Þræðir: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Hönnuð fyrir næstu kynslóð tölvuleikja",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                    "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                    "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. kynslóðar Intel® Core™ i9 örgjörvi",
                    "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Orrustustöð sameinast vinnustöð",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                    "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. kynslóðar Intel® Core™ i9 örgjörvi",
                    "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Áreiðanleg afköst þegar þú þarft mest á þeim að halda",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i3 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i3 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Aukakraftur þegar þörfin er mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Aukakraftur þegar þörfin er mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Framúrskarandi afköst þegar þú þarft mest á þeim að halda",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Framúrskarandi afköst þegar þú þarft mest á þeim að halda",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ býður upp á mikil afköst, innbyggða snjalltækni fyrir myndsímtöl, hraðari nettengingu, mun lengri rafhlöðuendingu og fleira.",
                "tr_title": "Aukin afköst þegar þú þarft á því að halda.",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ býður upp á mikil afköst, innbyggða snjalltækni fyrir myndsímtöl, hraðari nettengingu, mun lengri rafhlöðuendingu og fleira.",
                "tr_title": "Framúrskarandi afköst þegar þú þarft mest á þeim að halda",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ býður upp á mikil afköst, innbyggða snjalltækni fyrir myndsímtöl, hraðari nettengingu, mun lengri rafhlöðuendingu og fleira.",
                "tr_title": "Framúrskarandi afköst í frábærri fartölvu",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Með Intel® Turbo Boost-tækni 2.0, allt að [max_turbo_frequency] GHz",
                "tr_title": "Hannaður fyrir viðskipti",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Fá innbyggt öryggi í vélbúnaðinum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Stjórna fjartengdum tækjum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hámarka sjálfkrafa Wi-Fi-frammistöðu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vinna knökralaust með gagnavinnsluöpp",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mesta framleiðnin með bættum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Með Intel® Turbo Boost-tækni 2.0, allt að [max_turbo_frequency] GHz",
                "tr_title": "Vinnur hratt svo þú getir einbeitt þér að verkefnunum.",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Fá innbyggt öryggi í vélbúnaðinum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Stjórna fjartengdum tækjum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hámarka sjálfkrafa Wi-Fi-frammistöðu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vinna knökralaust með gagnavinnsluöpp",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mesta framleiðnin með bættum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Með Intel® Turbo Boost-tækni 2.0, allt að [max_turbo_frequency] GHz",
                "tr_title": "Bættu þinn rekstur",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "12. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Fá innbyggt öryggi í vélbúnaðinum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Stjórna fjartengdum tækjum",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hámarka sjálfkrafa Wi-Fi-frammistöðu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vinna knökralaust með gagnavinnsluöpp",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mesta framleiðnin með bættum afköstum",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Áreiðanleg frammistaða fyrir meiri afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i3 örgjörvi",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Raunheimsframmistaða sem einsetur sér að ná lengra",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Raunheimsframmistaða sem einsetur sér að ná lengra",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Öflug frammistaða sem er helguð því að gera meira",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Öflug frammistaða sem er helguð því að gera meira",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Meira en afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tengdu við símann þinn án erfiðleika<sup>24</sup>, veldu besta Wi-Fi-ið og hladdu hratt með rafhlöðu sem endist.",
                "tr_title": "Enn betri afkastageta til að koma meiru í verk hvar sem er",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tengdu við símann þinn án erfiðleika<sup>24</sup>, veldu besta Wi-Fi-ið og hladdu hratt með rafhlöðu sem endist.",
                "tr_title": "Enn betri afkastageta til að láta tölvuna þína vinna enn fleiri störf í einu",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tengdu við símann þinn án erfiðleika<sup>24</sup>, veldu besta Wi-Fi-ið og hladdu hratt með rafhlöðu sem endist.",
                "tr_title": "Enn betri afkastageta til að fara hratt í gegnum mest krefjandi verkefnin",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Meira en afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                    "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                    "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13. kynslóðar Intel® Core™ i9 örgjörvi",
                    "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Meira en afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Áreiðanleg frammistaða fyrir meiri afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 örgjörvi",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Raunheimsframmistaða sem einsetur sér að ná lengra",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Öflug frammistaða sem er helguð því að gera meira",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Meira en afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Áreiðanleg frammistaða fyrir meiri afköst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 örgjörvi",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Raunheimsframmistaða sem einsetur sér að ná lengra",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 örgjörvi",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Öflug frammistaða sem er helguð því að gera meira",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 örgjörvi",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 ÖRGJÖRVI",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 örgjörvi",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 ÖRGJÖRVI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kjarnar</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-kjarnar"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>þræðir</span></span>",
                    "tr_badgePreTitle": "[p_thread] on Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] on Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] á LPE-kjörnum"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 örgjörvi",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 örgjörvi",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Tölvuleikir án málamiðlana",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Lítil fyrirtæki eiga skilið stórar fyrirtækjalausnir.",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i5 ÖRGJÖRVI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Fáðu öryggisvernd sem byggir á vélbúnaði beint úr kassanum",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjálpaðu til við að vernda tæki og gögn fyrir raunverulegum svikaárásum",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Lítil fyrirtæki eiga skilið stórar fyrirtækjalausnir.",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i7 ÖRGJÖRVI",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Fáðu öryggisvernd sem byggir á vélbúnaði beint úr kassanum",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjálpaðu til við að vernda tæki og gögn fyrir raunverulegum svikaárásum",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Lítil fyrirtæki eiga skilið stórar fyrirtækjalausnir.",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i9 örgjörvi",
                "tr_gen_core_title_uppercase": "13. KYNSLÓÐAR INTEL® CORE™ i9 ÖRGJÖRVI",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVERNIG ER ÞESSI ÖRGJÖRVI Í SAMANBURÐI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Núverandi tæki",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Deyfa hljóð og gera bakgrunn óskýran",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sérsníða afköst sjálfkrafa til að setja algengustu verkefni í forgang",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Upplifa áreynslulaus hágæða netsamtöl",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Fáðu öryggisvernd sem byggir á vélbúnaði beint úr kassanum",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjálpaðu til við að vernda tæki og gögn fyrir raunverulegum svikaárásum",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Opnaðu fyrir nýja gervigreindarupplifun í viðskiptum",
                "tr_title": "Vertu tilbúin(n) fyrir framtíðina með Intel® Core™ Ultra&nbsp;[XX] örgjörvum og Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] örgjörvi",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "LYFTU VIÐSKIPTUM ÞÍNUM Á ÆÐRA PLAN MEÐ GERVIGREINDARTÖLVUM FRÁ INTEL",
                    "tr_badgeSubTitle": "Öflugar tölvur sem skapa frábæra upplifun eru kjarni afkasta í viðskiptum. Intel vPro® styrkir árangur og framleiðni starfsfólks í margs konar verkefnum og forritum, þökk sé nýrri uppbyggingu sem skilar auknu afli og skilvirkni í afköstum í ýmsum flóknum viðskiptatengdum verkefnum með því að senda rétt verk í réttar vélar á réttum tíma.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "MARGLAGA ÖRYGGISEIGINLEIKAR SEM ÞURFA ENGA SÉRSTAKA UPPSETNINGU",
                    "tr_badgeSubTitle": "Nýjar og yfirvofandi ógnanir kalla á yfirgripsmikla nálgun að öryggi. Intel vPro® veitir vernd á vélbúnaðarstigi og í gegnum allan staflann þannig að þinn rekstur er betur varinn. Þessar einstöku öryggisráðstafanir sem þurfa enga sérstaka uppsetningu, þar á meðal gervigreindarknúin hættugreining, vernda notendur þína, gögn og viðskipti.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INNBYGGÐ VERKFÆRI SEM STUÐLA AÐ FRAMLEIÐNI NOTENDA NÁNAST HVAÐAN SEM ER",
                    "tr_badgeSubTitle": "Skrifstofur eru alls staðar og það eru tölvur líka. Intel vPro® veitir nútíma vélbúnað sem byggir á stjórnunarhæfni sem hjálpar fyrirtækjum að létta byrðar á tölvuflotastjórnun, einfaldar stuðning, bætir upplifun notenda og virkjar á sama tíma sjálfbært verklag þegar kemur að tölvuvinnslu.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meira en stórkostleg tölva, gervigreindartölva",
                "tr_subtitle1": "KNÚIN AF INTEL®&nbsp;CORE™ ULTRA ÖRGJÖRVUM (LÍNA&nbsp;2)",
                "tr_subtitle2": "Það er mögulegt á Intel AI PC",
                "tr_subtitle": "Skilvirkasti, öruggasti og öflugasti snjalltækjaörgjörvi Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 örgjörvi (lína&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Meira skapandi</span> með gervigreindarverkfærum til að skapa enn hraðar"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Meiri árangur</span> með tímasparandi gervigreindaraðstoð"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Meira öryggi</span> með innbyggðum vörnum"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meira en stórkostleg tölva, gervigreindartölva",
                "tr_subtitle1": "KNÚIN AF INTEL®&nbsp;CORE™ ULTRA ÖRGJÖRVUM (LÍNA&nbsp;2)",
                "tr_subtitle2": "Það er mögulegt á Intel AI PC",
                "tr_subtitle": "Skilvirkasti, öruggasti og öflugasti snjalltækjaörgjörvi Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 örgjörvi (lína&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Meira skapandi</span> með gervigreindarverkfærum til að skapa enn hraðar"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Meiri árangur</span> með tímasparandi gervigreindaraðstoð"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Meira öryggi</span> með innbyggðum vörnum"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meira en stórkostleg tölva, gervigreindartölva",
                "tr_subtitle1": "KNÚIN AF INTEL®&nbsp;CORE™ ULTRA ÖRGJÖRVUM (LÍNA&nbsp;2)",
                "tr_subtitle2": "Það er mögulegt á Intel AI PC",
                "tr_subtitle": "Skilvirkasti, öruggasti og öflugasti snjalltækjaörgjörvi Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 örgjörvi (lína&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meira en stórkostleg tölva, gervigreindartölva",
                "tr_subtitle1": "KNÚIN AF INTEL®&nbsp;CORE™ ULTRA ÖRGJÖRVUM (LÍNA&nbsp;2)",
                "tr_subtitle2": "Það er mögulegt á Intel AI PC",
                "tr_subtitle": "Skilvirkasti, öruggasti og öflugasti snjalltækjaörgjörvi Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 örgjörvi (lína&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_tileTitle1": "Aðskilin myndvinnsla",
                "tr_title": "HVAÐ ER SKJÁKJARNI?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Skjákjarninn (GPU) skapar myndina sem þú sérð á skjánum.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Innbyggður skjákjarni",
                    "tr_badgeSubTitle": "Innbyggður skjákjarni notar vinnsluminnið sem er notað fyrir aðra vinnslu tölvunnar og er því sparneytnari á orku.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Sérstakur skjákjarni",
                    "tr_badgeSubTitle": "Þetta er öflugasta tegund skjákjarnanna því skjákjarnar í þessum flokki eru búnir eigin vinnsluminni og öðrum tilföngum sem hafa þann eina tilgang að skila frábærri grafík.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Blandaður skjákjarni",
                    "tr_badgeSubTitle": "Þetta er öflugasta tegund skjákjarnanna því skjákjarnar í þessum flokki eru búnir eigin vinnsluminni og öðrum tilföngum sem hafa þann eina tilgang að skila frábærri grafík.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Auðvelt að vinna með myndir og myndbönd",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Horfðu á 4K-myndbönd",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Spilaðu vinsæla leiki með ótrúlegum smáatriðum",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Tveir skjákjarnar",
                    "tr_badgeSubTitle": "Sum kerfi, eins og þau sem eru með Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnslu, geta nýtt bæði skjákjarnann á örgjörvanum og þann sérstaka til að dreifa álagi við myndvinnslu.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Innbyggður skjákjarni",
                    "tr_badgeSubTitle": "Innbyggður skjákjarni á sömu flögu og aðalörgjörvinn. Þessi gerð skjákjarna notar sama vinnsluminni og önnur tilföng tölvunnar, sem gerir hann sparneytnari á orku, sem hentar fyrir þynnri og léttari tæki.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Sérstakur skjákjarni",
                    "tr_badgeSubTitle": "Þetta er öflugasta tegund skjákjarnanna því skjákjarnar í þessum flokki eru búnir eigin vinnsluminni og öðrum tilföngum sem hafa þann eina tilgang að skila frábærri grafík.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Hefðbundinn kraftur",
                    "tr_badgeSubTitle": "Allt að 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Keppniskraftur",
                    "tr_badgeSubTitle": "Allt að 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ofurkraftur",
                    "tr_badgeSubTitle": "Meira en 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Rosalegur kraftur fyrir myndvinnslu",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Rík og lífleg innbyggð grafík",
                "tr_pretitle": "Intel® Iris® Plus myndvinnsla",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® Plus myndvinnsla leyfir þér að gleyma þér í 4K HDR-myndböndum og spila tölvuleiki í HD án þess að þurfa annað skjákort.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Ótrúlegt sjónarspil, hnökralaus afköst",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Graphics gefur byltingarkennda og samþætta myndvinnslugetu sem styður við hnökralausa hönnun höfunda og magnaða leikjaspilunarupplifun.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Intel® Iris® Pro myndvinnsla",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Intel® UHD Graphics með X<sup class='small_text_sup_xe'>e</sup> högun",
                "tr_pretitle": "Áhrifarík myndræn upplifun",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Upplifðu hnökralausa vinnslu mynda og myndbanda, horfðu á myndbönd í 4K eða spilaðu vinsæla leiki með ótrúlegri grafík.",
                "tr_photo_text": "Auðvelt að vinna með myndir og myndbönd",
                "tr_video_text": "Horfðu á 4K-myndbönd",
                "tr_game_text": "Spilaðu vinsæla leiki með ótrúlegum smáatriðum",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUN MEIRA EN BARA FALLEGAR MYNDIR",
                "tr_subtitle": "Samspil skjákortsins (eða GPU) og örgjörvans segir fyrir um það hvernig leikir keyra og hvernig þeir líta út. VRAM er vinnsluminni sem sinnir aðeins myndvinnslu og er góð vísbending um afkastagetu skjákjarnans.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FYRIR TÖLVULEIKJAGRAFÍK SEM GERIR KRÖFUR",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf sérstakt skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FYRIR TÖLVULEIKJAGRAFÍK SEM GERIR KRÖFUR",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf sérstakt skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUN MEIRA EN BARA FALLEGAR MYNDIR",
                "tr_subtitle": "Samspil skjákortsins (eða GPU) og örgjörvans segir fyrir um það hvernig leikir keyra og hvernig þeir líta út. VRAM er vinnsluminni sem sinnir aðeins myndvinnslu og er góð vísbending um afkastagetu skjákjarnans.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Rosalegur kraftur fyrir myndvinnslu",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "FYRIR TÖLVULEIKJAGRAFÍK SEM GERIR KRÖFUR",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf sérstakt skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Veitir nóg af myndvinnsluafli til að uppfylla lágmarkskröfur tölvuleikja.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Fyrir kröfuharða spilara sem krefjast spilunar sem hæfir fyrir keppni",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Fyrir dygga leikjaspilara sem nota tölvuna einnig fyrir kröfuharða efnissköpun",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Lífleg myndbönd og magnaðir tölvuleikir",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Fáðu sem mest út úr háupplausnarskjánum með Intel® HD Graphics og njóttu margmiðlunarefnis og innbyggðrar myndvinnslu.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Knýr myndbönd og myndir í líflegum litum",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Fáðu meiri smáatriði og líflega liti úr streymdum myndböndum og myndum.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Ótrúleg sköpun og tölvuleikjaspilun",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla færir byltingarkennd myndafköst í þunnar og léttar tölvur til að þú getir notað ótakmarkaðrar afþreyingar, sinnt kröfuharðri efnissköpun og spilað vinsæla tölvuleiki.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_pretitle": "FYRIR TÖLVULEIKJAGRAFÍK SEM GERIR KRÖFUR",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> myndvinnsla",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf sérstakt skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Gefðu sköpunargáfunni lausan tauminn",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX-myndvinnsla",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Einbeittu þér að skapandi verkefnum og gleymdu þér í fjölbreyttum leikjum með sameinuðum krafti innbyggðrar myndvinnslu og fyrstu Intel® myndvinnsluvélarinnar með sérstöku skjákorti.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX-myndvinnsla",
                "tr_pretitle": "FYRIR TÖLVULEIKJAGRAFÍK SEM GERIR KRÖFUR",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Fyrir kröfuhörðustu myndvinnsluna þarf sérstakt skjákort sem er aðeins tileinkað því að skapa fallegar og skýrar myndir og hnökralausar hreyfimyndir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafík",
                "tr_title": "Falleg innbyggð grafík",
                "tr_subtitle": "Njóttu tölvuleikja, kvikmynda og vefmyndbanda með ótrúlegum smáatriðum og skýrleika.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafík",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Bættu verkflæðin þín"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Komdu leikjaspilun þinni á hæsta stig"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Komdu leikjaspilun þinni á hæsta stig"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Komdu leiknum þínum upp á næsta stig"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "GEFÐU SKÖPUNARGÁFUNNI LAUSAN TAUMINN",
                    "tr_specText": "Auktu framleiðni þína hvar sem þú ert með umfangsmestu margmiðlunargetunni, eins og <strong>AV1 fastbúnaðarkóðun sem er leiðandi í greininni</strong> og efnissköpun sem nýtur aðstoðar gervigreindar.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "GEFÐU SKÖPUNARGÁFUNNI LAUSAN TAUMINN",
                    "tr_specText": "Auktu framleiðni þína með umfangsmestu margmiðlunargetunni, eins og <strong>AV1 fastbúnaðarkóðun sem er leiðandi í greininni</strong> og efnissköpun sem nýtur aðstoðar gervigreindar.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "GEFÐU SKÖPUNARGÁFUNNI LAUSAN TAUMINN",
                    "tr_specText": "Upplifðu framúrstefnulega efnissköpun sem nýtur aðstoðar gervigreindar með <strong>AV1 fastbúnaðarkóðun sem er leiðandi í greininni</strong> og háþróaðri <strong>X<sup>e</sup> margmiðlunarvél.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "GEFÐU SKÖPUNARGÁFUNNI LAUSAN TAUMINN",
                    "tr_specText": "Upplifðu framúrstefnulega efnissköpun sem nýtur aðstoðar gervigreindar með <strong>AV1 fastbúnaðarkóðun sem er leiðandi í greininni</strong> og háþróaðri <strong>X<sup>e</sup> margmiðlunarvél.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "GEFÐU SKÖPUNARGÁFUNNI LAUSAN TAUMINN",
                    "tr_specText": "Upplifðu framúrstefnulega efnissköpun sem nýtur aðstoðar gervigreindar með <strong>AV1 fastbúnaðarkóðun sem er leiðandi í greininni</strong> og háþróaðri <strong>X<sup>e</sup> margmiðlunarvél.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "MEÐFÆRILEIKI ÁSAMT AFKÖSTUM",
                    "tr_specText": "Spilaðu nýjustu leikina á ferðinni og njóttu framúrskarandi afkasta og nákvæmni með nútímalegri leikjaspilunargetu. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir aukin afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikjaspilunina upp á nýtt raunveruleikastig með myndvinnslutækni, eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "TAKTU STJÓRN Á LEIKJUNUM ÞÍNUM",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> gerir þér kleift að fínstilla upplifunina þína með auðskiljanlegu og nútímalegu viðmóti. Fáðu snurðulausar uppfærslur á reklum, fínstilltu leikina þína og meira til."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "TAKTU STJÓRN Á LEIKJUNUM ÞÍNUM",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> gerir þér kleift að fínstilla upplifunina þína með auðskiljanlegu og nútímalegu viðmóti. Fáðu snurðulausar uppfærslur á reklum, fínstilltu leikina þína og meira til."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "TAKTU STJÓRN Á LEIKJUNUM ÞÍNUM",
                    "tr_specText": "Fáðu snurðulausar uppfærslur á reklum og fínstilltu marga af eftirlætisleikjunum þínum með <strong>Intel® Arc™ Control</strong> hugbúnaði."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "HÁMARKAÐU INTEL® VERKVANGINN ÞINN",
                    "tr_specText": "Paraðu þig við samhæfa Intel® Core™ örgjörva til að nýta þér <strong>Intel® Deep Link Technology</strong> til að bæta sköpunar-, leikjaspilunar- og streymisupplifun þína."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "HÁMARKAÐU INTEL® KERFIÐ ÞITT",
                    "tr_specText": "Paraðu þig við samhæfa Intel® Core™ örgjörva til að nýta þér <strong>Intel® Deep Link Technology</strong> til að bæta sköpunar-, leikjaspilunar- og streymisupplifun þína."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "HÁMARKAÐU INTEL® VERKVANGINN ÞINN",
                    "tr_specText": "Paraðu þig við samhæfa Intel® Core™ örgjörva til að nýta þér <strong>Intel® Deep Link Technology</strong> til að bæta sköpunar-, leikjaspilunar- og streymisupplifun þína."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HÁMARKAÐU INTEL® VERKVANGINN ÞINN",
                    "tr_specText": "Paraðu þig við samhæfa Intel® Core™ örgjörva til að nýta þér <strong>Intel® Deep Link Technology</strong> til að bæta sköpunar-, leikjaspilunar- og streymisupplifun þína."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HÁMARKAÐU INTEL® KERFIÐ ÞITT",
                    "tr_specText": "Paraðu þig við samhæfa Intel® Core™ örgjörva til að nýta þér <strong>Intel® Deep Link Technology</strong> til að bæta sköpunar-, leikjaspilunar- og streymisupplifun þína."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "KOMDU ÞÉR FYRIR Á MIÐJU SVIÐINU",
                    "tr_specText": "Sendu snurðulaust út með háþróaðri streymistækni sem nýtir sér <strong>Intel® Arc™ Control</strong> hugbúnað."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "KOMDU ÞÉR FYRIR Á MIÐJU SVIÐINU",
                    "tr_specText": "Sendu snurðulaust út með háþróaðri streymistækni sem nýtir sér <strong>Intel® Arc™ Control</strong> hugbúnað."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "UPPLIFÐU LEIKJASPILUN EINS OG HÚN GERIST BEST",
                    "tr_specText": "Skilaðu ávallt hámarksafköstum með nýjustu AAA-leikjunum í hárri upplausn og með hárri glæðingartíðni. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir aukin afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikjaspilunina upp á nýtt raunveruleikastig með myndvinnslutækni, eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "UPPLIFÐU LEIKJASPILUN EINS OG HÚN GERIST BEST",
                    "tr_specText": "Skilaðu ávallt hámarksafköstum með nýjustu AAA-leikjunum í hárri upplausn og með hárri glæðingartíðni. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir aukin afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikjaspilunina upp á nýtt raunveruleikastig með myndvinnslutækni, eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "GERÐU LEIKINA ÞÍNA BETRI",
                    "tr_specText": "Farðu með leikjaspilunarupplifunina þína upp á næsta stig með: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir meiri afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikjaspilunina upp á nýtt raunveruleikastig með myndvinnslutækni, eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "UPPLIFÐU HÁÞRÓAÐA OG HRAÐA LEIKJASPILUN",
                    "tr_specText": "Njóttu snurðulausrar spilunar á mörgum vinsælum AAA-leikjum, og leikjaspilunar með hárri glæðingartíðni, allt frá æsilegum skotleikjum til hraðra rauntímaherkænskuleikja. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir aukin afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikina upp á nýtt raunveruleikastig með myndvinnslutækni eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "UPPLIFÐU LEIKJASPILUN EINS OG HÚN GERIST BEST",
                    "tr_specText": "Skilaðu ávallt hámarksafköstum með nýjustu AAA-leikjunum í hárri upplausn og með hárri glæðingartíðni. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> ofursömplun):  </strong>Intel® gervigreindaraukin uppkvörðunartækni fyrir aukin afköst og snurðulausa leikjaspilun. <br/><br/>Stuðningur við <strong>DirectX* 12 Ultimate:  </strong>Farðu með leikjaspilunina upp á nýtt raunveruleikastig með myndvinnslutækni, eins og geislarakningu og breytilegri skyggingu."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Heill myndvinnsluheimur þar sem pláss er fyrir alla"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "FÁÐU HRAÐARI RAMMA OG MEIRI HRAÐA Í LEIKINA ÞÍNA",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling tækni (X<sup class='small_text_sup_xe'>e</sup>SS) fer með þína leikjaspilun á næsta stig með gervigreindarbættri uppkvörðunartækni—fyrir hámarksafköst með mikilli myndanákvæmni. X<sup class='small_text_sup_xe'>e</sup>SS er fínstillt fyrir Intel® Arc™ myndvinnsluvörur svo að þú getur nýtt þér Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) vélbúnaðarhröðun að fullu."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "ÞÚ HEFUR ALDREI SÉÐ ANNAÐ EINS SJÓNARSPIL",
                    "tr_specText": "Með fullum stuðningi fyrir DirectX* 12 Ultimate í allri Intel® Arc™ myndvinnslu, muntu njóta áhrifamikillar og grípandi leikjaspilunar, þökk sé nýjustu myndvinnslutækni eins og geislarakningu, breytilegri skyggingu, netskyggingu og tökuendurgjöf - grundvallaratriði leikjaspilunar næstu kynslóðar."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "HNÖKRALAUS GERÐ MYNDA, MYNDSKEIÐA OG MARGMIÐLUNAREFNIS",
                    "tr_specText": "Slepptu ímyndunaraflinu lausu og heillaðu áhorfendur þína með ríkulegu stafrænu efni með háþróaðri margmiðlunarvél—sem efld er með gervigreind og sækir hröðun úr Intel® tækni. Búðu til stórkostlegt efni, sem knúið er af myndvinnslu með stuðningi úr öllum helstu margmiðlunarsniðum og nýttu þér á sama tíma bestu kóðunargetu fyrir AV1 myndskeið."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "UPPLIFÐU FRAMÚRSTEFNULEGA GERVIGREINDARSKÖPUN OG LEIKJASPILUN",
                    "tr_specText": "Intel® Arc™ myndvinnsla opnar dyrnar fyrir öflugri gervigreindargetu, allt frá háþróaðri framleiðslu til grípandi leikjaspilunar. Fáðu sem mest út úr þróaðri framleiðslu hugbúnaðar dagsins í dag, þar á meðal sköpun texta við myndir og myndskeiðavinnslu með aðstoð gervigreindar. Auk þess geturðu notið leikjaspilunar í mikilli upplausn í gegnum gervigreindarhraðaða X<sup class='small_text_sup_xe'>e</sup>SS uppkvörðun."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafík",
                    "tr_specText": "Hér hefst myndvinnsla fagfólksins"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "FRÁBÆRT FYRIR HÖFUNDA, HÖNNUÐI OG VERKFRÆÐINGA SEM ERU Á FERÐINNI",
                    "tr_specText": "Innbyggð Intel® Arc™ Pro myndvinnsla, sem er fáanleg á völdum Intel® Core™ Ultra H-series örgjörvum, opnar fyrir tvöföldun í myndvinnsluafköstum með vottun frá samstarfsaðilum Intel úr hópi sjálfstæðra hugbúnaðarsala sem skilar auknum áreiðanleika og gefur skilvirka gervigreind og hugbúnaðarhröðun með geislarakningu sem gefur stórkostlega framleiðslu efnis á ferðinni.<br/><br/><span class='expandable_heading'>Hugbúnaðarvottun<br/></span><br/>Intel hefur unnið náið með hundruð hugbúnaðarfyrirtækja í gegnum árin og þessi einstaka reynsla skilar sér í Intel® Arc™ Pro A-series skjákortunum okkar. Vottanir eru okkur jafnmikilvægar og þær eru þér."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "GERUM GERVIGREIND AÐGENGILEGA",
                    "tr_specText": "Þessi skjákjarni felur í sér mikið úrval frábærrar tækni, þar á meðal stuðning fyrir samhæfð gervigreindartól með innbyggðri og sérhannaðri gervigreindarhröðun hvenær sem þú þarft á henni að halda."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "GEISLARAKNING ÁN HÆRRA VERÐS",
                    "tr_specText": "Skjákort þurfa að gera meira en einfaldlega að ýta pixlum á skjáinn, þau þurfa einnig að flýta fyrir sköpun fallegra mynda. Vélbúnaðartækni fyrir geislarakningu sem innbyggð er í Intel® Arc™ Pro A-series myndvinnslu gerir samhæfðum hugbúnaði þínum kleift að byrja að kanna þessi nýju faglegu verkflæði. Geislarakning er vanalega einungis fáanleg í dýrum myndvinnslubúnaði en nýjustu myndvinnsluvörurnar frá Intel bjóða þeim væntingum birginn."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "FÆRIR HÖFUNDUM SKJÁMYNDIR SEM GAGNTAKA",
                    "tr_specText": "Með fullum stuðningi fyrir DirectX* 12 Ultimate í allri Intel® Arc™ myndvinnslu, muntu njóta áhrifamikillar og grípandi leikjaspilunar, þökk sé nýjustu myndvinnslutækni eins og geislarakningu, breytilegri skyggingu, netskyggingu og tökuendurgjöf - grundvallaratriði leikjaspilunar næstu kynslóðar."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DÝNAMÍSKT BESTAÐAR MYNDIR",
                    "tr_specText": "Með sérstakri velbúnaðarkóðun í Pro GPU, hjálpar Dolby Vision* þér að ná meiri dýpt, öflugri skerpu og fleiri litum á samhæfða skjánum þínum. Dolby Vision* opnar fyrir alla möguleika HDR-tækni með dýnamískri bestun myndgæða sem byggir á þjónustu þinni, skjátæki og kerfi sem skilar stórkostlegu sjónarspili í hvert sinn."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "HUGBÚNAÐUR VOTTAÐUR AF SÉRFRÆÐINGUM",
                    "tr_specText": "Intel hefur unnið náið með hundruð hugbúnaðarfyrirtækja í gegnum árin og þessi einstaka reynsla endurspeglast í þessum faglegu Intel® Arc™ Pro A-series myndvinnsluvörum. Vottanir og hæfni hugbúnaðarverkflæðis eru eins mikilvægar okkur og þær eru þér."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "GEISLARAKNING ÁN HÆRRA VERÐS",
                    "tr_specText": "Skjákort þurfa að gera meira en einfaldlega að ýta pixlum á skjáinn, þau þurfa einnig að flýta fyrir sköpun fallegra mynda. Vélbúnaðartækni fyrir geislarakningu sem innbyggð er í Intel® Arc™ Pro A-series myndvinnslu gerir samhæfðum hugbúnaði þínum kleift að byrja að kanna þessi nýju faglegu verkflæði. Geislarakning er vanalega einungis fáanleg í dýrum myndvinnslubúnaði en nýjustu myndvinnsluvörurnar frá Intel bjóða þeim væntingum birginn."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "MARGIR STÓRIR SKJÁIR MEÐ EINUM LITLUM ÖRGJÖRVA FARTÖLVUSKJÁKORTS",
                    "tr_specText": "Intel® Arc™ Pro skjákort fyrir fartölvur geta stutt allt að fjóra risaskjái til að auka skilvirkni þíns verkflæðis. Bættu einnig við HDR-stuðningi og þú hefur allt sem þú þarft fyrir samfélagsmiðlatengda fjölskjáauppsetningu heima fyrir eða á skrifstofunni."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "MÖGULEIKI Á BANDVÍDD MEÐ FULLU MINNI",
                    "tr_specText": "Bandvídd skjákortsins þíns er mikilvægur þáttur þegar kemur að vinnustöðvum. Veljir þú ekki rétta kostinn getur það hægt á verkflæði fagmannsins og það er þess vegna sem við höfum gætt þess að Intel® Arc™ Pro A-series GPU búi yfir stuðning fyrir nútíma PCIe* 4.0, án þess að það komi niður á afturvirku samhæfi. Myndvinnsluminni er einnig aukið með miklum bandvíddarhraða sem leyfir enn hraðari aðgang hugbúnaðarins að verkgögnum."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "BLÁSTU LÍFI Í HVERN EINASTA RAMMA",
                    "tr_specText": "<span class='expandable_heading'>Gervigreindaraukning. Studd af Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS er gervigreindarbætt uppkvörðunartækni sem eykur afköstin án þess að fórna fegurð hvers ramma — svo að þú getir upplifað leikjaspilun eins og hún á að vera."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "RAUNTÍMI.<br/> RAUNVERULEG GAGNTEKNING.<br/> RAUNVERULEIKI EINS OG HANN GERIST BESTUR.",
                    "tr_specText": "Raunverulegri veröld bíður þín. Með innbyggðri geislarakningu upplifir þú allt frá raunverulegum skuggum og endurskini til stórkostlegrar lýsingar. Með öðrum orðum, þetta er leikjasjónarspil sem þú vilt ekki missa af."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "ÞÍN BESTA LEIKJASPILUN, DEILT EINS OG BEST VERÐUR Á KOSIÐ",
                    "tr_specText": "Veittu áhorfendum þínum þá hnökralausu leikjaspilun sem þeir verðskulda að sjá. Með vélbúnarhraðaðri AV1 kóðunarhæfni Intel® Arc™ myndvinnslu munu þeir verða gagnteknir af hverri hreyfingu. Meiri sjónræn gæði á sama bitahraða gera þér kleift að streyma þínu besta og spara bandvídd á sama tíma."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Velkomnir, allir höfundar",
                    "tr_specText": "Ný og öflugri gervigreindarupplifun er rétt innan seilingar. Með háþróaðri gervigreindarhröðun sem innbygð er í Intel® Arc™ A-series myndvinnslu getur þú varið meiri tíma í að skapa og minni tíma í að bíða eftir forritum.<br/><br/>Taktu myndbandaframleiðslu þína á næsta stig og vertu í fararbroddi með háþróuðum, geymsluskilvirkum AV1 myndskeiðum, sem drifin eru áfram af háþróaðri AV1 vélbúnaðarvinnslu sem innbyggð er í Intel® Arc™ myndvinnslu."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Allt sem þarf til að koma hlutum í verk",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Öflug frammistaða sem er létt og þunn og gerð til að endast – þú getur notið þess alls. Þú finnur það sem þú ert að leita að í Windows* 10 tölvu með mikilli rafhlöðuendingu, fallegum snertiskjáum* og nýjustu SSD-drifunum.",
                "tr_noteSection": "*Háð vélbúnaði",
                "titleArray": [
                  {
                    "tr_title": "Minna fyrir meira",
                    "tr_subtitle": "Tölvurnar í dag nota yfirleitt minna afl og því endist rafhlaðan lengur – að meðaltali í átta klukkustundir við spilun á myndböndum.**",
                    "tr_noteSection": "**Rafhlöðuending er mjög breytileg eftir stillingum, notkun og öðrum þáttum."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALLT SEM ÞARF TIL AÐ KOMA HLUTUM Í VERK",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Öflug frammistaða sem er létt og þunn og gerð til að endast – þú getur notið þess alls. Þú finnur það sem þú ert að leita að í Windows* 10 tölvu með mikilli rafhlöðuendingu, fallegum snertiskjáum* og nýjustu SSD-drifunum.",
                "tr_noteSection": "*Háð vélbúnaði",
                "titleArray": [
                  {
                    "tr_title": "MINNA FYRIR MEIRA",
                    "tr_subtitle": "Tölvurnar í dag nota yfirleitt minna afl og því endist rafhlaðan lengur – að meðaltali í átta klukkustundir við spilun á myndböndum.**",
                    "tr_noteSection": "**Rafhlöðuending er mjög breytileg eftir stillingum, notkun og öðrum þáttum."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Öryggi og afköst í forgangi",
                "tr_pretitle": "Windows* 10 í S-stillingu",
                "tr_subtitle": "Þegar Windows* 10 keyrir í S-stillingu færðu aukna vörn til að þú getir unnið, skipað og vafrað með fullvissu um að tækið sé öruggt."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ÖRYGGI OG AFKÖST Í FORGANGI",
                "tr_title": "Windows* 10 í S-stillingu",
                "tr_subtitle": "Þegar Windows* 10 keyrir í S-stillingu færðu aukna vörn til að þú getir unnið, skipað og vafrað með fullvissu um að tækið sé öruggt."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Gerðu eitthvað frábært",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Afköst, verð og miklir möguleikar. Þú getur fundið Windows* tölvu við hæfi á öllum verðbilum og í stíl sem er þér að skapi – hvort sem um er að ræða nýstárlegar 2 í 1-tölvur, þunnar og léttar fartölvur, snertiskjái eða aftengjanleg lyklaborð."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GERÐU EITTHVAÐ FRÁBÆRT",
                "tr_title": "Windows*",
                "tr_subtitle": "Afköst, verð og miklir möguleikar. Þú getur fundið Windows* tölvu við hæfi á öllum verðbilum og í stíl sem er þér að skapi – hvort sem um er að ræða nýstárlegar 2 í 1-tölvur, þunnar og léttar fartölvur, snertiskjái eða aftengjanleg lyklaborð."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stýrikerfi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minni",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVERSU MIKIÐ VINNSLUMINNI ÞARFTU?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hversdagsnotkun – 2 TIL 8 GB VINNSLUMINNI",
                    "tr_badgeSubTitle": "Hentar vel fyrir þau sem nota tölvuna fyrir ritvinnslu og vefinn og nota oftast eitt forrit í einu."
                  },
                  {
                    "tr_badgeTitle": "Kraftur – 8 TIL 16 GB VINNSLUMINNI",
                    "tr_badgeSubTitle": "Meira vinnsluminni gerir forritum kleift að keyra hraðar og gerir þér einnig kleift að keyra fleiri forrit í einu."
                  },
                  {
                    "tr_badgeTitle": "Enn meiri kraftur – MEIRA EN 16 GB AF VINNSLUMINNI",
                    "tr_badgeSubTitle": "Ef þú hefur áhuga á tölvuleikjum, mynd- eða myndbandavinnslu eða gerð þrívíddarlíkana þarftu meira vinnsluminni til að keyra þessi kröfuhörðu forrit."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB vinnsluminni fyrir fjölnotkun á hverjum degi",
                "tr_subtitle": "Minnið fær einföld forrit á borð við ritvinnsluforrit og vafra til að opnast og keyra hnökralaust.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VINNSLUMINNI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB vinnsluminni fyrir mikla fjölnotkun",
                "tr_subtitle": "Ef þú notar mörg forrit í einu gera [memory_total] GB af minni þér kleift að skipta á milli þeirra án vandræða.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VINNSLUMINNI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB vinnsluminni fyrir enn meiri fjölnotkun",
                "tr_subtitle": "Þú færð nóg af minni til að keyra mörg kröfuhörð forrit í einu án þess að hægja á tölvunni.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VINNSLUMINNI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB minni = [Value2] GB Intel® Optane™ minni + [Value3] GB vinnsluminni",
                "tr_subtitle": "Þessi samsetning minnislausna gefur hraða drifvinnslu með Intel® Optane™ minni og styttri viðbrögð með vinnsluminninu.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>MINNI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minni",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Minni bið. Meira af því skemmtilega.",
                "tr_subtitle": "Þegar Intel® Optane™ minnið vinnur ásamt kerfisminninu færðu hraðari aðgang að þeim forritum sem þú notar oftast þannig að þau opnast og hlaðast um leið og þú þarft á þeim að halda.",
                "tr_pretitle": "[XX] GB Intel® Optane™ minni",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "HVAÐ GERIR MINNI FYRIR TÖLVUNA MÍNA?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Hraðari efnissköpun",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Viðbragðsfljótari leikir",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Auðveldari fjölvinnsla",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Hraðari opnun forrita",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minni",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Fáðu meiri hraða í tölvuleikina</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ minni",
                "tr_pretitle1": "[XX] GB Intel® Optane™ minni hraðar á tölvuleikjunum",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ræsing leikja er allt að<br/><span class=\"optane-game\">98% hraðari</span><br/>Af gagnadrifi með [optane_legal_processor_name] örgjörva með Intel® Optane™ minni<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Hleðsla borða er allt að<br/><span class=\"optane-game\">4,1X hraðari</span><br/>Af gagnadrifi með [optane_legal_processor_name] örgjörva með Intel® Optane™ minni<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minni",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Fáðu meiri hraða í tölvuleikina</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ minni",
                "tr_pretitle1": "[XX] GB Intel® Optane™ minni hraðar á tölvuleikjunum",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ræsing leikja er allt að<br/><span class=\"optane-game\">98% hraðari</span><br/>Af gagnadrifi með [optane_legal_processor_name] örgjörva með Intel® Optane™ minni<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Hleðsla borða er allt að<br/><span class=\"optane-game\">4,1X hraðari</span><br/>Af gagnadrifi með [optane_legal_processor_name] örgjörva með Intel® Optane™ minni<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB minni = [Value2] GB Intel® Optane™ minni + [Value3] GB vinnsluminni",
                "tr_subtitle": "Þessi samsetning minnislausna gefur hraða drifvinnslu með Intel® Optane™ minni og styttri viðbrögð með vinnsluminninu.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB vinnsluminni fyrir fjölvinnslu",
                    "tr_subtitle": "Ef þú notar mörg forrit í einu gera [memory_total] GB af minni þér kleift að skipta á milli þeirra án vandræða."
                  },
                  {
                    "tr_title": "Fyrir verkefni hversdagsins",
                    "tr_subtitle": "Minnið fær einföld forrit á borð við ritvinnsluforrit og vafra til að opnast og keyra hnökralaust."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB vinnsluminni",
                "tr_pretitle": "FYRIR MEIRI FJÖLVINNSLU",
                "tr_subtitle": "Vinnsluminni gefur örgjörvanum aðgang að forritsskrám til að hann hafi hraðari aðgang að þeim og geti sinnt mörgum verkefnum í einu.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hefðbundið minni",
                    "tr_badgeSubTitle": "Allt að 8 GB vinnsluminni"
                  },
                  {
                    "tr_badgeTitle": "Aukið minni",
                    "tr_badgeSubTitle": "Meira en 8 GB og allt að 16 GB vinnsluminni"
                  },
                  {
                    "tr_badgeTitle": "Mikið minni",
                    "tr_badgeSubTitle": "Meira en 16 GB vinnsluminni"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB fyrir meiri fjölvinnslu",
                "tr_pretitle": "FYRIR MEIRI FJÖLVINNSLU",
                "tr_subtitle": "Vinnsluminni gefur örgjörvanum aðgang að forritsskrám til að hann hafi hraðari aðgang að þeim og geti sinnt mörgum verkefnum í einu.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hefðbundið minni",
                    "tr_badgeSubTitle": "Allt að 8 GB vinnsluminni"
                  },
                  {
                    "tr_badgeTitle": "Aukið minni",
                    "tr_badgeSubTitle": "Meira en 8 GB og allt að 16 GB vinnsluminni"
                  },
                  {
                    "tr_badgeTitle": "Mikið minni",
                    "tr_badgeSubTitle": "Meira en 16 GB vinnsluminni"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞETTA MINNI Í SAMANBURÐI VIÐ ÖNNUR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Hefðbundinn kraftur",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Aukið",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Mikið",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minni",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVERNIG ER ÞETTA MINNI Í SAMANBURÐI VIÐ ÖNNUR?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "HVERNIG ER ÞETTA MINNI Í SAMANBURÐI VIÐ ÖNNUR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Núverandi tæki",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Hversdagsleg notkun",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Mikið afl",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Aukið",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>vinnslu&shy;minni</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Geymsla",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "HVERSU MIKIÐ KEMST FYRIR Í TÖLVUNNI?",
                "tr_subtitle": "Þú þarft ekki að giska. Við sýnum þér nákvæmlega hvað þú getur geymt í þessari tölvu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Komdu þér upp safni með </span><br/><span class=\"storage-standard\">[num_songs] lögum<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>miðað við 5 MB á hvert lag að meðaltali",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Komdu þér upp safni með </span><br/><span class=\"storage-standard\">[num_games] leikjum<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>miðað við 40 GB á hvern leik að meðaltali",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Pláss fyrir</span><br/><span class=\"storage-standard\">[num_videos] myndbönd<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>miðað við 5 GB á hvert myndband að meðaltali",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Taktu og geymdu </span><br/><span class=\"storage-standard\">[num_photos] myndir<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>miðað við 4 MB á hverja mynd að meðaltali",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ minni + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD-geymsla",
                "tr_preTitle1": "[capacity] TB SSD-geymsla",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Þessi tölva er búin SSD-drifi með [capacity] GB geymsluplássi. Það þýðir að þú getur geymt ansi mikið af myndum, myndböndum og forritum og nálgast þau mun hraðar en ef þau væru á hörðum diski.",
                    "tr_title": "Staður fyrir allt í tölvunni"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Þessi tölva er búin SSD-drifi með [capacity] TB geymsluplássi. Það þýðir að þú getur geymt ansi mikið af myndum, myndböndum og forritum og nálgast þau mun hraðar en ef þau væru á hörðum diski.",
                    "tr_title": "Þetta tæki er með mikið geymslupláss"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD-geymsla",
                "tr_preTitle1": "[capacity] TB HDD-geymsla",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Harðir diskar henta vel fyrir fólk sem vill hafa mikið geymslupláss í tölvunni.",
                    "tr_title": "Staður fyrir allt í tölvunni"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Harðir diskar henta vel fyrir fólk sem vill hafa mikið geymslupláss í tölvunni.",
                    "tr_title": "Þetta tæki er með mikið geymslupláss"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "HVERSU MIKIÐ KEMST FYRIR Í TÖLVUNNI?",
                "tr_subtitle": "Þú þarft ekki að giska. Við sýnum þér nákvæmlega hvað þú getur geymt í þessari tölvu.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD-drif með auknum hraða [optane_memory_size] GB Intel® Optane™ minnis"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD-drif með auknum hraða [optane_memory_size] GB Intel® Optane™ minnis"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD-geymsla",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD-geymsla",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD-geymsla",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD-geymsla",
                "titleArray": [
                  {
                    "tr_title": "Staður fyrir allt í tölvunni",
                    "tr_title1": "Þetta tæki er með mikið geymslupláss",
                    "tr_subtitle": "Þessi tölva er búin SSD-drifi með [convertedSSD] GB geymsluplássi og [convertedHDD] GB hörðum diski. Þannig færðu bæði gríðarlegt geymslupláss og hraða.",
                    "tr_subtitle1": "Þessi tölva er búin SSD-drifi með [convertedSSD] TB geymsluplássi og [convertedHDD] GB hörðum diski. Þannig færðu bæði gríðarlegt geymslupláss og hraða.",
                    "tr_subtitle2": "Þessi tölva er búin SSD-drifi með [convertedSSD] GB geymsluplássi og [convertedHDD] TB hörðum diski. Þannig færðu bæði gríðarlegt geymslupláss og hraða.",
                    "tr_subtitle3": "Þessi tölva er búin SSD-drifi með [convertedSSD] TB geymsluplássi og [convertedHDD] TB hörðum diski. Þannig færðu bæði gríðarlegt geymslupláss og hraða."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>GEYMSLA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Fáðu hraðari aðgang að stórri geymslu með Intel® Optane™ minni með SSD.",
                "tr_title": "Risastórar skrár velkomnar",
                "tr_pretitle": "[total_capacity] GB Intel® SSD-drif með auknum hraða [optane_memory_size] GB Intel® Optane™ minnis",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD-drif með auknum hraða [optane_memory_size] GB Intel® Optane™ minnis",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Þessi byltingarkennda geymslulausn er sú fyrsta til að sameina Intel® Optane™ minni og SSD-geymslu á einni flögu. Þetta gerir hún fyrir þig:",
                    "tr_title": "HVAÐ ER INTEL® OPTANE™ MINNI MEÐ SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Opnaðu það sem þú þarft með hraði",
                    "tr_badgeSubTitle": "Með því að forhlaða skrár og forrit sem þú notar oft geturðu varið meiri tíma til að njóta og minni tíma í að bíða.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Geymslupláss og hraði",
                    "tr_badgeSubTitle": "Þú hefur allt það pláss sem þú þarft fyrir miðlaskrár með hraðri virkni SSD-drifs.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Hraðari viðbrögð",
                    "tr_badgeSubTitle": "Intel® Optane™ minni með SSD lærir á hvernig þú starfar til að auka skilvirkni tölvunnar.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEYMSLA",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Hafðu allar miðlaskrárnar þínar innan handar með nóg pláss í áreiðanlegri geymslu.",
                "tr_title": "Pláss fyrir allar myndirnar, myndböndin og lögin"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjár",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milljónir pixla til að strjúka, ýta á og dást að",
                "tr_subtitle": "Notaðu tölvuna eins og snjallsíma með einfaldri stjórnun og skarpari og litríkari mynd.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplausn: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] milljónir"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommu skjár",
                    "tr_badgeSubTitle": "Hæð skjás: [screenHeight] tommur",
                    "tr_badgePreTitle": "Breidd skjás: [YY] tommur"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Snertiskjár",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjár",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Gleymdu þér í [screenResolution] milljónum pixla",
                "tr_subtitle": "Þetta snýst allt um pixla. Því fleiri sem þeir eru, því skarpari og líflegri verða myndirnar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplausn: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] milljónir"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommu skjár",
                    "tr_badgeSubTitle": "Hæð skjás: [screenHeight] tommur",
                    "tr_badgePreTitle": "Breidd skjás: [YY] tommur"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjár",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] tommur <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "GLUGGINN INN Í LEIKJAHEIMA",
                "tr_subtitle": "Rétt eins og á við um sjónvörp skipta stærð og upplausn mestu máli fyrir skjái fyrir tölvuleiki.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplausn: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] milljónir"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommu skjár",
                    "tr_badgeSubTitle": "Hæð skjás: [screenHeight] tommur",
                    "tr_badgePreTitle": "Breidd skjás: [screenWidth] tommur"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Snertiskjár"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjár",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Þetta tæki er með flottum skjá",
                "tr_subtitle": "Meira en átta sinnum fleiri pixlar en með HD gæða myndbönd og tölvuleiki óviðjafnanlegri skerpu og litum.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplausn: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] milljónir"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommu skjár",
                    "tr_badgeSubTitle": "Hæð skjás: [screenHeight] tommur",
                    "tr_badgePreTitle": "Breidd skjás: [YY] tommur"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjár",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milljónir pixla til að ýta á, strjúka og snerta",
                    "tr_subTitle": "Skoðaðu og notaðu hlutina sem þú elskar með skarpari og litríkari mynd."
                  },
                  {
                    "tr_title": "[screenResolution] milljónir pixla til að gæða stafræna heiminn lífi",
                    "tr_subTitle": "Þetta snýst allt um pixla. Því fleiri sem þeir eru, því skarpari og líflegri verða myndirnar."
                  },
                  {
                    "tr_title": "Þetta tæki er með flottum skjá",
                    "tr_subTitle": "Nánast fjórum sinnum fleiri pixlar en með HD gæða myndbönd og tölvuleiki óviðjafnanlegri skerpu og litum."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tengigeta",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 tækni býður upp á hröðustu og sveigjanlegustu tengingarnar fyrir hvaða dokku, skjá eða gagnatæki sem er.",
                "tr_title": "Eitt tengi fyrir allt",
                "tr_title1": "EITT TENGI FYRIR ALLT",
                "titleArray": [
                  {
                    "tr_title": "HVERNIG GET ÉG NOTAÐ THUNDERBOLT™ 3 TÆKNI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 tækni"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-skjáir",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tengist skjáum með magnaðri upplausn, skerpu og lit"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Utanáliggjandi skjákort",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Býður upp á gríðarmikla uppfærslu fyrir myndvinnslu í hvaða tölvu sem er"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Hraðar nettengingar",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Býður upp á hraðar tengingar við önnur tæki"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tengigeta",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 tækni flytur gögn með meiri hraða en hefðbundin USB-tengi til að þú getir flutt skrár hratt og auðveldlega á milli jaðarbúnaðar á borð við marga skjái eða utanáliggjandi skjákort.",
                "tr_title": "Thunderbolt™ 3 tækni",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "LEIFTURHRÖÐ GÖGN",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 tækni",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tengigeta",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Eitt altækt tengi og ein snúra fyrir alla uppáhaldsaukahlutina þína</li> <li>Áreiðanleg hleðsla fyrir fartölvur</li> <li>Trygg vottun veitir hugarró</li></ul>",
                "tr_title": "Algjörlega samræmd snúrutengigeta",
                "tr_title1": "ALGJÖRLEGA SAMRÆMD SNÚRUTENGIGETA",
                "tr_noteSection": "<sup>†</sup>Samkvæmt lágmarkskerfiskröfum",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVERNIG GET ÉG NOTAÐ THUNDERBOLT™ 4 TÆKNI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 tækni"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skapaðu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tengdu ytri skjá og hraðgeymslu til að skoða og vinna með myndir og myndbönd."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Tengdu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Komdu þér upp einfaldri og hreinni vinnuaðstöðu með því að tengja fartölvuna við alla aukahluti með einni snúru."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spilaðu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bættu við fleiri SSD- og HDD-drifum með allt að 3.000 MB/s til að geyma leikjasöfn og margmiðlunarskrár."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hámarksafköst<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hraðara USB* 3.2 <br/>2x hraðara en HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tengigeta",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Njóttu ótrúlegra afkasta Thunderbolt™ 3 tækninnar með enn meiri möguleikum sem gera þér kleift að tengjast og nota nýjustu Thunderbolt™ dokkur, skjái, hraðgeymslu og hvaða USB-aukabúnað* sem er.",
                "tr_title": "Thunderbolt™ 4 tækni",
                "tr_pretitle": "ALGJÖRLEGA SAMRÆMD SNÚRUTENGIGETA",
                "tr_noteSection": "<sup>†</sup>Samkvæmt lágmarkskerfiskröfum",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Ein Thunderbolt™ 4 snúra getur komið í staðinn fyrir allar USB-C* snúrurnar þínar til að gera það hratt og auðvelt að velja snúru."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 dokka býður upp meiri sveigjanleika með allt að fjórum Thunderbolt™ tengjum til að tengja hvaða aukahluti sem er."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hámarksafköst<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hraðara USB* 3.2 <br/>2x hraðara en HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tengigeta",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Eitt altækt tengi og ein snúra fyrir alla uppáhaldsaukahlutina þína</li> <li>Áreiðanleg hleðsla fyrir fartölvur</li> <li>Trygg vottun veitir hugarró</li></ul>",
                "tr_title": "Fáðu frábæra upplifun með Thunderbolt™ aukahlutum",
                "tr_noteSection": "<sup>†</sup>Samkvæmt lágmarkskerfiskröfum",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVERNIG GET ÉG NOTAÐ THUNDERBOLT™ 4 TÆKNI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skapaðu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tengdu ytri skjá og hraðgeymslu til að skoða og vinna með myndir og myndbönd."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Tengdu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Komdu þér upp einfaldri og hreinni vinnuaðstöðu með því að tengja fartölvuna við alla aukahluti með einni snúru."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spilaðu",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bættu við fleiri SSD- og HDD-drifum með allt að 3.000 MB/s til að geyma leikjasöfn og margmiðlunarskrár."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hámarksafköst<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hraðara USB* 3.2 <br/>2x hraðara en HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Algengustu gerðir Wi‑Fi eru:",
                "tr_title": "UM WI-FI-STAÐLA",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 gefur enn meiri hraða jafnvel þegar margir notendur nota sama merki.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Þessi nýjasta Wi-Fi-tækninýjung Intel gefur enn meiri hraða en með gígabita Wi-Fi, jafnvel þegar margir notendur nota sama merki.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6E er stærsta stökkið í Wi-Fi-tækni síðustu 20 árin og opnar fyrir nýjar háhraðarásir til að auka afköst og áreiðanleika og minnka truflanir."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 gefur enn meiri hraða jafnvel þegar margir notendur nota sama merki.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Allir kostir Wi-Fi 6E ásamt Intel® Killer™ forgangsröðunarvél sem færir bandvíddina yfir á vefsíður og forrit sem þurfa mest á henni að halda."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Þar sem færri eru á hverri háhraðarás færðu betri afköst og meiri áreiðanleika."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 gefur enn meiri hraða jafnvel þegar margir notendur nota sama merki.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Minni truflanir með Wi-Fi 6 og snjöll frammistaða Intel® Killer™ forgangsröðunarvélarinnar gera netið hraðara og viðbragðsfljótara fyrir þig.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Þar sem færri eru á hverri háhraðarás færðu betri afköst og meiri áreiðanleika."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Meira en 5 Gbps PC Wi-Fi-hraði með stöðugum sérlega stuttum biðtíma - deildu skjölum á nokkrum sekúndum í stað mínútna."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) er nýjasta tæknin sem tryggir framúrskarandi áreiðanleika, lítinn biðtíma og fer með hraða í gegnum gervigreindarmiðaða hámarksnettengingu."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 gefur enn meiri hraða jafnvel þegar margir notendur nota sama merki.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) nær meiri hraða en 1 Gbps með áreiðanleika og stuttum biðtíma með því að nota 6 GHz rásirnar."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Meiri nýtni, afkastageta og hraði borið saman við Wi-Fi 5 á tepptum netkerfum."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Vertu í betra sambandi með Wi-Fi [XX] og njóttu hraðara internets<sup>†</sup> og meiri áreiðanleika.",
                "tr_title": "Fáðu aukinn hraða og drægi",
                "tr_noteSection": "<sup>†</sup>Raunverulegt gegnumstreymi um net og/eða sendistyrkur eru breytileg.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nú á tímum fjölspilunar og netspilunar er mikilvægara en nokkru sinni fyrr að vera í sambandi. Auk hraða viltu að hraði þráðlausu tengingarinnar haldist óbreyttur í meiri fjarlægð.",
                "tr_title": "[XX]",
                "tr_pretitle": "TENGINGIN ER LYKILATRIÐI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tengstu netinu með Intel® Wi-Fi 6 (Gig+) við Wi-Fi 6 beina með stuðningi við 160 MHz rásir til að bjóða upp á hraðari tengingar og meiri áreiðanleika fyrir virka notendur.",
                "tr_title": "Nærri því 3x meiri hraði með Intel® <span class='no-warp'>Wi-Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Miðað við hefðbundið AC Wi-Fi. Valdir eiginleikar eru aðeins í boði með tilteknum SKU-birgðaeiningum. Hafðu samband við framleiðanda til að fá upplýsingar. Nánari upplýsingar um Wi-Fi fást á intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grunnútgáfa af tveggja rása Intel® Wi-Fi 6 styður bætt afköst fyrir allt það helsta, svo sem tölvupóst og vefskoðun á netkerfum með mikilli umferð.<sup>9</sup>",
                "tr_title": "Ómissandi Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nú á tímum fjölspilunar og netspilunar er mikilvægara en nokkru sinni fyrr að vera í sambandi. Auk hraða viltu að hraði þráðlausu tengingarinnar haldist óbreyttur í meiri fjarlægð.",
                "tr_title": "[XX]",
                "tr_pretitle": "TENGINGIN ER LYKILATRIÐI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Þessar nýju Wi-Fi-rásir þýða að þú þarft ekki að deila bandvíddinni með eldri tækjum í nágrenninu til að þú njótir hnökralauss streymis, hraðrar vefskoðunar og snurðulausrar fjarvinnu og náms.",
                "tr_title": "Tenging án samkeppni",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Vertu í betra sambandi með [XX] og njóttu hraðara internets<sup>†</sup> og meiri áreiðanleika.",
                "tr_title": "Fáðu aukinn hraða og drægi",
                "tr_noteSection": "<sup>†</sup>Raunverulegt gegnumstreymi um net og/eða sendistyrkur eru breytileg.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nú á tímum fjölspilunar og netspilunar er mikilvægara en nokkru sinni fyrr að vera í sambandi. Auk hraða viltu að hraði þráðlausu tengingarinnar haldist óbreyttur í meiri fjarlægð.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "TENGINGIN ER LYKILATRIÐI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Innbyggt ofurhratt Wi‑Fi",
                "tr_subtitle": "Taktu úr sambandi og vertu í tengslum við netheiminn."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Spilun á netinu var að fá stóra uppfærslu með sérstökum háhraðarásum fyrir minni truflun og Intel® Killer™ forgangsröðunarvél sem setur tölvuleiki í fyrsta sætið.",
                "tr_title": "Taktu fram úr keppinautunum",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Spilun á netinu var að fá stóra uppfærslu með sérstökum háhraðarásum fyrir minni truflun og Intel® Killer™ forgangsröðunarvél sem setur tölvuleiki í fyrsta sætið.",
                "tr_title": "[XX]",
                "tr_pretitle": "TAKTU FRAM ÚR KEPPINAUTUNUM",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Spilun á netinu var að fá stóra uppfærslu með sérstökum háhraðarásum fyrir minni truflun sem setja tölvuleiki í fyrsta sætið.",
                "tr_title": "[XX]",
                "tr_pretitle": "TAKTU FRAM ÚR KEPPINAUTUNUM",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi hraðar sjálfkrafa á netumferð fyrir forrit sem þurfa mest á henni að halda til að straumspiluð myndbönd séu í hárri upplausn og með litlum biðtíma.",
                "tr_title": "Hraðari og snjallari leið til að tengjast.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nú á tímum fjölspilunar og netspilunar er mikilvægara en nokkru sinni fyrr að vera í sambandi. Auk hraða viltu að hraði þráðlausu tengingarinnar haldist óbreyttur í meiri fjarlægð.",
                "tr_title": "[XX]",
                "tr_pretitle": "TENGINGIN ER LYKILATRIÐI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Vertu í betra sambandi með Wi-Fi 5 og njóttu hraðara internets<sup>†</sup> og meiri áreiðanleika.",
                "tr_title": "Fáðu aukinn hraða og drægi",
                "tr_noteSection": "<sup>†</sup>Raunverulegt gegnumstreymi um net og/eða sendistyrkur eru breytileg.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nú á tímum fjölspilunar og netspilunar er mikilvægara en nokkru sinni fyrr að vera í sambandi. Auk hraða viltu að hraði þráðlausu tengingarinnar haldist óbreyttur í meiri fjarlægð.",
                "tr_title": "[XX]",
                "tr_pretitle": "TAKTU FRAM ÚR KEPPINAUTUNUM",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Eiginleikar Wi-Fi 7 byggja á og upphefja fyrri Wi-Fi-kynslóðir. Þetta þýðir ekki einungis meiri hraða, heldur líka stórbættan viðbragðsflýti og áreiðanleika fyrir áhrifaríka notendaupplifun og fágaða framtíðartækni.",
                "tr_title": "Nettenging tölvu eins og í gegnum snúru",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Eiginleikar Wi-Fi 7 byggja á og upphefja fyrri Wi-Fi-kynslóðir. Þetta þýðir ekki einungis meiri hraða, heldur líka stórbættan viðbragðsflýti og áreiðanleika fyrir áhrifaríka notendaupplifun og fágaða framtíðartækni.",
                "tr_title": "[XX]",
                "tr_pretitle": "UMBREYTING Á NOTANDAUPPLIFUN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Meiri hraði, minni biðtími og aukinn áreiðanleiki með gervigreindarmiðaðri hámarksnettengingu",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Meiri hraði, minni biðtími og aukinn áreiðanleiki með gervigreindarmiðaðri hámarksnettengingu.",
                "tr_title": "[XX]",
                "tr_pretitle": "VIÐBRAGÐSHRAÐI EINS OG Í GEGNUM SNÚRU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hvernig er þetta mælt?",
                    "tr_badgeSubTitle": "Flestir framleiðendur sýna rafhlöðuendingu í klukkustundum. Þrátt fyrir að sá útreikningur sé ekki 100% nákvæmur ætti hann að hjálpa þér að bera rafhlöðuendingu einnar tölvu saman við aðra."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "TIL AÐ SPILA LEIKI Á FERÐINNI",
                "tr_subtitle": "Það þarf enginn að vera fastur við skrifborð með krafti leikjafartölvanna í dag. Nú geturðu tekið leikina hvert sem er.",
                "tr_title": "[Dynamic.battery.values.hours] klukkustunda rafhlöðuending"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Aftengdu tölvuna og vertu í sambandi með forritum, leikjum og tónlist klukkustundum saman.",
                "tr_title": "[XX] klukkustundir af streymi á einni hleðslu<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Samkvæmt yfirlýsingu tölvuframleiðanda um rafhlöðuendingu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] klst.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Aftengdu tölvuna og vertu í sambandi með forritum, leikjum og tónlist klukkustundum saman.",
                "tr_title": "[Dynamic.battery.values.hours] klukkustundir af afli á ferðinni<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Samkvæmt yfirlýsingu tölvuframleiðanda um rafhlöðuendingu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] klst.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Aftengdu tölvuna og vertu í sambandi með forritum, leikjum og tónlist klukkustundum saman.",
                "tr_title": "Ein [Dynamic.battery.values.hours] klukkustunda hleðsla fyrir allan daginn<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Samkvæmt yfirlýsingu tölvuframleiðanda um rafhlöðuendingu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] klst.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Rafhlaða",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Þessi tölva fínstillir rafhlöðuna til að hún endist lengur og gefi þér að minnsta kosti 4 klukkustundir með 30 mínútna hleðslu.<sup>4</sup>",
                "tr_title": "Aftengdu þig lengur með [Dynamic.battery.values.hours] klukkustunda rafhlöðuendingu<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Samkvæmt yfirlýsingu tölvuframleiðanda um rafhlöðuendingu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] klst.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Heima",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Af hverju þessi tölva",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Skoða ítarlega tæknilýsingu",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Fjartenging",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Farsími",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Lagalegar upplýsingar",
        "tr_paragraph_array": [
          "Hugbúnaður og vinnuálag sem notuð eru í afkastaprófunum eru fínstillt miðað við Intel® örgjörva. Afkastapróf, svo sem SYSmark* og MobileMark*, eru mæld með sérstökum tölvukerfum, íhlutum og aðgerðum. Breyting á einhverjum þessara þátta gæti breytt útkomunni. Þú ættir að kanna aðrar upplýsingar og afkastapróf til að hjálpa þér við að meta fyrirhuguð kaup, þar á meðal frammistöðu vörunnar þegar hún er notuð með öðrum vörum. Til að fá frekari upplýsingar um afköst og samanburðarniðurstöður skaltu fara á www.intel.com/benchmarks.",
          "¹Intel® Wi‑Fi 6 (Gig+) vörur styðja valfrjálsar 160 MHz rásir sem bjóða upp á mesta fræðilega hámarkshraða (2402 Mbps) fyrir hefðbundnar 2x2 802.11ax Wi‑Fi-vörur fyrir tölvur. Premium Intel® Wi‑Fi 6 (Gig+) vörur bjóða upp á 2-4x meiri fræðilegan hámarkshraða miðað við staðlaðar 2x2 (1201 Mbps) eða 1x1 (600 Mbps) 802.11ax Wi‑Fi-vörur fyrir tölvur, sem styðja aðeins áskildar kröfur 80 MHz rása.",
          "²Ráðleggingar eru aðeins til upplýsingar. Kannaðu einnig aðrar upplýsingar þegar þú tekur ákvörðun um kaup.",
          "³Eiginleikar og kostir Intel-tækni eru háðir kerfisstillingum og gætu þurft aukinn vélbúnað, hugbúnað og virkjun þjónustu. Afköst geta verið mismunandi eftir kerfisstillingum. Ekkert tölvukerfi getur verið algjörlega öruggt. Kannaðu hjá kerfisframleiðanda eða söluaðila eða kynntu þér intel.com.",
          "⁴Fyrir kerfi með FHD-skjám við notkun fyrir þráðlausa vefskoðun. Þegar slökkt er á tölvunni, úr sjálfgefinni frágangsstöðu frumframleiðanda.",
          "⁵Miðað við aðra tölvutengingartækni, þar á meðal eSATA, USB og IEEE 1394* FireWire. Afköst eru breytileg eftir þeim vélbúnaði og hugbúnaði sem er notaður. Nota verður tæki sem styður Thunderbolt™ tækni.",
          "⁶Nærri því 3x meiri hraði: 802.11ax 2x2 160 MHz býður upp á 2402 Mbps fræðilegan hámarkshraða við gagnaflutning. ~3x meiri hraði en með stöðluðu 802.11ac 2x2 80 MHz (867 Mbps) Wi‑Fi samkvæmt lýsingu IEEE 802.11* staðalsins fyrir þráðlaus netkerfi og krefst notkun þráðlausra netbeina sem eru stilltir samkvæmt 802.11ax.",
          "⁷75% minni biðtími: Byggir á gögnum úr hermun Intel (79%) á 802.11ax með og án OFDMA með 9 biðlurum. Meðalbiðtími án OFDM er 36 ms en með OFDMA minnkar meðalbiðtími niður í 7,6 ms. Til að stytta biðtíma þurfa 802.11ax (Wi‑Fi 6) beinir og allir biðlarar að styðja OFDMA.",
          "⁸Samkvæmt samanburði á fræðilegum hámarksgagnahraða í 802.11 tæknilýsingunni getur 2x2 160 MHz Wi-Fi 6/6E (802.11ax) skilað 2402 Mbps miðað við að 2x2 80 MHz Wi-Fi 5 (802.11ac) getur skilað 867 Mbps, sem jafngildir 2,8x meiri hraða.",
          "⁹Wi‑Fi 6 í hæsta gæðaflokki: Intel® Wi‑Fi 6 (Gig+) vörur styðja valfrjálsar 160 MHz rásir sem bjóða upp á mesta fræðilega hámarkshraða (2402 Mbps) fyrir hefðbundnar 2x2 802.11ax Wi‑Fi-vörur fyrir tölvur. Premium Intel® Wi‑Fi 6 (Gig+) vörur bjóða upp á 2-4x meiri fræðilegan hámarkshraða miðað við staðlaðar 2x2 (1201 Mbps) eða 1x1 (600 Mbps) 802.11ax Wi‑Fi-vörur fyrir tölvur, sem styðja aðeins áskildar kröfur 80 MHz rása.",
          "Aðeins fyrir valdar SKU-birgðaeiningar, virkni er mismunandi eftir hönnun frumframleiðanda. Hafðu samband við frumframleiðanda eða söluaðila til að fá upplýsingar. Nánari upplýsingar um Wi-Fi fást á intel.com/wifi6disclaimers.",
          "¹⁰Thunderbolt™ 4 tækni með 40 Gbps hraða veitir hröðustu, einföldustu og áreiðanlegustu snúrulausnina fyrir dokkur, skjái eða gagnatæki miðað við aðra tölvutengingartækni, þar á meðal eSATA*, USB* og IEEE 1394* FireWire.",
          "¹¹Krefst beinis sem byggist á 802.11ax og styður OFDMA og marga biðlara á netkerfi með stuðningi við AX. Betri afköst nást í þéttu umhverfi með OFDMA-eiginleikum sem studdir eru af 802.11ax biðlurum og API. 2 Gbps samkvæmt ályktunum miðað við um 70% af fræðilegum hámarksgagnahraða tæknilýsingarinnar IEEE 802.11* með 802.11ax 160 MHz 2402 Mbps.",
          "¹²Samkvæmt mælingu Document Launch with Background Activity Workload með samanburði á 8. kynslóðar Intel® Core™ i7-8565U örgjörva (512 GB TLC SSD) miðað við 8. kynslóðar Intel® Core™ i7-8565U örgjörva (32 GB + 512 GB Intel® Optane™ minni H10 með SSD-geymslu).",
          "¹³Intel® Thread Director er hluti af hönnun 12. kynslóðar Intel® Core™ örgjörva og hjálpar stýrikerfum að beina vinnuálagi með snjallari hætti í réttan kjarna. Ekki er þörf á beinum aðgerðum notanda. Sjá upplýsingar á intel.com.",
          "¹⁴Ekki í boði fyrir tilteknar gerðir 12. kynslóðar Gen Intel® Core™ örgjörva. Afkastamikil, blönduð uppsetning tvinnar saman tvær nýjar öruppsetningar á kjarna, Performance-core (P-core) og Efficient-core (E-core) í einu og sama örgjörvamótinu. Tilteknar gerðir af 12. kynslóðar Intel® Core™ örgjörvum (tilteknar gerðir af 12. kynslóðar Intel® Core™ i5 örgjörvum og eldri gerðir) styðja ekki afkastamikla, blandaða uppsetningu, heldur aðeins P-core.",
          "¹⁵Eins og mælt er af Game Launch Workload sem ber 8. kynslóðar Intel® Core™ i7-8750H örgjörvann (32GB Intel® Optane™ memory module + Intel® SSD 256GB PCIe* + 1TB HDD) saman við 8. kynslóðar Intel® Core™ i7-8750H örgjörvann (Intel® SSD 256GB PCIe* + 1TB HDD). Game Launch Workload - vinnuálag sem þróað er af Intel og mælir þann tíma sem það tekur að hefja Total War*: WARHAMMER* II Build: 5577,0 og ná aðalvalmyndinni með óvirkum kynningarmyndböndum (Hefja). Intel® Optane™ minnishröðun gagnadrifssamskipunar: Intel® Core™ i7-8750H örgjörvi, PL1=45W TDP, 6C12T, Turbo allt að 4GHz á forframleiddu OEM-kerfi, myndvinnsla: NVIDIA* GeForce* GTX 1070, minni: 2x4GB DDR4, geymslupláss: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD + 32GB Intel® Optane™ minni, stýrikerfi: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H örgjörvi, PL1=45W TDP, 6C12T, Turbo allt að 4GHz á forframleiddu OEM-kerfi, myndvinnsla: NVIDIA* GeForce* GTX 1070, minni: 2x4GB DDR4, geymslupláss: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD, stýrikerfi: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Eins og mælt er af Game Level Load Workload sem ber saman 8. kynslóðar Intel® Core™ i7-8750H örgjörvann (32GB Intel® Optane™ minniseining) saman við 8. kynslóðar Intel® Core™ i7-8750H örgjörvann (aðeins HDD). Game Level Load Workload - vinnuálag sem þróað er af Intel og mælir tímann sem það tekur að fara frá aðalvalmynd og þar til búið er hlaða öllu borðinu (Level Load) í Total War*: WARHAMMER* II Build: 5577,0.V Intel® Optane™ minnishröðun gagnadrifssamskipunar: Intel® Core™ i7-8750H örgjörvi, PL1=45W TDP, 6C12T, Turbo allt að 4GHz á forframleiddu OEM-kerfi, myndvinnsla: NVIDIA* GeForce* GTX 1070, minni: 2x4GB DDR4, geymslupláss: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD + 32GB Intel® Optane™ minni, stýrikerfi: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H örgjörvi, PL1=45W TDP, 6C12T, Turbo allt að 4GHz á forframleiddu OEM-kerfi, myndvinnsla: NVIDIA* GeForce* GTX 1070, minni: 2x4GB DDR4, geymslupláss: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Á afkastakjörnum. Afköst eru breytileg eftir notkun, stillingu og öðrum þáttum. Frekari upplýsingar er að finna á www.intel.com/PerformanceIndex.",
          "¹⁸Breyting á tiftíðni eða rafspennu gæti skemmt eða dregið úr líftíma örgjörvans og annarra kerfishluta og gæti dregið úr stöðugleika kerfis og afköstum þess. Ábyrgð á tækjum gæti fallið úr gildi ef örgjörvinn er látinn vinna umfram hönnunarlýsingu. Leitaðu til framleiðenda kerfis og íhluta ef meiri upplýsinga er óskað.",
          "¹⁹Samkvæmt mælingum óviðjafnanlegrar samsetningar Intel vPro® kerfisins á öryggisgetu stýrikerfisins, forrita- og gagnavörnum, og ítarlegum vörum gegn ógnum fyrir fyrirtæki af hvaða stærð sem er, sem og nálgun Intel á öryggi hvað varðar vöruhönnun, framleiðslu og stuðning. Allar PC-tölvur fyrir fyrirtæki sem byggja á Intel vPro® kerfinu hafa verið staðfestar með tilliti til stífra tæknilýsinga, þar með talið einstakra öryggiseiginleika vélbúnaðar. Sjá nánari upplýsingar á www.intel.com/PerformanceIndex (platforms). Engin vara eða íhlutur getur verið fullkomlega öruggur.",
          "²⁰Intel® Connectivity Performance Suite er eingöngu í boði fyrir kerfi sem byggja á Windows*. Over The Air (OTA) Wi-Fi-prófanir Intel á tepptu netkerfi í samanburði við án forgangs fyrir þráðlausa umferð ICPS hugbúnaðarforrits og snjallskipta milli aðgangsstaða. Sjá nánari upplýsingar á www.intel.com/PerformanceIndex (connectivity) Niðurstöður geta verið mismunandi.",
          "²¹Í samanburði við fyrri kynslóð örgjörva.",
          "²²Intel® Arc™ grafík er leiðandi á markaðnum með AV1-kóðunarstuðningi (frá fyrsta ársfjórðungi 2022). Nánari upplýsingar á intel.com/performanceindex.",
          "²³Samkvæmt mælingum einstakra eiginleika Intel® Evo™ hönnunar, sem einnig er staðfest að uppfylli lykilviðmið um notendaupplifun fartækja. Prófanir frá febrúar 2022.",
          "²⁴Intel® Unison™ er eingöngu í boði eins og er á gjaldgengri Intel® Evo™ hönnun í tölvum sem keyra Windows* og parast aðeins við síma sem keyra Android- eða iOS-stýrikerfi; öll tæki verða að keyra studda stýrikerfisútgáfu.",
          "²⁵Hámarksminnishraði er tengdur við stillingar með 1 DIMM á hverja rás (1DPC). Frekari DIMM-hleðsla á hvaða rás sem er getur haft áhrif á hámarksminnishraða. Allt að DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 og DDR5-5200 1Rx8, 1Rx16, 2Rx8 á völdum SKU. Hægt er að ná fram hámarksminnisafköstum með 2DPC stillingum.",
          "²⁶Miðað við staðlað Wi-Fi. Farðu á intel.com/performance-wireless til að fá frekari upplýsingar. Niðurstöður geta verið mismunandi.",
          "²⁷Afkastamikil, blönduð uppsetning tvinnar saman tvær nýjar öruppsetningar á kjarna; Performance-core (P-core) og Efficient-core (E-core) í einu og sama örgjörvamótinu sem fyrst var kynnt til leiks með 12. kynslóð Intel® Core™ örgjörva. Tilteknar gerðir af 12. kynslóðar og nýrri Intel® Core™ örgjörvum eru ekki með afkastamikla, blandaða uppsetningu heldur aðeins P-core og eru með jafnstórt skyndiminni og fyrri kynslóðir; sjá ark.intel.com fyrir SKU-upplýsingar.",
          "²⁸Intel® Thread Director, sem er innbyggt í vélbúnaðinn, er aðeins til staðar í afkastamikilli blandaðri uppsetningu 12. kynslóðar eða nýrri Intel® Core™ örgjörva; stýrikerfisheimildar er krafist. Eiginleikar og virkni eru mismunandi eftir stýrikerfum.",
          "²⁹Intel® Thread Director, sem er innbyggt í vélbúnaðinn, er aðeins til staðar í afkastamikilli blandaðri uppsetningu 12. og 13. kynslóðar Intel® Core™ örgjörva; stýrikerfisheimildar er krafist. Eiginleikar og virkni eru mismunandi eftir stýrikerfum.",
          "³⁰Samkvæmt mælingum kerfis fyrir viðmiðunarstaðfestingu á afköstum Intel®&nbsp;Core™ Ultra snjalltækjaörgjörva (lína 2 — kóðaheiti: Lunar Lake) í samanburði við Intel®&nbsp;Core™ Ultra snjalltækjaörgjörva (lína 1 — kóðaheiti: Meteor Lake) á 3DMark Time Spy. Sjá nánari upplýsingar á intel.com/performanceindex.  Niðurstöður geta verið mismunandi.",
          "³¹Óskýr bakgrunnur og andlitsrakning eru eingöngu í boði fyrir kerfi sem byggja á Windows*.",
          "³²Wi-Fi 7 vörur geta haft aðgang að rásum á 320 MHz í 6 GHz og nýjum 160 MHz rásasamsetningum með 5 GHz með nýrri sendingargetu fyrir fjölda-tilfangaeiningar.",
          "³³Byggt á þráðlausum tæknistöðlum IEEE og fræðilegum hámarkshraða við gagnaflutning fyrir tveggja strauma tæki.",
          "³⁴Hermun Intel-hönnunar af umhverfi tepptra netkerfa gefur til kynna að umtalsvert styttri biðtími er mögulegur með nýrri getu Wi-Fi 7 til fjöldatenginga.",
          "³⁵Lágmarkshleðslu náð frá sjálfkrafa OEM-stöðvunarstigi. Niðurstöður einstakra kerfa geta verið mismunandi. Farðu á intel.com/performance-evo til að fá frekari upplýsingar.",
          "³⁶6 GHz Wi-Fi-rásin er ekki í boði í öllum löndum/svæðum.",
          "³⁷Windows* eingöngu",
          "³⁸Hleðslu náð frá sjálfkrafa OEM-stöðvunarstigi. Til að fá frekari upplýsingar um afköst og samanburðarniðurstöður skaltu fara á intel.com/Evo.",
          "³⁹Vísar til Intel®&nbsp;Core™ Ultra (lína&nbsp;2) snjalltækjaörgjörva, sem byggja á einstökum vélbúnaðarbyggðum öryggiseiginleikum, óviðjafnanlegri samsetningu öryggisgetu stýrikerfis og tilkomumiklum afköstum, jafnvel við litla orkunotkun samanborið við fyrri kynslóð (frá og með september 2024). Sjá nánari upplýsingar á intel.com/performanceindex.  Niðurstöður geta verið mismunandi.",
          "⁴⁰Sýndur hugbúnaður er eingöngu til útskýringar. Gervigreindareiginleikar geta krafist kaupa á hugbúnaði, áskriftar eða að veitandi hugbúnaðar eða kerfis virki þá, eða gert tilteknar kröfur um stillingar eða samhæfni. Upplýsingar á www.intel.com/PerformanceIndex.  Niðurstöður geta verið mismunandi. © Intel Corporation.",
          "⁴¹Myndum sem sýndar eru kann að hafa verið breytt eða þær hermaðar. Gervigreindareiginleikar geta krafist kaupa á hugbúnaði, áskriftar eða að veitandi hugbúnaðar eða kerfis virki þá, eða gert tilteknar kröfur um stillingar eða samhæfni. Upplýsingar á www.intel.com/AIPC.",
          "⁴²Sett á markað jafnt og þétt í forskoðun með nýjustu uppfærslum á Windows* 11 á völdum alþjóðlegum mörkuðum. Mismunandi er eftir tæki og markaði hvenær viðkomandi tæki verður fáanlegt. Nánari upplýsingar má finna hér: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Intel® Unison™ lausnin er fáanleg eins og er á gjaldgengri hönnun. Sjá nánari upplýsingar á www.intel.com/PerformanceIndex.",
          "⁴⁴FPS uppkvarðað með Intel® X<sup>e</sup>SS sbr. Native FPS samkvæmt mælingum með Dying Light 2 Stay Human sem keyrir á Intel® Core™ Ultra 7 örgjörva 165H. Sjá nánari upplýsingar á www.intel.com/PerformanceIndex.  Niðurstöður geta verið mismunandi.",
          "⁴⁵Samkvæmt mælingu SoC lítillar orkunotkunar Intel®&nbsp;Core™ Ultra snjalltækjaörgjörva (lína 2 — kóðaheiti: Lunar Lake) viðmiðunarstaðfestingarkerfis sbr. Intel®&nbsp;Core™ Ultra snjalltækjaörgjörva (lína 1 — kóðaheiti: Meteor Lake) viðmiðunarstaðfestingarkerfis á YouTube* 4K 30FPS AV1. Sjá nánari upplýsingar á intel.com/performanceindex.  Niðurstöður geta verið mismunandi.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel er ekki bótaskylt vegna rangs verðs.",
          "*Aðrir geta gert tilkall til annarra nafna og vörumerkja sem þeirra eign.",
          "HÖFUNDARRÉTTUR 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES OG TENGD HÖNNUN OG SAMSETT MERKI ERU VÖRUMERKI INFLEXION STUDIOS INC. ALLUR RÉTTUR ÁSKILINN.",
          "Intel tækni kann að krefjast virks vélbúnaðar, hugbúnaðar eða virkjunar á þjónustu.",
          "Allar niðurstöður og gögn NERO* eru eingöngu veitt til hæginda og upplýsingar. Intel Corporation ber enga ábyrgð, sem dæmi, á nákvæmi NERO* appsins, útkomu eða einkunnum fyrir afköst eða möguleg frávik frá öðrum niðurhlaðanlegum útgáfum appsins sem eru í boði á www.nero.com eða öðrum vefsvæðum þriðju aðila. Tölvuafkastapróf NERO* eru mæld með sérstökum tölvukerfum, íhlutum, hugbúnaði, skipunum og aðgerðum. Breyting á einhverjum þessara þátta gæti breytt útkomunni. Þú ættir að kanna aðrar upplýsingar og afkastapróf til að hjálpa þér við að meta fyrirhuguð kaup, þar á meðal frammistöðu vörunnar þegar hún er notuð með öðrum vörum.",
          "Allar útgáfur Intel vPro® kerfisins krefjast Intel® Core™ örgjörva sem uppfyllir kröfur, studds stýrikerfis, Intel® LAN og/eða WLAN silíkons, bóta á fastbúnaði, og annars vélbúnaðar og hugbúnaðar sem þarf til að skila þeim meðfærilegu notkunaraðstæðum, öryggiseiginleikum, afköstum kerfis og stöðugleika sem einkenna kerfið.",
          "Afköst eru breytileg eftir notkun, stillingu og öðrum þáttum. Frekari upplýsingar er að finna á www.intel.com/PerformanceIndex.",
          "Intel hvorki stjórnar né fer yfir gögn þriðju aðila. Athugaðu einnig aðrar upplýsingar til að meta nákvæmni.",
          "© Intel Corporation. Intel, Intel-lógóið og önnur merki Intel eru vörumerki Intel Corporation eða dótturfélaga þess. *Aðrir geta gert tilkall til annarra nafna og vörumerkja sem þeirra eign.",
          "Engin vara eða íhlutur getur verið fullkomlega öruggur.",
          "Kostnaður þinn og niðurstöður geta verið mismunandi.",
          "Intel, Intel-lógóið, Intel Core, Intel Optane, Intel Iris, Thunderbolt og Thunderbolt-lógóið eru skrásett vörumerki Intel Corporation eða dótturfélaga þess. Aðrir geta gert tilkall til annarra heita og vörumerkja sem þeirra eign.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Heima",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Skannaðu til að bera saman",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Vinsælt",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Opnaðu fyrir nýja gervigreindarupplifun með Intel® Core™ Ultra örgjörvum",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Opnaðu fyrir nýja gervigreindarupplifun með Intel® Core™ Ultra örgjörvum",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Opnaðu fyrir nýja gervigreindarupplifun með Intel® Core™ Ultra örgjörvum",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Texti við mynd",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Myndvinnsla",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Myndskerping",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Djúpfölsunargreinir",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Samvinnuáhrif (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Bendingastjórnun",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Samstilltu tölvuna þína við símann og skoðaðu tilkynningar, símtöl og textaskilaboð",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Rík og lífleg innbyggð grafík",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Stjórnaðu lífi þínu á þinn hátt",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Þú stjórnar öllu. Allt innan seilingar með einni stroku",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Keyrðu örgjörvann þinn með meiri hraða og með meiri tíðni með því að fínstilla kerfið þitt",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Auktu afköst borðtölvu eða fartölvu þinnar án þess að fórna geymsluplássi",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Auktu afköst borðtölvu eða fartölvu þinnar án þess að fórna geymsluplássi",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: Falleg sýn",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Meiri hraði, minni biðtími og aukinn áreiðanleiki með gervigreindarmiðaðri hámarksnettengingu",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Nettenging tölvu eins og í gegnum snúru",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Nettenging tölvu eins og í gegnum snúru",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Nærri því 3x meiri hraði með Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Tenging án samkeppni",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Algjörlega samræmd snúrutengigeta",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Spilaðu eins og atvinnumennirnir: Sjáðu hvernig Intel knýr leikjaspilun og tölvuleikjaspilara",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Spilaðu eins og atvinnumennirnir: Meistaratilþrif með Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "VELKOMIN(N)",
    "tr_welcomePagetrSubtitle": "Stjórnaðu þessari tölvu með snjallsímanum",
    "tr_welcomePagembSubtitle": "Velkomin(n), kannaðu tæknilýsingu þessarar tölvu í snjallsímanum þínum",
    "tr_welcomePageBtnText": "Hefjast handa",
    "tr_welcomePageText": "Skoðaðu og gerðu samanburð á upplýsingum um þessa tölvu í snjallsíma",
    "tr_scrollText": "Flettu um í tækinu fyrir framan þig",
    "tr_toolTipTextc1": "Notaðu þessa hnappa til að fletta á aðrar síður í tækinu fyrir framan þig.",
    "tr_toolTipTextc2": "Ef þú ýtir á spjöldin hér að neðan opnast síða með nákvæmri tæknilýsingu um hvert tæki.",
    "tr_toolTipTextc3": "Notaðu þessa hnappa til að fara upp og niður á skjánum fyrir framan þig.",
    "tr_toolTipTextc4": "Slökktu á fjarstillingu til að skoða tæknilýsingu tækisins í snjallsímanum þínum.",
    "tr_toolTipTextmb1": "Ýttu á spjöldin til að sjá síður með ítarlegum upplýsingum.",
    "tr_toolTipTextmb2": "Ýttu til að sjá samantekt á öllum tæknilýsingum tækja",
    "tr_toolTipTextmb3": "Ýttu á hnappinn til að skanna QR-kóða og bera saman allt að fjögur tæki",
    "tr_nextButton": "Áfram",
    "tr_prevButton": "Til baka",
    "tr_gotItButton": "ÉG SKIL",
    "tr_skipButton": "Sleppa",
    "tr_placeholderText": "*staðgengill",
    "tr_processorHeading": "Örgjörvi",
    "tr_processorSubheading": "10. kynslóðar Intel® Core™ i7 örgjörvi",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ minni + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX örgjörvi",
    "tr_coachmarkGraphics": "[XX] myndvinnsla",
    "tr_coachmarkMemory": "Tæknilýsingargildi",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 USD",
    "tr_compare": "Bera saman",
    "tr_viewallspecs": "SKOÐA ÍTARLEGA TÆKNILÝSINGU",
    "tr_operatingSystem": "Stýrikerfi",
    "tr_AmdProcessorNonIntel": "AMD* örgjörvi",
    "tr_QualcommProcessorNonIntel": "Qualcomm* örgjörvi",
    "tr_GBText": "GB",
    "tr_remoteText": "Tæknilýsing er birt á skjá fartölvunnar",
    "tr_scanErrorText": "Ekki er hægt að skanna fleiri en þrjú tæki í einu. Eyddu einu eða fleiri tækjum.",
    "tr_scanErrorText2": "Þú hefur þegar skannað þetta tæki – skannaðu annað.",
    "tr_scanMsg": "Skannaðu til að bæta við tæki",
    "tr_backToScan": "Til baka í samanburð",
    "tr_scanToCompare": "Skannaðu til að bera saman",
    "tr_compareDevice": "Bera saman tæki",
    "tr_processorTitle": "ÖRGJÖRVI",
    "tr_graphicTitle": "GRAFÍK",
    "tr_storageTitle": "GEYMSLA",
    "tr_displayTitle": "SKJÁR",
    "tr_batteryTitle": "RAFHLAÐA",
    "tr_memoryTitle": "MINNI",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "TENGIGETA",
    "tr_priceTitle": "VERÐ",
    "tr_operatingSystemTitle": "STÝRIKERFI",
    "tr_batteryHoursText": "[XX] klukkustundir",
    "tr_hrsText": "klst.",
    "tr_touchscreeenText": "[screenDiagonal]” snertiskjár",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, snertiskjár",
    "tr_FHDTouchScreenText": "FHD, snertiskjár",
    "tr_4KTouchscreenText": "4K, snertiskjár",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Vista",
    "tr_device_comparison": "Samanburðarskjal tækja [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "Vista sem JPEG",
    "tr_Save_As_PDF": "Vista sem PDF",
    "tr_Downloading_PDF": "Sækir PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Opna",
    "tr_logo": "lógó",
    "tr_laptop_name": "Heiti fartölvu",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ minni",
    "tr_inchUnit": "tommur",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MINNI",
    "tr_Thunderbolt3": "Thunderbolt™ 3 tækni",
    "tr_Thunderbolt4": "Thunderbolt™ 4 tækni",
    "tr_processorGraphicsCaps": "ÖRGJÖRVAMYNDVINNSLA",
    "tr_processorGraphicsSmall": "Örgjörvamyndvinnsla",
    "tr_graphicsCardCaps": "AÐSKILIN MYNDVINNSLA",
    "tr_graphicsCardSmall": "Aðskilin myndvinnsla",
    "tr_processorTitleCamel": "Örgjörvi",
    "tr_graphicTitleCamel": "Grafík",
    "tr_storageTitleCamel": "Geymsla",
    "tr_displayTitleCamel": "Skjár",
    "tr_batteryTitleCamel": "Rafhlaða",
    "tr_memoryTitleCamel": "Minni",
    "tr_connectivityTitleCamel": "Tengigeta",
    "tr_priceTitleCamel": "Verð",
    "tr_operatingSystemTitleCamel": "Stýrikerfi",
    "tr_viewallspecsCamel": "Skoða ítarlega tæknilýsingu",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] snertiskjár",
    "tr_OS": "Stýrikerfi",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Tæknilýsing tölvu",
    "tr_explorePC_text": "Kynntu þér allt um þessa tölvu",
    "tr_mtlPC_text": "Sjáðu hvað hönnun til að gera allt þýðir í raun og veru",
    "tr_optaneMemory": "Intel® Optane™ minni",
    "tr_displayText_Display": "[screenDiagonal]” SKJÁR",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] skjár",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType] SNERTISKJÁR",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” SNERTISKJÁR",
    "tr_learnMoreLabel": "Frekari upplýsingar",
    "tr_batteryHoursTextUpperCase": "[XX] KLUKKUSTUNDIR",
    "tr_processorGraphicsIrisXe": "11. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MYNDVINNSLA",
    "tr_processorGraphicsIrisXeMax": "11. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX-MYNDVINNSLA",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TÆKNI",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TÆKNI",
    "tr_processorWithArcGraphics": "12. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® ARC™ MYNDVINNSLA",
    "tr_processorGraphicsIrisXe12Gen": "12. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MYNDVINNSLA",
    "tr_processorGraphicsIrisXeMax12Gen": "12. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX-MYNDVINNSLA",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® ARC™ MYNDVINNSLA",
    "tr_processorGraphicsIrisXe13Gen": "13. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MYNDVINNSLA",
    "tr_processorGraphicsIrisXeMax13Gen": "13. KYNSLÓÐAR INTEL® CORE™ [cpu_fam] ÖRGJÖRVI OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX-MYNDVINNSLA",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M MYNDVINNSLA",
      "tr_A370M": "INTEL® ARC™ A370M MYNDVINNSLA",
      "tr_A730M": "INTEL® ARC™ A730M MYNDVINNSLA",
      "tr_A770M": "INTEL® ARC™ A770M MYNDVINNSLA",
      "tr_A310": "INTEL® ARC™ A310 MYNDVINNSLA",
      "tr_A380": "INTEL® ARC™ A380 MYNDVINNSLA",
      "tr_A580": "INTEL® ARC™ A580 MYNDVINNSLA",
      "tr_A750": "INTEL® ARC™ A750 MYNDVINNSLA",
      "tr_A770": "INTEL® ARC™ A770 MYNDVINNSLA"
    },
    "tr_memoryValue": "[XX] GB vinnsluminni",
    "tr_memoryWithOptaneDesc": "[Value1] GB minni = [Value2] GB Intel® Optane™ minni + [Value3] GB vinnsluminni",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ minni + [convertedSSD] GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ minni + [convertedSSD] TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home í S-stillingu",
      "tr_OS21": "Windows* 10 Pro í S-stillingu",
      "tr_OS22": "Windows* 10 Enterprise í S-stillingu",
      "tr_OS23": "Windows* 11 Home í S-stillingu",
      "tr_OS24": "Windows* 11 Pro í S-stillingu",
      "tr_OS25": "Windows* 11 Enterprise í S-stillingu"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 Graphics",
      "tr_GR387": "Intel® Arc™ A350M Graphics",
      "tr_GR388": "Intel® Arc™ A370M Graphics",
      "tr_GR389": "Intel® Arc™ A380 Graphics",
      "tr_GR390": "Intel® Arc™ A530M Graphics",
      "tr_GR391": "Intel® Arc™ A550M Graphics",
      "tr_GR392": "Intel® Arc™ A570M Graphics",
      "tr_GR393": "Intel® Arc™ A580 Graphics",
      "tr_GR394": "Intel® Arc™ A730M Graphics",
      "tr_GR395": "Intel® Arc™ A750 Graphics",
      "tr_GR396": "Intel® Arc™ A770 Graphics",
      "tr_GR397": "Intel® Arc™ A770M Graphics",
      "tr_GR398": "Intel® Arc™ Graphics",
      "tr_GR399": "Intel® Arc™ Pro A30M Graphics",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 Graphics",
      "tr_GR401": "Intel® Arc™ Pro A60 Graphics",
      "tr_GR402": "Intel® Arc™ Pro A60M Graphics",
      "tr_GR403": "Intel® Graphics",
      "tr_GR404": "Intel® HD Graphics 2000",
      "tr_GR405": "Intel® HD Graphics 2500",
      "tr_GR406": "Intel® HD Graphics 3000",
      "tr_GR407": "Intel® HD Graphics 400",
      "tr_GR408": "Intel® HD Graphics 4000",
      "tr_GR409": "Intel® HD Graphics 405",
      "tr_GR410": "Intel® HD Graphics 4200",
      "tr_GR411": "Intel® HD Graphics 4400",
      "tr_GR412": "Intel® HD Graphics 4600",
      "tr_GR413": "Intel® HD Graphics 500",
      "tr_GR414": "Intel® HD Graphics 5000",
      "tr_GR415": "Intel® HD Graphics 505",
      "tr_GR416": "Intel® HD Graphics 510",
      "tr_GR417": "Intel® HD Graphics 515",
      "tr_GR418": "Intel® HD Graphics 520",
      "tr_GR419": "Intel® HD Graphics 530",
      "tr_GR420": "Intel® HD Graphics 5300",
      "tr_GR421": "Intel® HD Graphics 5500",
      "tr_GR422": "Intel® HD Graphics 5600",
      "tr_GR423": "Intel® HD Graphics 6000",
      "tr_GR424": "Intel® HD Graphics 610",
      "tr_GR425": "Intel® HD Graphics 615",
      "tr_GR426": "Intel® HD Graphics 620",
      "tr_GR427": "Intel® HD Graphics 630",
      "tr_GR428": "Intel® HD Graphics",
      "tr_GR429": "Intel® Iris® Graphics 5100",
      "tr_GR430": "Intel® Iris® Graphics 540",
      "tr_GR431": "Intel® Iris® Graphics 550",
      "tr_GR432": "Intel® Iris® Graphics 6100",
      "tr_GR433": "Intel® Iris® Plus myndvinnsla 640",
      "tr_GR434": "Intel® Iris® Plus myndvinnsla 645",
      "tr_GR435": "Intel® Iris® Plus myndvinnsla 650",
      "tr_GR436": "Intel® Iris® Plus myndvinnsla 655",
      "tr_GR437": "Intel® Iris® Plus myndvinnsla",
      "tr_GR438": "Intel® Iris® Pro Graphics 5200",
      "tr_GR439": "Intel® Iris® Pro Graphics 580",
      "tr_GR440": "Intel® Iris® Pro Graphics 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> Graphics ",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> Max Graphics A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> Max Graphics",
      "tr_GR444": "Intel® Integrated Graphics",
      "tr_GR445": "Intel® UHD Graphics 600",
      "tr_GR446": "Intel® UHD Graphics 605",
      "tr_GR447": "Intel® UHD Graphics 610",
      "tr_GR448": "Intel® UHD Graphics 615",
      "tr_GR449": "Intel® UHD Graphics 617",
      "tr_GR450": "Intel® UHD Graphics 620",
      "tr_GR451": "Intel® UHD Graphics 630",
      "tr_GR452": "Intel® UHD Graphics 710",
      "tr_GR453": "Intel® UHD Graphics 730",
      "tr_GR454": "Intel® UHD Graphics 770",
      "tr_GR455": "Intel® UHD Graphics",
      "tr_GR456": "Intel® UHD Graphics",
      "tr_GR457": "Intel® UHD Graphics",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 með Max-Q hönnun",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti með Max-Q hönnun",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 með Max-Q hönnun",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 með Max-Q hönnun",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 með Max-Q hönnun",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 með Max-Q hönnun",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti með Max-Q hönnun",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti með Max-Q hönnun",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN svart",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 með Max-Q hönnun",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 með Max-Q hönnun",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super með Max-Q hönnun",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 með Max-Q hönnun",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super með Max-Q hönnun",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 með Max-Q hönnun",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 með Max-Q hönnun",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 með Max-Q hönnun",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 með Max-Q hönnun",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 með Max-Q hönnun",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 með Max-Q hönnun",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 með Max-Q hönnun",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 með Max-Q hönnun",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 með Max-Q hönnun",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 með Max-Q hönnun",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada kynslóð",
      "tr_GR773": "NVIDIA* RTX 3000 Ada kynslóð",
      "tr_GR774": "NVIDIA* RTX 3500 Ada kynslóð",
      "tr_GR775": "NVIDIA* RTX 4000 Ada kynslóð",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada kynslóð",
      "tr_GR777": "NVIDIA* RTX 4500 Ada kynslóð",
      "tr_GR778": "NVIDIA* RTX 5000 Ada kynslóð",
      "tr_GR779": "NVIDIA* RTX 5880 Ada kynslóð",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada kynslóð",
      "tr_GR811": "NVIDIA* RTX 1000 Ada kynslóð",
      "tr_GR812": "Intel® Arc™ Graphics",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada kynslóð",
      "tr_GR815": "AMD Radeon* Pro W7900 tvöföld rauf"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Kaupa núna",
    "tr_textTryagain": "Reyndu aftur",
    "tr_textBuynowErr": "Vegna truflunar á tengingu er valkosturinn „Kaupa núna“ ekki tiltækur",
    "tr_chatbotPlaceholder_text": "Spurðu mig að hverju sem er varðandi tækið",
    "tr_deviceSpecs_text": "Tæknilýsingar tækis"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 örgjörvi með Intel® Hybrid-tækni"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 örgjörvi með Intel® Hybrid-tækni"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Græjaðu þig með Intel® fyrir máttugri tölvuleiki",
                "tr_subtitle": "Gæddu Marvel’s Avengers* lífi með fínstillingu leikja knúinni af Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Upplifðu Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Þar sem heimsins bestu leikjaspilarar keppa",
                "tr_subtitle": "Intel® Extreme Masters er lengsta viðburðaröð í sögu tölvuleikjageirans og setur viðmið fyrir rafíþróttir.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Spilaðu eins og atvinnumennirnir",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Sigurvegarar nota Intel.",
                "tr_subtitle": "Soniqs fara yfir leikjaferilinn sinn, framtíð rafíþrótta og samstarf sitt við Intel eftir sigur sinn í deildarkeppni Norður-Ameríku í Rainbow Six Siege* árið 2021.",
                "tr_pretitle": "Við kynnum Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Tilbúin fyrir sýndarveruleika",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Þegar sýndarveruleiki verður að veruleika",
                "tr_subtitle": "Ótrúlegir, raunverulegir og umlykjandi sýndarheimar geta keyrt á tölvu. Með nýjustu Intel® Core™ örgjörvunum getur þú verið hver sem er, farið hvert sem er og upplifað tölvuleiki í nýrri vídd.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Spilaðu fleiri en 100 vandaða leikjatölvuleiki á borð við Sea of Thieves* og Psychonauts* 2 í Windows* tölvunni þinni, farsíma og spjaldtölvu með Xbox* Game Pass Ultimate og samhæfri fjarstýringu.",
                "titleArray": [
                  {
                    "tr_title": "Alli geta spilað saman í mismunandi tækjum",
                    "tr_subtitle": "Hjarta Xbox* er samfélag milljóna spilara sem eru spenntir fyrir því að byrja að spila saman. Vertu í sambandi og spilaðu við aðra spilara með sameiginlegu leikjasafni, hvort sem þeir eru hinum megin á hnettinum eða sitja við hliðina á þér."
                  },
                  {
                    "tr_title": "Spilun í Windows* PC-tölvum",
                    "tr_subtitle": "Spilaðu safn leikjatölvuleikja úr skýinu með Xbox* appinu fyrir Windows* og samhæfri fjarstýringu."
                  },
                  {
                    "tr_title": "Spilun án tafar",
                    "tr_subtitle": "Njóttu frábærra Xbox* leikja á fleiri stöðum en áður. Spilaðu fleiri en 100 leiki í tölvunni, fartölvunni og spjaldtölvunni hvort sem þú ert heima á meðan verið er að nota sjónvarpið, á kaffihúsi eða hvar sem er þar sem nettengingin er hröð."
                  },
                  {
                    "tr_title": "Skemmtun án fjarstýringar",
                    "tr_subtitle": "Snertistýringar Xbox* bjóða upp á nýjar leiðir til að byrja strax að spila með kunnuglegri upplifun og spilun sem líkist því sem þekkist með fjarstýringu."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kastljós",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Yfir 100 gervigreindarupplifanir",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Stjórna meira, snerta minna",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Vertu í ramma í hvaða umhverfi sem er",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sköpun",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Stilltu lýsingu eins og fagmaður",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Bættu auðveldlega við frábærum brellum",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Endurhljóðblandaðu lög með hjálp gervigreindar",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Framleiðni",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bættu framleiðni og friðhelgi",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Lengri tími án innstungu",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Afþreying",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Stöðugt streymi, jafnvel án snúru",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Hröð, mögnuð leikjaspilun",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kastljós",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Yfir 100 gervigreindarupplifanir",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Gerðu uppáhaldslögin sannarlega að þínum",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Sjáðu hugsýn þína raungerast",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sköpun",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Stysta leiðin að lokaútgáfunni",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Gerðu allar myndir að veggjaprýði",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Framleiðni",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bættu framleiðni og friðhelgi",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Meiri afköst. Meira frelsi.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Afþreying",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Snurðulaust háskerpustreymi, jafnvel án þess að vera í sambandi við innstungu",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Mögnuð leikjaspilun á fleiri stöðum",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Meira skapandi",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Myndvinnsla gerð auðveld",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Búið til myndir sem verðskulda innrömmun á nokkrum sekúndum",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Þú skrifar það. Gervigreindin býr það til.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meiri afköst",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Svindlkóðinn fyrir hnökralausa leikjaspilun",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Lengri tími án innstungu",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ótrúlega áhrifaríkt",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meiri árangur",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Þinn daglegi gervigreindarfélagi",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Fullkomið myndsímtal",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meira öryggi",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Innbyggðar varnir til að tryggja öryggi og friðhelgi gagnanna þinna",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Meira skapandi",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Myndvinnsla gerð auðveld",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Gervigreind. Flýtileið að lokaafurðinni.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Umskrifaðu uppáhalds söngtextana þína á engum tíma",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Sparið klukkustundir af vinnu með gervigreind",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meiri afköst",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Svindlkóðinn fyrir hnökralausa leikjaspilun",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Lengri tími án innstungu",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ótrúlega áhrifaríkt",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meiri árangur",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Þinn daglegi gervigreindarfélagi",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Fullkomið myndsímtal",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meira öryggi",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Innbyggðar varnir til að tryggja öryggi og friðhelgi gagnanna þinna",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Öll afþreyingin. Ein tölva.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Fyrir tölvu sem einnig er afþreyingarmiðstöð þarf geymslupláss sem nægir fyrir öll lögin, myndböndin og tölvuleikina og rétta samsetningu örgjörva og skjás til að birta áhrifaríkar myndir. Þessi tölva er búin öllu þrennu.",
                "titleArray": [
                  {
                    "tr_title": "Tilbúin fyrir næstu kynslóð myndefnis",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Spilun og streymi án hnökra",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Afkastaðu meiru. Hvar sem er.",
              "tr_tileTitle1": "Komdu meiru í verk. Hratt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og réttu rafhlöðunni til að geta verið á ferðinni.",
                "tr_subtitle1": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika til að hjálpa þér að koma meiru í verk á minni tíma. Samsetning öflugs örgjörva sem fær aukinn hraða með miklu minni og leifturhröðu Wi-Fi gerir verkefni dagsins að leik einum.",
                "tr_subtitle2": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og rétta minninu fyrir fjölvinnslu.",
                "titleArray": [
                  {
                    "tr_title": "Hönnuð til að gera meira",
                    "tr_title1": "Snjöll afköst sem skipta máli",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bestu tengingar sem völ er á",
                    "tr_title1": "Tengstu því sem skiptir máli",
                    "tr_title2": "Stórt stökk í Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Ekkert mál að skipta á milli forrita",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Frelsið til að skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Efnissköpun er eitt af kröfuhörðustu verkefnum tölvunnar. Sem betur fer búa þessir íhlutir yfir kraftinum til að einfalda sköpunarferlið.",
                "titleArray": [
                  {
                    "tr_title": "Snjallari leið til að skapa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gæddu myndirnar lífi",
                    "tr_title1": "Byrjum að skapa",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minni fyrir fjölvinnslu",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Innan í þessari tölvu",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Þetta eru þrír helstu íhlutirnir sem þarf að skoða við val á nýrri tölvu. Með því að ákveða hvernig þú ætlar að nota nýju tölvuna færðu góða mynd af því að velja hversu mikið þú þarft af þessum mikilvægu íhlutum. Þetta eru:",
                "titleArray": [
                  {
                    "tr_title": "Afköst fyrir tölvuleiki og streymi",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Geymsla",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minni",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Öll afþreyingin. Ein tölva.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Fyrir tölvu sem einnig er afþreyingarmiðstöð þarf geymslupláss sem nægir fyrir öll lögin, myndböndin og tölvuleikina og rétta samsetningu örgjörva og skjás til að birta áhrifaríkar myndir. Þessi tölva er búin öllu þrennu.",
                "titleArray": [
                  {
                    "tr_title": "Tilbúin fyrir næstu kynslóð myndefnis",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Spilun og streymi án hnökra",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Minni",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Skjár",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Fjölvinnsla á mikum hraða",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FJÖLVINNSLA Á MIKUM HRAÐA",
                "tr_subtitle": "Gerðu meira án þess að hægja á þér",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir hraða fjölvinnslu:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur keyrt nokkur forrit í einu."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Minni sem skiptir hnökralaust á milli forrita."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndspjall",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDSPJALL",
                "tr_subtitle": "Kristaltærar tengingar",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndspjall:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur skráð og deilt myndum samtímis."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Vefmyndavél sem sýnir þig í kristaltæru ljósi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tilbúin fyrir sýndarveruleika",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Þegar sýndarveruleiki verður að veruleika",
                "tr_subtitle": "Hvað þarf til þess að skapa sýndarheim? Í tölvunni þarf samræmingu milli örgjörva, skjákorts og minnis.",
                "titleArray": [
                  {
                    "tr_title": "Knýr sýndarheima",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Raunveruleikinn er í smáatriðunum",
                    "tr_title1": "Gleymdu þér í innlifun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Myndbandagerð",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDBANDAGERð",
                "tr_subtitle": "Skapaðu kvikmyndatöfra",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndbandagerð:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við stórar skrár."
                  },
                  {
                    "tr_title": "Minni sem gerir klippihugbúnað hraðan og viðbragðsfljótan."
                  },
                  {
                    "tr_title": "Grafík sem styttir birtingartíma."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fjölvinnsla á mikum hraða",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FJÖLVINNSLA Á MIKUM HRAÐA",
                "tr_subtitle": "Gerðu meira án þess að hægja á þér",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir hraða fjölvinnslu:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur keyrt nokkur forrit í einu."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Minni sem skiptir hnökralaust á milli forrita."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tilbúin fyrir sýndarveruleika",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Þegar sýndarveruleiki verður að veruleika",
                "tr_subtitle": "Hvað þarf til þess að skapa sýndarheim? Í tölvunni þarf samræmingu milli örgjörva, skjákorts og minnis.",
                "titleArray": [
                  {
                    "tr_title": "Knýr sýndarheima",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Raunveruleikinn er í smáatriðunum",
                    "tr_title1": "Gleymdu þér í innlifun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Þrívíddarlíkanagerð",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÞRÍVÍDDARLÍKANAGERÐ",
                "tr_subtitle": "Sköpun á næsta stigi",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir þrívíddarlíkanagerð:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við flókinn sköpunarhugbúnað."
                  },
                  {
                    "tr_title": "Minni sem er fljótt að sækja stórar skrár."
                  },
                  {
                    "tr_title": "Grafík fyrir öfluga sköpun og breytingar á myndum."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samstarf í rauntíma",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMSTARF Í RAUNTÍMA",
                "tr_subtitle": "Sinntu þinni vinnu hvar sem er",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir samstarf á netinu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi til að knýja skrifstofuhugbúnað."
                  },
                  {
                    "tr_title": "Wi‑Fi sem þú getur treyst á."
                  },
                  {
                    "tr_title": "Vefmyndavél sem tekur upp í frábærri háskerpu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tilbúin fyrir sýndarveruleika",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Þegar sýndarveruleiki verður að veruleika",
                "tr_subtitle": "Hvað þarf til þess að skapa sýndarheim? Í tölvunni þarf samræmingu milli örgjörva, skjákorts og minnis.",
                "titleArray": [
                  {
                    "tr_title": "Knýr sýndarheima",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Raunveruleikinn er í smáatriðunum",
                    "tr_title1": "Gleymdu þér í innlifun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Fartölvur sem eru svona þunnar og léttar eiga ekki að vera svona öflugar",
                "tr_subtitle": "Intel® Evo™ fartölvur eru hannaðar fyrir afköst og meðfærileika og búa yfir hraðanum, myndvinnslunni og rafhlöðuendingu sem þú þarft í ótrúlega léttri og þunnri umgjörð.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Komdu hlutunum í verk með hraði",
                    "tr_subtitle": "Njóttu einstaklegra skjótra viðbragða með 11. kynslóð Intel® Core™ örgjörva."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt sem þú þarft og meira til í þunnri og fisléttri fartölvu.",
                "tr_subtitle": "Intel® Evo™ fartölvur tryggja þér mikla afkastagetu þegar þú ert á ferðinni og hönnunin miðast við að veita þér sem besta upplifun<sup>2</sup> í öllu sem þú tekur þér fyrir hendur.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Framúrskarandi afköst",
                    "tr_subtitle": "Snjöll afköst og 12. kynslóðar Intel® Core™ örgjörvar gefa þér aukinn kraft þegar mest á reynir."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Fartölvur sem eru svona þunnar og léttar eiga ekki að vera svona öflugar",
                "tr_subtitle": "Intel® Evo™ fartölvur eru hannaðar fyrir afköst og meðfærileika og búa yfir hraðanum, myndvinnslunni og rafhlöðuendingu sem þú þarft í ótrúlega léttri og þunnri umgjörð.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Komdu hlutunum í verk með hraði",
                    "tr_subtitle": "Njóttu einstaklegra skjótra viðbragða með 11. kynslóð Intel® Core™ örgjörva."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt sem þú þarft og meira til í þunnri og fisléttri fartölvu.",
                "tr_subtitle": "Intel® Evo™ fartölvur tryggja þér mikla afkastagetu þegar þú ert á ferðinni og hönnunin miðast við að veita þér sem besta upplifun<sup>2</sup> í öllu sem þú tekur þér fyrir hendur.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Framúrskarandi afköst",
                    "tr_subtitle": "Snjöll afköst og 12. kynslóðar Intel® Core™ örgjörvar gefa þér aukinn kraft þegar mest á reynir."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt sem þú þarft og meira til í þunnri og fisléttri fartölvu.",
                "tr_subtitle": "Intel® Evo™ fartölvur tryggja þér mikla afkastagetu þegar þú ert á ferðinni og hönnunin miðast við að veita þér sem besta upplifun<sup>2</sup> í öllu sem þú tekur þér fyrir hendur.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Framúrskarandi afköst",
                    "tr_subtitle": "Snjöll afköst og 13. kynslóðar Intel® Core™ örgjörvar gefa þér aukinn kraft þegar mest á reynir."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ fartölvur",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt sem þú þarft og meira til í þunnri og fisléttri fartölvu.",
                "tr_subtitle": "Intel® Evo™ fartölvur tryggja þér mikla afkastagetu þegar þú ert á ferðinni og hönnunin miðast við að veita þér sem besta upplifun<sup>2</sup> í öllu sem þú tekur þér fyrir hendur.",
                "titleArray": [
                  {
                    "tr_title": "Fartölvan er mikilvægari en nokkru sinni fyrr og því höfum við tryggt að hún geti gert enn meira en áður.",
                    "tr_subtitle": "Notaðu snertiskjáinn til að fletta, snúa og stilla aðdrátt."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Úr hvíldarstöðu með hraði",
                    "tr_subtitle": "Tölvan fer úr hvíldarstöðu á innan við sekúndu."
                  },
                  {
                    "tr_title": "Grandskoðun",
                    "tr_subtitle": "Fallegur skjár gæðir myndefnið lífi í skýrri upplausn og líflegum litum."
                  },
                  {
                    "tr_title": "Framúrskarandi afköst",
                    "tr_subtitle": "Snjöll afköst og 13. kynslóðar Intel® Core™ örgjörvar gefa þér aukinn kraft þegar mest á reynir."
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "tr_subtitle": "Vertu í sambandi með tölvu sem lengir rafhlöðuendinguna og hleður rafhlöðuna einstaklega hratt."
                  },
                  {
                    "tr_title": "Samræmd snúrutengigeta",
                    "tr_subtitle": "Einfalt er að tengja önnur tæki við tölvuna með hraðri og öruggri Thunderbolt™ 4-tækni.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hraðar og áreiðanlegar tengingar",
                    "tr_subtitle": "Taktu þátt í myndspjalli eða deildu skrám með tengingu sem þú getur reitt þig á með Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kastljós",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hvað er Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvers vegna Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sköpun",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vertu meira skapandi á fleiri stöðum",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Myndvinnsla á fullum hraða",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Búðu til list á staðnum",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Framleiðni",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hámarkaðu afköstin með gervigreind til upprifjunar",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Greindu djúpfalsanir með gervigreind áður en þú deilir",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hreyfanleiki",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Veifaðu til bendingarstjórnunar",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Rafhlöðuending sem heldur ekki aftur af þér",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Heldur þér sjálfkrafa í sambandi",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kastljós",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hvað er Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvers vegna Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sköpun",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fljót og hnökralaus myndbandsspilun",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Framleiddu smelli í krafti gervigreindar",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Fljótasta leiðin að lokaútgáfunni",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Framleiðni",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hámarkaðu afköstin með gervigreind til upprifjunar",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Greindu djúpfalsanir með gervigreind áður en þú deilir",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Þinn heimur. Í tækjunum þínum.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hreyfanleiki",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Samvinna hvaðan sem er",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Rafhlöðuending sem heldur ekki aftur af þér",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Heldur þér sjálfkrafa í sambandi",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Öll afþreyingin. Ein tölva.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Fyrir tölvu sem einnig er afþreyingarmiðstöð þarf geymslupláss sem nægir fyrir öll lögin, myndböndin og tölvuleikina og rétta samsetningu örgjörva og skjás til að birta áhrifaríkar myndir. Þessi tölva er búin öllu þrennu.",
                "titleArray": [
                  {
                    "tr_title": "Ótrúleg afköst við leikjaspilun og streymi",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Spilun og streymi án hnökra",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Komdu þér upp stafrænu safni",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Innsýn í afþreyingu",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Afkastaðu meiru. Hvar sem er.",
              "tr_tileTitle1": "Komdu meiru í verk. Hratt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og réttu rafhlöðunni til að geta verið á ferðinni.",
                "tr_subtitle1": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika til að hjálpa þér að koma meiru í verk á minni tíma. Samsetning öflugs örgjörva sem fær aukinn hraða með miklu minni og leifturhröðu Wi-Fi gerir verkefni dagsins að leik einum.",
                "tr_subtitle2": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og rétta minninu fyrir fjölvinnslu.",
                "titleArray": [
                  {
                    "tr_title": "Snjöll afköst sem skipta máli",
                    "tr_title1": "Meiri hraði með snjöllum afköstum",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bestu tengingar sem völ er á",
                    "tr_title1": "Tengstu því sem skiptir máli",
                    "tr_title2": "Stórt stökk í Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Ekkert mál að skipta á milli forrita",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Frelsið til að skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Efnissköpun er eitt af kröfuhörðustu verkefnum tölvunnar. Sem betur fer búa þessir íhlutir yfir kraftinum til að einfalda sköpunarferlið.",
                "titleArray": [
                  {
                    "tr_title": "Hraðari sköpun",
                    "tr_title1": "Einbeittu þér að skapandi verkefnum",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Sköpunin nær nýjum hæðum",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minni fyrir fjölvinnslu",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Innan í þessari tölvu",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Þetta eru þrír helstu íhlutirnir sem þarf að skoða við val á nýrri tölvu. Með því að ákveða hvernig þú ætlar að nota nýju tölvuna færðu góða mynd af því að velja hversu mikið þú þarft af þessum mikilvægu íhlutum. Þetta eru:",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Geymsla",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minni",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Frelsið til að skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Efnissköpun er eitt af kröfuhörðustu verkefnum tölvunnar. Sem betur fer búa þessir íhlutir yfir kraftinum til að einfalda sköpunarferlið.",
                "titleArray": [
                  {
                    "tr_title": "Snjallari leið til að skapa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gæddu myndirnar lífi",
                    "tr_title1": "Byrjum að skapa",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minni fyrir fjölvinnslu",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Afkastaðu meiru. Hvar sem er.",
              "tr_tileTitle1": "Komdu meiru í verk. Hratt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og réttu rafhlöðunni til að geta verið á ferðinni.",
                "tr_subtitle1": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika til að hjálpa þér að koma meiru í verk á minni tíma. Samsetning öflugs örgjörva sem fær aukinn hraða með miklu minni og leifturhröðu Wi-Fi gerir verkefni dagsins að leik einum.",
                "tr_subtitle2": "Þessi tölva er með fullkomnu jafnvægi afls og meðfærileika. Auk örgjörva sem er gerður fyrir þunna og létta hönnun er tölvan einnig búin Wi-Fi sem heldur þér í sambandi og rétta minninu fyrir fjölvinnslu.",
                "titleArray": [
                  {
                    "tr_title": "Hönnuð til að gera meira",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bestu tengingar sem völ er á",
                    "tr_title1": "Tengstu því sem skiptir máli",
                    "tr_title2": "Stórt stökk í Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Enn lengur án rafmagns",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Ekkert mál að skipta á milli forrita",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tilbúin fyrir sýndarveruleika",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Þegar sýndarveruleiki verður að veruleika",
                "tr_subtitle": "Hvað þarf til þess að skapa sýndarheim? Í tölvunni þarf samræmingu milli örgjörva, skjákorts og minnis.",
                "titleArray": [
                  {
                    "tr_title": "Knýr sýndarheima",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Raunveruleikinn er í smáatriðunum",
                    "tr_title1": "Gleymdu þér í innlifun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Falleg sýn",
      "tr_sub_header": "Blátt ljós getur valdið óþægindum og truflað svefnvenjur. SGS Eye Care display-tested OLED-skjárinn frá Samsung hjálpar ekki með því að skipta um lit, heldur með því að draga úr bylgjulengd mögulegs blás ljóss, sem er þægilegra fyrir augun og dregur úr augnþreytu.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Ekki eins skaðlegt blátt ljós"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Betra að öllu leyti",
      "tr_sub_header": "Ofurlétt og ofurþunn hönnun OLED-skjásins hámarkar myndgæði en lágmarkar þyngd."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Kolsvart",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Hefðbundinn</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streymi",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREYMI",
                "tr_subtitle": "Endalaus skemmtun",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir streymi:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur streymt í háskerpu."
                  },
                  {
                    "tr_title": "Skjár fyrir bjartar og fallegar myndir."
                  },
                  {
                    "tr_title": "Wi‑Fi sem krefst minni notkunar biðminnis og hleðslutíma."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vefskoðun",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VEFSKOÐUN",
                "tr_subtitle": "Internetið gert auðvelt",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir vefskoðun:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem er fljótur að hlaða síðum með miklu margmiðlunarefni."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt og áreiðanlegt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndspjall",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDSPJALL",
                "tr_subtitle": "Kristaltærar tengingar",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndspjall:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur skráð og deilt myndum samtímis."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Vefmyndavél sem sýnir þig í kristaltæru ljósi."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fjölvinnsla á mikum hraða",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FJÖLVINNSLA Á MIKUM HRAÐA",
                "tr_subtitle": "Gerðu meira án þess að hægja á þér",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir hraða fjölvinnslu:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur keyrt nokkur forrit í einu."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Minni sem skiptir hnökralaust á milli forrita."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndspjall",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDSPJALL",
                "tr_subtitle": "Kristaltærar tengingar",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndspjall:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur skráð og deilt myndum samtímis."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Vefmyndavél sem sýnir þig í kristaltæru ljósi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Létt tölvuleikjaspilun",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "LÉTT TÖLVULEIKJASPILUN",
                "tr_subtitle": "Tímabært að spila",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir létta leikjaspilun:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem knýr hnökralausa spilun."
                  },
                  {
                    "tr_title": "Minni sem getur ræður við raddspjall og streymi á sama tíma."
                  },
                  {
                    "tr_title": "Grafík sem knýr ótrúleg myndgæði."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndbreytingar",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDBREYTINGAR",
                "tr_subtitle": "Búðu til fullkomnar myndir",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndbreytingar:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við að breyta stórum myndahópum."
                  },
                  {
                    "tr_title": "Minni sem knýr hugbúnaðinn."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4-tækni sem gerir þér kleift að flytja skrár hratt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fjölvinnsla á mikum hraða",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FJÖLVINNSLA Á MIKUM HRAÐA",
                "tr_subtitle": "Gerðu meira án þess að hægja á þér",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir hraða fjölvinnslu:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem getur keyrt nokkur forrit í einu."
                  },
                  {
                    "tr_title": "Wi‑Fi sem er hratt, öflugt og áreiðanlegt."
                  },
                  {
                    "tr_title": "Minni sem skiptir hnökralaust á milli forrita."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samstarf í rauntíma",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMSTARF Í RAUNTÍMA",
                "tr_subtitle": "Sinntu þinni vinnu hvar sem er",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir samstarf á netinu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi til að knýja skrifstofuhugbúnað."
                  },
                  {
                    "tr_title": "Wi‑Fi sem þú getur treyst á."
                  },
                  {
                    "tr_title": "Vefmyndavél sem tekur upp í frábærri háskerpu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndbandagerð",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDBANDAGERð",
                "tr_subtitle": "Skapaðu kvikmyndatöfra",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndbandagerð:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við stórar skrár."
                  },
                  {
                    "tr_title": "Minni sem gerir klippihugbúnað hraðan og viðbragðsfljótan."
                  },
                  {
                    "tr_title": "Grafík sem styttir birtingartíma."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Myndbreytingar",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDBREYTINGAR",
                "tr_subtitle": "Búðu til fullkomnar myndir",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndbreytingar:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við að breyta stórum myndahópum."
                  },
                  {
                    "tr_title": "Minni sem knýr hugbúnaðinn."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4-tækni sem gerir þér kleift að flytja skrár hratt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Myndbandagerð",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MYNDBANDAGERð",
                "tr_subtitle": "Skapaðu kvikmyndatöfra",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir myndbandagerð:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við stórar skrár."
                  },
                  {
                    "tr_title": "Minni sem gerir klippihugbúnað hraðan og viðbragðsfljótan."
                  },
                  {
                    "tr_title": "Grafík sem styttir birtingartíma."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Þrívíddarlíkanagerð",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÞRÍVÍDDARLÍKANAGERÐ",
                "tr_subtitle": "Sköpun á næsta stigi",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir þrívíddarlíkanagerð:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi sem ræður við flókinn sköpunarhugbúnað."
                  },
                  {
                    "tr_title": "Minni sem er fljótt að sækja stórar skrár."
                  },
                  {
                    "tr_title": "Grafík fyrir öfluga sköpun og breytingar á myndum."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samstarf í rauntíma",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMSTARF Í RAUNTÍMA",
                "tr_subtitle": "Sinntu þinni vinnu hvar sem er",
                "tr_subtitle1": "Hér er það sem við fundum í þessari PC-tölvu sem hentar fullkomlega fyrir samstarf á netinu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Örgjörvi til að knýja skrifstofuhugbúnað."
                  },
                  {
                    "tr_title": "Wi‑Fi sem þú getur treyst á."
                  },
                  {
                    "tr_title": "Vefmyndavél sem tekur upp í frábærri háskerpu."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ forrit",
    "tr_title": "Opnaðu tengda heiminn þinn",
    "tr_subtitle": "Flyttu skrár og myndir með snurðulausum hætti um leið og þú hefur umsjón með símatilkynningum, símtölum og SMS-skilaboðum frá tölvunni þinni.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "FLYTTU SKRÁR OG MYNDIR",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "HRINGDU OG TAKTU Á MÓTI SÍMTÖLUM",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SENDU OG TAKTU Á MÓTI SMS-SKILABOÐUM",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "HAFÐU UMSJÓN MEÐ SÍMATILKYNNINGUM",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "OPNAÐU TENGDA HEIMINN ÞINN",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}