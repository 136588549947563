const Library_mobile_id = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Didukung oleh prosesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Didukung oleh prosesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Layaknya otak, prosesor mengendalikan apa yang dilakukan PC. Artinya, video yang Anda tonton, game yang Anda mainkan, dan situs web yang Anda kunjungi, semuanya dimulai dengan prosesor Anda.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Prosesor adalah otak PC&nbsp;Anda"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Performa dan nilai.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Prosesor Intel®&nbsp;Pentium&#65279;® Silver",
                    "tr_subtitle": "Tetap unggul di dunia digital dengan performa untuk mengakses file dari web. Multitasking sederhana dengan mudah beralih antarprogram dan menyelesaikan lebih banyak hal dalam waktu lebih singkat.",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Pentium&#65279;® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold",
                    "tr_subtitle": "Produktivitas yang Disempurnakan - Performa yang ditingkatkan untuk mendukung hari-hari Anda yang paling menuntut. Penelusuran Web yang Efisien - Memuat halaman web yang kaya media secara cepat agar bisa menjelajah lebih banyak dengan waktu tunggu lebih singkat.",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Prosesor Intel® Celeron&#65279;®",
                    "tr_subtitle": "Performa untuk memaksimalkan pekerjaan dan hiburan Anda. Nikmati film dan video web dalam detail yang luar biasa. Dapatkan lebih banyak waktu produktif dan lebih sedikit waktu menunggu.",
                    "tr_gen_core_title": "Prosesor Intel® Celeron&#65279;®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Prosesor Intel® Pentium®",
                    "tr_subtitle": "Dirancang untuk hiburan, streaming video, dan produktivitas yang hebat. Nikmati film dan video web dalam detail yang luar biasa. Dapatkan lebih banyak waktu produktif  dan lebih sedikit waktu menunggu.",
                    "tr_gen_core_title": "Prosesor Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Performa yang Anda butuhkan. Keterjangkauan harga yang Anda inginkan.",
                "tr_title": "Prosesor Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Nilai dalam kategori tersendiri",
                    "tr_subtitle": "Prosesor Intel® dapat menangani semua tugas yang Anda butuhkan dengan harga yang dapat Anda jangkau. Dengan mudah beralih dari satu perangkat lunak ke yang lainnya. Atau nikmati grafis berkualitas tinggi dengan performa stabil.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Buat koneksi yang lebih kuat",
                    "tr_subtitle": "Kualitas video yang lebih tajam, kejelasan audio yang lebih kaya, dan koneksi internet yang hampir 3x lebih cepat memungkinkan Anda untuk berkolaborasi dengan percaya diri di mana pun Anda membutuhkannya.<sup>26</sup> ",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Perbedaannya bagaikan siang dan malam",
                    "tr_subtitle": "Mulai dari menonton film secara maraton di pagi hari hingga penyempurnaan foto cepat, Prosesor Intel® memperpanjang masa pakai baterai perangkat Anda untuk pengalaman yang benar-benar imersif dan tanpa gangguan.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Setiap lingkungan adalah tempat belajar",
                    "tr_subtitle": "Jalankan beberapa alat pembelajaran online dengan mudah dari setiap sudut rumah Anda."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Belajar di mana pun Anda mau",
                    "tr_subtitle": "Selesaikan pelajaran di mana pun Anda membutuhkannya sekaligus menjalankan berbagai alat e-learning di saat yang bersamaan."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dapatkan kecanggihan untuk hiburan dan produktivitas yang sesuai dengan anggaran dan gaya hidup Anda.",
                "tr_title": "Performa Intel untuk setiap anggaran.",
                "tr_gen_core_title": "Prosesor Intel® Celeron&#65279;®",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA DAN NILAI YANG&nbsp;TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Prosesor Intel® Celeron&#65279;®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Penelusuran web yang cepat dan kaya media",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dengan mudah beralih antar-program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming kasual",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pengeditan foto dan video ringan",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produktivitas cepat dengan performa cerdas",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;7"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;6"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;5"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;4"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;3"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;2"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Didukung oleh prosesor Intel® Core™ [cpu_fam] Generasi&nbsp;ke&#8209;1"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Direkomendasikan untuk:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i3 Generasi ke-8",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ Generasi ke-9 dibuat untuk mengimbangi perkembangan dunia digital&nbsp;Anda. Dengan kombinasi kecepatan dan performa, Anda akan dapat melakukan lebih banyak hal yang Anda sukai dari PC tanpa frustrasi.",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i3 Generasi&nbsp;ke&#8209;9",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performa serbaguna ditambah dengan masa pakai baterai yang tahan lama, untuk Anda gunakan di mana saja.",
                "tr_title": "Kecanggihan sepanjang&nbsp;hari",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;10",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA GENERASI KE&#8209;10 YANG TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming film dalam 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konektivitas andal dan cepat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masking foto dan pemfilteran video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game pada resolusi 1080p dengan kecepatan bingkai lancar",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paling tepat untuk bekerja dengan program kreatif dan file berukuran besar",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Beralih dengan mudah dari streaming film ke membuat presentasi tanpa&nbsp;mengganggu&nbsp;performa.",
                "tr_title": "Kecanggihan sepanjang&nbsp;hari",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop yang dirancang khusus bagi Anda yang selalu aktif.",
                "tr_title": "Inilah laptop generasi baru",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Dirancang khusus untuk PC portabel, prosesor Intel® Core™ i3 Generasi ke-11 memungkinkan Anda melakukan lebih banyak dari sebelumnya, di mana pun.",
                "tr_title": "Dapatkan lebih banyak manfaat dari laptop baru Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i3 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Ketika seluruh keluarga Anda mengandalkan satu PC desktop untuk semua yang mereka lakukan, Anda memerlukan kecanggihan dan keserbagunaan prosesor Intel® Core™ i3 Generasi ke-11.",
                "tr_title": "Siap menghadapi semua tugas dengan performa terobosan.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i3 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i5 Generasi&nbsp;ke&#8209;8",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ Generasi ke-9 dibuat untuk mengimbangi perkembangan dunia digital&nbsp;Anda. Dengan kombinasi kecepatan dan performa, Anda akan dapat melakukan lebih banyak hal yang Anda sukai dari PC tanpa frustrasi.",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i5 Generasi&nbsp;ke&#8209;9",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "GAMEPLAY DAN VR LANCAR PADA GAME YANG PALING BERAT",
                "tr_title": "Bermain game tingkat tinggi berawal di sini",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Beralih ke beberapa program dengan mudah sehingga Anda siap untuk mengerjakan apa saja, kapan saja, di mana saja.",
                "tr_title": "Multitasking di mana saja",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA GENERASI KE&#8209;10 YANG TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming film dalam 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konektivitas andal dan cepat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masking foto dan pemfilteran video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game pada resolusi 1080p dengan kecepatan bingkai lancar",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paling tepat untuk bekerja dengan program kreatif dan file berukuran besar",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tingkatkan semua yang Anda lakukan mulai dari hiburan hingga produktivitas dan pembuatan konten.",
                "tr_title": "Upgrade ke PC yang lebih&nbsp;canggih",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop yang dirancang khusus bagi Anda yang selalu aktif.",
                "tr_title": "Inilah laptop generasi baru",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dirancang untuk streaming dan bermain game AAA tanpa hambatan",
                "tr_title": "Bermain sesuai keinginan&nbsp;Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dirancang untuk streaming dan bermain game AAA tanpa hambatan",
                "tr_title": "Bermain sesuai keinginan&nbsp;Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Beralih ke beberapa program dengan mudah sehingga Anda siap untuk mengerjakan apa saja, kapan saja, di mana saja.",
                "tr_title": "Multitasking di mana saja",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;10",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Prosesor Intel® Core™ i5 Generasi ke-11 memberikan pengalaman visual yang memukau dan produktivitas yang beragam ke dalam PC paling tipis dan&nbsp;ringan.",
                "tr_title": "Kekuatan laptop yang mengesankan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Ketika seluruh keluarga Anda mengandalkan satu PC desktop untuk semua yang mereka lakukan, Anda memerlukan kecanggihan dan keserbagunaan prosesor Intel® Core™ i5 Generasi ke-11.",
                "tr_title": "Siap menghadapi semua tugas dengan performa terobosan.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ i5 Generasi ke-11 menghadirkan pengalaman visual yang hidup dan produktivitas yang beragam ke laptop tipis & ringan.",
                "tr_title": "Kekuatan laptop yang mengesankan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i7 Generasi&nbsp;ke&#8209;8",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Prosesor Intel® Core™ Generasi ke-9 dibuat untuk mengimbangi perkembangan dunia digital&nbsp;Anda. Dengan kombinasi kecepatan dan performa, Anda akan dapat melakukan lebih banyak hal yang Anda sukai dari PC tanpa frustrasi.",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i7 Generasi&nbsp;ke&#8209;9",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS LEBIH TINGGI HINGGA 32% saat bermain game, streaming, dan merekam dibandingkan PC BERUMUR 3 TAHUN<sup>3</sup>",
                "tr_title": "Kemampuan untuk berbagi momen bermain game yang paling luar biasa",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dirancang untuk efisiensi, perangkat ramping ini dapat memberikan pengaruh besar terhadap cara Anda berkreasi, terhubung, dan streaming.",
                "tr_title": "Performa canggih di&nbsp;mana&nbsp;saja",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA GENERASI KE&#8209;10 YANG TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming film dalam 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konektivitas andal dan cepat",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masking foto dan pemfilteran video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game pada resolusi 1080p dengan kecepatan bingkai lancar",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paling tepat untuk bekerja dengan program kreatif dan file berukuran besar",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Performa canggih untuk meningkatkan segala yang Anda lakukan ke level berikutnya.",
                "tr_title": "Kekuatan untuk segala hal",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop yang dirancang khusus bagi Anda yang selalu aktif.",
                "tr_title": "Inilah laptop generasi baru",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bermain game, streaming, dan merekam dengan keunggulan kompetitif",
                "tr_title": "Sempurnakan dunia gaming Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bermain game, streaming, dan merekam dengan keunggulan kompetitif",
                "tr_title": "Sempurnakan dunia gaming Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dirancang untuk efisiensi, perangkat yang menawan ini dapat memiliki dampak besar pada cara Anda berkreasi, terhubung, dan melakukan streaming.",
                "tr_title": "Performa premium di mana pun",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Prosesor Intel® Core™ i7 Generasi ke-11 menghadirkan pembuatan konten canggih, gaming yang lancar, dan hiburan tingkat lanjut ke PC ultra portabel.",
                "tr_title": "Performa laptop terobosan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Buat PC Anda menjadi perangkat andalan untuk segala hal yang diperlukan di rumah dengan performa terobosan prosesor Intel® Core™ i7 Generasi ke-11.",
                "tr_title": "Bekerja. Belajar. Bermain. Tanpa&nbsp;batas.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ i7 Generasi ke-11 menghadirkan pembuatan konten canggih, gaming yang lancar, dan hiburan tingkat lanjut ke laptop tipis dan ringan.",
                "tr_title": "Performa laptop terobosan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Buat PC Anda menjadi perangkat andalan untuk segala hal yang diperlukan di rumah dengan performa terobosan prosesor Intel® Core™ i7 Generasi ke-11.",
                "tr_title": "Berkreasi tanpa batasan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan pembuatan konten tingkat lanjut, gaming lancar, dan hiburan tingkat berikutnya pada laptop tipis & ringan, prosesor Intel® Core™ i7 Generasi ke-11 kini semakin baik.",
                "tr_title": "Performa laptop terobosan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i9 Generasi&nbsp;ke&#8209;8",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ Generasi ke-9 dibuat untuk mengimbangi perkembangan dunia digital&nbsp;Anda. Dengan kombinasi kecepatan dan performa, Anda akan dapat melakukan lebih banyak hal yang Anda sukai dari PC tanpa frustrasi.",
                "tr_title": "Didukung oleh prosesor Intel® Core™ i9 Generasi&nbsp;ke&#8209;9",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS LEBIH TINGGI HINGGA 41% saat bermain game, streaming, dan merekam dibandingkan PC BERUMUR 3 TAHUN<sup>4</sup>",
                "tr_title": "Para gamer menuntut. Intel® Core™ i9 memenuhi.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dorong potensi Anda dengan pengalaman gaming terbaik",
                "tr_title": "Bersaing di tingkat tertinggi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dorong potensi Anda dengan pengalaman gaming terbaik",
                "tr_title": "Bersaing di tingkat tertinggi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Dorong potensi Anda dengan pengalaman kreator terbaik",
                "tr_title": "Berkreasi di tingkat tertinggi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop Intel® Evo™ yang didukung oleh prosesor Intel® Core™ i5 Generasi ke-11 dan grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> memberi Anda kecepatan dan performa yang mengagumkan, semuanya dalam desain yang tipis dan ringan.",
                "tr_title": "Kecanggihan yang lebih tinggi untuk laptop yang berevolusi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop Intel® Evo™ yang didukung oleh prosesor Intel® Core™ i7 Generasi ke-11 dan grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> memberi Anda kecepatan dan performa tingkat lanjut untuk pengalaman mobile premium.",
                "tr_title": "Kecanggihan tingkat lanjut untuk laptop yang berevolusi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nikmati keseimbangan performa, pengalaman media, dan konektivitas yang hebat dengan nilai yang luar biasa.",
                "tr_title": "Performa dan konektivitas dengan nilai luar biasa.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Pentium&#65279;® Silver",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA DAN NILAI YANG&nbsp;TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Pentium&#65279;® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Penelusuran web yang cepat dan kaya media",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dengan mudah beralih antar-program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming kasual",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pengeditan foto dan video ringan",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produktivitas cepat dengan performa cerdas",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nikmati multitasking yang lancar, pengeditan foto dan video yang sederhana, video 4K serta grafis yang tajam dengan nilai yang luar biasa.",
                "tr_title": "Performa mengagumkan untuk bekerja dan bermain.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold",
                "processorCompare": {
                  "tr_title": "TEMUKAN TINGKAT PERFORMA DAN NILAI YANG&nbsp;TEPAT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Pentium&#65279;® Silver"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Pentium&#65279;® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Penelusuran web yang cepat dan kaya media",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dengan mudah beralih antar-program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming kasual",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pengeditan foto dan video ringan",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produktivitas cepat dengan performa cerdas",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA dan masa pakai baterai tahan lama di PC ultraportabel. Tidak perlu berkompromi dalam bermain game.",
                "tr_title": "Tidak perlu berkompromi.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR GAMING ULTRAPORTABEL INTEL® CORE™ ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i5-11300H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i7-11370H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™ i7&#8209;11375H Generasi ke&#8209;11 Edisi&nbsp;Khusus"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 inti/ 8&nbsp;thread",
                      "tr_td2_title": "4 inti/ 8&nbsp;thread",
                      "tr_td3_title": "4 inti/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Teknologi Intel® Turbo Boost",
                      "tr_td2_title": "Teknologi Intel® Turbo Boost",
                      "tr_td3_title": "Teknologi Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache 8 MB",
                      "tr_td2_title": "Intel® Smart Cache 12 MB",
                      "tr_td3_title": "Intel® Smart Cache 12 MB"
                    },
                    {
                      "tr_td1_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Bermain game AAA 1080p dengan FPS tinggi pada pengaturan tinggi dan performa mengagumkan saat tidak terhubung ke charger pada PC ultraportabel.",
                "tr_title": "Semua yang Anda butuhkan ada di sini.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR GAMING ULTRAPORTABEL INTEL® CORE™ ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i5-11300H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i7-11370H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™ i7&#8209;11375H Generasi ke&#8209;11 Edisi&nbsp;Khusus"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 inti/ 8&nbsp;thread",
                      "tr_td2_title": "4 inti/ 8&nbsp;thread",
                      "tr_td3_title": "4 inti/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Teknologi Intel® Turbo Boost",
                      "tr_td2_title": "Teknologi Intel® Turbo Boost",
                      "tr_td3_title": "Teknologi Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache 8 MB",
                      "tr_td2_title": "Intel® Smart Cache 12 MB",
                      "tr_td3_title": "Intel® Smart Cache 12 MB"
                    },
                    {
                      "tr_td1_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Kecepatan clock maksimum hingga 5,0 GHz untuk mendorong game Anda lebih jauh dan PC ultraportabel untuk dibawa ke mana pun Anda pergi.",
                "tr_title": "Tipis, ringan, lebih&nbsp;bertenaga.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11 Edisi&nbsp;Khusus",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Prosesor Intel® Core™ i7 Edisi Khusus",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR GAMING ULTRAPORTABEL INTEL® CORE™ ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i5-11300H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Prosesor Intel® Core™ i7-11370H Generasi ke&#8209;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™ i7&#8209;11375H Generasi ke&#8209;11 Edisi&nbsp;Khusus"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Kecepatan clock maksimum hingga&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 inti/ 8&nbsp;thread",
                      "tr_td2_title": "4 inti/ 8&nbsp;thread",
                      "tr_td3_title": "4 inti/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Teknologi Intel® Turbo Boost",
                      "tr_td2_title": "Teknologi Intel® Turbo Boost",
                      "tr_td3_title": "Teknologi Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Intel® Smart Cache 8 MB",
                      "tr_td2_title": "Intel® Smart Cache 12 MB",
                      "tr_td3_title": "Intel® Smart Cache 12 MB"
                    },
                    {
                      "tr_td1_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prosesor Intel® Core™ i7 Generasi ke-11 Edisi Khusus menghadirkan pembuatan konten tingkat lanjut, gaming yang lancar, dan hiburan tingkat berikutnya ke PC ultraportabel.",
                "tr_title": "Performa laptop terobosan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11 Edisi&nbsp;Khusus",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11 EDISI KHUSUS"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Prosesor Intel® Core™ i7 Generasi ke-11 Edisi Khusus menghadirkan pembuatan konten tingkat lanjut, gaming yang lancar, dan hiburan tingkat berikutnya ke PC ultraportabel.",
                "tr_title": "Performa laptop terobosan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11 Edisi&nbsp;Khusus",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11 EDISI KHUSUS",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Prosesor Intel® Core™ i7 Edisi Khusus",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bekerja sama dengan lancar untuk menghadirkan pengalaman terbaik pada gaming kompetitif dan pembuatan konten yang kompleks.",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Keseimbangan yang tepat antara kecepatan clock dan performa inti untuk pengalaman terbaik saat bermain game, baik game kompetitif dengan FPS tinggi atau game AAA yang imersif.",
                "tr_title": "Kekuatan unggulan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bermain game, merekam, dan streaming pada judul yang menuntut performa dengan FPS tinggi serta beralih dengan mudah antara beban kerja multitasking yang berat.",
                "tr_title": "Kekuatan yang menembus batas.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bekerja sama dengan lancar untuk menghadirkan pengalaman terbaik pada gaming kompetitif dan pembuatan konten yang kompleks.",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Beralih dengan mudah dari streaming film ke membuat presentasi tanpa&nbsp;mengganggu&nbsp;performa.",
                "tr_title": "Kecanggihan sepanjang&nbsp;hari",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 dengan Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Prosesor Intel®&nbsp;Core™&nbsp;i3 dengan Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performa serbaguna ditambah dengan masa pakai baterai yang tahan lama, untuk Anda gunakan di mana saja.",
                "tr_title": "Kecanggihan sepanjang&nbsp;hari",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 dengan Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Prosesor Intel®&nbsp;Core™&nbsp;i3 dengan Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Beralih ke beberapa program dengan mudah sehingga Anda siap untuk mengerjakan apa saja, kapan saja, di mana saja.",
                "tr_title": "Multitasking di mana saja",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 dengan Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Prosesor Intel®&nbsp;Core™&nbsp;i5 dengan Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tingkatkan semua yang Anda lakukan mulai dari hiburan hingga produktivitas dan pembuatan konten.",
                "tr_title": "Upgrade ke PC yang lebih&nbsp;canggih",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 dengan Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Prosesor Intel®&nbsp;Core™&nbsp;i5 dengan Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Performa gaming sekaliber desktop dipadukan dengan mobilitas, baik untuk permainan kompetitif dengan FPS tinggi maupun pengalaman AAA yang imersif.",
                "tr_title": "Kekuatan unggulan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Performa gaming sekaliber desktop dipadukan dengan mobilitas, baik untuk permainan kompetitif dengan FPS tinggi maupun pengalaman AAA yang imersif.",
                "tr_title": "Kekuatan unggulan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bermain game, merekam, dan streaming secara bersamaan dengan FPS tinggi serta beralih ke beban kerja multitasking yang berat dengan lancar dalam satu laptop.",
                "tr_title": "Kekuatan yang menembus batas.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bermain game, merekam, dan streaming secara bersamaan dengan FPS tinggi serta beralih ke beban kerja multitasking yang berat dengan lancar dalam satu laptop.",
                "tr_title": "Kekuatan yang menembus batas.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Perpaduan antara kecepatan clock yang tinggi dan jumlah inti yang banyak untuk memaksimalkan gaming kompetitif dan pembuatan konten yang kompleks, dari mana saja. ",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Perpaduan antara kecepatan clock yang tinggi dan jumlah inti yang banyak untuk memaksimalkan gaming kompetitif dan pembuatan konten yang kompleks, dari mana saja. ",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Perpaduan antara kecepatan clock yang tinggi, jumlah inti yang banyak, dan performa unlocked memaksimalkan gaming kompetitif dan pembuatan konten yang kompleks, dari mana saja.",
                "tr_title": "Kekuatan unlocked yang tangguh dan seimbang.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Perpaduan antara kecepatan clock yang tinggi, jumlah inti yang banyak, dan performa unlocked memaksimalkan gaming kompetitif dan pembuatan konten yang kompleks, dari mana saja.",
                "tr_title": "Kekuatan unlocked yang tangguh dan seimbang.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bekerja sama dengan lancar untuk menghadirkan pengalaman terbaik pada gaming kompetitif dan pembuatan konten yang kompleks.",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bersinergi dengan lancar untuk memaksimalkan pembuatan konten rumit, di mana saja.",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bersinergi dengan lancar untuk memaksimalkan pembuatan konten rumit.",
                "tr_title": "Tangguh, Seimbang, dan Sempurna. ",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dorong potensi Anda dengan pengalaman gaming terbaik",
                "tr_title": "Bersaing di tingkat tertinggi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dorong potensi Anda dengan pengalaman gaming terbaik",
                "tr_title": "Bersaing di tingkat tertinggi",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ GENERASI KE&#8209;10 UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA Tanpa Hambatan",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming dan merekam",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pembuatan konten canggih",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performa puncak bermain gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Unlocked dan dapat di-overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Laptop yang dirancang khusus bagi Anda yang selalu aktif.",
                "tr_title": "Inilah laptop generasi baru",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bekerja sama dengan lancar untuk menghadirkan pengalaman terbaik pada gaming kompetitif dan pembuatan konten yang kompleks.",
                "tr_title": "Kekuatan unlocked yang tangguh dan seimbang.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi serta performa unlocked bersinergi dengan lancar untuk memaksimalkan pembuatan konten yang rumit, di mana saja.",
                "tr_title": "Kekuatan unlocked yang tangguh dan seimbang.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kecepatan clock dan jumlah inti yang tinggi bersinergi dengan lancar untuk memaksimalkan pembuatan konten rumit.",
                "tr_title": "Kekuatan unlocked yang tangguh dan seimbang.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;11",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gameplay dan VR lancar pada game yang paling berat",
                "tr_title": "Bermain game tingkat tinggi berawal di sini",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS LEBIH TINGGI HINGGA 32% <br/><span class='tr_subtitle_font'>saat bermain game, streaming, dan merekam vs. PC berumur 3 tahun<sup>³</sup></span>",
                "tr_title": "Kemampuan untuk berbagi momen bermain game yang paling luar biasa",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS LEBIH TINGGI HINGGA 41% <br/><span class='tr_subtitle_font'>saat bermain game, streaming, dan merekam vs. PC berumur 3 tahun<sup>⁴</sup></span>",
                "tr_title": "Para gamer menuntut. Intel® Core™ i9 memenuhi.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Inti</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hingga <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Unlocked",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TEMUKAN PROSESOR INTEL® CORE™ UNTUK BERMAIN GAME SESUAI KEINGINAN ANDA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Prosesor Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Bermain Game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game kompetitif",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bermain game, streaming, dan merekam tanpa kompromi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prosesor Intel® Core™ Generasi ke-9 terbaik untuk game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kecepatan clock<sup>2</sup>: Hingga&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Inti: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa terbaik untuk next-gen gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;12",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;12",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Bekerja dan bermain di&nbsp;satu&nbsp;tempat",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;12",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa andal ketika Anda memerlukannya",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i3 GENERASI KE-12",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Peningkatan performa tepat saat dibutuhkan",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Peningkatan performa tepat saat dibutuhkan",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa unggul tepat saat Anda butuhkan",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa unggul tepat saat Anda butuhkan",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan laptop Intel® Evo™, Anda mendapatkan performa, kecerdasan bawaan untuk melakukan obrolan video dengan percaya diri, internet yang lebih cepat, masa pakai baterai yang panjang, dan banyak lagi.",
                "tr_title": "Peningkatan performa tepat saat dibutuhkan.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan laptop Intel® Evo™, Anda mendapatkan performa, kecerdasan bawaan untuk melakukan obrolan video dengan percaya diri, internet yang lebih cepat, masa pakai baterai yang panjang, dan banyak lagi.",
                "tr_title": "Performa unggul tepat saat Anda butuhkan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan laptop Intel® Evo™, Anda mendapatkan performa, kecerdasan bawaan untuk melakukan obrolan video dengan percaya diri, internet yang lebih cepat, masa pakai baterai yang panjang, dan banyak lagi.",
                "tr_title": "Performa ekstrem dalam laptop premium",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dengan Teknologi Intel® Turbo Boost 2.0 hingga [max_turbo_frequency] GHz",
                "tr_title": "Dibuat untuk bisnis",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendapatkan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menghadirkan kemampuan pengelolaan perangkat jarak jauh",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Secara otomatis mengoptimalkan performa Wi&#8209;Fi Anda<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendukung aplikasi yang didorong data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Produktivitas terbaik dengan performa yang disempurnakan",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dengan Teknologi Intel® Turbo Boost 2.0 hingga [max_turbo_frequency] GHz",
                "tr_title": "Berperforma tinggi, sehingga Anda dapat berfokus pada bisnis",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendapatkan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menghadirkan kemampuan pengelolaan perangkat jarak jauh",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Secara otomatis mengoptimalkan performa Wi&#8209;Fi Anda<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendukung aplikasi yang didorong data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Produktivitas terbaik dengan performa yang disempurnakan",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dengan Teknologi Intel® Turbo Boost 2.0 hingga [max_turbo_frequency] GHz",
                "tr_title": "Akselerasi bisnis Anda",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;12",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-12",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendapatkan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Menghadirkan kemampuan pengelolaan perangkat jarak jauh",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Secara otomatis mengoptimalkan performa Wi&#8209;Fi Anda<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mendukung aplikasi yang didorong data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Produktivitas terbaik dengan performa yang disempurnakan",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa andalan untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 Generasi&nbsp;ke&#8209;13",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa sesungguhnya untuk melakukan lebih banyak hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-13",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa sesungguhnya untuk melakukan lebih banyak hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-13",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa canggih untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-13",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa canggih untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-13",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa tak tertandingi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-13",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan mudah terhubung ke ponsel yang Anda miliki<sup>24</sup>, dengan cerdas memilih Wi-Fi terbaik, dan isi daya cepat dengan baterai yang tahan lama.",
                "tr_title": "Performa tak tertandingi untuk menyelesaikan lebih banyak hal di mana saja",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan mudah terhubung ke ponsel yang Anda miliki<sup>24</sup>, dengan cerdas memilih Wi-Fi terbaik, dan isi daya cepat dengan baterai yang tahan lama.",
                "tr_title": "Performa tak tertandingi untuk memungkinkan PC melakukan lebih banyak hal secara bersamaan",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan mudah terhubung ke ponsel yang Anda miliki<sup>24</sup>, dengan cerdas memilih Wi-Fi terbaik, dan isi daya cepat dengan baterai yang tahan lama.",
                "tr_title": "Performa tak tertandingi untuk dengan cepat menyelesaikan tugas yang paling menuntut",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa tak tertandingi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;13",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;13",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;13",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa tak tertandingi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa andalan untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa sesungguhnya untuk melakukan lebih banyak hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa canggih untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa tak tertandingi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa andalan untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa sesungguhnya untuk melakukan lebih banyak hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Performa canggih untuk&nbsp;melakukan lebih&nbsp;banyak&nbsp;hal",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Prosesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Inti</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core",
                    "tr_badgeSubTitle1": "[l_core] LPE-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] pada Performance-core",
                    "tr_badgeSubTitle": "[e_thread] pada Efficient-core",
                    "tr_badgeSubTitle1": "[l_thread] pada LPE-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Prosesor Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Bermain game tanpa kompromi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Usaha kecil layak mendapatkan solusi bisnis besar.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i5 GENERASI KE-13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Mendapatkan perlindungan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Membantu melindungi perangkat dan data dari serangan perusakan fisik",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Usaha kecil layak mendapatkan solusi bisnis besar.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i7 GENERASI KE-13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Mendapatkan perlindungan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Membantu melindungi perangkat dan data dari serangan perusakan fisik",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Usaha kecil layak mendapatkan solusi bisnis besar.",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i9 Generasi&nbsp;ke&#8209;13",
                "tr_gen_core_title_uppercase": "PROSESOR INTEL® CORE™ i9 GENERASI KE-13",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN PROSESOR INI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Perangkat <br/>saat ini",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Meminimalkan kebisingan dan mengaburkan latar belakang Anda",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Performa yang disesuaikan otomatis untuk memprioritaskan apa yang paling sering Anda lakukan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Menikmati pengalaman panggilan web yang lancar dan berkualitas tinggi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Mendapatkan perlindungan keamanan berbasis perangkat keras yang siap pakai",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Membantu melindungi perangkat dan data dari serangan perusakan fisik",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Nikmati kecanggihan AI terkini untuk segala urusan bisnis",
                "tr_title": "Taklukkan tantangan masa depan dengan prosesor Intel® Core™ Ultra&nbsp;[XX] dan Intel vPro®",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;[XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "TINGKATKAN BISNIS ANDA DENGAN PC AI DARI INTEL",
                    "tr_badgeSubTitle": "PC canggih yang menciptakan pengalaman luar biasa menjadi inti dari produktivitas bisnis. Intel vPro® meningkatkan efektivitas dan performa karyawan di seluruh beban kerja dan aplikasi berkat arsitektur baru yang menghadirkan performa hemat daya yang superior untuk berbagai beban kerja bisnis yang kompleks dengan mengirimkan tugas yang tepat ke mesin yang tepat pada waktu yang tepat.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "FITUR KEAMANAN MULTILAPIS SIAP PAKAI",
                    "tr_badgeSubTitle": "Ancaman baru yang muncul memerlukan pendekatan komprehensif terhadap keamanan. Intel vPro® memberikan perlindungan di tingkat perangkat keras dan di seluruh rangkaian sehingga bisnis Anda lebih terlindungi. Langkah-langkah keamanan unik yang siap pakai ini termasuk kemampuan deteksi ancaman yang didukung AI, yang membantu melindungi pengguna, data, dan bisnis Anda.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "DENGAN ALAT BAWAAN YANG MENJAGA PRODUKTIVITAS PENGGUNA DI MANA SAJA",
                    "tr_badgeSubTitle": "Kantor bisa dimana saja, begitu pula dengan PC. Intel vPro® memberikan kemampuan pengelolaan berbasis perangkat keras modern untuk membantu bisnis meringankan beban pengelolaan armada PC, menyederhanakan dukungan, dan meningkatkan pengalaman pengguna akhir, sekaligus mendukung praktik komputasi yang berkelanjutan.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Lebih dari sekadar PC canggih, inilah PC AI",
                "tr_subtitle1": "DIDUKUNG OLEH PROSESOR INTEL®&nbsp;CORE™ ULTRA (SERI&nbsp;2)",
                "tr_subtitle2": "PC AI Intel Anda memungkinkan segalanya",
                "tr_subtitle": "Prosesor mobile Intel® yang paling efisien, aman, dan berperforma tinggi<sup>39</sup>",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Seri&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Lebih kreatif</span> dengan perangkat AI untuk berkreasi lebih cepat"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lebih produktif</span> dengan asisten AI yang menghemat waktu"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lebih aman</span> dengan perlindungan data bawaan"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Lebih dari sekadar PC canggih, inilah PC AI",
                "tr_subtitle1": "DIDUKUNG OLEH PROSESOR INTEL®&nbsp;CORE™ ULTRA (SERI&nbsp;2)",
                "tr_subtitle2": "PC AI Intel Anda memungkinkan segalanya",
                "tr_subtitle": "Prosesor mobile Intel® yang paling efisien, aman, dan berperforma tinggi<sup>39</sup>",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Seri&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Lebih kreatif</span> dengan perangkat AI untuk berkreasi lebih cepat"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lebih produktif</span> dengan asisten AI yang menghemat waktu"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lebih aman</span> dengan perlindungan data bawaan"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Lebih dari sekadar PC canggih, inilah PC AI",
                "tr_subtitle1": "DIDUKUNG OLEH PROSESOR INTEL®&nbsp;CORE™ ULTRA (SERI&nbsp;2)",
                "tr_subtitle2": "PC AI Intel Anda memungkinkan segalanya",
                "tr_subtitle": "Prosesor mobile Intel® yang paling efisien, aman, dan berperforma tinggi<sup>39</sup>",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Seri&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Lebih dari sekadar PC canggih, inilah PC AI",
                "tr_subtitle1": "DIDUKUNG OLEH PROSESOR INTEL®&nbsp;CORE™ ULTRA (SERI&nbsp;2)",
                "tr_subtitle2": "PC AI Intel Anda memungkinkan segalanya",
                "tr_subtitle": "Prosesor mobile Intel® yang paling efisien, aman, dan berperforma tinggi<sup>39</sup>",
                "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Seri&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_tileTitle1": "Grafis Khusus",
                "tr_title": "APA YANG DIMAKSUD DENGAN UNIT PEMROSESAN GRAFIS?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Unit pemrosesan grafis atau GPU menciptakan gambar yang Anda lihat pada layar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU Terintegrasi",
                    "tr_badgeSubTitle": "GPU terintegrasi menggunakan RAM yang sama dengan bagian komputer lainnya, sehingga membuatnya lebih hemat energi.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU Terpisah",
                    "tr_badgeSubTitle": "GPU ini merupakan kelas paling canggih karena memiliki RAM sendiri dan sumber daya lain yang dikhususkan untuk membuat grafis luar biasa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU Hibrida",
                    "tr_badgeSubTitle": "GPU ini merupakan kelas paling canggih karena memiliki RAM sendiri dan sumber daya lain yang dikhususkan untuk membuat grafis luar biasa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Pengeditan foto dan video yang disederhanakan",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Menonton konten video 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Bermain game populer dalam detail yang menakjubkan",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "GPU Ganda",
                    "tr_badgeSubTitle": "Beberapa sistem, seperti sistem dengan grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, dapat memanfaatkan GPU pada prosesor dan GPU terpisah untuk mendistribusikan beban kerja grafis.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU Terintegrasi",
                    "tr_badgeSubTitle": "GPU terintegrasi tersedia dalam chip yang sama dengan prosesor utama atau CPU. Tipe GPU ini menggunakan RAM dan sumber daya lain yang sama seperti komputer lainnya, sehingga lebih efisien dalam menggunakan energi dan ukuran perangkat dapat menjadi lebih tipis dan ringan.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU Khusus",
                    "tr_badgeSubTitle": "GPU ini merupakan kelas paling canggih karena memiliki RAM sendiri dan sumber daya lain yang dikhususkan untuk membuat grafis luar biasa.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standar",
                    "tr_badgeSubTitle": "VRAM hingga 4&nbsp;GB",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Kompetitif",
                    "tr_badgeSubTitle": "VRAM hingga 8&nbsp;GB",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "VRAM lebih dari 8&nbsp;GB",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Daya grafis yang kuat",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis yang dapat berfokus khususnya pada pembuatan gambar yang indah dan tajam serta animasi lancar.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Grafis internal yang kaya dan hidup ",
                "tr_pretitle": "Grafis Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dengan grafis Intel® Iris® Plus, Anda akan terhanyut dalam video 4K HDR dan gaming HD menyeluruh tanpa perlu kartu grafis tambahan.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Visual yang menakjubkan, performa&nbsp;yang&nbsp;mudah",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafis Intel® menghadirkan performa grafis terintegrasi transformasional untuk mendukung desain yang lancar bagi kreator serta pengalaman gaming yang kaya.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Grafis Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Grafis Intel® UHD yang dilengkapi arsitektur X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Pengalaman visual yang imersif",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Nikmati pengeditan foto dan video yang disederhanakan, menonton konten video premium dalam 4K, atau bermain game populer dalam detail yang menakjubkan.",
                "tr_photo_text": "Pengeditan foto dan video yang disederhanakan",
                "tr_video_text": "Menonton konten video 4K",
                "tr_game_text": "Bermain game populer dalam detail yang menakjubkan",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "JAUH LEBIH DARI SEKADAR GAMBAR INDAH",
                "tr_subtitle": "Melalui kerja sama dengan prosesor (atau CPU), kartu grafis (atau GPU) akan membantu menentukan bagaimana game berjalan dan tampilannya. VRAM atau RAM video, yang dikhususkan hanya untuk menghasilkan grafis, adalah cara cepat untuk mengetahui kekuatan GPU Anda.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "UNTUK GRAFIS GAME YANG CANGGIH",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis terpisah yang dapat berfokus secara khusus pada penciptaan gambar yang jernih dan animasi yang lancar",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "UNTUK GRAFIS GAME YANG CANGGIH",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis terpisah yang dapat berfokus secara khusus pada penciptaan gambar yang jernih dan animasi yang lancar",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "JAUH LEBIH DARI SEKADAR GAMBAR INDAH",
                "tr_subtitle": "Melalui kerja sama dengan prosesor (atau CPU), kartu grafis (atau GPU) akan membantu menentukan bagaimana game berjalan dan tampilannya. VRAM atau RAM video, yang dikhususkan hanya untuk menghasilkan grafis, adalah cara cepat untuk mengetahui kekuatan GPU Anda.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Daya grafis yang kuat",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis yang dapat berfokus khususnya pada pembuatan gambar yang indah dan tajam serta animasi lancar.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "UNTUK GRAFIS GAME YANG CANGGIH",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis yang dapat berfokus khususnya pada pembuatan gambar yang indah dan tajam serta animasi lancar.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis terpisah yang dapat berfokus secara khusus pada penciptaan gambar yang jernih dan animasi yang lancar",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Menghadirkan kemampuan grafis tinggi untuk memenuhi kebutuhan minimum game Anda.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Untuk pemain dengan kelas lebih tinggi yang menuntut gaming tingkat kompetisi",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Untuk gamer tingkat lanjut yang juga menggunakan PC mereka untuk pembuatan konten berat",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Video jernih dan gaming&nbsp;imersif",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Dapatkan manfaat optimal dari layar resolusi tinggi dengan Intel® HD Graphics dan nikmati pengalaman multimedia kaya serta pemrosesan grafis internal.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Mendukung video dan gambar yang hidup",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Dapatkan lebih banyak detail dan warna yang brilian dari foto dan video streaming Anda.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Pembuatan konten dan gaming luar biasa",
                "tr_pretitle": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dari hiburan yang imersif dan pembuatan konten canggih hingga gaming umum, grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> menghadirkan performa grafis terobosan ke PC tipis dan ringan.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_pretitle": "UNTUK GRAFIS GAME YANG CANGGIH",
                "tr_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis terpisah yang dapat berfokus secara khusus pada penciptaan gambar yang jernih dan animasi yang lancar",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[x] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Tunjukkan kreativitas Anda",
                "tr_pretitle": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kerjakan proyek kreatif dengan mudah dan hanyutkan diri Anda dalam berbagai macam game dengan kekuatan gabungan dari grafis pada prosesor dan mesin grafis diskrit Intel® yang pertama.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "tr_title": "Grafis Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "UNTUK GRAFIS GAME YANG CANGGIH",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Pemrosesan grafis paling canggih memerlukan kartu grafis terpisah yang dapat berfokus secara khusus pada penciptaan gambar yang jernih dan animasi yang lancar",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM</span><span class='value'>[x] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafis",
                "tr_title": "Grafis cantik internal",
                "tr_subtitle": "Nikmati game, film, dan video web dengan detail dan kejernihan luar biasa.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafis",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Tingkatkan alur kerja Anda"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Tingkatkan gaming Anda"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Tingkatkan gaming Anda"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Tingkatkan permainan game Anda"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "TUNJUKKAN KREATIVITAS ANDA",
                    "tr_specText": "Akselerasi produktivitas ke mana pun Anda pergi dengan kemampuan media yang paling komprehensif, seperti <strong>encoding perangkat keras AV1 pertama di industri</strong> dan pembuatan konten yang diakselerasi AI.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "TUNJUKKAN KREATIVITAS ANDA",
                    "tr_specText": "Akselerasi produktivitas Anda dengan kemampuan media paling komprehensif, seperti <strong>encoding perangkat keras AV1 pertama di industri</strong> dan pembuatan konten yang diakselerasi AI.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "TUNJUKKAN KREATIVITAS ANDA",
                    "tr_specText": "Nikmati pembuatan konten canggih yang diakselerasi AI dengan <strong>encoding perangkat keras AV1 pertama di industri</strong> dan Mesin Media <strong>X<sup>e</sup> yang canggih.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "TUNJUKKAN KREATIVITAS ANDA",
                    "tr_specText": "Nikmati pembuatan konten canggih yang diakselerasi AI dengan <strong>encoding perangkat keras AV1 pertama di industri</strong> dan Mesin Media <strong>X<sup>e</sup> yang canggih.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "TUNJUKKAN KREATIVITAS ANDA",
                    "tr_specText": "Nikmati pembuatan konten canggih yang diakselerasi AI dengan <strong>encoding perangkat keras AV1 pertama di industri</strong> dan Mesin Media <strong>X<sup>e</sup> yang canggih.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PORTABILITAS BERPADU DENGAN PERFORMA",
                    "tr_specText": "Mainkan game terbaru di mana saja dan dapatkan performa dan fidelitas tinggi dengan kemampuan gaming modern. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "KENDALIKAN GAME ANDA",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> memungkinkan Anda untuk menyempurnakan pengalaman dengan tata letak yang intuitif dan modern. Dapatkan pembaruan driver lancar, optimalkan game Anda, dan banyak lagi."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "KENDALIKAN GAME ANDA",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> memungkinkan Anda untuk menyempurnakan pengalaman dengan tata letak yang intuitif dan modern. Dapatkan pembaruan driver lancar, optimalkan game Anda, dan banyak lagi."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "KENDALIKAN GAME ANDA",
                    "tr_specText": "Dapatkan pembaruan driver lancar dan optimalkan banyak game favorit Anda dengan perangkat lunak <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAKSIMALKAN PLATFORM INTEL® ANDA",
                    "tr_specText": "Padukan dengan prosesor Intel® Core™ yang kompatibel untuk mendapatkan <strong>Teknologi Intel® Deep Link</strong> guna menyempurnakan pengalaman kreasi, gaming, dan streaming Anda."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAKSIMALKAN SISTEM INTEL® ANDA",
                    "tr_specText": "Padukan dengan prosesor Intel® Core™ yang kompatibel untuk mendapatkan <strong>Teknologi Intel® Deep Link</strong> guna menyempurnakan pengalaman kreasi, gaming, dan streaming Anda."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAKSIMALKAN PLATFORM INTEL® ANDA",
                    "tr_specText": "Padukan dengan prosesor Intel® Core™ yang kompatibel untuk mendapatkan <strong>Teknologi Intel® Deep Link</strong> guna menyempurnakan pengalaman kreasi, gaming, dan streaming Anda."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMALKAN PLATFORM INTEL® ANDA",
                    "tr_specText": "Padukan dengan prosesor Intel® Core™ yang kompatibel untuk mendapatkan <strong>Teknologi Intel® Deep Link</strong> guna menyempurnakan pengalaman kreasi, gaming, dan streaming Anda."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMALKAN SISTEM INTEL® ANDA",
                    "tr_specText": "Padukan dengan prosesor Intel® Core™ yang kompatibel untuk mendapatkan <strong>Teknologi Intel® Deep Link</strong> guna menyempurnakan pengalaman kreasi, gaming, dan streaming Anda."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "TEMPATI PANGGUNG UTAMA",
                    "tr_specText": "Lakukan siaran dengan lancar menggunakan teknologi streaming canggih yang didukung oleh perangkat lunak <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "TEMPATI PANGGUNG UTAMA",
                    "tr_specText": "Lakukan siaran dengan lancar menggunakan teknologi streaming canggih yang didukung oleh perangkat lunak <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "RASAKAN GAMING BERPERFORMA TINGGI YANG <br/>IMERSIF",
                    "tr_specText": "Mengutamakan performa terbaik Anda dengan game AAA terbaru pada resolusi dan refresh rate tinggi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "RASAKAN GAMING BERPERFORMA TINGGI YANG <br/>IMERSIF",
                    "tr_specText": "Mengutamakan performa terbaik Anda dengan game AAA terbaru pada resolusi dan refresh rate tinggi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "TINGKATKAN GAMING ANDA",
                    "tr_specText": "Bawa pengalaman gaming Anda ke tingkat berikutnya dengan: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "NIKMATI GAMING CANGGIH BERKECEPATAN TINGGI",
                    "tr_specText": "Nikmati gaming lancar pada berbagai game AAA populer serta gaming kecepatan refresh tinggi mulai dari eSport tembak-menembak yang sangat seru hingga game RTS alur cepat. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading. "
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "RASAKAN GAMING BERPERFORMA TINGGI YANG <br/>IMERSIF",
                    "tr_specText": "Mengutamakan performa terbaik Anda dengan game AAA terbaru pada resolusi dan refresh rate tinggi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>teknologi peningkatan skala Intel® yang didukung AI untuk performa lebih dan gaming yang lancar. <br/><br/>Dukungan <strong>DirectX* 12 Ultimate:  </strong>Bawa gaming Anda ke tingkat realisme yang baru dengan teknologi grafis, seperti ray tracing dan variable rate shading."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Dunia grafis dengan ruang untuk semua orang"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "AKSELERASI FRAME, PERCEPAT&nbsp;GAME",
                    "tr_specText": "Teknologi Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) membawa gaming ke tingkat berikutnya dengan peningkatan skala yang disempurnakan AI—untuk performa puncak dengan kejernihan gambar yang tinggi. X<sup class='small_text_sup_xe'>e</sup>SS dioptimalkan untuk produk grafis Intel® Arc™ sehingga Anda dapat memanfaatkan akselerasi perangkat keras Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) secara maksimal."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "VISUAL TERBAIK ADA DI SINI",
                    "tr_specText": "Dengan dukungan penuh untuk DirectX* 12 Ultimate pada semua grafis Intel® Arc™, Anda akan menikmati permainan game yang menakjubkan dan imersif berkat teknologi grafis terbaru seperti ray tracing, variable rate shading, mesh shading, dan sampler feedback—yang merupakan fondasi dari gaming generasi berikutnya."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "KREASI FOTO, VIDEO, DAN MEDIA YANG LANCAR",
                    "tr_specText": "Bebaskan imajinasi dan buat audiens terpukau dengan pembuatan konten digital yang kaya menggunakan mesin media sangat canggih—yang disempurnakan dengan AI dan diakselerasi dengan teknologi Intel®. Ciptakan konten spektakuler yang didukung oleh grafis dengan dukungan untuk semua format media terkemuka saat ini sekaligus tetap mengikuti perkembangan terbaru dengan kemampuan enkode video AV1 paling canggih."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "RASAKAN PENGALAMAN KREASI DAN GAMING AI YANG CANGGIH",
                    "tr_specText": "Grafis Intel® Arc™ membuka kemampuan AI yang canggih, mulai dari pembuatan konten tingkat lanjut hingga gaming yang imersif. Dapatkan hasil maksimal dari perangkat lunak kreasi canggih saat ini, termasuk pembuatan teks ke gambar dan pengeditan video yang diakselerasi AI. Selain itu, nikmati permainan game resolusi tinggi melalui peningkatan skala X<sup class='small_text_sup_xe'>e</sup>SS yang diakselerasi AI."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafis",
                    "tr_specText": "Grafis profesional dimulai di sini"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "IDEAL UNTUK KREATOR, DESAINER, DAN ENGINEER AKTIF",
                    "tr_specText": "Tersedia pada prosesor Intel® Core™ Ultra seri H tertentu, grafis Intel® Arc™ Pro bawaan memberikan peningkatan performa grafis 2x lipat dengan sertifikasi Vendor Perangkat Lunak Independen (ISV) untuk keandalan tambahan dan memberikan akselerasi perangkat keras AI serta ray tracing yang efisien untuk pembuatan konten yang menakjubkan di mana saja.<br/><br/><span class='expandable_heading'>Sertifikasi perangkat lunak<br/></span> <br/>Intel telah bekerja erat dengan ratusan perusahaan perangkat lunak selama bertahun-tahun, dan pengalaman yang tak tertandingi ini dihadirkan dalam kartu grafis Intel® Arc™ Pro seri A kami. Sertifikasi menjadi hal yang sama pentingnya bagi kami seperti halnya bagi Anda."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "MEMBUAT AI LEBIH DAPAT DIJANGKAU",
                    "tr_specText": "Rangkaian GPU ini memiliki banyak pilihan teknologi hebat, termasuk dukungan untuk alat AI yang kompatibel dengan akselerasi AI khusus bawaan saat Anda membutuhkannya."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "TEKNOLOGI RAY TRACING TANPA HARGA PREMIUM",
                    "tr_specText": "Kartu grafis perlu melakukan lebih banyak hal dari sekadar menampilkan piksel di layar, kartu grafis juga perlu mengakselerasi penciptaan gambar yang indah. Teknologi perangkat keras ray tracing bawaan dalam grafis Intel® Arc™ Pro seri A memungkinkan perangkat lunak Anda yang kompatibel untuk mulai menjelajahi alur kerja profesional baru ini. Ray tracing biasanya dianggap hanya tersedia dengan grafis yang mahal, tetapi rangkaian grafis profesional terbaru Intel memutarbalikkan anggapan itu."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "MENDUKUNG KREATOR DENGAN LAYAR YANG IMERSIF",
                    "tr_specText": "Dengan dukungan penuh untuk DirectX* 12 Ultimate pada semua grafis Intel® Arc™, Anda akan menikmati permainan game yang menakjubkan dan imersif berkat teknologi grafis terbaru seperti ray tracing, variable rate shading, mesh shading, dan sampler feedback—yang merupakan fondasi dari gaming generasi berikutnya."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "GAMBAR YANG DIOPTIMALKAN SECARA DINAMIS",
                    "tr_specText": "Dengan decoding perangkat keras khusus pada GPU Pro, Dolby Vision* membantu untuk menambahkan kedalaman yang lebih, kontras yang lebih tajam, dan lebih banyak warna ke layar Anda yang kompatibel. Dolby Vision* membuka potensi penuh teknologi HDR dengan secara dinamis mengoptimalkan kualitas gambar berdasarkan layanan Anda, perangkat layar, dan platform untuk menghadirkan visual yang mengagumkan setiap saat."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "PERANGKAT LUNAK YANG DIVERIFIKASI PARA AHLI",
                    "tr_specText": "Intel telah bekerja erat dengan ratusan perusahaan perangkat lunak selama beberapa tahun, dan pengalaman yang tidak tertandingi ini tercermin dalam rangkaian grafis Intel® Arc™ Pro seri A profesional kami. Sertifikasi dan kualifikasi alur kerja perangkat lunak sama pentingnya bagi kami seperti halnya bagi Anda."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "TEKNOLOGI RAY TRACING TANPA HARGA PREMIUM",
                    "tr_specText": "Kartu grafis perlu melakukan lebih banyak hal dari sekadar menampilkan piksel di layar, kartu grafis juga perlu mengakselerasi penciptaan gambar yang indah. Teknologi perangkat keras ray tracing bawaan dalam grafis Intel® Arc™ Pro seri A memungkinkan perangkat lunak Anda yang kompatibel untuk mulai menjelajahi alur kerja profesional baru ini. Ray tracing biasanya dianggap hanya tersedia dengan grafis yang mahal, tetapi rangkaian grafis profesional terbaru Intel memutarbalikkan anggapan itu."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "BEBERAPA LAYAR BESAR DENGAN SATU GPU MOBILE KECIL",
                    "tr_specText": "Grafis Intel® Arc™ Pro untuk laptop dapat mendukung hingga empat layar ultrabesar untuk meningkatkan efisiensi alur kerja Anda. Tambahkan dukungan High Dynamic Range (HDR) dan Anda akan miliki semua yang Anda perlukan untuk rumah yang layak ditampilkan di media sosial atau kantor dengan pengaturan beberapa layar."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENSI BANDWITH MEMORI PENUH",
                    "tr_specText": "Bandwidth kartu grafis Anda merupakan pertimbangan penting pada workstation. Jika salah, Anda dapat memperlambat alur kerja profesional, yang menjadi alasan kami memastikan GPU Intel® Arc™ Pro seri A memiliki dukungan untuk PCIe* 4.0 modern, tanpa mengorbankan kompatibilitas ke belakang. Memori grafis lebih lanjut ditingkatkan oleh kecepatan bandwidth tinggi, memungkinkan data proyek untuk diakses oleh perangkat lunak Anda dengan jauh lebih cepat."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "BERIKAN KEHIDUPAN KE SETIAP FRAME",
                    "tr_specText": "<span class='expandable_heading'>Ditingkatkan AI. Didukung Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS adalah teknologi peningkatan skala yang ditingkatkan AI yang meningkatkan performa Anda tanpa mengorbankan keindahan setiap frame—sehingga Anda dapat menikmati permainan game seperti yang seharusnya."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "REAL-TIME.<br/> SANGAT IMERSIF.<br/> REALISME TERBAIK.",
                    "tr_specText": "Dunia yang lebih realistis menunggu. Dengan ray tracing bawaan, Anda akan menikmati semuanya mulai dari bayangan dan pantulan yang nyata hingga pencahayaan yang hebat. Dengan kata lain, ini adalah pengalaman gaming visual yang tidak ingin Anda lewatkan."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "PERMAINAN TERBAIK ANDA, DIBAGIKAN DENGAN CARA TERBAIK",
                    "tr_specText": "Berikan audiens Anda permainan game yang lancar yang layak mereka lihat. Dengan kemampuan encoding AV1 yang diakselerasi perangkat keras grafis Intel® Arc™, mereka akan terpesona oleh setiap gerakan Anda. Kualitas visual yang lebih tinggi pada bitrate yang sama artinya Anda bisa memberikan streaming terbaik sekaligus menghemat bandwidth."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Menyambut semua kreator",
                    "tr_specText": "Pengalaman AI baru dan yang disempurnakan tersedia tepat di ujung jari Anda. Dengan akselerator AI tingkat lanjut yang tertanam di seluruh grafis Intel® Arc™ seri A, habiskan lebih banyak waktu untuk berkreasi dan lebih sedikit waktu menunggu di banyak aplikasi.<br/><br/>Bawa kreasi video Anda ke tingkat berikutnya dan pimpin dengan video AV1 berkualitas tinggi serta hemat penyimpanan, yang didukung oleh kemampuan perangkat keras AV1 tingkat lanjut yang terintegrasi dalam grafis Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Semua yang Anda perlukan untuk menyelesaikan pekerjaan",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Performa canggih yang tipis, ringan, dan dirancang untuk tahan lama — Anda dapat memiliki semuanya. Dengan masa pakai baterai yang luar biasa, layar sentuh yang indah*, dan Solid-State Drive (SSD) terbaru, Anda akan menemukan hal yang Anda cari di PC Windows* 10.",
                "tr_noteSection": "*Tergantung perangkat keras",
                "titleArray": [
                  {
                    "tr_title": "Lebih sederhana itu lebih baik",
                    "tr_subtitle": "PC saat ini biasanya menggunakan lebih sedikit sedikit daya, sehingga Anda mendapatkan masa pakai baterai yang lebih panjang — rata-rata lebih dari delapan jam pemutaran video.**",
                    "tr_noteSection": "**Masa pakai baterai bervariasi secara signifikan tergantung pengaturan, penggunaan, dan faktor lainnya."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "SEMUA YANG ANDA PERLUKAN UNTUK MENYELESAIKAN PEKERJAAN",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Performa canggih yang tipis, ringan, dan dirancang untuk tahan lama — Anda dapat memiliki semuanya. Dengan masa pakai baterai yang luar biasa, layar sentuh yang indah*, dan Solid-State Drive (SSD) terbaru, Anda akan menemukan hal yang Anda cari di PC Windows* 10.",
                "tr_noteSection": "*Tergantung perangkat keras",
                "titleArray": [
                  {
                    "tr_title": "LEBIH SEDERHANA ITU LEBIH BAIK",
                    "tr_subtitle": "PC saat ini biasanya menggunakan lebih sedikit sedikit daya, sehingga Anda mendapatkan masa pakai baterai yang lebih panjang — rata-rata lebih dari delapan jam pemutaran video.**",
                    "tr_noteSection": "**Masa pakai baterai bervariasi secara signifikan tergantung pengaturan, penggunaan, dan faktor lainnya."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Disederhanakan untuk keamanan dan performa",
                "tr_pretitle": "Windows* 10 dalam mode S",
                "tr_subtitle": "Saat Windows* 10 berjalan pada mode S, Anda mendapatkan lapisan perlindungan tambahan yang memungkinkan untuk bekerja, bermain, dan menjelajah dengan rasa percaya diri bahwa perangkat Anda aman."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DISEDERHANAKAN UNTUK KEAMANAN DAN PERFORMA",
                "tr_title": "Windows* 10 dalam mode S",
                "tr_subtitle": "Saat Windows* 10 berjalan pada mode S, Anda mendapatkan lapisan perlindungan tambahan yang memungkinkan untuk bekerja, bermain, dan menjelajah dengan rasa percaya diri bahwa perangkat Anda aman."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Lakukan hal-hal hebat",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Banyak pilihan performa, harga, dan lainnya. Mulai dari laptop 2-in-1 yang inovatif hingga laptop tipis dan ringan, layar sentuh, keyboard yang dapat dilepas — berapa pun anggaran atau apa pun gaya Anda, selalu ada PC Windows* yang sesuai untuk Anda."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "LAKUKAN HAL-HAL HEBAT",
                "tr_title": "Windows*",
                "tr_subtitle": "Banyak pilihan performa, harga, dan lainnya. Mulai dari laptop 2-in-1 yang inovatif hingga laptop tipis dan ringan, layar sentuh, keyboard yang dapat dilepas — berapa pun anggaran atau apa pun gaya Anda, selalu ada PC Windows* yang sesuai untuk Anda."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistem Operasi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memori",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "BERAPA BESAR RAM YANG ANDA PERLUKAN?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Harian - RAM 2 GB HINGGA 8 GB",
                    "tr_badgeSubTitle": "Ideal untuk pelanggan yang menggunakan PC mereka terutama untuk pengolahan kata dan penelusuran web, serta cenderung berfokus pada satu program dalam satu waktu."
                  },
                  {
                    "tr_badgeTitle": "Canggih - RAM 8 GB HINGGA 16 GB",
                    "tr_badgeSubTitle": "Dengan RAM lebih besar, aplikasi akan berjalan lebih cepat dan Anda juga dapat menjalankan beberapa aplikasi secara bersamaan."
                  },
                  {
                    "tr_badgeTitle": "Tingkat Lanjut - RAM LEBIH DARI 16&nbsp;GB",
                    "tr_badgeSubTitle": "Jika Anda tertarik pada gaming, pengeditan foto atau video, atau pemodelan 3D, Anda akan memerlukan RAM tambahan untuk menangani program intensif ini."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB untuk multitasking harian",
                "tr_subtitle": "Memori akan memungkinkan aplikasi dasar seperti pengolahan kata dan penelusuran web dimuat serta berjalan dengan lancar.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB untuk multitasking canggih",
                "tr_subtitle": "Jika Anda menggunakan beberapa program secara bersamaan, memori sebesar [memory_total] GB akan memungkinkan Anda untuk beralih di antara program tersebut dengan mudah.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB untuk multitasking tingkat lanjut",
                "tr_subtitle": "Anda akan memiliki banyak memori untuk menjalankan beberapa program intensif secara bersamaan tanpa memperlambat pengoperasian PC.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM</span><span class='value'>[XX] GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Memori [Value1] GB = Memori Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Kombinasi solusi memori ini akan memberikan performa hard drive yang diakselerasi dari memori Intel® Optane™ dan responsivitas lebih tinggi dari RAM Anda.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>MEMORI<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memori Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Waktu tunggu lebih&nbsp;singkat. Untuk lebih&nbsp;banyak hal yang Anda sukai.",
                "tr_subtitle": "Ketika memori Intel® Optane™ bekerja sama dengan memori sistem Anda, ini akan mempercepat akses ke program yang sering digunakan, sehingga program ini akan diluncurkan dan dimuat secara instan saat Anda membutuhkannya.",
                "tr_pretitle": "Memori Intel® Optane™ [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "APA YANG DILAKUKAN MEMORI UNTUK PC SAYA?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Pembuatan konten lebih cepat",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Bermain game lebih responsif",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Multitasking lebih mudah",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Membuka program dengan lebih cepat",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memori Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Percepat permainan game Anda</span>",
                "tr_pretitle": "Memori Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Memori Intel® Optane™ [XX] GB mempercepat gaming Anda",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Meluncurkan game hingga<br/><span class=\"optane-game\">98% lebih cepat</span><br/>Dari drive data dengan prosesor [optane_legal_processor_name] yang dilengkapi memori Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Memuat level hingga<br/><span class=\"optane-game\">4,1X lebih cepat</span><br/>Dari drive data dengan prosesor [optane_legal_processor_name] yang dilengkapi memori Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Memori Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Percepat permainan game Anda</span>",
                "tr_pretitle": "Memori Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Memori Intel® Optane™ [XX] GB mempercepat gaming Anda",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Meluncurkan game hingga<br/><span class=\"optane-game\">98% lebih cepat</span><br/>Dari drive data dengan prosesor [optane_legal_processor_name] yang dilengkapi memori Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Memuat level hingga<br/><span class=\"optane-game\">4,1X lebih cepat</span><br/>Dari drive data dengan prosesor [optane_legal_processor_name] yang dilengkapi memori Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Memori [Value1] GB = Memori Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Kombinasi solusi memori ini akan memberikan performa hard drive yang diakselerasi dari memori Intel® Optane™ dan responsivitas lebih tinggi dari RAM Anda.",
                "titleArray": [
                  {
                    "tr_title": "RAM [XX] GB untuk multitasking",
                    "tr_subtitle": "Jika Anda menggunakan beberapa program secara bersamaan, memori sebesar [memory_total] GB akan memungkinkan Anda untuk beralih di antara program tersebut dengan mudah."
                  },
                  {
                    "tr_title": "Untuk tugas <br/>sehari-hari",
                    "tr_subtitle": "Memori akan memungkinkan aplikasi dasar seperti pengolahan kata dan penelusuran web dimuat serta berjalan dengan lancar."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "RAM [XX] GB",
                "tr_pretitle": "UNTUK MULTITASKING LEBIH BANYAK",
                "tr_subtitle": "RAM memberi prosesor akses yang lebih mudah ke file program, sehingga CPU dapat mengaksesnya dengan lebih cepat dan mengerjakan beberapa tugas sekaligus.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memori standar",
                    "tr_badgeSubTitle": "RAM hingga 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Memori canggih",
                    "tr_badgeSubTitle": "RAM lebih dari 8 GB dan hingga 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Memori elite",
                    "tr_badgeSubTitle": "RAM lebih dari 16 GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB untuk multitasking lebih banyak",
                "tr_pretitle": "UNTUK MULTITASKING LEBIH BANYAK",
                "tr_subtitle": "RAM memberi prosesor akses yang lebih mudah ke file program, sehingga CPU dapat mengaksesnya dengan lebih cepat dan mengerjakan beberapa tugas sekaligus.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memori standar",
                    "tr_badgeSubTitle": "RAM hingga 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Memori canggih",
                    "tr_badgeSubTitle": "RAM lebih dari 8 GB dan hingga 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Memori elite",
                    "tr_badgeSubTitle": "RAM lebih dari 16 GB"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN MEMORI INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standar",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 8 GB</span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Tingkat Lanjut",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 16 GB</span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 32 GB</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memori",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "BAGAIMANA PERBANDINGAN MEMORI INI?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "BAGAIMANA PERBANDINGAN MEMORI INI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Perangkat <br/>saat ini",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Harian",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 8 GB</span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Canggih",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 16 GB</span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Tingkat Lanjut",
                      "tr_badgeSubTitle": "<span class='memory-value'><span class='ram_value'>RAM</span> 32 GB</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Penyimpanan",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "BERAPA KAPASITAS PENYIMPANAN PC ANDA?",
                "tr_subtitle": "Tidak perlu menebak. Kami akan menunjukkan apa saja yang dapat Anda simpan di PC ini.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Buat pustaka berisi</span><br/> <span class=\"storage-standard\">[num_songs] lagu<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>berdasarkan rata-rata 5 MB per lagu",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Buat pustaka berisi</span> <br/><span class=\"storage-standard\">[num_games] game<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>berdasarkan rata-rata 40 GB per game",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Penyimpanan untuk</span> <br/><span class=\"storage-standard\">[num_videos] video<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>berdasarkan rata-rata 5 GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Ambil dan simpan</span> <br/><span class=\"storage-standard\">[num_photos] foto<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>berdasarkan rata-rata 4 MB per foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Memori Intel® Optane™ [total_optane_memory] GB + SSD [convertedSSD]&nbsp;GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Penyimpanan SSD [capacity] GB",
                "tr_preTitle1": "Penyimpanan SSD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "PC ini menghadirkan SSD dengan penyimpanan [capacity] GB. Ini berarti Anda akan dapat menyimpan begitu banyak foto, video, dan program, serta mengaksesnya dengan jauh lebih cepat dibandingkan jika Anda menyimpannya di hard disk drive.",
                    "tr_title": "Tempat untuk segalanya di PC Anda"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "PC ini menghadirkan SSD dengan penyimpanan [capacity] TB. Ini berarti Anda akan dapat menyimpan begitu banyak foto, video, dan program, serta mengaksesnya dengan jauh lebih cepat dibandingkan jika Anda menyimpannya di hard disk drive.",
                    "tr_title": "Perangkat ini memiliki penyimpanan luar&nbsp;biasa"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] GB<br/></span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] TB<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Penyimpanan HDD [capacity] GB",
                "tr_preTitle1": "Penyimpanan HDD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Hard disk drive adalah pilihan tepat bagi orang yang menginginkan penyimpanan besar langsung di PC.",
                    "tr_title": "Tempat untuk segalanya di PC Anda"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Hard disk drive adalah pilihan tepat bagi orang yang menginginkan penyimpanan besar langsung di PC.",
                    "tr_title": "Perangkat ini memiliki penyimpanan luar&nbsp;biasa"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span><span class='value'>[capacity]<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "BERAPA KAPASITAS PENYIMPANAN PC ANDA?",
                "tr_subtitle": "Tidak perlu menebak. Kami akan menunjukkan apa saja yang dapat Anda simpan di PC ini.",
                "titleArray": [
                  {
                    "tr_title": "HDD [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "Intel® SSD [total_capacity] GB diakselerasi oleh memori Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Intel® SSD [total_capacity] TB diakselerasi oleh memori Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD]&nbsp;GB + HDD&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD]&nbsp;TB + HDD&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD]&nbsp;GB + HDD&nbsp;[convertedHDD]&nbsp;TB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD]&nbsp;TB + HDD&nbsp;[convertedHDD]&nbsp;TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Penyimpanan SSD [convertedSSD] GB + HDD [convertedHDD] GB",
                "tr_preTitle1": "Penyimpanan SSD [convertedSSD] TB + HDD [convertedHDD] GB",
                "tr_preTitle2": "Penyimpanan SSD [convertedSSD] GB + HDD [convertedHDD] TB ",
                "tr_preTitle3": "Penyimpanan SSD [convertedSSD] TB + HDD [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Tempat untuk segalanya di PC Anda",
                    "tr_title1": "Perangkat ini memiliki penyimpanan luar&nbsp;biasa",
                    "tr_subtitle": "PC ini dilengkapi dengan solid state drive dengan penyimpanan [convertedSSD] GB dan hard disk drive [convertedHDD] GB. Ini akan memberi Anda kapasitas yang sangat besar serta performa penyimpanan yang cepat.",
                    "tr_subtitle1": "PC ini dilengkapi dengan solid state drive dengan penyimpanan [convertedSSD] TB dan hard disk drive [convertedHDD] GB. Ini akan memberi Anda kapasitas yang sangat besar serta performa penyimpanan yang cepat.",
                    "tr_subtitle2": "PC ini dilengkapi dengan solid state drive dengan penyimpanan [convertedSSD] GB dan hard disk drive [convertedHDD] TB. Ini akan memberi Anda kapasitas yang sangat besar serta performa penyimpanan yang cepat.",
                    "tr_subtitle3": "PC ini dilengkapi dengan solid state drive dengan penyimpanan [convertedSSD] TB dan hard disk drive [convertedHDD] TB. Ini akan memberi Anda kapasitas yang sangat besar serta performa penyimpanan yang cepat."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] GB<br/></span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>PENYIMPANAN</span> <span class='value'>[capacity] TB<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Dapatkan akses cepat ke penyimpanan berkapasitas tinggi menggunakan memori Intel®&nbsp;Optane™ dengan&nbsp;SSD.",
                "tr_title": "Mendukung file berukuran besar",
                "tr_pretitle": "Intel® SSD [total_capacity] GB diakselerasi oleh memori Intel® Optane™ [optane_memory_size] GB",
                "tr_pretitle1": "Intel® SSD [total_capacity] TB diakselerasi oleh memori Intel® Optane™ [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Solusi penyimpanan revolusioner ini adalah yang pertama dalam menggabungkan memori Intel® Optane™ dan SSD pada satu chip. Berikut kemampuan yang dihasilkan untuk Anda:",
                    "tr_title": "APA ITU MEMORI INTEL® OPTANE™ DENGAN SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Membuka yang Anda perlukan dengan cepat",
                    "tr_badgeSubTitle": "Dengan melakukan pra-muat untuk file dan program yang sering digunakan, waktu tunggu menjadi lebih singkat dan Anda dapat menghabiskan lebih banyak waktu berkreasi.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Penyimpanan dan kecepatan",
                    "tr_badgeSubTitle": "Anda akan memiliki ruang penyimpanan yang diperlukan untuk file media dengan performa cepat SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Lebih responsif seiring waktu",
                    "tr_badgeSubTitle": "Memori Intel® Optane™ dengan SSD mempelajari cara Anda bekerja, sehingga menjadikan PC Anda lebih efisien.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PENYIMPANAN",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Simpan semua media di tempat yang mudah diakses dengan penyimpanan besar yang andal.",
                "tr_title": "Ruang untuk semua foto, video, dan lagu Anda"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Layar",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] juta piksel untuk menggeser dan mencubit layar, serta tampilan yang mengagumkan",
                "tr_subtitle": "Berinteraksi dengan PC Anda seperti smartphone dengan kontrol intuitif dan detail yang lebih jelas dan tajam.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType]<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolusi: [screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] juta"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Layar [screenDiagonal]&nbsp;inci",
                    "tr_badgeSubTitle": "Tinggi Layar: [screenHeight] inci",
                    "tr_badgePreTitle": "Lebar Layar: [YY] inci"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Tampilan layar sentuh",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Layar",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Hanyutkan diri Anda&nbsp;dalam [screenResolution]&nbsp;juta&nbsp;piksel",
                "tr_subtitle": "Semua tergantung piksel. Semakin banyak piksel, gambar akan semakin tajam dan terang.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType]<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolusi: [screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] juta"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Layar [screenDiagonal]&nbsp;inci",
                    "tr_badgeSubTitle": "Tinggi Layar: [screenHeight] inci",
                    "tr_badgePreTitle": "Lebar Layar: [YY] inci"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Layar",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] inci<br/>[screenResolutionX]x[screenResolutionY]",
                "tr_pretitle": "JENDELA MENUJU DUNIA GAMING ANDA",
                "tr_subtitle": "Seperti halnya TV, kekhawatiran utama mengenai layar game adalah ukuran dan resolusi.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolusi: [screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] juta"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Layar [screenDiagonal]&nbsp;inci",
                    "tr_badgeSubTitle": "Tinggi Layar: [screenHeight] inci",
                    "tr_badgePreTitle": "Lebar Layar: [screenWidth] inci"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Tampilan layar sentuh"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Layar",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Perangkat ini memiliki tampilan mengagumkan",
                "tr_subtitle": "Dengan jumlah piksel yang lebih dari 8x lipat dari HD, video dan game Anda akan berada di tingkat ketajaman dan warna yang benar-benar baru.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolusi: [screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] juta"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Layar [screenDiagonal]&nbsp;inci",
                    "tr_badgeSubTitle": "Tinggi Layar: [screenHeight] inci",
                    "tr_badgePreTitle": "Lebar Layar: [YY] inci"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Layar",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] juta piksel untuk diketuk, digeser, dan disentuh",
                    "tr_subTitle": "Lihat dan berinteraksi dengan hal yang Anda sukai dalam detail yang lebih jernih dan lebih tajam."
                  },
                  {
                    "tr_title": "[screenResolution] juta piksel untuk menyempurnakan dunia digital Anda",
                    "tr_subTitle": "Semua tergantung piksel. Semakin banyak piksel, gambar akan semakin tajam dan terang."
                  },
                  {
                    "tr_title": "Perangkat ini memiliki tampilan mengagumkan",
                    "tr_subTitle": "Dengan jumlah piksel hampir empat kali lipat dari HD, video dan game Anda berada di tingkat ketajaman dan warna yang benar-benar baru."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konektivitas",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Teknologi Thunderbolt™ 3 menghadirkan sambungan yang paling serbaguna dan tercepat ke perangkat data, layar, atau dock apa pun.",
                "tr_title": "Satu port untuk semuanya",
                "tr_title1": "SATU PORT UNTUK SEMUANYA",
                "titleArray": [
                  {
                    "tr_title": "BAGAIMANA SAYA DAPAT MENGGUNAKAN TEKNOLOGI THUNDERBOLT™ 3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Teknologi Thunderbolt™&nbsp;3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitor 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sambungkan ke layar dengan resolusi, kontras, dan warna mengagumkan"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Kartu grafis eksternal",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Langsung memberikan peningkatan besar pada grafis PC"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Jaringan cepat",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Menyediakan koneksi peer-to-peer yang cepat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konektivitas",
                "detailBGUrl": "",
                "tr_subtitle": "Teknologi Thunderbolt™ 3 mentransfer data pada kecepatan yang lebih tinggi daripada USB biasa, sehingga Anda dapat memindahkan file dengan cepat dan mengintegrasikan periferal seperti beberapa monitor atau kartu grafis eksternal dengan mudah.",
                "tr_title": "Teknologi Thunderbolt™&nbsp;3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DATA SUPER CEPAT",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Teknologi Thunderbolt™&nbsp;3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konektivitas",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Satu port dan kabel universal yang mendukung semua aksesori favorit Anda</li> <li>Pengisian daya notebook yang andal</li> <li>Sertifikasi lengkap memberikan ketenangan pikiran</li></ul>",
                "tr_title": "Konektivitas<br/>kabel universal<br/>yang sesungguhnya",
                "tr_title1": "KONEKTIVITAS KABEL UNIVERSAL YANG SESUNGGUHNYA",
                "tr_noteSection": "<sup>†</sup>Berdasarkan persyaratan spesifikasi minimum",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "BAGAIMANA CARA MENGGUNAKAN TEKNOLOGI THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Teknologi Thunderbolt™&nbsp;4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Berkreasi",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Hubungkan monitor eksternal dan penyimpanan cepat untuk melihat dan mengedit foto dan video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Buat konfigurasi meja yang sederhana dan rapi dengan menghubungkan laptop ke semua aksesori Anda menggunakan satu kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Bermain Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tambahkan kapasitas SSD dan HDD dengan kecepatan hingga 3.000 MB/detik untuk menyimpan koleksi game dan file media."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performa Maksimum<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x lebih cepat dibandingkan USB*&nbsp;3.2<br/>2x lebih cepat dibandingkan HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konektivitas",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Nikmati performa luar biasa yang sama dari teknologi Thunderbolt™ 3 bahkan dengan kemampuan yang lebih banyak yang memungkinkan Anda untuk terhubung dan menggunakan dock, monitor, penyimpanan cepat Thunderbolt™ terbaru, atau aksesori USB* apa pun dengan mudah.",
                "tr_title": "Teknologi Thunderbolt™&nbsp;4",
                "tr_pretitle": "KONEKTIVITAS KABEL UNIVERSAL YANG SESUNGGUHNYA",
                "tr_noteSection": "<sup>†</sup>Berdasarkan persyaratan spesifikasi minimum",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Satu kabel universal Thunderbolt™ 4 dapat menggantikan semua kabel USB-C* Anda, membuat pemilihan kabel menjadi cepat dan mudah."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Teknologi Thunderbolt™ 4 menawarkan hingga empat port Thunderbolt™ untuk fleksibilitas yang lebih baik dalam menghubungkan semua aksesori Anda."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performa Maksimum<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x lebih cepat dibandingkan USB*&nbsp;3.2<br/>2x lebih cepat dibandingkan HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konektivitas",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Satu port dan kabel universal yang mendukung semua aksesori favorit Anda</li> <li>Pengisian daya notebook yang andal</li> <li>Sertifikasi lengkap memberikan ketenangan pikiran</li></ul>",
                "tr_title": "Maksimalkan pengalaman Anda dengan aksesori Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Berdasarkan persyaratan spesifikasi minimum",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "BAGAIMANA CARA MENGGUNAKAN TEKNOLOGI THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Berkreasi",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Hubungkan monitor eksternal dan penyimpanan cepat untuk melihat dan mengedit foto dan video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Buat konfigurasi meja yang sederhana dan rapi dengan menghubungkan laptop ke semua aksesori Anda menggunakan satu kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Bermain Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tambahkan kapasitas SSD dan HDD dengan kecepatan hingga 3.000 MB/detik untuk menyimpan koleksi game dan file media."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performa Maksimum<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x lebih cepat dibandingkan USB*&nbsp;3.2<br/>2x lebih cepat dibandingkan HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Jenis Wi&#8209;Fi yang paling umum adalah:",
                "tr_title": "MEMAHAMI STANDAR WI&#8209;FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 memungkinkan kecepatan yang lebih tinggi bahkan ketika banyak pengguna menggunakan sinyal yang sama.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Dengan inovasi Wi-Fi terbaru dari Intel, Anda dapat mencapai kecepatan yang lebih tinggi dibandingkan Wi-Fi gigabit bahkan dengan beberapa pengguna pada sinyal yang sama.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Sebagai kemajuan Wi&#8209;Fi terbesar dalam 20 tahun, Wi&#8209;Fi 6E membuka saluran berkecepatan tinggi yang baru untuk performa dan keandalan yang lebih baik serta lebih sedikit gangguan."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 memungkinkan kecepatan yang lebih tinggi bahkan ketika banyak pengguna menggunakan sinyal yang sama.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Semua manfaat Wi-Fi 6E dipadukan dengan Intel® Killer™ Prioritization Engine yang mengalihkan bandwidth ke situs web dan aplikasi yang paling memerlukannya."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E",
                    "tr_badgeSubTitle": "Dengan lebih sedikit tantangan pada saluran kecepatan tinggi Anda, Anda akan menikmati performa dan keandalan yang meningkat."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 memungkinkan kecepatan yang lebih tinggi bahkan ketika banyak pengguna menggunakan sinyal yang sama.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Dengan lebih sedikit interferensi daripada Wi-Fi 6 dan performa cerdas dari Intel® Killer™ Prioritization Engine, pengalaman online Anda akan lebih cepat dan responsif.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Dengan lebih sedikit tantangan pada saluran kecepatan tinggi Anda, Anda akan menikmati performa dan keandalan yang meningkat."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Kecepatan Wi-Fi PC lebih tinggi dari 5 Gbps dengan latensi ultra-rendah yang konsisten - pembagian file dapat dilakukan dalam hitungan detik dibandingkan menit."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) adalah teknologi terkini yang memungkingkan keandalan, latensi rendah, dan kecepatan “Ekstrem” dengan optimasi jaringan berbasis AI."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 memungkinkan kecepatan yang lebih tinggi bahkan ketika banyak pengguna menggunakan sinyal yang sama.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Kecepatan Intel® Wi-Fi 6E (Gig+) lebih dari 1 Gbps, keandalan, dan latensi rendah dengan saluran 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Efisiensi, kapasitas, dan kecepatan yang lebih baik dibandingkan Wi-Fi 5 pada saluran yang padat."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Tetap terhubung dengan Wi&#8209;Fi [XX] serta nikmati internet yang lebih cepat<sup>†</sup> dan andal.",
                "tr_title": "Dapatkan jangkauan dan kecepatan yang lebih baik",
                "tr_noteSection": "<sup>†</sup>Throughput dan/atau jangkauan nirkabel sebenarnya dapat beragam.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Di era game multiplayer dan game online, untuk tetap tersambung kini jauh lebih penting. Selain kecepatan, Anda juga menginginkan sinyal nirkabel yang dapat mempertahankan kecepatan tersebut dari kejauhan.",
                "tr_title": "[XX]",
                "tr_pretitle": "SAMBUNGAN INTERNET ADALAH KUNCINYA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Terhubung dengan Intel® Wi&#8209;Fi 6 (Gig+) ke router Wi&#8209;Fi 6 dengan dukungan saluran 160 MHz untuk memungkinkan koneksi yang lebih cepat dan keandalan yang lebih baik bagi lebih banyak pengguna aktif.",
                "tr_title": "Kecepatan hampir 3x lebih tinggi dengan Intel® <span class='no-warp'>Wi&#8209;Fi&nbsp;6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dibandingkan Wi&#8209;Fi AC standar. Beberapa fitur hanya tersedia di SKU tertentu. Hubungi produsen untuk detailnya. Untuk informasi lebih lanjut tentang Wi&#8209;Fi, kunjungi intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 dual band tingkat pemula mendukung peningkatan performa untuk kebutuhan konektivitas dasar seperti email dan penelusuran internet pada saluran yang padat.<sup>9</sup>",
                "tr_title": "Wi-Fi Penting",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Di era game multiplayer dan game online, untuk tetap tersambung kini jauh lebih penting. Selain kecepatan, Anda juga menginginkan sinyal nirkabel yang dapat mempertahankan kecepatan tersebut dari kejauhan.",
                "tr_title": "[XX]",
                "tr_pretitle": "SAMBUNGAN INTERNET ADALAH KUNCINYA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Dengan memiliki saluran Wi&#8209;Fi baru ini, Anda tidak perlu berbagi bandwidth dengan perangkat yang lebih tua di lingkungan Anda, sehingga Anda dapat melakukan streaming dengan lancar, penelusuran cepat, serta pekerjaan dan pembelajaran jarak jauh tanpa hambatan.",
                "tr_title": "Terhubung tanpa berebut koneksi",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Tetap terhubung dengan [XX] dan nikmati internet dengan kecepatan lebih tinggi<sup>†</sup> serta lebih andal.",
                "tr_title": "Dapatkan jangkauan dan kecepatan yang lebih baik",
                "tr_noteSection": "<sup>†</sup>Throughput dan/atau jangkauan nirkabel sebenarnya dapat beragam.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Di era game multiplayer dan game online, untuk tetap tersambung kini jauh lebih penting. Selain kecepatan, Anda juga menginginkan sinyal nirkabel yang dapat mempertahankan kecepatan tersebut dari kejauhan.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "SAMBUNGAN INTERNET ADALAH KUNCINYA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi&#8209;Fi internal ultracepat",
                "tr_subtitle": "Tetap terhubung ke dunia online tanpa terhubung dengan&nbsp;kabel&nbsp;internet."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Dengan jalur berkecepatan tinggi khusus untuk mengurangi gangguan dan Intel® Killer™ Prioritization Engine yang memprioritaskan gaming, gaming online mendapatkan peningkatan besar.",
                "tr_title": "Tinggalkan pesaing di belakang",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Dengan jalur berkecepatan tinggi khusus untuk mengurangi gangguan dan Intel® Killer™ Prioritization Engine yang memprioritaskan gaming, gaming online mendapatkan peningkatan besar.",
                "tr_title": "[XX]",
                "tr_pretitle": "TINGGALKAN PESAING DI BELAKANG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Dengan jalur khusus berkecepatan tinggi untuk mengurangi gangguan yang mendahulukan gaming, gaming online mengalami peningkatan besar.",
                "tr_title": "[XX]",
                "tr_pretitle": "TINGGALKAN PESAING DI BELAKANG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi secara otomatis mempercepat lalu lintas jaringan untuk aplikasi yang paling memerlukannya sehingga video streaming Anda memiliki detail yang tajam dan lebih sedikit buffer.",
                "tr_title": "Cara lebih cepat dan pintar untuk terhubung.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Di era game multiplayer dan game online, untuk tetap tersambung kini jauh lebih penting. Selain kecepatan, Anda juga menginginkan sinyal nirkabel yang dapat mempertahankan kecepatan tersebut dari kejauhan.",
                "tr_title": "[XX]",
                "tr_pretitle": "SAMBUNGAN INTERNET ADALAH KUNCINYA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Tetap terhubung dengan Wi&#8209;Fi&nbsp;5 serta nikmati internet yang lebih cepat<sup>†</sup> dan andal.",
                "tr_title": "Dapatkan jangkauan dan kecepatan yang lebih baik",
                "tr_noteSection": "<sup>†</sup>Throughput dan/atau jangkauan nirkabel sebenarnya dapat beragam.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Di era game multiplayer dan game online, untuk tetap tersambung kini jauh lebih penting. Selain kecepatan, Anda juga menginginkan sinyal nirkabel yang dapat mempertahankan kecepatan tersebut dari kejauhan.",
                "tr_title": "[XX]",
                "tr_pretitle": "TINGGALKAN PESAING DI BELAKANG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fitur Wi-Fi 7 akan dibuat berdasarkan, dan meningkatkan, generasi Wi-Fi sebelumnya. Hal ini bukan hanya berarti kecepatan lebih tinggi saja, tetapi juga responsivitas dan keandalan yang meningkat secara drastis untuk pengalaman konsumen yang imersif serta teknologi masa depan yang canggih.",
                "tr_title": "Jaringan PC Layaknya Berkabel",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Fitur Wi-Fi 7 akan dibuat berdasarkan, dan meningkatkan, generasi Wi-Fi sebelumnya. Hal ini bukan hanya berarti kecepatan lebih tinggi saja, tetapi juga responsivitas dan keandalan yang meningkat secara drastis untuk pengalaman konsumen yang imersif serta teknologi masa depan yang canggih.",
                "tr_title": "[XX]",
                "tr_pretitle": "MENTRANSORMASI PENGALAMAN PENGGUNA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Kecepatan dan keandalan yang ditingkatkan serta penurunan latensi dengan optimasi jaringan berbasis AI.",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Kecepatan dan keandalan yang ditingkatkan serta penurunan latensi dengan optimasi jaringan berbasis AI.",
                "tr_title": "[XX]",
                "tr_pretitle": "RESPONSIVITAS LAYAKNYA BERKABEL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Cara pengukuran",
                    "tr_badgeSubTitle": "Kebanyakan produsen menunjukkan masa pakai baterainya dalam hitungan jam. Meskipun tidak 100% akurat, penghitungan tersebut dapat membantu Anda untuk membandingkan masa pakai baterai suatu PC dengan yang lainnya."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "UNTUK BERMAIN GAME DI MANA SAJA",
                "tr_subtitle": "Dengan kemampuan laptop gaming saat ini, tidak ada alasan untuk terikat ke meja. Sekarang game Anda dapat dibawa ke mana pun.",
                "tr_title": "[Dynamic.battery.values.hours] jam masa pakai baterai"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Lepas kabel daya dan tetap akses aplikasi, game, dan musik selama berjam-jam.",
                "tr_title": "[XX] jam streaming dengan satu kali pengisian daya<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Berdasarkan masa pakai baterai yang dinyatakan produsen PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] jam",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Lepas kabel daya dan tetap akses aplikasi, game, dan musik selama berjam-jam.",
                "tr_title": "[Dynamic.battery.values.hours] jam daya kapan&nbsp;saja di&nbsp;mana&nbsp;saja<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Berdasarkan masa pakai baterai yang dinyatakan produsen PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] jam",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Lepas kabel daya dan tetap akses aplikasi, game, dan musik selama berjam-jam.",
                "tr_title": "[Dynamic.battery.values.hours] jam untuk pemakaian seharian dengan satu kali pengisian daya<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Berdasarkan masa pakai baterai yang dinyatakan produsen PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] jam",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterai",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "PC ini mengoptimalkan masa pakai baterai agar bertahan lebih lama dan Anda dapat memakai laptop selama minimum 4 jam dengan mengisi daya selama 30 menit.<sup>4</sup>",
                "tr_title": "Penggunaan lebih lama tanpa charger dengan masa pakai baterai [Dynamic.battery.values.hours] jam<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Berdasarkan masa pakai baterai yang dinyatakan produsen PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] jam",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Beranda",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Mengapa PC Ini",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Lihat semua spesifikasi",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Jarak Jauh",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobile",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Hukum",
        "tr_paragraph_array": [
          "Perangkat lunak dan beban kerja yang digunakan dalam pengujian performa mungkin telah dioptimalkan hanya untuk performa pada mikroprosesor Intel®. Pengujian performa, seperti SYSmark* dan MobileMark*, diukur menggunakan sistem komputer, komponen, dan fungsi yang spesifik. Perubahan apa pun terhadap salah satu faktor tersebut dapat menyebabkan hasil yang beragam. Sebaiknya pelajari pengujian performa dan informasi lain untuk membantu Anda sepenuhnya mengevaluasi rencana pembelian, termasuk performa produk bila dipadukan dengan produk lain. Untuk informasi selengkapnya tentang hasil performa dan benchmark, kunjungi www.intel.com/benchmarks.",
          "¹Produk Intel® Wi-Fi 6 (Gig+) mendukung saluran 160 MHz opsional, memungkinkan kecepatan maksimum teoritis tercepat (2402 Mbps) untuk produk Wi-Fi PC 2x2 802.11ax standar. Produk Intel® Wi-Fi 6 (Gig+) premium memungkinkan kecepatan teoritis maksimum 2-4x lebih cepat dibandingkan dengan produk Wi-Fi PC 2x2 (1201 Mbps) atau 1x1 (600 Mbps) 802.11ax standar, yang hanya mendukung persyaratan wajib saluran 80 MHz.",
          "²Rekomendasi hanya untuk tujuan informasi. Lihat sumber lain saat membuat keputusan pembelian.",
          "³Fitur dan keunggulan teknologi Intel tergantung konfigurasi sistem dan mungkin memerlukan perangkat keras, perangkat lunak, atau aktivasi layanan yang mendukung. Performa dapat beragam, tergantung pada konfigurasi sistem. Tidak ada sistem komputer yang sepenuhnya aman. Hubungi produsen atau peritel sistem Anda, atau pelajari lebih lanjut di intel.com.",
          "⁴Untuk sistem dengan dengan layar FHD, ketika digunakan untuk browsing web secara nirkabel. Ketika dimatikan, dari tingkat pemadaman default OEM.",
          "⁵Dibandingkan dengan teknologi koneksi I/O PC lainnya termasuk eSATA, USB, dan IEEE 1394* FireWire. Performa dapat beragam bergantung pada perangkat keras dan perangkat lunak spesifik yang digunakan. Harus menggunakan perangkat yang mendukung teknologi Thunderbolt™.",
          "⁶Hampir 3x lebih cepat: 802.11ax 2x2 160 MHz memungkinkan kecepatan data teoretis maksimum 2402 Mbps. ~3x lebih cepat dibandingkan Wi&#8209;Fi 802.11ac 2x2 80 MHz (867 Mbps) seperti yang didokumentasikan dalam spesifikasi standar nirkabel IEEE 802.11* dan memerlukan penggunaan router jaringan nirkabel 802.11ax dengan konfigurasi serupa.",
          "⁷Latensi 75% lebih rendah: Berdasarkan data simulasi Intel (79%) dari 802.11ax dengan dan tanpa OFDMA dengan menggunakan 9 klien. Latensi rata-rata tanpa OFDMA adalah 36 ms, dengan latensi rata-rata OFDMA berkurang menjadi 7,6 ms. Peningkatan latensi mengharuskan router 802.11ax (Wi-Fi 6) dan semua klien mendukung OFDMA.",
          "⁸Berdasarkan perbandingan tingkat data maksimum teoretis dalam spesifikasi 802.11, 2x2 160 MHz Wi&#8209;Fi 6/6E (802.11ax) dapat menghasilkan 2402 Mbps vs. 2x2 80 MHz Wi&#8209;Fi 5 (802.11ac) dapat menghasilkan 867 Mbps yang setara dengan kecepatan 2,8X lebih tinggi.",
          "⁹Wi&#8209;Fi 6 Terbaik di Kelasnya: Produk Intel® Wi&#8209;Fi 6 (Gig+) mendukung saluran 160 MHz opsional, yang memungkinkan kecepatan maksimum teoretis tercepat (2402 Mbps) untuk produk Wi&#8209;Fi PC 2x2 802.11ax standar. Produk Intel® Wi&#8209;Fi 6 (Gig+) premium memungkinkan kecepatan teoretis maksimum 2-4x lebih cepat dibandingkan produk Wi&#8209;Fi PC 2x2 (1201 Mbps) atau 1x1 (600 Mbps) 802.11ax standar, yang hanya mendukung persyaratan wajib saluran 80 MHz.",
          "Hanya SKU tertentu, fungsi beragam berdasarkan desain OEM. Hubungi OEM atau peritel untuk detail. Untuk informasi lebih lanjut tentang Wi&#8209;Fi, kunjungi intel.com/wifi6disclaimers.",
          "¹⁰Teknologi Thunderbolt™ 4, dengan kecepatan 40 Gbps, menghadirkan solusi kabel paling cepat, paling andal, dan paling sederhana untuk dock, layar, atau perangkat data apa pun dibandingkan teknologi koneksi I/O PC lainnya, termasuk eSATA*, USB*, dan IEEE 1394* FireWire.",
          "¹¹Memerlukan router berbasis 802.11ax yang mendukung OFDMA dan beberapa klien di jaringan dengan dukungan untuk AX. Fitur OFDMA memungkinkan performa yang lebih baik di lingkungan padat dengan dukungan klien 802.11ax dan AP. 2 Gbps berdasarkan asumsi sekitar 70% kecepatan data teoretis maksimum spesifikasi IEEE 802.11* dari 802.11ax 160 MHz 2402 Mbps.",
          "¹²Sebagaimana diukur oleh Peluncuran Dokumen dengan Beban Kerja Aktivitas Latar Belakang yang membandingkan prosesor Intel® Core™ i7-8565U Generasi ke-8 (SSD TLC 512 GB) vs. prosesor Intel® Core™ i7-8565U Generasi ke-8 (32 GB + memori Intel® Optane™ H10 512 GB dengan Penyimpanan Solid State).",
          "¹³Intel® Thread Director dirancang dalam prosesor Intel® Core™ Generasi ke-12 dan membantu mendukung sistem operasi untuk menyalurkan beban kerja ke inti yang tepat dengan lebih cerdas. Tidak memerlukan tindakan dari pengguna. Kunjungi intel.com untuk detail selengkapnya.",
          "¹⁴Tidak tersedia pada prosesor Intel® Core™ Generasi ke-12 tertentu. Arsitektur hibrida performa tinggi menggabungkan dua mikroarsitektur inti baru, Performance-core (P-core) dan Efficient-core (E-core), pada satu die prosesor. Prosesor Intel® Core™ Generasi ke-12 tertentu (prosesor Intel® Core™ i5 Generasi ke-12 tertentu dan yang lebih rendah) tidak mendukung arsitektur hibrida performa tinggi, hanya P-core.",
          "¹⁵Sebagaimana diukur menggunakan Beban Kerja Peluncuran Game yang membandingkan prosesor Intel® Core™ i7-8750H Generasi ke-8 (modul memori Intel® Optane™ 32 GB + Intel® SSD PCIe* 256 GB + HDD 1 TB) dengan prosesor Intel® Core™ i7-8750H Generasi ke-8 (Intel® SSD PCIe* 256 GB + HDD 1 TB). Beban Kerja Peluncuran Game - Beban kerja yang dikembangkan oleh Intel untuk mengukur waktu yang diperlukan agar dapat mengaktifkan Total War*: WARHAMMER* II Build: 5577.0 dan membuka Menu Utama dengan video intro dinonaktifkan (Peluncuran). Konfigurasi Akselerasi Drive Data memori Intel® Optane™: Prosesor Intel® Core™ i7-8750H, TDP PL1=45 W, 6C12T, Turbo hingga 4 GHz pada Sistem OEM Praproduksi, Grafis: NVIDIA* GeForce* GTX 1070, Memori: 2 x DDR4 4 GB, Penyimpanan: Intel® SSD Seri 760p (PCIe*) 256 GB + HDD 1 TB + memori Intel® Optane™ 32 GB, OS: Windows* 10 RS3 Build 1709, MCU 0x84; prosesor Intel® Core™ i7-8750H, TDP PL1=45 W, 6C12T, Turbo hingga 4 GHz pada Sistem OEM Praproduksi, Grafis: NVIDIA* GeForce* GTX 1070, Memori: 2 x DDR4 4 GB, Penyimpanan: Intel® SSD Seri 760p (PCIe*) 256 GB + HDD 1 TB, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Sesuai pengukuran dengan Beban Kerja Pemuatan Level Game yang membandingkan prosesor Intel® Core™ i7-8750H Generasi ke-8 (modul memori Intel® Optane™ 32 GB) dengan prosesor Intel® Core™ i7-8750H Generasi ke-8 (Hanya HDD). Beban Kerja Pemuatan Level Game - Beban kerja yang dikembangkan oleh Intel untuk mengukur waktu yang diperlukan, mulai dari menu utama hingga pemuatan level selesai (Pemuatan Level) pada Total War*: WARHAMMER* II Build: 5577.0. Konfigurasi Akselerasi Drive Data memori Intel® Optane™: Prosesor Intel® Core™ i7-8750H, TDP PL1=45 W, 6C12T, Turbo hingga 4 GHz pada Sistem OEM Praproduksi, Grafis: NVIDIA* GeForce* GTX 1070, Memori: 2 x DDR4 4 GB, Penyimpanan: Intel® SSD Seri 760p (PCIe*) 256 GB + HDD 1 TB + memori Intel® Optane™ 32 GB, OS: Windows* 10 RS3 Build 1709, MCU 0x84; prosesor Intel® Core™ i7-8750H, TDP PL1=45 W, 6C12T, Turbo hingga 4 GHz pada Sistem OEM Praproduksi, Grafis: NVIDIA* GeForce* GTX 1070, Memori: 2 x DDR4 4 GB, Penyimpanan: Intel® SSD Seri 760p (PCIe*) 256 GB + HDD 1 TB, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Pada Performance-core. Performa beragam berdasarkan penggunaan, konfigurasi, dan faktor lainnya. Pelajari lebih lanjut di www.intel.com/PerformanceIndex.",
          "¹⁸Mengubah frekuensi atau tegangan clock dapat merusak atau mengurangi masa pakai prosesor dan komponen sistem lainnya, dan dapat mengurangi performa serta stabilitas sistem. Garansi produk mungkin tidak berlaku jika prosesor dioperasikan di luar spesifikasinya. Untuk detail tambahan, hubungi produsen sistem dan komponen.",
          "¹⁹Sebagaimana diukur dengan kombinasi tanpa tanding dari platform Intel vPro® di atas dan di bawah kemampuan keamanan OS, perlindungan data dan aplikasi, serta perlindungan ancaman tingkat lanjut, Intel vPro® mampu memenuhi tuntutan bisnis segala ukuran, begitu juga pendekatan keamanan prioritas dari Intel terhadap desain produk, produksi, dan dukungan. Semua PC bisnis yang dibangun di platform Intel vPro® telah divalidasi terhadap spesifikasi yang ketat, termasuk fitur keamanan berbasis perangkat keras yang unik. Lihat www.intel.com/PerformanceIndex (platform) untuk detailnya. Tidak ada produk atau komponen yang sepenuhnya aman.",
          "²⁰Intel® Connectivity Performance Suite hanya tersedia pada sistem berbasis Windows*. Pengujian Wi-Fi Over The Air (OTA) Intel pada jaringan yang padat versus tanpa prioritas lalu lintas nirkabel aplikasi perangkat lunak ICPS dan switching titik akses pintar. Lihat www.intel.com/PerformanceIndex (konektivitas) untuk detailnya. Hasil mungkin berbeda.",
          "²¹Sebagaimana dibandingkan dengan generasi prosesor sebelumnya.",
          "²²Grafis Intel® Arc™ adalah grafis pertama yang memiliki dukungan encoding AV1 di pasar (per Kuartal 1 2022). Detail dapat dilihat di intel.com/performanceindex.",
          "²³Sebagaimana diukur dengan fitur unik dari desain Intel® Evo™, yang juga diverifikasi untuk memenuhi batas pengalaman pengguna mobile. Pengujian per Februari 2022.",
          "²⁴Solusi Intel® Unison™ saat ini hanya tersedia pada desain Intel® Evo™ yang memenuhi syarat pada PC berbasis Windows* dan hanya dapat dipasangankan dengan ponsel berbasis Android atau iOS; semua perangkat harus menjalankan versi OS yang didukung.",
          "²⁵Kecepatan memori maksimum terkait dengan konfigurasi 1 DIMM per Saluran (1DPC). Muatan DIMM tambahan pada saluran apa pun dapat berdampak pada kecepatan memori maksimum. Hingga DDR5-5600 MT/detik 1DPC UDIMM 1Rx8, 1Rx16 dan DDR5-5200 1Rx8, 1Rx16, 2Rx8 pada SKU tertentu. Kapasitas memori maksimum dapat dicapai dengan konfigurasi 2DPC.",
          "²⁶Dibandingkan Wi-Fi standar. Lihat intel.com/performance-wireless untuk detailnya. Hasil mungkin berbeda.",
          "²⁷Arsitektur hibrida performa menggabungkan dua mikroarsitektur inti, yaitu Performance-core (P-core) dan Efficient-core (E-core), pada satu die prosesor pertama kali diperkenalkan pada prosesor Intel® Core™ Generasi ke-12. Prosesor Intel® Core™ Generasi ke-12 tertentu dan yang lebih baru tidak memiliki arsitektur hibrida performa, hanya P-cores, dan memiliki ukuran cache yang sama dengan generasi sebelumnya; lihat ark.intel.com untuk detail SKU.",
          "²⁸Intel® Thread Director yang tertanam dalam perangkat keras hanya tersedia dalam konfigurasi arsitektur hibrida performa prosesor Intel® Core™ Generasi ke-12 atau yang lebih baru; pengaktifan OS diperlukan. Fitur dan fungsi yang tersedia bervariasi berdasarkan OS.",
          "²⁹Intel® Thread Director yang tertanam dalam perangkat keras hanya hadir dalam konfigurasi arsitektur hibrida performa prosesor Intel® Core™ Generasi ke-12 dan ke-13; dan memerlukan pengaktifan OS. Fitur dan fungsi yang tersedia bervariasi berdasarkan OS.",
          "³⁰Sebagaimana diukur menurut performa platform validasi referensi untuk prosesor mobile Intel®&nbsp;Core™ Ultra (Seri 2 — Nama kode: Lunar Lake) dibandingkan dengan prosesor mobile Intel®&nbsp;Core™ Ultra (Seri 1 — Nama kode: Meteor Lake) pada 3DMark Time Spy. Lihat www.intel.com/performanceindex untuk detailnya. Hasil mungkin berbeda.",
          "³¹Latar belakang buram dan pelacakan wajah hanya tersedia di sistem berbasis Windows*.",
          "³²Produk Wi-Fi 7 dapat mengakses saluran 320 MHz pada 6 GHz dan kombinasi saluran 160 MHz baru pada 5 GHz dengan kemampuan Multi-Resource Unit Puncturing (Penembusan Unit Multi-Sumber daya) baru.",
          "³³Berdasarkan spesifikasi standar nirkabel IEEE dan kecepatan data teoritis maksimum untuk perangkat dengan 2 data stream.",
          "³⁴Simulasi rekayasa Intel untuk lingkungan jaringan padat mengindikasikan penurunan latensi besar mungkin dengan kemampuan Operasi Multi-Link Wi-Fi 7 baru.",
          "³⁵Batas daya minimum sebelum penonaktifan perangkat berdasarkan default OEM. Hasil masing-masing sistem mungkin berbeda. Kunjungi intel.com/performance-evo untuk detailnya.",
          "³⁶Band Wi-Fi 6 GHz tidak tersedia untuk digunakan di semua negara/wilayah.",
          "³⁷Khusus Windows*",
          "³⁸Tingkat daya sebelum penonaktifan perangkat berdasarkan default OEM. Untuk informasi selengkapnya tentang hasil performa dan benchmark, kunjungi intel.com/Evo.",
          "³⁹Mengacu pada prosesor mobile Intel®&nbsp;Core™ Ultra (Seri&nbsp;2), berdasarkan fitur keamanan berbasis perangkat keras yang unik, kombinasi kemampuan keamanan di atas dan di bawah OS yang tak tertandingi, performa yang mengagumkan bahkan dengan daya yang lebih rendah dibandingkan generasi sebelumnya (per September 2024). Lihat www.intel.com/performanceindex untuk detailnya. Hasil mungkin berbeda.",
          "⁴⁰Aplikasi perangkat lunak yang digambarkan hanya untuk tujuan ilustratif. Fitur AI mungkin memerlukan pembelian, langganan, atau pengaktifan oleh penyedia perangkat lunak atau platform, atau mungkin memiliki persyaratan konfigurasi atau kompatibilitas tertentu. Informasi detail dapat dilihat di www.intel.com/PerformanceIndex. Hasil mungkin berbeda. © Intel Corporation.",
          "⁴¹Gambar yang ditampilkan kemungkinan telah diubah atau disimulasikan. Fitur AI kiranya memerlukan pembelian, langganan, atau pengaktifan oleh penyedia perangkat lunak atau platform, atau kiranya memiliki persyaratan konfigurasi atau kompatibilitas tertentu. Detail di www.intel.com/AIPC.",
          "⁴²Diluncurkan secara bertahap dalam status pratinjau pada pembaruan terbaru ke Windows* 11 di pasar global tertentu. Waktu ketersediaan bervariasi berdasarkan perangkat dan pasar. Pelajari lebih lanjut di sini: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Solusi Intel® Unison™ saat ini tersedia untuk desain yang memenuhi syarat. Lihat www.intel.com/PerformanceIndex untuk detail.",
          "⁴⁴FPS yang di-upscale oleh Intel® X<sup>e</sup>SS vs. FPS Native sebagaimana diukur oleh Dying Light 2 Stay Human yang berjalan pada prosesor Intel® Core™ Ultra 7 165H. Lihat www.intel.com/PerformanceIndex untuk detail. Hasil mungkin berbeda.",
          "⁴⁵Sebagaimana diukur menurut daya SoC yang lebih rendah dari platform validasi referensi prosesor mobile Intel®&nbsp;Core™ Ultra (Seri 2 — Nama kode: Lunar Lake) dibandingkan dengan platform validasi referensi prosesor mobile Intel®&nbsp;Core™ Ultra (Seri 1 — Nama kode: Meteor Lake) pada YouTube* 4K 30 FPS AV1. Lihat www.intel.com/performanceindex untuk detailnya. Hasil mungkin berbeda.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel tidak bertanggung jawab atas kesalahan harga.",
          "*Nama dan merek lain adalah hak milik dari masing-masing pemiliknya.",
          "HAK CIPTA 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES, SERTA DESAIN TERKAIT DAN MEREK KOMPOSIT ADALAH MEREK DAGANG INFLEXION STUDIOS INC. HAK CIPTA DILINDUNGI UNDANG-UNDANG.",
          "Teknologi Intel mungkin memerlukan pengaktifan perangkat keras, perangkat lunak, atau aktivasi layanan yang mendukung.",
          "Hasil dan data dari NERO* disediakan sebagai kepraktisan dan hanya sebagai informasi. Intel Corporation tidak bertanggung jawab atas, sebagai contoh, akurasi aplikasi NERO*, hasil dan skor performa, atau potensi variasi performa dari versi lain aplikasi yang dapat diunduh di www.nero.com atau situs web pihak ketiga lainnya. Skor benchmark NERO* PC diukur menggunakan sistem, komponen, perangkat lunak, operasi, dan fungsi  komputer tertentu. Perubahan apa pun terhadap salah satu faktor tersebut dapat menyebabkan hasil yang beragam. Sebaiknya pelajari pengujian performa dan informasi lain untuk membantu Anda sepenuhnya mengevaluasi rencana pembelian, termasuk performa produk bila dipadukan dengan produk lain.",
          "Semua versi platform Intel vPro® membutuhkan prosesor Intel® Core™ yang memenuhi syarat, dukungan sistem operasi, silikon Intel® LAN dan/atau WLAN, peningkatan firmware, serta perangkat keras dan perangkat lunak lainnya yang diperlukan untuk menyediakan kasus penggunaan pengelolaan, fitur keamanan, performa sistem, dan stabilitas yang menentukan platform tersebut.",
          "Performa beragam berdasarkan penggunaan, konfigurasi, dan faktor lainnya. Pelajari lebih lanjut di www.Intel.com/PerformanceIndex.",
          "Intel tidak mengontrol atau mengaudit data pihak ketiga. Sebaiknya pertimbangkan sumber lainnya untuk mengevaluasi akurasi.",
          "© Intel Corporation. Intel, logo Intel, dan merek Intel lainnya adalah merek dagang dari Intel Corporation atau anak perusahaannya. *Nama dan merek lain adalah hak milik dari masing-masing pemiliknya.",
          "Tidak ada produk atau komponen yang sepenuhnya aman.",
          "Biaya dan hasil Anda mungkin berbeda.",
          "Intel, logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt, dan logo Thunderbolt adalah merek dagang dari Intel Corporation atau anak perusahaannya. Nama dan merek lain adalah hak milik dari masing-masing pemiliknya.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Beranda",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Pindai Untuk Membandingkan",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tren",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Nikmati kecanggihan AI terkini dengan prosesor Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Nikmati kecanggihan AI terkini dengan prosesor Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Nikmati kecanggihan AI terkini dengan prosesor Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Gambar dari teks",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Pengeditan foto",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Upscaling gambar",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Detektor Deepfake",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Efek Kolaborasi (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Kontrol Gestur",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Sinkronkan PC Anda dengan ponsel dan lihat notifikasi, panggilan, dan pesan teks",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Grafis internal yang kaya dan hidup ",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Jalani hidup, dengan cara Anda",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Semua yang penting bagi Anda. Semua dapat dilihat, dalam sekali usap",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Jalankan prosesor Anda lebih cepat pada frekuensi yang lebih tinggi dengan menyesuaikan setelan sistem",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Tingkatkan performa sistem desktop atau mobile Anda tanpa mengorbankan ruang penyimpanan",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Tingkatkan performa sistem desktop atau mobile Anda tanpa mengorbankan ruang penyimpanan",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: Nyaman di mata",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Kecepatan dan keandalan yang ditingkatkan serta penurunan latensi dengan optimasi jaringan berbasis AI.",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Jaringan PC Layaknya Berkabel",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Jaringan PC Layaknya Berkabel",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Kecepatan hampir 3x lebih tinggi dengan Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Terhubung tanpa berebut koneksi",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Konektivitas<br/>kabel universal<br/>yang sesungguhnya",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Bermainlah layaknya profesional: Lihat bagaimana Intel mendukung gaming dan gamer",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Bermainlah layaknya profesional: Performa gaming juara? Pastikan Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "SELAMAT DATANG",
    "tr_welcomePagetrSubtitle": "Kendalikan PC ini dari jarak jauh dengan smartphone Anda",
    "tr_welcomePagembSubtitle": "Selamat datang, jelajahi spesifikasi PC ini di smartphone Anda",
    "tr_welcomePageBtnText": "Mulai",
    "tr_welcomePageText": "Jelajahi dan bandingkan detail PC ini di&nbsp;smartphone&nbsp;Anda",
    "tr_scrollText": "Navigasikan perangkat di depan Anda",
    "tr_toolTipTextc1": "Gunakan tombol ini untuk beralih ke halaman yang berbeda pada perangkat di depan Anda.",
    "tr_toolTipTextc2": "Mengetuk kartu di bawah ini akan membawa Anda ke halaman terperinci dari setiap spesifikasi perangkat.",
    "tr_toolTipTextc3": "Gunakan tombol ini untuk menggerakkan ke atas dan bawah pada layar di hadapan Anda.",
    "tr_toolTipTextc4": "Matikan mode jarak jauh untuk melihat spesifikasi perangkat di smartphone Anda.",
    "tr_toolTipTextmb1": "Ketuk pada kartu untuk melihat halaman konten terperinci.",
    "tr_toolTipTextmb2": "Ketuk untuk melihat ringkasan semua spesifikasi perangkat",
    "tr_toolTipTextmb3": "Ketuk tombol untuk memindai kode QR dan membandingkan hingga 4 perangkat",
    "tr_nextButton": "Berikutnya",
    "tr_prevButton": "Sebelumnya",
    "tr_gotItButton": "OKE",
    "tr_skipButton": "Lewatkan",
    "tr_placeholderText": "*placeholder",
    "tr_processorHeading": "Prosesor",
    "tr_processorSubheading": "Prosesor Intel®&nbsp;Core™&nbsp;i7 Generasi&nbsp;ke&#8209;10",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Memori Intel® Optane™ [XX] GB + SSD [YY]&nbsp;GB",
    "tr_coachmarkProcessor": "Prosesor XX",
    "tr_coachmarkGraphics": "Grafis [XX]",
    "tr_coachmarkMemory": "Nilai spesifikasi",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$999,95",
    "tr_compare": "Bandingkan",
    "tr_viewallspecs": "LIHAT SEMUA SPESIFIKASI",
    "tr_operatingSystem": "SISTEM OPERASI",
    "tr_AmdProcessorNonIntel": "Prosesor AMD*",
    "tr_QualcommProcessorNonIntel": "Prosesor Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Spesifikasi ditampilkan di layar laptop",
    "tr_scanErrorText": "Anda hanya dapat memindai hingga 3 perangkat sekaligus. Hapus satu perangkat atau lebih.",
    "tr_scanErrorText2": "Anda telah memindai perangkat ini, pindai perangkat lainnya.",
    "tr_scanMsg": "Pindai untuk menambahkan perangkat",
    "tr_backToScan": "Kembali ke Bandingkan",
    "tr_scanToCompare": "Pindai untuk Membandingkan",
    "tr_compareDevice": "Bandingkan Perangkat",
    "tr_processorTitle": "PROSESOR",
    "tr_graphicTitle": "GRAFIS",
    "tr_storageTitle": "PENYIMPANAN",
    "tr_displayTitle": "LAYAR",
    "tr_batteryTitle": "BATERAI",
    "tr_memoryTitle": "MEMORI",
    "tr_wifiTitle": "Wi&#8209;Fi",
    "tr_connectivityTitle": "KONEKTIVITAS",
    "tr_priceTitle": "HARGA",
    "tr_operatingSystemTitle": "SISTEM OPERASI",
    "tr_batteryHoursText": "[XX] jam",
    "tr_hrsText": "jam",
    "tr_touchscreeenText": "Layar sentuh [screenDiagonal]″",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Layar sentuh",
    "tr_FHDTouchScreenText": "FHD, Layar sentuh",
    "tr_4KTouchscreenText": "4K, Layar sentuh",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Simpan",
    "tr_device_comparison": "Dokumen perbandingan perangkat [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Simpan sebagai JPEG",
    "tr_Save_As_PDF": "Simpan sebagai PDF",
    "tr_Downloading_PDF": "Mengunduh PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Buka",
    "tr_logo": "logo",
    "tr_laptop_name": "Nama laptop",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Memori Intel® Optane™",
    "tr_inchUnit": "inci",
    "tr_wifi1": "Wi&#8209;Fi 1",
    "tr_wifi2": "Wi&#8209;Fi 2",
    "tr_wifi3": "Wi&#8209;Fi 3",
    "tr_wifi4": "Wi&#8209;Fi 4",
    "tr_wifi5": "Wi&#8209;Fi 5",
    "tr_wifi6": "Intel® Wi&#8209;Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI&#8209;FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi&#8209;Fi 6E",
    "tr_wifiXX": "Wi&#8209;Fi [XX]",
    "tr_wifiXXCaps": "WI&#8209;FI [XX]",
    "tr_wifi6E": "Intel® Wi&#8209;Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MEMORI INTEL® OPTANE™",
    "tr_Thunderbolt3": "Teknologi Thunderbolt™&nbsp;3",
    "tr_Thunderbolt4": "Teknologi Thunderbolt™&nbsp;4",
    "tr_processorGraphicsCaps": "GRAFIS PROSESOR",
    "tr_processorGraphicsSmall": "Grafis Prosesor",
    "tr_graphicsCardCaps": "GRAFIS KHUSUS",
    "tr_graphicsCardSmall": "Grafis Khusus",
    "tr_processorTitleCamel": "Prosesor",
    "tr_graphicTitleCamel": "Grafis",
    "tr_storageTitleCamel": "Penyimpanan",
    "tr_displayTitleCamel": "Layar",
    "tr_batteryTitleCamel": "Baterai",
    "tr_memoryTitleCamel": "Memori",
    "tr_connectivityTitleCamel": "Konektivitas",
    "tr_priceTitleCamel": "Harga",
    "tr_operatingSystemTitleCamel": "Sistem Operasi",
    "tr_viewallspecsCamel": "Lihat semua spesifikasi",
    "tr_displayText": "[screenDiagonal]″",
    "tr_displayTextResolution": "[screenResolutionType] [screenDiagonal]″",
    "tr_displayTextResolutionTouchscreen": "Layar Sentuh [screenResolutionType] [screenDiagonal]″",
    "tr_OS": "SISTEM OPERASI",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Spesifikasi PC",
    "tr_explorePC_text": "Jelajahi semua tentang PC ini",
    "tr_mtlPC_text": "Lihat arti sebenarnya dari dirancang untuk segala tugas",
    "tr_optaneMemory": "Memori Intel®&nbsp;Optane™",
    "tr_displayText_Display": "LAYAR [screenDiagonal]″",
    "tr_displayTextResolution_Display": "LAYAR [screenResolutionType] [screenDiagonal]″ ",
    "tr_displayTextResolutionTouchscreen_Display": "LAYAR SENTUH [screenResolutionType] [screenDiagonal]″ ",
    "tr_displayTextTouchscreeenText": "LAYAR SENTUH [screenDiagonal]″ ",
    "tr_learnMoreLabel": "Pelajari Lebih Lanjut",
    "tr_batteryHoursTextUpperCase": "[XX] JAM",
    "tr_processorGraphicsIrisXe": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-11 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-11 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TEKNOLOGI THUNDERBOLT™&nbsp;3",
    "tr_Thunderbolt4UpperCase": "TEKNOLOGI THUNDERBOLT™&nbsp;4",
    "tr_processorWithArcGraphics": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-12 DAN GRAFIS INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-12 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-12 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-13 DAN GRAFIS INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-13 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROSESOR INTEL® CORE™ [cpu_fam] GENERASI KE-13 DAN GRAFIS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFIS INTEL® ARC™ A350M",
      "tr_A370M": "GRAFIS INTEL® ARC™ A370M",
      "tr_A730M": "GRAFIS INTEL® ARC™ A730M",
      "tr_A770M": "GRAFIS INTEL® ARC™ A770M",
      "tr_A310": "GRAFIS INTEL® ARC™ A310",
      "tr_A380": "GRAFIS INTEL® ARC™ A380",
      "tr_A580": "GRAFIS INTEL® ARC™ A580",
      "tr_A750": "GRAFIS INTEL® ARC™ A750",
      "tr_A770": "GRAFIS INTEL® ARC™ A770"
    },
    "tr_memoryValue": "RAM [XX] GB",
    "tr_memoryWithOptaneDesc": "Memori [Value1] GB = Memori Intel® Optane™ [Value2] GB + RAM [Value3] GB",
    "tr_textGBGB": "SSD [convertedSSD]&nbsp;GB + HDD&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD [convertedSSD]&nbsp;GB + HDD&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD [convertedSSD]&nbsp;TB + HDD&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD [convertedSSD]&nbsp;TB + HDD&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD [convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "SSD [convertedSSD] TB",
    "tr_textGBHDD": "HDD [convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "HDD [convertedHDD] TB",
    "tr_textStorageWithOptaneGB": "Memori Intel®&nbsp;Optane™ [XX]&nbsp;GB +&nbsp;SSD&nbsp;[convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Memori Intel®&nbsp;Optane™ [XX]&nbsp;GB +&nbsp;SSD&nbsp;[convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home dalam mode S",
      "tr_OS21": "Windows* 10 Pro dalam mode S",
      "tr_OS22": "Windows* 10 Enterprise dalam mode S",
      "tr_OS23": "Windows* 11 Home dalam mode S",
      "tr_OS24": "Windows* 11 Pro dalam mode S",
      "tr_OS25": "Windows* 11 Enterprise dalam mode S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafis Intel® Arc™ A310",
      "tr_GR387": "Grafis Intel® Arc™ A350M",
      "tr_GR388": "Grafis Intel® Arc™ A370M",
      "tr_GR389": "Grafis Intel® Arc™ A380",
      "tr_GR390": "Grafis Intel® Arc™ A530M",
      "tr_GR391": "Grafis Intel® Arc™ A550M",
      "tr_GR392": "Grafis Intel® Arc™ A570M",
      "tr_GR393": "Grafis Intel® Arc™ A580",
      "tr_GR394": "Grafis Intel® Arc™ A730M",
      "tr_GR395": "Grafis Intel® Arc™ A750",
      "tr_GR396": "Grafis Intel® Arc™ A770",
      "tr_GR397": "Grafis Intel® Arc™ A770M",
      "tr_GR398": "Grafis Intel® Arc™",
      "tr_GR399": "Grafis Intel® Arc™ Pro A30M",
      "tr_GR400": "Grafis Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Grafis Intel® Arc™ Pro A60",
      "tr_GR402": "Grafis Intel® Arc™ Pro A60M",
      "tr_GR403": "Grafis Intel®",
      "tr_GR404": "Grafis Intel® HD 2000",
      "tr_GR405": "Grafis Intel® HD 2500",
      "tr_GR406": "Grafis Intel® HD 3000",
      "tr_GR407": "Grafis Intel® HD 400",
      "tr_GR408": "Grafis Intel® HD 4000",
      "tr_GR409": "Grafis Intel® HD 405",
      "tr_GR410": "Grafis Intel® HD 4200",
      "tr_GR411": "Grafis Intel® HD 4400",
      "tr_GR412": "Grafis Intel® HD 4600",
      "tr_GR413": "Grafis Intel® HD 500",
      "tr_GR414": "Grafis Intel® HD 5000",
      "tr_GR415": "Grafis Intel® HD 505",
      "tr_GR416": "Grafis Intel® HD 510",
      "tr_GR417": "Grafis Intel® HD 515",
      "tr_GR418": "Grafis Intel® HD 520",
      "tr_GR419": "Grafis Intel® HD 530",
      "tr_GR420": "Grafis Intel® HD 5300",
      "tr_GR421": "Grafis Intel® HD 5500",
      "tr_GR422": "Grafis Intel® HD 5600",
      "tr_GR423": "Grafis Intel® HD 6000",
      "tr_GR424": "Grafis Intel® HD 610",
      "tr_GR425": "Grafis Intel® HD 615",
      "tr_GR426": "Grafis Intel® HD 620",
      "tr_GR427": "Grafis Intel® HD 630",
      "tr_GR428": "Grafis Intel® HD",
      "tr_GR429": "Grafis Intel® Iris® 5100",
      "tr_GR430": "Grafis Intel® Iris® 540",
      "tr_GR431": "Grafis Intel® Iris® 550",
      "tr_GR432": "Grafis Intel® Iris® 6100",
      "tr_GR433": "Grafis Intel® Iris® Plus 640",
      "tr_GR434": "Grafis Intel® Iris® Plus 645",
      "tr_GR435": "Grafis Intel® Iris® Plus 650",
      "tr_GR436": "Grafis Intel® Iris® Plus 655",
      "tr_GR437": "Grafis Intel® Iris® Plus",
      "tr_GR438": "Grafis Intel® Iris® Pro 5200",
      "tr_GR439": "Grafis Intel® Iris® Pro 580",
      "tr_GR440": "Grafis Intel® Iris® Pro 6200",
      "tr_GR441": "Grafis Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafis Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafis Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Grafis Terintegrasi Intel®",
      "tr_GR445": "Grafis Intel® UHD 600",
      "tr_GR446": "Grafis Intel® UHD 605",
      "tr_GR447": "Grafis Intel® UHD 610",
      "tr_GR448": "Grafis Intel® UHD 615",
      "tr_GR449": "Grafis Intel® UHD 617",
      "tr_GR450": "Grafis Intel® UHD 620",
      "tr_GR451": "Grafis Intel® UHD 630",
      "tr_GR452": "Grafis Intel® UHD 710",
      "tr_GR453": "Grafis Intel® UHD 730",
      "tr_GR454": "Grafis Intel® UHD 770",
      "tr_GR455": "Grafis Intel® UHD",
      "tr_GR456": "Grafis Intel® UHD",
      "tr_GR457": "Grafis Intel® UHD",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 dengan Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti dengan Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 dengan Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 dengan Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 dengan Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 dengan Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti dengan Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti dengan Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 dengan Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 dengan Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super dengan Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 dengan Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super dengan Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 dengan Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 dengan Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 dengan Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 dengan Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 dengan Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 dengan Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 dengan Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 dengan Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 dengan Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 dengan Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Grafis Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Beli Sekarang",
    "tr_textTryagain": "Coba lagi",
    "tr_textBuynowErr": "Karena gangguan koneksi, opsi Beli Sekarang tidak tersedia",
    "tr_chatbotPlaceholder_text": "Tanyakan apa pun tentang perangkat",
    "tr_deviceSpecs_text": "Spesifikasi Perangkat"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i3 dengan Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Prosesor Intel®&nbsp;Core™&nbsp;i5 dengan Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Pilih Intel® untuk Performa Gaming yang Lebih Hebat dan Tangguh",
                "tr_subtitle": "Hidupkan kekuatan Super Hero Marvel’s Avengers* dengan optimalisasi PC game yang didukung oleh Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Jelajahi Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Tempat gamer terbaik di dunia datang untuk bersaing",
                "tr_subtitle": "Seri acara terpanjang dalam sejarah video game, Intel® Extreme Masters menetapkan standar untuk eSport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Bermainlah layaknya profesional",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Performa juara? Pastikan Intel.",
                "tr_subtitle": "Setelah memenangkan kejuaraan Rainbow Six Siege* North American League 2021, Soniqs melihat kembali sejarah gaming mereka, masa depan eSport, dan kemitraan mereka dengan Intel.",
                "tr_pretitle": "Menampilkan Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Mendukung VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Saat VR menjadi nyata",
                "tr_subtitle": "Pengalaman virtual reality yang paling luar biasa, hidup, dan imersif, yang berjalan di komputer. Dengan prosesor Intel® Core™ terbaru, Anda dapat menjadi siapa pun, pergi ke mana pun, dan menikmati permainan game dalam dimensi tingkat lanjut.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Mainkan 100+ game console berkualitas tinggi seperti Sea of Thieves* dan Psychonauts* 2 di PC Windows*, ponsel, dan tablet Anda dengan Xbox* Game Pass Ultimate dan kontroler yang kompatibel.",
                "titleArray": [
                  {
                    "tr_title": "Bermain bersama lintas perangkat",
                    "tr_subtitle": "Rasakan sensasi utama Xbox* dengan komunitas jutaan pemain yang siap dan menunggu untuk bermain bersama. Terhubung dan bermain dengan yang lain dari pustaka game bersama, baik mereka berada di sisi dunia yang lain atau duduk tepat di sebelah Anda."
                  },
                  {
                    "tr_title": "Gaming PC Windows*",
                    "tr_subtitle": "Mainkan katalog game konsol dari cloud menggunakan aplikasi Xbox* untuk Windows* dan kontroler yang kompatibel."
                  },
                  {
                    "tr_title": "Pilih dan mainkan",
                    "tr_subtitle": "Nikmati game Xbox* yang hebat di lebih banyak tempat. Mainkan 100+ game di PC, ponsel, dan tablet Anda baik ketika Anda berada di rumah sementara TV Anda sedang digunakan, di kafe, atau di mana pun dengan koneksi internet berkecepatan tinggi."
                  },
                  {
                    "tr_title": "Keseruan tanpa kontroler",
                    "tr_subtitle": "Gaming kontrol sentuh Xbox* membuka cara baru untuk bergabung dan bermain yang menghadirkan pengalaman familiar dan mendukung tingkat permainan seperti dengan kontroler fisik."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sorotan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ kecanggihan AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Lebih banyak kontrol dengan lebih sedikit sentuhan",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Tetap dalam bingkai di lingkungan apa pun",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitas",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Koreksi pencahayaan layaknya profesional",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Tambahkan efek yang menakjubkan dengan mudah",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remix lagu dengan kecanggihan AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitas",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tingkatkan produktivitas dan privasi Anda",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Nikmati lebih banyak waktu tanpa harus selalu terhubung ke charger",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiburan",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming lancar meskipun tidak terhubung ke charger",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Gaming yang cepat dan imersif",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sorotan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ kecanggihan AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Buat lagu favorit jadi benar-benar milik Anda",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Lihat visi Anda secara real time",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitas",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cara tercepat untuk menyelesaikan editan video Anda",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Sempurnakan setiap foto",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitas",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tingkatkan produktivitas dan privasi Anda",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Lebih efisien. Lebih bebas.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiburan",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming HD lancar meskipun saat tidak terhubung ke charger",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Gaming imersif di lebih banyak tempat",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Lebih Kreatif",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pengeditan foto jadi mudah",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Buat foto yang layak untuk dibingkai dalam hitungan detik",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Anda mengetiknya, AI&nbsp;membuatnya.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Performa",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rahasia untuk gaming yang lebih lancar",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Nikmati lebih banyak waktu tanpa harus selalu terhubung ke charger",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-imersif",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Produktif",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pendamping Anda untuk berbagai kebutuhan AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Panggilan video yang sempurna",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Aman",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Perlindungan bawaan untuk membantu menjaga data Anda lebih aman dan pribadi",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Lebih Kreatif",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pengeditan foto jadi mudah",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Proses pengeditan video jadi makin cepat.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Salin lirik lagu favorit Anda dengan cepat",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Hemat waktu pengeditan berjam-jam dengan AI",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Performa",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rahasia untuk gaming yang lebih lancar",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Nikmati lebih banyak waktu tanpa harus selalu terhubung ke charger",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-imersif",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Produktif",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pendamping Anda untuk berbagai kebutuhan AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Panggilan video yang sempurna",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih Aman",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Perlindungan bawaan untuk membantu menjaga data Anda lebih aman dan pribadi",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Semua hiburan Anda. Satu&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Untuk PC yang berfungsi ganda sebagai pusat hiburan, Anda perlu penyimpanan yang cukup untuk semua lagu, video, dan game favorit, serta kombinasi prosesor dan layar yang tepat untuk menciptakan visual yang imersif. PC ini memiliki ketiganya.",
                "titleArray": [
                  {
                    "tr_title": "Siap untuk visual generasi berikutnya",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming dan streaming tanpa hambatan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih produktif. Di&nbsp;mana&nbsp;pun.",
              "tr_tileTitle1": "Selesaikan lebih banyak hal. Dengan&nbsp;cepat.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dibuat untuk desain tipis dan ringan, PC ini juga dilengkapi fitur Wi&#8209;Fi agar Anda selalu terhubung dan baterai yang tepat agar Anda tidak perlu terhubung dengan catu daya.",
                "tr_subtitle1": "PC ini memiliki keseimbangan kekuatan dan performa yang sempurna untuk membantu Anda menyelesaikan lebih banyak hal dengan lebih cepat. Kombinasi prosesor canggih yang diakselerasi oleh memori yang mumpuni dan Wi-Fi secepat kilat akan memperlancar tugas harian Anda.",
                "tr_subtitle2": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dirancang untuk desain tipis dan ringan, serta fitur Wi&#8209;Fi yang memungkinkan Anda untuk selalu terhubung, dan memori yang tepat untuk multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Diciptakan untuk melakukan lebih banyak hal",
                    "tr_title1": "Performa cerdas yang&nbsp;penting",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Koneksi terbaik di kelasnya",
                    "tr_title1": "Terhubung ke hal yang penting",
                    "tr_title2": "Kemajuan besar dalam Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih lama",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Beralih antar program layaknya seorang profesional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kebebasan berkreasi",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pembuatan konten adalah salah satu tugas yang paling menuntut untuk PC Anda. Untungnya, komponen berikut dapat melancarkan proses kreatif&nbsp;Anda.",
                "titleArray": [
                  {
                    "tr_title": "Cara lebih pintar untuk berkreasi",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hidupkan gambar Anda",
                    "tr_title1": "Mari Berkreasi",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memori untuk multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lihat ke dalam PC ini",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ini adalah tiga komponen utama yang perlu diperhatikan saat memilih PC baru. Dengan mengetahui bagaimana Anda akan menggunakan PC baru, Anda akan dapat menentukan seberapa besar kapasitas yang diperlukan dari ketiga komponen utama ini. Komponen-komponen tersebut adalah:",
                "titleArray": [
                  {
                    "tr_title": "Performa untuk Gaming dan Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Penyimpanan",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memori",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Semua hiburan Anda. Satu&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Untuk PC yang berfungsi ganda sebagai pusat hiburan, Anda perlu penyimpanan yang cukup untuk semua lagu, video, dan game favorit, serta kombinasi prosesor dan layar yang tepat untuk menciptakan visual yang imersif. PC ini memiliki ketiganya.",
                "titleArray": [
                  {
                    "tr_title": "Siap untuk visual generasi berikutnya",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming dan streaming tanpa hambatan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memori",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Layar",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitasking berkecepatan tinggi",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING BERKECEPATAN TINGGI",
                "tr_subtitle": "Lakukan lebih banyak hal tanpa jadi lambat",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menjalankan beberapa program sekaligus."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Memori yang dengan lancar beralih antar aplikasi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obrolan video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "OBROLAN VIDEO",
                "tr_subtitle": "Koneksi yang sangat&nbsp;jernih",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk obrolan video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat merekam dan membagikan gambar di saat yang bersamaan."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Webcam yang akan membantu Anda terlihat jelas di layar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mendukung VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Saat VR menjadi nyata",
                "tr_subtitle": "Apa saja yang diperlukan untuk menciptakan dunia yang benar&#8209;benar virtual? Anda memerlukan koordinasi antara prosesor, kartu&nbsp;grafis, dan memori PC.",
                "titleArray": [
                  {
                    "tr_title": "Mendukung dunia virtual",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Detail yang nyata",
                    "tr_title1": "Nikmati pengalaman imersif",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Pengeditan video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENGEDITAN VIDEO",
                "tr_subtitle": "Buat keajaiban film",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pengeditan video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menangani file besar."
                  },
                  {
                    "tr_title": "Memori yang membuat perangkat lunak pengeditan jadi cepat dan responsif."
                  },
                  {
                    "tr_title": "Grafis yang akan memangkas waktu rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitasking berkecepatan tinggi",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING BERKECEPATAN TINGGI",
                "tr_subtitle": "Lakukan lebih banyak hal tanpa jadi lambat",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menjalankan beberapa program sekaligus."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Memori yang dengan lancar beralih antar aplikasi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mendukung VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Saat VR menjadi nyata",
                "tr_subtitle": "Apa saja yang diperlukan untuk menciptakan dunia yang benar&#8209;benar virtual? Anda memerlukan koordinasi antara prosesor, kartu&nbsp;grafis, dan memori PC.",
                "titleArray": [
                  {
                    "tr_title": "Mendukung dunia virtual",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Detail yang nyata",
                    "tr_title1": "Nikmati pengalaman imersif",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Pemodelan 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PEMODELAN 3D",
                "tr_subtitle": "Kreasi tingkat&nbsp;lanjut",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pemodelan 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menangani perangkat lunak kreatif tingkat lanjut."
                  },
                  {
                    "tr_title": "Memori yang dapat dengan cepat membuka file besar."
                  },
                  {
                    "tr_title": "Grafis untuk pembuatan dan pengeditan gambar yang canggih."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kolaborasi real-time",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "KOLABORASI REAL-TIME",
                "tr_subtitle": "Bekerja dari mana saja",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk kolaborasi online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Prosesor untuk mendukung perangkat lunak kantor."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang dapat diandalkan."
                  },
                  {
                    "tr_title": "Webcam yang merekam dalam HD yang tajam."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mendukung VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Saat VR menjadi nyata",
                "tr_subtitle": "Apa saja yang diperlukan untuk menciptakan dunia yang benar&#8209;benar virtual? Anda memerlukan koordinasi antara prosesor, kartu&nbsp;grafis, dan memori PC.",
                "titleArray": [
                  {
                    "tr_title": "Mendukung dunia virtual",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Detail yang nyata",
                    "tr_title1": "Nikmati pengalaman imersif",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptop setipis dan seringan ini seharusnya tidak secanggih ini",
                "tr_subtitle": "Dirancang untuk performa mobile, laptop Intel® Evo™ menghadirkan kecepatan, grafis, dan masa pakai baterai yang Anda butuhkan. Semuanya dalam desain tipis dan ringan yang menawan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Selesaikan banyak hal dengan cepat",
                    "tr_subtitle": "Nikmati performa ultra responsif dari prosesor Intel® Core™ Generasi ke-11."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Semua yang Anda perlukan dan banyak lagi dalam laptop tipis dan ringan.",
                "tr_subtitle": "Laptop Intel® Evo™ dirancang untuk performa mobile serta memberikan pengalaman terbaik<sup>2</sup>, apa pun yang Anda lakukan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Performa revolusioner",
                    "tr_subtitle": "Dapatkan kekuatan ekstra tepat saat Anda butuhkan dengan performa cerdas dari prosesor Intel® Core™ Generasi ke-12."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptop setipis dan seringan ini seharusnya tidak secanggih ini",
                "tr_subtitle": "Dirancang untuk performa mobile, laptop Intel® Evo™ menghadirkan kecepatan, grafis, dan masa pakai baterai yang Anda butuhkan. Semuanya dalam desain tipis dan ringan yang menawan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Selesaikan banyak hal dengan cepat",
                    "tr_subtitle": "Nikmati performa ultra responsif dari prosesor Intel® Core™ Generasi ke-11."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Semua yang Anda perlukan dan banyak lagi dalam laptop tipis dan ringan.",
                "tr_subtitle": "Laptop Intel® Evo™ dirancang untuk performa mobile serta memberikan pengalaman terbaik<sup>2</sup>, apa pun yang Anda lakukan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Performa revolusioner",
                    "tr_subtitle": "Dapatkan kekuatan ekstra tepat saat Anda butuhkan dengan performa cerdas dari prosesor Intel® Core™ Generasi ke-12."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Semua yang Anda perlukan dan banyak lagi dalam laptop tipis dan ringan.",
                "tr_subtitle": "Laptop Intel® Evo™ dirancang untuk performa mobile serta memberikan pengalaman terbaik<sup>2</sup>, apa pun yang Anda lakukan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Performa revolusioner",
                    "tr_subtitle": "Dapatkan kekuatan ekstra tepat saat Anda butuhkan dengan performa cerdas dari prosesor Intel® Core™ Generasi ke-13."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Semua yang Anda perlukan dan banyak lagi dalam laptop tipis dan ringan.",
                "tr_subtitle": "Laptop Intel® Evo™ dirancang untuk performa mobile serta memberikan pengalaman terbaik<sup>2</sup>, apa pun yang Anda lakukan.",
                "titleArray": [
                  {
                    "tr_title": "Laptop semakin berarti, jadi kami memastikannya dapat melakukan lebih banyak lagi.",
                    "tr_subtitle": "Gunakan layar sentuh Anda untuk menjelajah, memutar, dan memperbesar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Aktif dalam sekejap",
                    "tr_subtitle": "Dengan pengaktifan instan, PC Anda dapat beralih dari mode tidur menjadi&nbsp;siap digunakan dalam waktu kurang dari satu detik."
                  },
                  {
                    "tr_title": "Tontonan imersif",
                    "tr_subtitle": "Tampilan yang indah menghidupkan visual Anda dengan resolusi yang tajam dan warna&nbsp;yang&nbsp;hidup."
                  },
                  {
                    "tr_title": "Performa revolusioner",
                    "tr_subtitle": "Dapatkan kekuatan ekstra tepat saat Anda butuhkan dengan performa cerdas dari prosesor Intel® Core™ Generasi ke-13."
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih&nbsp;lama",
                    "tr_subtitle": "Tetap produktif dengan PC yang mengoptimalkan masa pakai baterai dan&nbsp;mengisi ulang daya dalam sekejap."
                  },
                  {
                    "tr_title": "Konektivitas kabel universal",
                    "tr_subtitle": "Menghubungkan perangkat lain ke PC Anda menjadi mudah, cepat, dan aman dengan teknologi Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Koneksi cepat dan andal",
                    "tr_subtitle": "Obrolan video atau berbagi file dengan koneksi yang dapat Anda andalkan menggunakan Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sorotan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Apa itu Edisi Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Mengapa Edisi Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitas",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jadi lebih kreatif di mana saja",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Pengeditan foto berkecepatan penuh",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Buat seni orisinal secara instan",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitas",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimalkan produktivitas dengan Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Deteksi deepfake dengan AI sebelum Anda berbagi",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitas",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lambaikan tangan ke kontrol gestur",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Masa pakai baterai yang tidak akan menghambat Anda",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Secara otomatis menjaga Anda tetap terhubung",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Sorotan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Apa itu Edisi Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Mengapa Edisi Intel® Evo™?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitas",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Pemutaran video yang cepat dan lancar",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Hasilkan hit dengan kekuatan AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Cara tercepat untuk menyelesaikan editan video Anda",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitas",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimalkan produktivitas dengan Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Deteksi deepfake dengan AI sebelum Anda berbagi",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Dunia Anda. Di&nbsp;perangkat&nbsp;Anda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitas",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Berkolaborasi secara virtual dari mana saja",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Masa pakai baterai yang tidak akan menghambat Anda",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Secara otomatis menjaga Anda tetap terhubung",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Semua hiburan Anda. Satu&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Untuk PC yang berfungsi ganda sebagai pusat hiburan, Anda perlu penyimpanan yang cukup untuk semua lagu, video, dan game favorit, serta kombinasi prosesor dan layar yang tepat untuk menciptakan visual yang imersif. PC ini memiliki ketiganya.",
                "titleArray": [
                  {
                    "tr_title": "Gaming dan streaming yang mengesankan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Gaming dan streaming tanpa hambatan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Buat pustaka digital Anda",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Jendela menuju hiburan Anda",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih produktif. Di&nbsp;mana&nbsp;pun.",
              "tr_tileTitle1": "Selesaikan lebih banyak hal. Dengan&nbsp;cepat.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dibuat untuk desain tipis dan ringan, PC ini juga dilengkapi fitur Wi&#8209;Fi agar Anda selalu terhubung dan baterai yang tepat agar Anda tidak perlu terhubung dengan catu daya.",
                "tr_subtitle1": "PC ini memiliki keseimbangan kekuatan dan performa yang sempurna untuk membantu Anda menyelesaikan lebih banyak hal dengan lebih cepat. Kombinasi prosesor canggih yang diakselerasi oleh memori yang mumpuni dan Wi-Fi secepat kilat akan memperlancar tugas harian Anda.",
                "tr_subtitle2": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dirancang untuk desain tipis dan ringan, serta fitur Wi&#8209;Fi yang memungkinkan Anda untuk selalu terhubung, dan memori yang tepat untuk multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Performa cerdas yang&nbsp;penting",
                    "tr_title1": "Lebih cepat dengan performa cerdas",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Koneksi terbaik di kelasnya",
                    "tr_title1": "Terhubung ke hal yang penting",
                    "tr_title2": "Kemajuan besar dalam Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih lama",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Beralih antar program layaknya seorang profesional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kebebasan berkreasi",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pembuatan konten adalah salah satu tugas yang paling menuntut untuk PC Anda. Untungnya, komponen berikut dapat melancarkan proses kreatif&nbsp;Anda.",
                "titleArray": [
                  {
                    "tr_title": "Kreativitas yang dipercepat",
                    "tr_title1": "Kerjakan proyek kreatif dengan&nbsp;mudah",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Pembuatan konten yang disempurnakan",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memori untuk multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lihat ke dalam PC ini",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ini adalah tiga komponen utama yang perlu diperhatikan saat memilih PC baru. Dengan mengetahui bagaimana Anda akan menggunakan PC baru, Anda akan dapat menentukan seberapa besar kapasitas yang diperlukan dari ketiga komponen utama ini. Komponen-komponen tersebut adalah:",
                "titleArray": [
                  {
                    "tr_title": "Prosesor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Penyimpanan",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memori",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Kebebasan berkreasi",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pembuatan konten adalah salah satu tugas yang paling menuntut untuk PC Anda. Untungnya, komponen berikut dapat melancarkan proses kreatif&nbsp;Anda.",
                "titleArray": [
                  {
                    "tr_title": "Cara lebih pintar untuk berkreasi",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Hidupkan gambar Anda",
                    "tr_title1": "Mari Berkreasi",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memori untuk multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lebih produktif. Di&nbsp;mana&nbsp;pun.",
              "tr_tileTitle1": "Selesaikan lebih banyak hal. Dengan&nbsp;cepat.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dibuat untuk desain tipis dan ringan, PC ini juga dilengkapi fitur Wi&#8209;Fi agar Anda selalu terhubung dan baterai yang tepat agar Anda tidak perlu terhubung dengan catu daya.",
                "tr_subtitle1": "PC ini memiliki keseimbangan kekuatan dan performa yang sempurna untuk membantu Anda menyelesaikan lebih banyak hal dengan lebih cepat. Kombinasi prosesor canggih yang diakselerasi oleh memori yang mumpuni dan Wi-Fi secepat kilat akan memperlancar tugas harian Anda.",
                "tr_subtitle2": "PC ini memiliki keseimbangan daya dan portabilitas yang sempurna. Mulai dari prosesor yang dirancang untuk desain tipis dan ringan, serta fitur Wi&#8209;Fi yang memungkinkan Anda untuk selalu terhubung, dan memori yang tepat untuk multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Diciptakan untuk melakukan lebih banyak hal",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Koneksi terbaik di kelasnya",
                    "tr_title1": "Terhubung ke hal yang penting",
                    "tr_title2": "Kemajuan besar dalam Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Masa pakai baterai lebih lama",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Beralih antar program layaknya seorang profesional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mendukung VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Saat VR menjadi nyata",
                "tr_subtitle": "Apa saja yang diperlukan untuk menciptakan dunia yang benar&#8209;benar virtual? Anda memerlukan koordinasi antara prosesor, kartu&nbsp;grafis, dan memori PC.",
                "titleArray": [
                  {
                    "tr_title": "Mendukung dunia virtual",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Detail yang nyata",
                    "tr_title1": "Nikmati pengalaman imersif",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Nyaman di mata",
      "tr_sub_header": "Cahaya biru dapat menyebabkan ketidaknyamanan visual dan mengganggu siklus tidur Anda. OLED Samsung yang diuji Layar Eye Care SGS membantu bukan dengan mengubah warna, tetapi dengan mengurangi panjang gelombang cahaya biru yang berpotensi membahayakan, memberikan kenyamanan pada mata dan mengurangi kelelahan mata.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Cahaya Biru yang Tidak Terlalu Berbahaya"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra dalam segala hal",
      "tr_sub_header": "Desain OLED yang ultraringan dan ultratipis memaksimalkan kualitas gambar sekaligus meminimalkan bobot."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Hitam Sejati",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Konvensional</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Hiburan tanpa batas",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk streaming:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat melakukan streaming dalam HD."
                  },
                  {
                    "tr_title": "Layar untuk gambar yang cerah dan indah."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang dapat memberikan waktu muat dan buffering yang lebih singkat."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Penelusuran web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENELUSURAN WEB",
                "tr_subtitle": "Dunia internet jadi&nbsp;lebih&nbsp;mudah",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk penelusuran web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat memuat halaman yang sarat media dengan cepat."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat dan andal."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obrolan video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "OBROLAN VIDEO",
                "tr_subtitle": "Koneksi yang sangat&nbsp;jernih",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk obrolan video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat merekam dan membagikan gambar di saat yang bersamaan."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Webcam yang akan membantu Anda terlihat jelas di layar."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking berkecepatan tinggi",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING BERKECEPATAN TINGGI",
                "tr_subtitle": "Lakukan lebih banyak hal tanpa jadi lambat",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menjalankan beberapa program sekaligus."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Memori yang dengan lancar beralih antar aplikasi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Obrolan video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "OBROLAN VIDEO",
                "tr_subtitle": "Koneksi yang sangat&nbsp;jernih",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk obrolan video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat merekam dan membagikan gambar di saat yang bersamaan."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Webcam yang akan membantu Anda terlihat jelas di layar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gaming kasual",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GAMING KASUAL",
                "tr_subtitle": "Waktunya bermain",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk gaming ringan:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang mendukung gameplay lancar."
                  },
                  {
                    "tr_title": "Memori untuk melakukan obrolan suara dan streaming di saat yang bersamaan."
                  },
                  {
                    "tr_title": "Grafis yang mendukung gambar menakjubkan."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pengeditan foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENGEDITAN FOTO",
                "tr_subtitle": "Foto yang sempurna",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pengeditan foto:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Prosesor dengan kecanggihan untuk mengedit kelompok gambar besar."
                  },
                  {
                    "tr_title": "Memori yang mempercepat perangkat lunak pengeditan Anda."
                  },
                  {
                    "tr_title": "Teknologi Thunderbolt™ 4 yang memungkinkan Anda mentransfer file dengan cepat."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking berkecepatan tinggi",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING BERKECEPATAN TINGGI",
                "tr_subtitle": "Lakukan lebih banyak hal tanpa jadi lambat",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menjalankan beberapa program sekaligus."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang cepat, kuat, dan andal."
                  },
                  {
                    "tr_title": "Memori yang dengan lancar beralih antar aplikasi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kolaborasi real-time",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "KOLABORASI REAL-TIME",
                "tr_subtitle": "Bekerja dari mana saja",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk kolaborasi online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Prosesor untuk mendukung perangkat lunak kantor."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang dapat diandalkan."
                  },
                  {
                    "tr_title": "Webcam yang merekam dalam HD yang tajam."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pengeditan video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENGEDITAN VIDEO",
                "tr_subtitle": "Buat keajaiban film",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pengeditan video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menangani file besar."
                  },
                  {
                    "tr_title": "Memori yang membuat perangkat lunak pengeditan jadi cepat dan responsif."
                  },
                  {
                    "tr_title": "Grafis yang akan memangkas waktu rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pengeditan foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENGEDITAN FOTO",
                "tr_subtitle": "Foto yang sempurna",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pengeditan foto:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Prosesor dengan kecanggihan untuk mengedit kelompok gambar besar."
                  },
                  {
                    "tr_title": "Memori yang mempercepat perangkat lunak pengeditan Anda."
                  },
                  {
                    "tr_title": "Teknologi Thunderbolt™ 4 yang memungkinkan Anda mentransfer file dengan cepat."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Pengeditan video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PENGEDITAN VIDEO",
                "tr_subtitle": "Buat keajaiban film",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pengeditan video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menangani file besar."
                  },
                  {
                    "tr_title": "Memori yang membuat perangkat lunak pengeditan jadi cepat dan responsif."
                  },
                  {
                    "tr_title": "Grafis yang akan memangkas waktu rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pemodelan 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PEMODELAN 3D",
                "tr_subtitle": "Kreasi tingkat&nbsp;lanjut",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk pemodelan 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Prosesor yang dapat menangani perangkat lunak kreatif tingkat lanjut."
                  },
                  {
                    "tr_title": "Memori yang dapat dengan cepat membuka file besar."
                  },
                  {
                    "tr_title": "Grafis untuk pembuatan dan pengeditan gambar yang canggih."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kolaborasi real-time",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "KOLABORASI REAL-TIME",
                "tr_subtitle": "Bekerja dari mana saja",
                "tr_subtitle1": "Berikut yang kami temukan pada PC ini yang sangat cocok untuk kolaborasi online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Prosesor untuk mendukung perangkat lunak kantor."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi yang dapat diandalkan."
                  },
                  {
                    "tr_title": "Webcam yang merekam dalam HD yang tajam."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplikasi Intel® Unison™",
    "tr_title": "Optimalkan dunia yang saling terhubung",
    "tr_subtitle": "Transfer file dan foto tanpa kendala sambil mengelola notifikasi ponsel, panggilan, dan pesan teks dari PC Anda.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFER FILE DAN FOTO",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "BUAT DAN TERIMA PANGGILAN",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "KIRIM DAN TERIMA PESAN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "KELOLA NOTIFIKASI TELEPON",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "OPTIMALKAN DUNIA YANG SALING TERHUBUNG",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}