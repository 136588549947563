const Library_ChromeBook_mobile_es = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Interminables programas y aplicaciones",
    "tr_rethinkStorage": "Replantéate el almacenamiento",
    "tr_alwaysOnSecurity": "Seguridad integrada",
    "tr_extendedBatteryLife": "Mayor autonomía<sup>1</sup>",
    "tr_viewMore": "Ver más",
    "tr_processorTitle": "PROCESADOR",
    "tr_processorTitleCamel": "Procesador",
    "tr_rethinkStorageTitle": "REPLANTÉATE EL ALMACENAMIENTO",
    "tr_alwaysOnSecurityTitle": "SEGURIDAD INTEGRADA",
    "tr_extendedBatteryLifeTitle": "MAYOR AUTONOMÍA<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "INTERMINABLES PROGRAMAS <br/>Y&nbsp;APLICACIONES",
    "tr_extendedBatteryLifeDescription": "[XX] horas",
    "tr_rethinkStorageDescriptionGB": "[XX]&nbsp;GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "SO",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD de [convertedSSD] GB",
    "tr_textTBSSD": "SSD de [convertedSSD] TB",
    "tr_textGBHDD": "HDD de [convertedHDD] GB",
    "tr_textTBHDD": "HDD de [convertedHDD] TB",
    "tr_graphicTitle": "GRÁFICOS",
    "tr_storageTitle": "ALMACENA&shy;MIENTO",
    "tr_displayTitle": "PANTALLA",
    "tr_batteryTitle": "BATERÍA",
    "tr_memoryTitle": "MEMORIA",
    "tr_priceTitle": "PRECIO"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Replantéate el almacenamiento",
      "tr_title": "Un enfoque innovador del almacenamiento",
      "tr_subtitle": "Chromebook* combina el almacenamiento de tu ordenador con el recurso casi ilimitado de Google* Drive en la nube. Estas son las&nbsp;ventajas:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Se realiza una copia de seguridad de todos tus archivos en la nube. Recuperarlos es tan sencillo como conectarte a tu cuenta de Google*.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Trabaja en línea o sin conexión con [XX]&nbsp;GB de almacenamiento en&nbsp;el&nbsp;dispositivo.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Trabaja en línea o sin conexión con [XX]&nbsp;TB de almacenamiento en&nbsp;el&nbsp;dispositivo.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Seguridad integrada",
      "tr_title": "Protección sin&nbsp;esfuerzo<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* actualiza automáticamente todas sus características de seguridad, por lo que podrás navegar por Internet y hacer clic en los enlaces con la confianza de que tu dispositivo está totalmente protegido."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Mayor autonomía<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Haz más y&nbsp;cárgalo&nbsp;menos<sup class='sup-text'>1</sup>",
          "tr_subtitle": "El diseño optimizado y energéticamente eficiente de un Chromebook* te permite pasar horas usándolo sin necesidad de&nbsp;conectarlo."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Carga menos, <br/>recarga más rápido.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "El diseño optimizado y energéticamente eficiente de un Chromebook* cumple con los exigentes requisitos de autonomía de la plataforma Intel® Evo™ para permitirte cargar con más rapidez y pasar más tiempo sin recargarlo."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Autonomía que te mantiene en movimiento.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Gracias a las baterías de larga duración y carga rápida de los Chromebooks* finos y ligeros, ya no tienes que preocuparte de dónde estará el enchufe más cercano."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Interminables programas y aplicaciones",
      "tr_title": "Cada día nuevas aplicaciones",
      "tr_subtitle": "Los usuarios de Chromebook* disponen de una enorme selección de aplicaciones y programas que se van ampliando continuamente.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Descarga de aplicaciones para Chrome* y Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Todas las aplicaciones y los datos se sincronizan a través de tu cuenta de Google*."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Conéctate, aprende, crea y juega de diversas maneras."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Amplía tus expectativas",
                "tr_subtitle": "Disfruta sin esfuerzo de tus programas y películas favoritos en tu Chromebook* con un procesador Intel® Core™ i3 de 10<sup>a</sup> generación.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado específicamente para obtener un rendimiento excepcional en cualquier lugar.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Equipado con un procesador Intel® Core™ i5 de 10<sup>a</sup> generación"
                  },
                  {
                    "tr_badgeTitle": "Potente, incluso desenchufado"
                  },
                  {
                    "tr_badgeTitle": "Carga rápida: 30 minutos para disponer de 4 horas de batería<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "La mejor conectividad de su clase con Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Productividad real. Rendimiento real.",
                "tr_subtitle": "Habla en el chat de vídeo, envía un correo electrónico y elabora una presentación mientras agilizas tu productividad en un Chromebook* con un procesador Intel® Core™ i5 de 10<sup>a</sup> generación.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado específicamente para obtener un rendimiento excepcional en cualquier lugar.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Equipado con un procesador Intel® Core™ i7 de 10<sup>a</sup> generación"
                  },
                  {
                    "tr_badgeTitle": "Potente, incluso desenchufado"
                  },
                  {
                    "tr_badgeTitle": "Carga rápida: 30 minutos para disponer de 4 horas de batería<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "La mejor conectividad de su clase con Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Porque el rendimiento importa",
                "tr_subtitle": "Edita tus vídeos con facilidad, carga y comparte recuerdos de forma instantánea en un Chromebook* con un procesador Intel® Core™ i7 de 10<sup>a</sup> generación.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento rápido con capacidad de respuesta",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Da potencia a tu pasión y productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesadores Intel®&nbsp;Core™&nbsp;i5 de&nbsp;8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Da potencia a tu pasión y productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Da potencia a tu pasión y productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Da potencia a tu pasión y productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento rápido con capacidad de respuesta",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ m3 de 8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alto rendimiento para las tareas que&nbsp;realizas&nbsp;actualmente",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 7<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Excelente rendimiento para las tareas que realizas actualmente",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 7<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento para las tareas que realizas actualmente",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ m3 de 7<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento mejorado para todas tus necesidades",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 6<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento avanzado para todas tus necesidades",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 6<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "El rendimiento adecuado a tus necesidades",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesadores Intel® Core™ m3 de 6<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Rendimiento revolucionario en los Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;11<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento hasta <br/><span class='big-blue-text'>2,4 veces  </span><span class='subt-text'>mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación por Internet hasta <br/><span class='big-blue-text'>3,2 veces  </span><span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeo hasta <br/><span class='big-blue-text'>2,1 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Imagina lo que puedes lograr con Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento hasta <br/><span class='big-blue-text'>2,7 veces  </span><span class='subt-text'>mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación por Internet hasta <br/><span class='big-blue-text'>3,3 veces  </span><span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeo hasta <br/><span class='big-blue-text'>2,8 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado para el rendimiento móvil",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento revolucionario con procesadores Intel® Core™ de 11<sup>a</sup> generación"
                  },
                  {
                    "tr_badgeTitle": "Se activa en < 1 segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi 3 veces más rápido con Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de larga duración y carga rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "El mejor procesador del mundo para Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento hasta <br/><span class='big-blue-text'>2,9 veces  </span><span class='subt-text'>mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación por Internet hasta <br/><span class='big-blue-text'>3,5 veces  </span><span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeo hasta <br/><span class='big-blue-text'>2,9 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado para el rendimiento móvil",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento revolucionario con procesadores Intel® Core™ de 11<sup>a</sup> generación"
                  },
                  {
                    "tr_badgeTitle": "Se activa en < 1 segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi 3 veces más rápido con Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de larga duración y carga rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento veloz para&nbsp;el&nbsp;día&nbsp;a&nbsp;día",
                "tr_subtitle": "Obtén un rendimiento excelente de todos tus programas y aplicaciones favoritos con la potencia de un procesador Intel® Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento veloz para&nbsp;el&nbsp;día&nbsp;a&nbsp;día",
                "tr_subtitle": "Obtén un rendimiento excelente de todos tus programas y aplicaciones favoritos con la potencia de un procesador Intel® Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mejora tu productividad diaria",
                "tr_subtitle": "Ya sea cuando inicies aplicaciones o cuando abras proyectos, con los procesadores Intel® Celeron® conseguirás hacer más cosas.",
                "tr_gen_core_title": "Procesador Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento y valor.",
                "tr_subtitle": "Para disfrutar de entretenimiento, transmisión de vídeos y productividad excelentes. Disfruta de películas y vídeos en Internet con detalles asombrosos. Pasa más tiempo haciendo cosas y menos esperando.",
                "tr_gen_core_title": "Procesador Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento veloz para&nbsp;el&nbsp;día&nbsp;a&nbsp;día",
                "tr_subtitle": "Obtén un rendimiento excelente de todos tus programas y aplicaciones favoritos con la potencia de un procesador Intel® Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento y valor.",
                "tr_subtitle": "Mayor productividad: Un rendimiento mejorado con la potencia que necesitas para los días más exigentes. Navegación por Internet simplificada: Carga rápidamente páginas web con contenido multimedia para explorar más y esperar menos.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento y valor.",
                "tr_subtitle": "Mantente al día de tu mundo digital con el rendimiento necesario para acceder a archivos desde Internet. Haz multitarea con sencillez cambiando fácilmente entre programas y consigue hacer más en menos tiempo.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rendimiento y valor.",
                "tr_subtitle": "Rendimiento para mejorar el trabajo y disfrutar más del entretenimiento. Disfruta de películas y vídeos en Internet con detalles asombrosos. Pasa más tiempo haciendo cosas y menos esperando.",
                "tr_gen_core_title": "Procesador Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Rendimiento fiable donde más lo necesitas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;12<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>con facilidad"
                  },
                  {
                    "tr_badgeTitle": "Desenfoque de fondo<br/>integrado"
                  },
                  {
                    "tr_badgeTitle": "Navegación web<br/>adaptable"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Un impulso de rendimiento donde más lo necesitas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>potente"
                  },
                  {
                    "tr_badgeTitle": "Supresión automática<br/>del ruido"
                  },
                  {
                    "tr_badgeTitle": "Transmisión<br/>en HD"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "El rendimiento móvil ha evolucionado.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento superior donde más lo necesitas, con procesadores Intel® Core™ de 12<sup>a</sup> generación<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Se activa en < 1 segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi 3 veces más rápido con Intel®&nbsp;Wi&#8209;Fi&nbsp;6/6E&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de larga duración y carga rápida"
                  },
                  {
                    "tr_badgeTitle": "Colaboración y conectividad mejoradas"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Rendimiento superior donde más lo necesitas.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>extrema"
                  },
                  {
                    "tr_badgeTitle": "Supresión de ruido<br/>y desenfoque de fondo<br/>integrados"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 8K"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "El rendimiento móvil ha evolucionado.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rendimiento superior donde más lo necesitas, con procesadores Intel® Core™ de 12<sup>a</sup> generación<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Se activa en < 1 segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi 3 veces más rápido con Intel®&nbsp;Wi&#8209;Fi&nbsp;6/6E&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de larga duración y carga rápida"
                  },
                  {
                    "tr_badgeTitle": "Colaboración y conectividad mejoradas"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 13<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 13<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>mejorada"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 13<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento muy superior.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 13<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>extrema"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Procesador Intel®",
                "tr_subtitle": "El rendimiento que necesitas. La asequibilidad que buscas.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i3 de 13<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>fácil"
                  },
                  {
                    "tr_badgeTitle": "Transmisión<br/>fluida"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>fácil"
                  },
                  {
                    "tr_badgeTitle": "Transmisión<br/>fluida"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>mejorada"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento muy superior.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>extrema"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Da vida a tus pasiones con un rendimiento superior",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Edición rápida<br/>de fotos y vídeos"
                  },
                  {
                    "tr_badgeTitle": "Mejores capacidades<br/>sin conexión"
                  },
                  {
                    "tr_badgeTitle": "Juega<br/>sin preocupaciones<br/>con una gran autonomía"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>fácil"
                  },
                  {
                    "tr_badgeTitle": "Transmisión<br/>fluida"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>mejorada"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que quieras con un rendimiento muy superior.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>extrema"
                  },
                  {
                    "tr_badgeTitle": "Transmisión fluida<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Accede a nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa<br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeos con IA<br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Accede a nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa<br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeos con IA<br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "La evolución<br/>de Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño fino y ligero que va más allá del rendimiento<br/><span class='marklist'>y</span> se conecta fácilmente con tu teléfono<sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápidamente con una gran autonomía"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Accede a nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa<br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de vídeos con IA<br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Información legal",
    "tr_paragraph_array": [
      "¹Hasta 10 horas de autonomía de la batería con una carga completa según pruebas internas de Google*.",
      "²Ningún producto o componente es completamente seguro. Consulte al fabricante del sistema o al distribuidor minorista, o bien puede encontrar más información en intel.com.",
      "³Según las mediciones de análisis de rendimiento del sector, pruebas de la guía de uso representativa y características exclusivas del procesador Intel® Core™ i7-1165G7. Para cargas de trabajo y configuraciones, visite www.intel.com/PerformanceIndex. Puede que los resultados varíen.",
      "⁴Según las mediciones de multitarea en Google Sheets.",
      "⁵Según las mediciones de Speedometer 2.0.",
      "⁶Según las mediciones de exportación de vídeo en KineMaster.",
      "Para las notas a pie de página 4-6, todas las pruebas se han completado en un procesador Intel® Core™ i3-1115G4 comparado con un procesador Intel®&nbsp;Celeron® N4020. Visite www.intel.com/PerformanceIndex para conocer más detalles sobre la carga de trabajo y la configuración. El rendimiento varía según el uso, la configuración y otros factores. Más información en www.intel.com/PerformanceIndex. Los resultados de rendimiento se basan en pruebas realizadas en las fechas indicadas en las configuraciones y puede que no reflejen todas las actualizaciones disponibles públicamente.",
      "⁷Tiempo necesario para agotar una batería desde el 100 % hasta niveles críticos mientras se ejecutan flujos de trabajo habituales en un entorno realista. Los resultados pueden variar. Para obtener información más detallada, visite intel.com/evo.",
      "⁸Los resultados pueden variar. Para obtener información más detallada, visite intel.com/evo.",
      "⁹Casi 3 veces más rápidas: 802.11ax 2 x 2 de 160 MHz permite velocidades de datos teóricas máximas de 2402 Mbps, aproximadamente 3 veces más rápido que la Wi‑Fi 802.11ac estándar 2 x 2 de 80 MHz (867 Mbps), tal y como se documenta en las especificaciones del estándar inalámbrico IEEE 802.11*, y requiere el uso de rúteres de red inalámbrica 802.11ax configurados de forma similar.",
      "¹⁰Carga obtenida desde el nivel de desconexión predeterminado por el OEM. Los resultados pueden variar. Para obtener información más detallada, visite intel.com/evo.",
      "¹¹En comparación con la generación anterior de procesadores.",
      "¹²Según medición de las características únicas de los diseños Intel® Evo™, como las CPU Intel® Core™ de alto rendimiento, los componentes de gama alta, la compatibilidad sin igual y las soluciones más recientes de conectividad en diseños elegantes e innovadores. Todos los diseños con la marca Intel® Evo™ deben cumplir exigentes requisitos en experiencias clave para usuarios móviles como la capacidad de respuesta y la autonomía. El rendimiento concreto de cada dispositivo puede variar. Más información en www.intel.com/PerformanceIndex (plataformas).",
      "¹³La solución Intel® Unison™ está disponible actualmente solo en determinados diseños Intel® Evo™ en ordenadores con Windows*, y solo se empareja con teléfonos Android* o iOS*. Todos los dispositivos deben ejecutar una versión del sistema operativo compatible. El rendimiento de cada dispositivo Intel® Evo™ puede variar. Más información en intel.com/Performance-Evo.",
      "Las características y ventajas de las tecnologías Intel® dependen de la configuración del sistema, y podrían requerir la activación de hardware, software o servicios. El rendimiento variará en función de la configuración del sistema. Ningún sistema informático es absolutamente seguro. Consulte al fabricante del sistema o al distribuidor minorista, o bien puede encontrar más información en intel.com.",
      "*Otros nombres comerciales y marcas pueden ser reclamados como propiedad de terceros.",
      "Intel no se responsabiliza de los errores en los precios.",
      "© 2022 Intel Corporation. Celeron, Intel, el logotipo Intel, Intel Core y Pentium son marcas comerciales de Intel Corporation o de sus filiales."
    ]
  }
}