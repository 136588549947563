const Library_ChromeBook_mobile_ja = {
  "uiText": {
    "tr_endlessProgramsAndApps": "豊富なプログラムとア&#xfeff;プ&#xfeff;リ",
    "tr_rethinkStorage": "ストレージを再考",
    "tr_alwaysOnSecurity": "内蔵セキュリティー機能",
    "tr_extendedBatteryLife": "向上したバッテリー持続時間<sup>1</sup>",
    "tr_viewMore": "詳細情報を表示",
    "tr_processorTitle": "プロセッサー",
    "tr_processorTitleCamel": "プロセッサー",
    "tr_rethinkStorageTitle": "ストレージを再考",
    "tr_alwaysOnSecurityTitle": "内蔵セキュリティー機能",
    "tr_extendedBatteryLifeTitle": "向上したバッテリー持続時間<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "豊富なプログラムとア&#xfeff;プ&#xfeff;リ",
    "tr_extendedBatteryLifeDescription": "[XX] 時間",
    "tr_rethinkStorageDescriptionGB": "[XX]GB",
    "tr_rethinkStorageDescriptionTB": "[XX]TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD]GB SSD + [convertedHDD]GB HDD",
    "tr_textGBTB": "[convertedSSD]GB SSD + [convertedHDD]TB HDD",
    "tr_textTBGB": "[convertedSSD]TB SSD + [convertedHDD]GB HDD",
    "tr_textTBTB": "[convertedSSD]TB SSD + [convertedHDD]TB HDD",
    "tr_textGBSSD": "[convertedSSD]GB SSD",
    "tr_textTBSSD": "[convertedSSD]TB SSD",
    "tr_textGBHDD": "[convertedHDD]GB HDD",
    "tr_textTBHDD": "[convertedHDD]TB HDD",
    "tr_graphicTitle": "グラフィックス",
    "tr_storageTitle": "ストレージ",
    "tr_displayTitle": "ディスプレイ",
    "tr_batteryTitle": "バッテリー",
    "tr_memoryTitle": "メモリー",
    "tr_priceTitle": "価格"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "ストレージを再考",
      "tr_title": "ストレージに対する革新的なアプローチ",
      "tr_subtitle": "Chromebook* は、PC 上のストレージと、クラウド上の Google* Drive のほぼ無制限のリソースを組み合わせてい﻿ま﻿す﻿。次のようなメリットがあります。",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "すべてのファイルがクラウドに自動的にバックアップさ&#65279;れ&#65279;、Google* アカウントにログインするだけで簡単に&#65279;復&#65279;元&#65279;で&#65279;き&#65279;ま&#65279;す&#65279;。",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "[XX]GB のデバイス容量により、オンラインでもオフラインでも作業できます。",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "[XX]TB のデバイス容量により、オンラインでもオフラインでも作業できます。",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "内蔵セキュリティー機能",
      "tr_title": "らくらく保護<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* ではすべてのセキュリティー機能が自動的にアップデートされるため、デバイスの保護性能を心配することなく安心してウェブサーフィンやリンクのクリックを行うことができます。"
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "向上したバッテリー持続時間<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "短い充電時間で長時間使用<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Chromebook* は合理的でエネルギー効率に優れた設計によ&#65279;っ&#65279;て、電源に接続せずに何時間も使用できます。"
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "充電回数を減らし、<br/>より高速に充電。<sup class='sup-text'>10</sup>",
          "tr_subtitle": "流線形でエネルギー効率に優れたデザインの Chromebook* は、インテル® Evo™ プラットフォームの厳格なバッテリー要件を満たし、高速充電が可能な上に再充電をしなくても長時間バッテリー駆動します。"
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "長時間持続バッテリーだ&#xfeff;か&#xfeff;ら&#xfeff;移&#xfeff;動&#xfeff;も&#xfeff;安&#xfeff;心<sup class='sup-text'>10</sup>",
          "tr_subtitle": "高速充電が可能で長時間持続するバッテリーを持つ薄型軽量の Chromebooks* なら、最寄りのコンセントを探す必要はもうありません。"
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "豊富なプログラムとア&#xfeff;プ&#xfeff;リ",
      "tr_title": "毎日たくさんのアプリが追&#65279;加",
      "tr_subtitle": "Chromebook* ユーザーは、常に追加され続ける膨大なア&#65279;プ&#65279;リ&#65279;やプログラムを利用できます。",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Chrome* および Android* アプリをダウンロードし&#65279;ま&#65279;す&#65279;。"
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "すべてのアプリとデータは、Google* アカウントを使用し&#65279;て&#65279;同期されます。"
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "つながる、学ぶ、作成する、遊ぶ、やり方は無数にありま&#65279;す&#65279;。"
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "期待を超えたパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "第 10 世代インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載 Chromebook*&nbsp;で大&#65279;好&#65279;き&#65279;な&#65279;番&#65279;組&#65279;や&#65279;映&#65279;画&#65279;を&#65279;気&#65279;軽&#65279;に観&#65279;ま&#65279;し&#65279;ょ&#65279;う。",
                "tr_gen_core_title": "第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "場所を選ばず優れたパフォーマンスを実&#65279;現するために設計",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 10 世代インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "tr_badgeTitle": "電源に接続されていなくてもパワフル"
                  },
                  {
                    "tr_badgeTitle": "高速充電: 30 分の充電で 4 時間のバ&#65279;ッ&#65279;テ&#65279;リ&#65279;ー&#65279;稼&#65279;動&#65279;<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi‑Fi 6 (GIG+) によるクラス最高レベルの接続性<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "本格的な仕事 リアルなゲームプレイ",
                "tr_subtitle": "第 10 世代インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載 Chromebook*&nbsp;な&#65279;ら、生産性を高めながらビデオチャット、メ&#65279;ー&#65279;ル、プレゼンテーションの作成がで&#65279;き&#65279;ま&#65279;す。",
                "tr_gen_core_title": "第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "場所を選ばず優れたパフォーマンスを実&#65279;現するために設計",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 10 世代インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載"
                  },
                  {
                    "tr_badgeTitle": "電源に接続されていなくてもパワフル"
                  },
                  {
                    "tr_badgeTitle": "高速充電: 30 分の充電で 4 時間のバ&#65279;ッ&#65279;テ&#65279;リ&#65279;ー&#65279;稼&#65279;動&#65279;<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi‑Fi 6 (GIG+) によるクラス最高レベルの接続性<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "パフォーマンスが重&#65279;要",
                "tr_subtitle": "第 10 世代インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー搭載 Chromebook*&nbsp;な&#65279;ら簡単にビデオを編集し、す&#65279;ぐ&#65279;ア&#65279;ッ&#65279;プ&#65279;ロ&#65279;ー&#65279;ドし&#65279;て&#65279;思い出をシェアできます。",
                "tr_gen_core_title": "第 10 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "素早く、反応のいいパフォーマンス",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "情熱と生産性の動力",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "情熱と生産性の動力",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "情熱と生産性の動力",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "情熱と生産性の動力",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "素早く、反応のいいパフォーマンス",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 世代インテル® Core™ m3 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "今日の作業に対応するハ&#65279;イ&#65279;パ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 世代インテル® Core™ i5 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "今日の作業に対応するプ&#65279;レ&#65279;ミ&#65279;ア&#65279;ム&#65279;・パ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 世代インテル® Core™ i7 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "今の作業に対応するパフォーマンス",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 世代インテル® Core™ m3 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "必要なことができる高いパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 世代インテル® Core™ i5 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "必要なことができる高度なパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 世代インテル® Core™ i7 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "必要なことができるパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス&#65279;",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 世代インテル® Core™ m3 プロセッサー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Chromebook* で&#xfeff;パ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マ&#xfeff;ン&#xfeff;スの境界を超える",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.4 倍 </span><span class='subt-text'>パフォーマンスが向上<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>3.2 倍 </span><span class='subt-text'>ウェブ・ブ&#xfeff;ラ&#xfeff;ウ&#xfeff;ジ&#xfeff;ン&#xfeff;グが&#xfeff;高&#xfeff;速<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.1 倍 </span><span class='subt-text'>ビデオ編集が高速<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Chromebook* で想&#65279;像&#65279;力を創造力に",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.7 倍 </span><span class='subt-text'>パフォーマンスが向上<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>3.3 倍 </span><span class='subt-text'>ウェブ・ブ&#xfeff;ラ&#xfeff;ウ&#xfeff;ジ&#xfeff;ン&#xfeff;グが&#xfeff;高&#xfeff;速<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.8 倍 </span><span class='subt-text'>ビデオ編集が高速<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "優れたモバイル・パ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 11 世代インテル® Core™ プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー&#65279;・ファミリー搭載で限&#65279;界&#65279;を&#65279;超&#65279;え&#65279;るパフォーマンス"
                  },
                  {
                    "tr_badgeTitle": "1 秒に満たない素早い起動<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi-Fi 6 (Gig+) でインターネットが 3 倍近く高速化<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "高速充電、長時間持続バッテリー"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "インテル史&#65279;上&#65279;最&#65279;高&#65279;ク&#65279;ラ&#65279;ス&#65279;の Chromebook* 向けプロセッサー<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.9&nbsp;倍 </span><span class='subt-text'>パ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;スが向上<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>3.5&nbsp;倍 </span><span class='subt-text'>ウェブ・ブ&#65279;ラ&#65279;ウ&#65279;ジ&#65279;ン&#65279;グが&#xfeff;高&#xfeff;速<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "最大 <br/><span class='big-blue-text'>2.9&nbsp;倍 </span><span class='subt-text'>ビデオ編集が高速<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "優れたモバイル・パ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 11 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 11 世代インテル® Core™ プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー&#65279;・ファミリー搭載で限&#65279;界&#65279;を&#65279;超&#65279;え&#65279;るパフォーマンス"
                  },
                  {
                    "tr_badgeTitle": "1 秒に満たない素早い起動<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi-Fi 6 (Gig+) でインターネットが 3 倍近く高速化<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "高速充電、長時間持続バッテリー"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "高速、日常的なパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "インテル® Pentium® プロセッサーのパワーで、お気に入りのプログラムやアプリすべてから優れたパフォーマンスを引き出します。",
                "tr_gen_core_title": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "高速、日常的なパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "インテル® Pentium® プロセッサーのパワーで、お気に入りのプログラムやアプリすべてから優れたパフォーマンスを引き出します。",
                "tr_gen_core_title": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "毎日の生産性を向上",
                "tr_subtitle": "アプリの起動からプロジェクトを開く操作まで、イ&#65279;ン&#65279;テ&#65279;ル&#65279;®&nbsp;Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ーならより多くの&#65279;こ&#65279;と&#65279;が&#65279;可&#65279;能&#65279;に&#65279;な&#65279;り&#65279;ま&#65279;す&#65279;。",
                "tr_gen_core_title": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "パフォーマンスと価値",
                "tr_subtitle": "素晴らしいエンターテインメント、ビデオ・ストリーミング、生産性を実現する設計。映画や Web ビデオを細部まできれいに表示可能。作業に費やす時間を増やし、待ち時間を減らす。",
                "tr_gen_core_title": "インテル® Pentium® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "高速、日常的なパ&#65279;フ&#65279;ォ&#65279;ー&#65279;マ&#65279;ン&#65279;ス",
                "tr_subtitle": "インテル® Pentium® プロセッサーのパワーで、お気に入りのプログラムやアプリすべてから優れたパフォーマンスを引き出します。",
                "tr_gen_core_title": "インテル® Pentium® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "パフォーマンスと価値",
                "tr_subtitle": "強化された生産性 - 最も負荷の高い日を乗り切るための高いパフォーマンス 効率的なウェブ・ブラウジング - メディアリッチなウェブページを迅速に読み込み、少ない待ち時間で、より多くを閲覧",
                "tr_gen_core_title": "インテル® Pentium® Gold プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "パフォーマンスと価値",
                "tr_subtitle": "ウェブ経由でファイルへアクセスするパフォーマンスによりデジタル世界の最新情報を把握します。プログラムを簡単に切り替えられるため、マルチタスクが容易になり、より多くを短時間でこなすことができます。",
                "tr_gen_core_title": "インテル® Pentium® Silver プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "パフォーマンスと価値",
                "tr_subtitle": "仕事とエンターテインメントの効率性を高めるパフォーマンス 映画や Web ビデオを細部まできれいに表示可能 作業に費やす時間を増やし、待ち時間を減らす。",
                "tr_gen_core_title": "インテル® Celeron® プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "最も必要な場面で頼れるパフォーマンスを発揮",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "余裕のマルチタスク"
                  },
                  {
                    "tr_badgeTitle": "背景ぼかし機能を内蔵"
                  },
                  {
                    "tr_badgeTitle": "ウェブサイトの<br/>閲覧も快適に"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "最も必要な場面でさらなるパフォーマンスを発揮。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "強力な<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "自動ノイズ<br/>抑制機能"
                  },
                  {
                    "tr_badgeTitle": "HD <br/>ストリーミング"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "モバイルのパフォーマンスの進化",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 12 世代インテル® Core™ プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;・ファミリーを搭&#xfeff;載&#xfeff;し&#xfeff;、最も必要な場面で卓越したパ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マ&#xfeff;ン&#xfeff;スを発揮<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "1 秒に満たない素早い起動<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi‑Fi 6/6E (Gig+) でイ&#xfeff;ン&#xfeff;タ&#xfeff;ー&#xfeff;ネ&#xfeff;ッ&#xfeff;トが 3 倍近く高速化<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "高速充電、長時間持続バッテリー"
                  },
                  {
                    "tr_badgeTitle": "コレボレーションや接続の強化"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "最も必要な場面で卓越したパフォーマンスを発揮。<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "究極の<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "ノイズ<br/>抑制機能と<br/>背景ぼかし機能を内蔵"
                  },
                  {
                    "tr_badgeTitle": "滑らかな <br/>8K ストリーミング"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "モバイルのパフォーマンスの進化",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 世代イ&#65279;ン&#65279;テ&#65279;ル&#65279;® Core™&nbsp;i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 12 世代インテル® Core™ プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー&#xfeff;・ファミリーを搭&#xfeff;載&#xfeff;し&#xfeff;、最も必要な場面で卓越したパ&#xfeff;フ&#xfeff;ォ&#xfeff;ー&#xfeff;マ&#xfeff;ン&#xfeff;スを発揮<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "1 秒に満たない素早い起動<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "インテル® Wi‑Fi 6/6E (Gig+) でイ&#xfeff;ン&#xfeff;タ&#xfeff;ー&#xfeff;ネ&#xfeff;ッ&#xfeff;トが 3 倍近く高速化<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "高速充電、長時間持続バッテリー"
                  },
                  {
                    "tr_badgeTitle": "コレボレーションや接続の強化"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 世代インテル® Core™ i5 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "優れたパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 世代インテル® Core™ i5 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "強化された<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>4K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "パワフルな<br/>制作機能"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 世代インテル® Core™ i7 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "圧巻のパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 世代インテル® Core™ i7 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "究極の<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>8K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "高度な<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "インテル® プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "tr_subtitle": "必要なパフォーマンスを、 手頃な価格で。",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "卓越したパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 世代インテル® Core™ i3 プロセッサー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "簡単な<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "素早く<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "卓越したパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i3 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "簡単な<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "素早く<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "優れたパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i5 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "強化された<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>4K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "パワフルな<br/>制作機能"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "圧巻のパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i7 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "究極の<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>8K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "高度な<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "優れたパフォーマンスで情熱を形に",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ i9 プ&#65279;ロ&#65279;セ&#65279;ッ&#65279;サ&#65279;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "高速な写真と<br/>ビデオの編集"
                  },
                  {
                    "tr_badgeTitle": "オフライン機能の<br/>向上"
                  },
                  {
                    "tr_badgeTitle": "長いバッテリー持続時間により<br/>安心して<br/>ゲームプレイが楽しめる"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "卓越したパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;3 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "簡単な<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "素早く<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "優れたパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "強化された<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>4K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "パワフルな<br/>制作機能"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "圧巻のパフォーマンスで思いのままに",
                "tr_subtitle": "",
                "tr_gen_core_title": "イ&#xfeff;ン&#xfeff;テ&#xfeff;ル&#xfeff;®&nbsp;Core™&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "究極の<br/>マルチタスク処理"
                  },
                  {
                    "tr_badgeTitle": "スムーズな<br/>8K ストリーミング"
                  },
                  {
                    "tr_badgeTitle": "高度な<br/>創作作業"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "新たな AI&nbsp;体&#xfeff;験&#xfeff;を",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;5 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成 AI を<br/>最大 65% 高速化"
                  },
                  {
                    "tr_badgeTitle": "AI ビデオ編集を<br/>最大 2.3 倍高速化"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "新たな AI&nbsp;体&#xfeff;験&#xfeff;を",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;7 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成 AI を<br/>最大 65% 高速化"
                  },
                  {
                    "tr_badgeTitle": "AI ビデオ編集を<br/>最大 2.3 倍高速化"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks* は<br/>新次元へ",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "圧巻のパフォーマンスを発揮する薄型軽量のデザイン<br/> <span class='marklist'>+</span> 既存の携帯電話とシームレスに接続<sup>13</sup><br/> <span class='marklist'>+</span> 最適な Wi-Fi または 5G 接続をインテリジェントに選択<br/> <span class='marklist'>+</span> 高速充電と長時間持続するバッテリー"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "プロセッサー",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "新たな AI&nbsp;体&#xfeff;験&#xfeff;を",
                "tr_subtitle": "",
                "tr_gen_core_title": "インテル® Core™ Ultra&nbsp;9 プ&#xfeff;ロ&#xfeff;セ&#xfeff;ッ&#xfeff;サ&#xfeff;ー",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成 AI を<br/>最大 65% 高速化"
                  },
                  {
                    "tr_badgeTitle": "AI ビデオ編集を<br/>最大 2.3 倍高速化"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "法務情報",
    "tr_paragraph_array": [
      "¹最大 10 時間のバッテリー持続時間 (満充電時、Google* の社内テストに基づく)。",
      "² 絶対的なセキュリティーを提供できる製品やコンポーネントはありません。詳細については、各システムメーカーまたは販売店にお問い合わせいただくか、http://www.intel.co.jp を参照してください。",
      "³業界ベンチマーク、代表的な使用ガイドテスト、インテル® Core™ i7-1165G7 プロセッサー独自の機能による測定。ワークロードと構成については、www.intel.com/PerformanceIndex をご覧ください。実際の結果はハードウェアやソフトウェアの構成によって異なります。",
      "⁴Google スプレッドシートのマルチタスク処理による測定。",
      "⁵Speedometer 2.0 による測定。",
      "⁶KineMaster の動画エクスポートによる測定。",
      "脚注 4 ～ 6 については、すべてのテストは、インテル® Core™ i3-1115G4 プロセッサーとインテル® Celeron® N4020 プロセッサーを比較して行われました。ワークロードと構成の詳細については、www.intel.com/PerformanceIndex (英語) を参照してください。性能は、使用状況、構成、その他の要因によって異なります。詳細については、www.intel.com/PerformanceIndex (英語) を参照してください。パフォーマンス実績は構成情報に記載された日に実施したテストに基づいています。また、現在公開中のすべてのアップデートが適用されているとは限りません。",
      "⁷現実の環境で標準的なワークフローを実行し、バッテリーレベルが 100% からクリティカルなレベルになるまでの時間。結果は状況によって変わります。詳細については、intel.com/evo を参照してください。",
      "⁸結果は状況によって変わります。詳細については、intel.com/evo を参照してください。",
      "⁹ほぼ 3 倍高速: 802.11ax 2x2 160MHz では、理論上の最大データ転送速度である 2402Mbps を実現します。これは、IEEE 802.11* 無線規格で規定される標準的な 802.11ac 2x2 80MHz (867Mbps) Wi‑Fi の約 3 倍の速度で、同様に構成された 802.11ax ワイヤレス・ネットワーク・ルーターを使用する必要があります。",
      "¹⁰OEM デフォルトのシャットダウン・レベルで達成された充電。結果は状況によって変わります。詳細については、intel.com/evo を参照してください。",
      "¹¹前世代のプロセッサーと比較。",
      "¹²スタイリッシュで画期的なデザインに搭載された高性能インテル® Core™ CPU、プレミアム・コンポーネント、比類のない互換性、最新の接続ソリューションなど、インテル® Evo™ デザインの独自機能により測定されています。インテル® Evo™ プラットフォームのブランドを冠した設計では、応答性やバッテリー持続時間など、モバイルユーザー体験の主要な要求条件を満たす必要があります。個々のデバイスによってパフォーマンスは異なります。詳細については、www.intel.com/PerformanceIndex (Platforms) (英語) をご覧ください。",
      "¹³インテル® Unison™ ソリューションは、現在 Windows* ベースの PC において対象のインテル® Evo™ デザインでのみ提供されており、Android* または iOS* ベースの携帯電話とのみペアリングできます。すべてのデバイスは、対応する OS バージョンを実行している必要があります。個々のインテル® Evo™ デバイスによってパフォーマンスは異なります。詳細については、intel.com/Performance-Evo (英語) を参照してください。",
      "インテルのテクノロジーの機能と利点はシステム構成によって異なり、対応するハードウェアやソフトウェア、およびサービスの有効化が必要となる場合があります。実際の性能はシステム構成によって異なります。絶対的なセキュリティーを提供できるコンピューター・システムはありません。詳細については、各システムメーカーまたは販売店にお問い合わせいただくか、intel.com を参照してください。",
      "*その他の社名、製品名などは、一般に各社の表示、商標または登録商標です。",
      "インテルは、価格の誤りに関して一切責任を負いません。",
      "© 2022 Intel Corporation.Celeron、Intel、インテル、Intel ロゴ、Intel Core、Pentium は、アメリカ合衆国および / またはその他の国における Intel Corporation またはその子会社の商標です。"
    ]
  }
}